export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';


export interface LoadingState {
    loading: boolean;
}

interface StartLoading {
    type: typeof START_LOADING;
    payload: {
        loading: true;
    };
}

interface EndLoading {
    type: typeof END_LOADING;
    payload: {
        loading: false;
    };
}
export type PageLoadActionTypes =
    | StartLoading
    | EndLoading