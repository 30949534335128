/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import { IPagingListState, RootState } from "../../../store/banlist/types";
import { get } from "lodash";
import { IBanlistUserDetailState, IBanlistUserItem } from "../../../store/banlist/types/user";

type BanListUserState = IPagingListState<IBanlistUserItem>;

const selector = (key: keyof BanListUserState) => useSelector((state: RootState) => get(state.banlist.user, key));

const userSelectors = {
  state: () => useSelector((state: RootState) => state.banlist.user) as BanListUserState,
  params: () => selector('params') as BanListUserState['params'],
  userDetail: {
    state: () => useSelector((state: RootState) => state.banlist.userDetail) as IBanlistUserDetailState,
    blog: () => useSelector((state: RootState) => state.banlist.userDetail.blog) as IBanlistUserDetailState['blog'],
    blogParams: () => useSelector((state: RootState) => state.banlist.userDetail.blog.params) as IBanlistUserDetailState['blog']['params'],
    data: () => useSelector((state: RootState) => state.banlist.userDetail.data) as IBanlistUserDetailState['data'],
  },
};

export default userSelectors;
