import { Avatar, Button, Modal } from 'antd';
import { useThunkDispatch } from '../../../store';
import { banlistUIActions } from '../../../store/banlist/reducer';
import blogSelectors from './selectors';
import banlistActions from '../../../store/banlist/actions';
import UserIcon from '../../../components/Icon/icons/banlist/UserIcon';
import Box from '../../../components/Box';
import { maskPhone } from '../../../utils/formatPhone';
import { CommentIcon, DislikeIcon, LikeIcon, ReportIcon } from '../../../components/Icon/icons/banlist/SocialIcons';
import styled from 'styled-components';
import useViewImagesModal from '../ViewImagesModal';

export const useDetailBlog = () => {
  const dispatch = useThunkDispatch();
  return (blogId: string) => dispatch(banlistActions.blog.detail(blogId));
};

const DetailBlog = () => {
  const [modalImageRef, contextHolder] = useViewImagesModal();
  const dispatch = useThunkDispatch();
  const data = blogSelectors.detailBlog();

  const onClose = () => {
    dispatch(banlistUIActions.closeBlogDetail());
  };

  const Images = () => {
    if (!data) return null;
    const length = data.images?.length;
    if (length == 0) return null;

    switch (length) {
      case 1: {
        const item = data.images[0];
        return (
          <div
            onClick={() => modalImageRef.current?.open(data.images, item)}
            className="detail-card-info-images" style={{ gridTemplateColumns: '1fr' }}>
            <div className="detail-card-info-image-item" style={{ width: '100%', height: '100%' }}>
              <img src={item} />
            </div>
          </div>
        )
      }
      case 2: {
        const item = data.images[0];
        const item2 = data.images[1];
        return (
          <div
            onClick={() => modalImageRef.current?.open(data.images)}
            className="detail-card-info-images" style={{ gridTemplateColumns: '1fr 1fr', width: '200px', height: '100px' }}>
            <div
              onClick={() => modalImageRef.current?.open(data.images, item)}
              className="detail-card-info-image-item" style={{ width: '100%', height: '100%' }}>
              <img src={item} />
            </div>
            <div
              onClick={() => modalImageRef.current?.open(data.images, item2)}
              className="detail-card-info-image-item" style={{ width: '100%', height: '100%' }}>
              <img src={item2} />
            </div>
          </div>
        )
      }
      default:
        return (
          <div className="detail-card-info-images"
            onClick={() => modalImageRef.current?.open(data.images)}
          >
            {data.images.slice(0, 4).map((o, i) => {
              return (
                <div
                  key={i} className="detail-card-info-image-item"
                  onClick={() => modalImageRef.current?.open(data.images, o)}
                >
                  <img src={o} />
                  {(i === 3 && data.images?.length - 4 > 0) ? <div className='detail-card-info-image-overlay'><span>{data.images?.length - 4}+</span></div> : null}
                </div>
              )
            })}
          </div>
        )
    };
  };

  if (!data) return null;

  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      forceRender
      centered
      width={'900px'}
    >
      <DetailCardStyled >
        {/* detail-card-header */}
        <DetailCardHeader>
          <div className='account-info'>
            <div>
              <Avatar size={'default'} src={data.avatar || 'fail'} >
                <UserIcon />
              </Avatar>
            </div>
            <div className='account-name'>{data.userName ? data.userName : '--'}</div>
          </div>

          <div className='detail-time-info'>
            <div className="createdDate">Published in {data.createdDate}</div>
            <div className="businessName">{data.businessName}</div>
            <div className="address">{data.address || ''}, {data?.city || ''}, {data?.state || ''}, {data?.zip || ''}</div>
          </div>
        </DetailCardHeader>

        {/* detail-card-info */}
        <DetailCardInfo>
          <div className="detail-card-info-content">
            <Box display='flex' alignItems='center' style={{ gap: 4 }}>
              <div className="customerName">{data.customerName}</div>
              <div className="customerPhone">{maskPhone(data.customerPhone)}</div>
            </Box>
            <div className="blog-content">{data.note}</div>
            <div className="blog-actions">
              <Button ghost className="blog-action-item">
                <span className="blog-icon"><LikeIcon /></span>
                <span className="blog-action-name">{data.totalLike || 0} Likes</span>
              </Button>
              <Button ghost className="blog-action-item">
                <span className="blog-icon"><DislikeIcon /></span>
                <span className="blog-action-name">{data.totalDisLike || 0} Dislikes</span>
              </Button>
              <Button ghost className="blog-action-item">
                <span className="blog-icon"><CommentIcon /></span>
                <span className="blog-action-name">{data.totalComment || 0} Comments</span>
              </Button>
              <Button ghost className="blog-action-item">
                <span className="blog-icon"><ReportIcon /></span>
                <span className="blog-action-name">{data.totalReport || 0} Reports</span>
              </Button>
            </div>
          </div>

          <Images />
        </DetailCardInfo>

        {/* detail-card-comments */}
        {!!data.comments.length &&
          <DetailCardComment>
            <div className="detail-card-comments">
              {data.comments.map(o => {
                return (
                  <div key={o.commentId} className="detail-card-comment-item">
                    <Avatar className="comment-item-user-avatar" size={'small'} src={o.avatar || 'fail'} >
                      <UserIcon />
                    </Avatar>
                    <div className='comment-item-user-content'>
                      <div className="comment-item-user-content-name">
                        {o.name}
                      </div>
                      <div className="comment-item-user-content-note">
                        {o.note}
                      </div>
                      <div className="comment-item-user-content-create-date">
                        {o.createdDate}
                      </div>
                    </div>
                  </div>)
              })}
            </div>
          </DetailCardComment>}
      </DetailCardStyled>
      {contextHolder}
    </Modal>
  )
}

export default DetailBlog;

const DetailCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const DetailCardHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .account-info {
    display: flex;
    padding: 4px 12px 4px 4px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #F2F3F8;
    min-width: 208px;
    .ant-avatar {
      background: rgb(227, 233, 237);
      display: flex;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      .ant-avatar-string {
        transform: translateY(3px) scale(1.3) !important;
      }
    }
    .account-name {
      color: #0B3558;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .detail-time-info {
    .createdDate, .businessName {
      color: #5F5F5F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .address {
      color: #5F5F5F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const DetailCardInfo = styled.div`
  display: flex;
  .detail-card-info-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .customerName {
      color: #0B3558;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .customerPhone { 
      color: #476788;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .blog-content {
      flex: 1;
      color: #0B3558;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .blog-actions {
      display: flex;
      align-items: center; 
      gap: 12px;
      .blog-action-item {
        display: flex;
        align-items: center; 
        gap: 4px;
        .blog-icon {
          line-height: 1;
        }
        .blog-action-name {
          color: #808080;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .detail-card-info-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    height: 132px;
    width: 132px;
    .detail-card-info-image-item {
      width: 64px;
      height: 64px;
      display: flex;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
      img {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
      }
      .detail-card-info-image-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.60);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #FFF;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
`;
const DetailCardComment = styled.div`
.detail-card-comments {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #F2F3F8;
  max-height: 200px;
  height: 200px;
  min-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #D6D6D6;
    &:hover {
      background-color: #D6D6D6;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #F5F5F5;
  }

  .detail-card-comment-item {
    display: flex;
    gap: 12px;
    .comment-item-user-avatar {
      &.ant-avatar {
        background: rgb(227, 233, 237);
        display: flex;
        display: flex;
        width: 26px;
        height: 25px;
        justify-content: center;
        align-items: center;
        .ant-avatar-string {
          transform: translateY(2px) !important;
        }
      }
    }

    .comment-item-user-content {
      display: flex;
      flex-direction: column;
      .comment-item-user-content-name {
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .comment-item-user-content-note {
        align-self: stretch;
        color: #0B3558;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .comment-item-user-content-create-date {
        color: #808080;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
`;