import React, { useEffect, useState } from 'react';
import {
    LogoutOutlined,
    UserOutlined,
    DownOutlined,
    SettingOutlined,
    BarChartOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, Dropdown, Row, Col } from 'antd';
import type { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { AccountState } from '../store/account/types';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { logout } from '../store/account/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../components/Icon';
import { RiAccountPinCircleLine, RiComputerLine, RiCustomerService2Line } from "react-icons/ri";
import { Footer } from 'antd/es/layout/layout';
import { unSelectShopState } from '../store/shop/actions';
import { ShopInfo } from '../store/shop/types';
import AddBanlistIcon from '../components/Icon/icons/AddBanlistIcon';

const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        className: key,
        label,
        type,
    } as MenuItem;
}

export const LayoutAdmin = ({ children, noneFooter }: { children: React.ReactNode, noneFooter?: boolean }) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const accountLogin = useSelector<AppState>((state) => state.account) as AccountState;
    const shopSelect = useSelector<AppState>((state) => state.shopState.shopInfo) as ShopInfo;
    const navigate = useNavigate();
    const location = useLocation();

    const [itemMenuRootSelect, setItemMenuRootSelect] = useState<string>('');
    const [menuSelectKey, setMenuSelect] = useState<string>('');
    useEffect(() => {
        if (accountLogin.user) {
            if (location.pathname === '/') {
                dispatch(unSelectShopState());
                setItemMenuRootSelect('main_dashboard');
                setMenuSelect('');
            } else {
                if (location.pathname.includes("/salon")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('1');
                } else if (location.pathname.includes("/banlist/")) { //banlist have '/customers' path 
                    setItemMenuRootSelect('main_banlist');
                    setMenuSelect('');
                } else if (location.pathname.includes("/customers")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('2');
                } else if (location.pathname.includes("/coupons")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('3');
                } else if (location.pathname.includes("/gift-card")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('4');
                } else if (location.pathname.includes("/marketing")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('5');
                } else if (location.pathname.includes("/setting")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('6');
                } else if (location.pathname.includes("/promotions")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('8');
                } else if (location.pathname.includes("/credit-card")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('9');
                } else if (location.pathname.includes("/accounts")) {
                    setItemMenuRootSelect('main_accounts');
                    setMenuSelect('');
                } else if (location.pathname.includes("/dashboard")) {
                    setItemMenuRootSelect('main_dashboard');
                    setMenuSelect('');
                } else if (location.pathname.includes("/report")) {
                    setItemMenuRootSelect('main_report_summary');
                    setMenuSelect('');
                } else if (location.pathname.includes("/banlist")) {
                    setItemMenuRootSelect('main_banlist');
                    setMenuSelect('');
                }
            }
        }
    }, [location.pathname]);

    const handleItemClick = async (item: any) => {
        if (item.key === '1') {
            const result = await Swal.fire({
                title: 'Confirm the end of the session',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, Logout',
                confirmButtonColor: '#d42b3c',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(logout());
            }
        }
    };

    const itemsMenuDropDow = [
        {
            label: accountLogin.user?.email,
            key: '0',
            onClick: () => handleItemClick(itemsMenuDropDow[0]),
        },
        {
            label: 'Logout',
            key: '1',
            icon: <LogoutOutlined />,
            onClick: () => handleItemClick(itemsMenuDropDow[1]),
        }
    ];

    const itemsMenuRoot: MenuItem[] = [
        getItem('Dashboard', 'main_dashboard', <RiComputerLine size={17} />),
        getItem('Accounts', 'main_accounts', <RiAccountPinCircleLine size={17} />),
        getItem('Report', 'main_report_summary', <BarChartOutlined size={17} />),
        getItem('Banlist', 'main_banlist', <AddBanlistIcon />),
        getItem('Request Support', 'main_request_support', <RiCustomerService2Line size={17} />),
    ];

    const itemsMenu: MenuItem[] = [
        getItem('Salon Info', '1', <Icon type='menuAccounts' />),
        getItem('Employees', '1.1', <UserOutlined size={17} />),
        getItem('Customer', '2', <Icon type='menuAccountEdit' />),
        { type: 'divider' },
        getItem('Promotion', '8', <Icon type='promotion' />),
        getItem('Coupons', '3', <Icon type='menuCoupons' />),
        getItem('Gift Card', '4', <Icon type='menuGiftCard' />),
        getItem('Marketing', '5', <Icon type='menuSocialMedia' />),
        getItem('Setting', '6', <SettingOutlined style={{ fontSize: '20px' }} />),
        { type: 'divider' },
        // getItem('Email', '6', <Icon type='menuEmail' />),
        // getItem('Social Media', '6', <Icon type='menuSocialMedia' />),
        // getItem('Loyalty Rewards', '7', <Icon type='menuLoyaltyRewards' />),
        // getItem('Vip', '9', <Icon type='menuVip' />),

        getItem('Credit Card', '9', <Icon type='menuCreditCard' />),
        // { type: 'divider' },

        // getItem('SYSTEM', 'label', null),
        // getItem('Password', '10', <Icon type='menuPw' />),
        // getItem('Licenses', '11', <Icon type='menuLicenses' />),
        // getItem('Acount User', '12', <Icon type='menuAccountEdit' />),
    ];

    const handleMenuRootItemClick = (key: React.Key) => {
        setItemMenuRootSelect(key.toString());
        switch (key) {
            case 'main_dashboard':
                navigate('/dashboard');
                break;
            case 'main_accounts':
                navigate('/accounts');
                break;
            case 'main_request_support':
                navigate('/request-support');
                break;
            case 'main_report_summary':
                navigate('/report');
                break;
            case 'main_banlist':
                navigate('/banlist');
                break;
            default:
        }
    };

    const handleMenuItemClick = (key: React.Key) => {
        setMenuSelect(key.toString());
        switch (key) {
            case '1':
                if (shopSelect !== null) {
                    navigate(`/salon/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '1.1':
                if (shopSelect !== null) {
                    navigate(`/employees/${shopSelect.id}`);
                }
                break;
            case '2':
                if (shopSelect !== null) {
                    navigate(`/customers/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '3':
                if (shopSelect !== null) {
                    navigate(`/coupons/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '4':
                if (shopSelect !== null) {
                    navigate(`/gift-card/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '5':
                if (shopSelect !== null) {
                    navigate(`/marketing/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '6':
                if (shopSelect !== null) {
                    navigate(`/setting/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '8':
                if (shopSelect !== null) {
                    navigate(`/promotions/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            case '9':
                if (shopSelect !== null) {
                    navigate(`/credit-card/${shopSelect.id}`);
                } else {
                    navigate(`/accounts`);
                }
                break;
            default:
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <MainHeaderStyled>
                <Row>
                    <HeaderColStyled>
                        <img style={{ width: '100px' }} src="/images/logo_w.png" alt="Logo" />
                    </HeaderColStyled>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        items={itemsMenuRoot}
                        selectedKeys={[itemMenuRootSelect]}
                        style={{ flex: 1, minWidth: 0 }}
                        onClick={({ key }) => handleMenuRootItemClick(key)}
                    />
                    <Col flex={'auto'} />

                    <HeaderColStyled>
                        <HeaderNotificationStyled>
                            <img src="/images/notification.png" alt="Notification" />
                            <NumberNotificationStyled>
                                <span>4</span>
                            </NumberNotificationStyled>
                        </HeaderNotificationStyled>
                    </HeaderColStyled>

                    <HeaderColStyled>
                        <HeaderAccountStyled>
                            <Dropdown menu={{ items: itemsMenuDropDow }}>
                                <span>
                                    <UserOutlined style={{ fontSize: '150%' }} /><DownOutlined style={{ fontSize: '80%' }} />
                                </span>
                            </Dropdown>
                        </HeaderAccountStyled>
                    </HeaderColStyled>
                </Row>
            </MainHeaderStyled>
            <Content style={{ display: 'flex' }}>
                <Layout style={{ flexGrow: 1 }}>
                    {shopSelect !== null && (
                        <Sider
                            style={{
                                padding: 0,
                                background: colorBgContainer,
                                position: 'relative',
                            }}
                            theme="light"
                            collapsible
                            collapsed={collapsed}
                            onCollapse={toggleCollapse}
                            trigger={null} >
                            <div className="demo-logo-vertical" />
                            <IconCollapseSideBar onClick={toggleCollapse}>
                                <Icon type={collapsed ? 'arrowRight' : 'arrowLeft'} />
                            </IconCollapseSideBar>
                            <CustomMenu
                                style={{ padding: '0.4rem', background: colorBgContainer }}
                                theme="light"
                                selectedKeys={[menuSelectKey]}
                                mode="inline"
                                items={itemsMenu.filter(i => !collapsed || i?.key !== 'label')}
                                onClick={({ key }) => handleMenuItemClick(key)}
                            />
                        </Sider>
                    )}

                    <MainLayoutStyled>
                        <MainContentStyled>
                            {children}
                        </MainContentStyled>
                        {!noneFooter && <Footer style={{ textAlign: 'center' }}>
                            Fozito Design ©2023 Created by Fozito Tech
                        </Footer>}
                    </MainLayoutStyled>
                </Layout>
            </Content>
        </Layout>
    );
};

export default LayoutAdmin;

const MainHeaderStyled = styled(Header)`
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
    && .ant-menu-root {
        margin-left: 10px;
    };
    
    && .ant-menu-item {
        font-weight: 400;
        font-size: 17px;
        &.main_banlist {
            display: flex;
            align-items: center;
            gap: 6px;
            svg {
                opacity: 0.5;
            }
        }
    };

    && .ant-menu-item: hover {
        background-color: #1677ff;
        &.main_banlist {
            svg {
                opacity: 1;
            }
        }
    };

    && .ant-menu-item-selected {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: inset 0 -6px 0 #ff3c4a;
        &.main_banlist {
            svg {
                opacity: 1;
            }
        }
    };
`;

const HeaderColStyled = styled(Col)`
  display: flex;
  align-items: center;
`;

const NumberNotificationStyled = styled(Content)`
  position: absolute;
  top: 10px;
  right: -8px;
  width: 18px;
  height: 18px;
  background: rgba(255, 136, 144, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
    && span {
      color: white;
      font-size: 14px;
    }
`;

const HeaderNotificationStyled = styled(Content)`
  position: relative;
    && img {
      vertical-align: middle;
    }
`;

const HeaderAccountStyled = styled(Content)`
  margin-left: 20px;
  border-radius: 67px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
    && span {
      color: rgba(255, 136, 144, 1);
      position: relative;
      top: -30%
    }
`;

const IconCollapseSideBar = styled(Content)`
  position: absolute;
  top: 0.75rem;
  right: -1.25rem;
  border-radius: 50%;
`;

const CustomMenu = styled(Menu)`
    && .ant-menu-item {
      height: 40px;
      line-height: 45px;
    }

    && .ant-menu-item-selected {
        background-color: rgba(255, 229, 231, 1);
        color: rgba(255, 136, 144, 1);
    }

    && .ant-menu-item-selected svg path {
      stroke: rgba(255, 136, 144, 1);
      fill: rgba(255, 136, 144, 1);
    }

    && .ant-menu-item-divider {
      border-color: rgba(218, 218, 218, 1);
      margin-top: 15px;
    }

    && .ant-menu-item-only-child {
      padding-left: 0px !important;
      pointer-events: none; /* Chặn các sự kiện click và hover */
      user-select: none; /* Chặn việc chọn nội dung bằng chuột */
    }
`;

const MainLayoutStyled = styled(Layout)`
  
`;

const MainContentStyled = styled(Content)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;