import React from 'react';
import { Button as ButtonAntd, message } from 'antd';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import colorTheme from '../../../constant/colors';
import { LinkOutlined, CopyOutlined, SettingOutlined } from '@ant-design/icons';
import { copyClipboard } from '../../../helpers/FunctionUtils';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../../store/loading/actions';
import { useDispatch } from 'react-redux';
import accountApis from '../../../services/accountApis';
import { setShopState } from '../../../store/shop/actions';
import { ButtonDeleteStyled } from '../../styles';


type ISalonItemProps = {
  salonData: any,
  handleDeleteSaLon: (id: string, shopName: string) => void;
  handleGenLink: (id: string, type: string, shopName: string) => void;
  handleUpdateSalon: (id: string) => void;
};

const SalonItem: React.FC<ISalonItemProps> = ({ salonData, handleDeleteSaLon, handleGenLink, handleUpdateSalon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditShop = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await accountApis.getShopById(id);
      if (response.status === 200) {
        dispatch(setShopState(response.data.data));
        navigate(`/salon/${id}`)
      } else {
        message.error({ content: response.data.message, duration: 5 });
      }
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <SalonItemStyled>
      <ContainerStyled>
        <ContainerAccountStyled>
          <Box>
            <Text mb={0.25} variant="CONTENT_2">
              {salonData.shopName}
            </Text>
            <Text variant="BODY_1" color="text_2">
              {salonData.address}, {salonData.city}
            </Text>
          </Box>
          <AccountButtonStyled>
            <Box className="d-center">
              <Text variant="CONTENT_1" color="blue">
                {salonData.accountName}
              </Text>
              <IconBoxStyled className="d-center">
                <RightOutlined style={{ fontSize: '0.75rem' }} />
              </IconBoxStyled>
            </Box>
          </AccountButtonStyled>
        </ContainerAccountStyled>

        <ContainerDeviceStyled>
          <Box style={{ flex: 1 }}>
            <Text mb={0.25} variant="CONTENT_2">
              Devices
            </Text>
            {salonData.deviceFeatures?.map((item: any) => {
              if (item.numberDevice > 0) {
                return (
                  <Text key={item.id} variant="BODY_1" mb={0.25}>
                    {item.feature === 'CHECK_IN'
                      ? 'Check In'
                      : item.feature === 'APPOINTMENT'
                        ? 'Appointment'
                        : item.feature === 'WAITING_LIST'
                          ? 'Waiting List'
                          : item.feature === 'TURN'
                            ? 'Turn'
                            : item.feature}{' '}
                    ({item.numberDevice} devices)
                  </Text>
                );
              }
              return null;
            })}

          </Box>
          <Box style={{ flex: 1 }}>
            <Text mb={0.25} variant="CONTENT_2">
              Online
            </Text>
            <Box>
              {
                salonData.bookingLink ?
                  (
                    <TextLinkStyled variant="CONTENT_1" color="blue"
                      onClick={() => { copyClipboard(salonData.bookingLink); }}>
                      <span style={{ marginRight: '5px' }}>Booking Online Link</span>
                      <CopyOutlined />
                    </TextLinkStyled>
                  )
                  :
                  (
                    <TextLinkStyled variant="CONTENT_1" color="blue"
                      onClick={() => handleGenLink(salonData.shopId, 'BOOKING', salonData.shopName)}>
                      <LinkOutlined />
                      <span style={{ marginLeft: '5px' }}>Gen Booking Online</span>
                    </TextLinkStyled>
                  )
              }
              {
                salonData.giftOnlineLink ?
                  (
                    <TextLinkStyled variant="CONTENT_1" color="blue"
                      onClick={() => { copyClipboard(salonData.giftOnlineLink); }}>
                      <span style={{ marginRight: '5px' }}>Gift Card Online Link</span>
                      <CopyOutlined />
                    </TextLinkStyled>
                  )
                  :
                  (
                    <TextLinkStyled variant="CONTENT_1" color="blue"
                      onClick={() => handleGenLink(salonData.shopId, 'GIFT_CARD', salonData.shopName)}>
                      <LinkOutlined />
                      <span style={{ marginLeft: '5px' }}>Gen Gift Card Online</span>
                    </TextLinkStyled>
                  )
              }
            </Box>
          </Box>
        </ContainerDeviceStyled>
      </ContainerStyled>

      <GroupButtonStyled className='d-between'>
        <ButtonSetingStyled variant="SECONDARY" onClick={() => handleEditShop(salonData.shopId)}>
          <SettingOutlined style={{ fontSize: '18px' }} />
          Setting
        </ButtonSetingStyled>
        <ButtonEditStyled variant="SECONDARY" icon="editV2" onClick={() => handleUpdateSalon(salonData.shopId)}>
          Edit
        </ButtonEditStyled>
        <ButtonDeleteStyled icon="delete" onClick={() => handleDeleteSaLon(salonData.shopId, salonData.shopName)}>
          Delete
        </ButtonDeleteStyled>
      </GroupButtonStyled>

    </SalonItemStyled>
  );
};

export default SalonItem;

const SalonItemStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-1, #dadada);
  border-left: 5px solid #008bff;
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  background: white
`;

const IconBoxStyled = styled.div`
  color: ${colorTheme.blue};
  margin-left: 0.25rem;
`;
const ContainerStyled = styled.div`
  flex: 1;
  max-height: calc(100% - /* height of ButtonGroup */);
  overflow-y: auto;
`;

const ContainerAccountStyled = styled(Box)`
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const ContainerDeviceStyled = styled(Box)`
  padding: 1rem;
  border-top: 1px solid #dadada;
  display: flex;
  justify-content: space-between;
`;

const AccountButtonStyled = styled(ButtonAntd)`
  display: inline-flex;
  height: 40px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: #e8f3ff;
  border: none;
`;

export const GroupButtonStyled = styled.div`
  padding: 0.5rem 1rem;
  border-top: 1px solid #dadada;
  display: flex;
  gap: 1rem;
  overflow-y: auto;
  &.only-icon-ui {
    .ButtonSettingStyled {
      width: 35px;
      display:flex;
       align-items: center;
       justify-content: center;
       padding:0;
       svg, span {
        margin: 0;
       }
    }
  }
`;

export const TextLinkStyled = styled(Text)`
  font-size: 15px;
  padding-top: 5px;
  height: 33px;
  cursor: copy;
`;

export const ButtonSetingStyled = styled(Button)`
    background: var(--text-text-1, #fff);
    color: var(--blue, #008bff);
    font-size: 14px;
    height: 35px;
    border: 2px solid;
    width: 100%;
    && span {
      margin-right: 10px;
    }

    &&:hover {
      background: #4096ff !important;
    }
`;

export const ButtonEditStyled = styled(Button)`
  font-size: 14px;
  height: 37px;
  width: 100%;
  &&:hover {
    background: #ff8890 !important;
    border: none;
  };
  > svg {
    margin-right: 10px;
    path {
      transition: fill; // Thêm transition cho hiệu ứng mượt hơn
    }
  };
  &:hover {
    > svg path {
      fill: var(#ff8890, #ff8890);
    }
  };
`;