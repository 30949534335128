import { FileType } from "../components/ModalUploadCustomer";
import { api } from "../helpers/api";
import { CouponTemplateType, PromotionStatus } from "../pages/account/Promotion/helpers";
import { IBodyCreatePromotion, IBodyCreatePromotionCoupon, IPromotionListParams, IPromotionPushNotificationBody } from "../pages/account/Promotion/types";

const getAll = async () => {
  return await api.get('/api/v1/admin/account').then((response) => {
    return response;
  });
};

const addAccount = async (businessName: string, timezone: string, phone: string, firstName: string,
  lastName: string, email: string, address: string, city: string, state: string, zip: string, password: string,
  bookingOnline: boolean, giftOnline: boolean, deviceFeatures: any) => {
  const payLoad = {
    businessName,
    timezone,
    phone,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zip,
    password,
    bookingOnline,
    giftOnline,
    deviceFeatures
  };
  return await api.post('/api/v1/admin/account', payLoad).then((response) => {
    return response;
  });
};

const filterSalon = async (
  accountId: string | null,
  shopName: string | null,
  page: number | null,
  size: number | null) => {
  const params = { accountId, shopName, size, page };
  return await api.get('/api/v1/admin/account/shop', { params }).then((response: any) => {
    return response;
  });
};

const deleteShop = async (id: any) => {
  return await api.delete(`/api/v1/admin/account/shop/${id}`).then((response: any) => {
    return response;
  });
};

const addSalon = async (accountId: string, businessName: string, timezone: string, phone: string,
  email: string, address: string, city: string, state: string, zip: string, password: string,
  bookingOnline: boolean, giftOnline: boolean, deviceFeatures: any) => {
  const payLoad = {
    accountId,
    businessName,
    timezone,
    phone,
    email,
    address,
    city,
    state,
    zip,
    password,
    bookingOnline,
    giftOnline,
    deviceFeatures
  };
  return await api.post('/api/v1/admin/account/shop', payLoad).then((response) => {
    return response;
  });
};

const genLinkShop = async (shopId: string, type: string) => {
  const payLoad = {
    shopId,
    type,
  };
  return await api.post('/api/v1/admin/account/shop/gen-link', payLoad).then((response: any) => {
    return response;
  });
};

const getShopById = async (shopId: any) => {
  return await api.get(`/api/v1/admin/account/shop/${shopId}`).then((response: any) => {
    return response;
  });
};

const updateSalon = async (shopId: string, shopName: string, timezone: string, phone: string, email: string,
  address: string, city: string, state: string, zip: string, deviceFeatures: any, giftOnline: boolean, bookingOnline: boolean) => {
  const payLoad = {
    shopId, shopName, timezone, phone, email, address, city, deviceFeatures, giftOnline, bookingOnline, state, zip
  };
  return await api.put('/api/v1/admin/account/shop-update', payLoad).then((response) => {
    return response;
  });
};

const getTimeZone = async () => {
  return await api.get('/api/v1/admin/account/timezone').then((response) => {
    return response;
  });
};

const resetPwdShop = async (shopId: any, password: string) => {
  const payLoad = {
    shopId,
    password,
  };
  return await api.post('/api/v1/admin/account/reset-password', payLoad).then((response: any) => {
    return response;
  });
};


const allCustomerGroupListByShopId = async (shopId: any) => {
  return await api.get(`/api/v1/admin/customer/group-list/${shopId}`).then((response: any) => {
    return response;
  });
};

const filterCustomerByShopId = async (shopId: any, groupId: string | null, pointFrom: string | null, pointTo: string | null,
  sendingAmountFrom: string | null, sendingAmountTo: string | null, types: any, lastPhoneNumber: string | null, page: number, size: number) => {
  const params: Record<string, string | any> = {
    shopId, groupId, pointFrom, pointTo,
    sendingAmountFrom, sendingAmountTo, lastPhoneNumber, page, size
  };
  if (types && types.length > 0) {
    params.types = types.join(',');
  }
  return await api.get(`/api/v1/admin/customer/${shopId}/list`, { params }).then((response: any) => {
    return response;
  });
};

const simpleSearchCustomers = async (shopId: string, lastPhoneNumber: string) => {
  return await api.get(`/api/v1/admin/customer/${shopId}/list`, { params: { lastPhoneNumber } });
}

const addGroupCustomerByShopId = async (shopId: any, customerIds: any, groupName: string) => {
  const payLoad = { shopId, customerIds, groupName };
  return await api.post('/api/v1/admin/customer/create-group', payLoad).then((response: any) => {
    return response;
  });
};

const filterSmsCampaignByShopId = async (shopId: string | null, status: string | null, type: string | null, title: string | null, page: number, size: number) => {
  const params = {
    shopId, status, type, title, page, size
  };
  return await api.get(`/api/v1/admin/marketing/${shopId}/campaigns`, { params }).then((response: any) => {
    return response;
  });
};

const updateCampaignStatus = async (shopId: any, status: any, campaignId: string) => {
  const payLoad = { shopId, status, campaignId };
  return await api.post(`/api/v1/admin/marketing/update-status/${status}`, payLoad).then((response: any) => {
    return response;
  });
};
const inactiveCampaign = async (shopId: any, campaignId: string, active: boolean) => {
  const payLoad = { shopId, campaignId, active };
  return await api.post(`/api/v1/admin/marketing/inactive`, payLoad).then((response: any) => {
    return response;
  });
};

const deleteMultipleCampaign = async (campaignIds: any[]) => {
  const payload = campaignIds.map(campaignId => ({ campaignId }));
  return await api.post('/api/v1/admin/marketing/delete', campaignIds).then((response: any) => {
    return response;
  });
};

const getLstCouponTemplateByType = async (type: string) => {
  return await api.get(`/api/v1/admin/coupon/template/${type}`).then((response: any) => {
    return response;
  });
};

const getLstCustomerByGroup = async (groupId: string) => {
  return await api.get(`/api/v1/admin/customer/list-by-group/${groupId}`).then((response: any) => {
    return response;
  });
};

const getLstGroupByShopId = async (shopId: string) => {
  return await api.get(`/api/v1/admin/customer/group-list/${shopId}`).then((response: any) => {
    return response;
  });
};

const exportExcelCustomer = async (shopId: any, groupId: any, pointFrom: any, pointTo: any,
  sendingAmountFrom: any, sendingAmountTo: any,
  types: any, lastPhoneNumber: any) => {
  const params: Record<string, string | any> = {
    shopId, groupId, pointFrom, pointTo,
    sendingAmountFrom, sendingAmountTo, lastPhoneNumber
  };
  if (types && types.length > 0) {
    params.types = types.join(',');
  }
  return await api.get(`/api/v1/admin/customer/exportExcel/${shopId}`, { params, responseType: 'blob' }).then((response: any) => {
    return response;
  });
};

const createSmsCampaign = async (shopId: any, title: any, message: any, sendTime: any, coupon: any, customers: any, campaignType: any, allCustomer?: boolean, sendNow?: boolean) => {
  const payLoad = { shopId, title, message, sendTime, coupon, customers, campaignType, allCustomer: allCustomer || false, sendNow: sendNow || false };
  return await api.post('/api/v1/admin/marketing/createSmsCampaign', payLoad).then((response: any) => {
    return response;
  });
};

const getCampaignById = async (campaignId: any) => {
  return await api.post(`/api/v1/admin/marketing/detail/${campaignId}`).then((response: any) => {
    return response;
  });
};

const updateSmsCampaign = async (campaignId: any, shopId: any, title: any, message: any, sendTime: any, coupon: any, customers: any, campaignType: any, allCustomer?: boolean) => {
  const payLoad = { campaignId, shopId, title, message, sendTime, coupon, customers, campaignType, allCustomer };
  return await api.post('/api/v1/admin/marketing/update-campaign', payLoad).then((response: any) => {
    return response;
  });
};

const getDashboard = async () => {
  return await api.get(`/api/v1/admin/dashboard/summary`).then((response: any) => {
    return response;
  });
};

const getSettingPackage = async (shopId: any, type: any) => {
  const params = {
    type
  };
  return await api.get(`/api/v1/admin/setting/package-notify/${shopId}`, { params }).then((response: any) => {
    return response;
  });
};

const settingExtend = async (shopId: any, quantity: any, type: any) => {
  const payLoad = { shopId, quantity, type };
  return await api.put('/api/v1/admin/setting/extend-package-notify', payLoad).then((response: any) => {
    return response;
  });
};

const onSettingUpdateActice = async (shopId: any, id: any, active: any, cost?: number) => {
  const payLoad = { shopId, id, active, cost };
  return await api.put('/api/v1/admin/setting/active-package-notify', payLoad).then((response: any) => {
    return response;
  });
};

const addOrUpdateReviewNotification = async (shopId: any, notifyId: any, enableOwner: boolean, enableCustomer: boolean, valueNotify: any[],
  rateStars: string, type: string, coupon: any
) => {
  const payLoad = {
    shopId, notifyId, enableOwner, enableCustomer, valueNotify, rateStars, type, coupon
  };
  return await api.post('/api/v1/admin/setting/modify-review-notify', payLoad).then((response: any) => {
    return response;
  });
};


const getListNotifyByShopId = async (shopId: any) => {
  return await api.get(`/api/v1/admin/setting/${shopId}/get-review-notify`).then((response: any) => {
    return response;
  });
};

const deleteNotification = async (notifyId: any) => {
  return await api.delete(`/api/v1/admin/setting/delete-review-notify/${notifyId}`).then((response: any) => {
    return response;
  });
};

const updateSalonBanner = async (shopId: string, body: FormData) => {
  return await api.post(`/api/v1/admin/store/${shopId}/update-banner`, body, {
    headers: {
      'accept': '*/*',
      'Content-Type': 'multipart/form-data',
    }
  });
};

const getSalonListGallery = async (shopId: string) => {
  return await api.get(`/api/v1/admin/store/${shopId}/list-gallery`);
};

const addNewSalonGallery = async (shopId: string, body: FormData) => {
  return await api.post(`/api/v1/admin/store/${shopId}/update-gallery`, body, {
    headers: {
      'accept': '*/*',
      'Content-Type': 'multipart/form-data',
    }
  });
};

const deleteSalonGallery = async (galleryId: string) => {
  return await api.delete(`/api/v1/admin/store/delete-gallery/${galleryId}`);
};

const getSalonListAboutUs = async (shopId: string) => {
  return await api.get(`/api/v1/admin/store/${shopId}/about-us`);
};

const updateSalonAboutUs = async (shopId: string, payload: { aboutUsId: string, description: string, workingTime: string, categoryNote: string, }) => {
  return await api.post(`/api/v1/admin/store/${shopId}/update-about-us`, payload);
};

const getAllStaff = async (shopId: string, page: number) => {
  return await api.get(`/api/v1/admin/staff/${shopId}/get-all`, { params: { page, size: 20 } })
};

const getPromotionSummary = async (shopId: string) => {
  return await api.get(`/api/v1/admin/promotion/${shopId}/summary`);
};

const getAccountPromotions = async (shopId: string, params: IPromotionListParams) => {
  return await api.get(`/api/v1/admin/promotion/${shopId}/list`, { params });
};

const updateAccountPromotionStatus = async (shopId: string, promotionId: string, status: PromotionStatus) => {
  return await api.post(`/api/v1/admin/promotion/update-status`, { shopId, promotionId, status });
};

const deleteAccountPromotion = async (promotionId: string) => {
  return await api.delete(`/api/v1/admin/promotion/delete/${promotionId}`);
};

const getCouponsByType = async (shopId: string, type: string) => {
  return await api.get(`/api/v1/admin/coupon/list-by-type/${shopId}`, { params: { type } });
};

const createPromotion = async (body: IBodyCreatePromotion) => {
  return await api.post(`/api/v1/admin/promotion/create`, body);
};

const createPromotionCoupon = async (body: IBodyCreatePromotionCoupon) => {
  return await api.post('/api/v1/admin/coupon/create', body);
};

const getPromotionDetail = async (promotionId: string) => {
  return await api.get(`/api/v1/admin/promotion/detail/${promotionId}`);
}

const getReportPromotionDetail = async (promotionId: string) => {
  return await api.get(`/api/v1/admin/promotion/reports/${promotionId}`);
}

const pushPromotionNotification = async (body: IPromotionPushNotificationBody) => {
  return await api.post('/api/v1/admin/promotion/push-notification', body);
};

const importShopCustomers = async (shopId: string, file: FileType) => {
  const formData = new FormData();
  formData.append('file', file);
  return await api.post(`/api/v1/admin/customer/${shopId}/import`, formData, {
    headers: {
      'accept': '*/*',
      'Content-Type': 'multipart/form-data',
    }
  });
};

const getSettingConfigs = async (shopId: string) => {
  return await api.get(`/api/v1/admin/setting/${shopId}/all-config`);
};


export interface IUpdateSettingConfigItem {
  id: string;
  enable: boolean;
  value: string;
  type: string;
  key: string;
}

const updateSettingConfigs = async (shopId: string, body: IUpdateSettingConfigItem[]) => {
  return await api.post(`/api/v1/admin/setting/${shopId}/update-config`, body);
};

const getAccountConfigsAuto = (shopId: string) => {
  return api.get(`/api/v1/admin/marketing/${shopId}/config-auto`);
};

export interface IAccountConfigAutoUpdateItem {
  id: string;
  type: string;
  message: string;
  value: number;
  couponId: string | null;
  active: boolean;
}
const updateAccountConfigsAuto = (shopId: string, body: IAccountConfigAutoUpdateItem[]) => {
  return api.post(`/api/v1/admin/marketing/${shopId}/update-config-auto`, body);
};

const creditCardUpdateOfflineMode = (id: string, offlineMode: boolean) => {
  return api.put('/api/v1/admin/credit-card/update-offline-mode', { id, offlineMode });
};

const getSettingTwoFactor = async (shopId: string) => {
  return await api.get(`/api/v1/admin/store/${shopId}/config-step-two`);
};

export interface IApiUpdateSettingTwoFactorBody {
  id: string;
  enableLogin: boolean;
  enableSalaryReport: boolean;
  phone: string;
  email: string;
}

const updateSettingTwoFactor = async (shopId: string, body: IApiUpdateSettingTwoFactorBody) => {
  return await api.post(`/api/v1/admin/store/${shopId}/update-config-step-two`, body);
};

const accountApis = {
  getAll,
  addAccount,
  filterSalon,
  deleteShop,
  addSalon,
  genLinkShop,
  getShopById,
  updateSalon,
  getTimeZone,
  resetPwdShop,
  allCustomerGroupListByShopId,
  filterCustomerByShopId,
  addGroupCustomerByShopId,
  filterEmailCampaignByShopId: filterSmsCampaignByShopId,
  updateCampaignStatus,
  deleteMultipleCampaign,
  getLstCouponTemplateByType,
  getLstCustomerByGroup,
  getLstGroupByShopId,
  exportExcelCustomer,
  createSmsCampaign,
  getCampaignById,
  updateSmsCampaign,
  getDashboard,
  getSettingPackage,
  settingExtend,
  onSettingUpdateActice,
  addOrUpdateReviewNotification,
  getListNotifyByShopId,
  deleteNotification,
  updateSalonBanner,
  getSalonListGallery,
  addNewSalonGallery,
  deleteSalonGallery,
  getSalonListAboutUs,
  updateSalonAboutUs,
  getAllStaff,
  simpleSearchCustomers,
  inactiveCampaign,
  getPromotionSummary,
  getAccountPromotions,
  updateAccountPromotionStatus,
  deleteAccountPromotion,
  getCouponsByType,
  createPromotion,
  createPromotionCoupon,
  getPromotionDetail,
  getReportPromotionDetail,
  pushPromotionNotification,
  importShopCustomers,
  getSettingConfigs,
  updateSettingConfigs,
  getAccountConfigsAuto,
  updateAccountConfigsAuto,
  creditCardUpdateOfflineMode,
  getSettingTwoFactor,
  updateSettingTwoFactor,
};

export default accountApis;
