import { DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import accountApis from '../../../services/accountApis';
import { FORMAT_DATE_REQUEST } from '../../../store/banlist/constants';
import { setLoading } from '../../../store/loading/actions';
import CouponInput from './AddNewCoupon';
import BannerPromotionUpload from './BannerPromotionUpload';
import InputPercentDollar from './InputPercentDollar';
import CurrencyInputPrecision from './InputPercentDollar/CurrencyInputPrecision';
import { appendListenerPromotionLayoutSubmitAction } from './Layout';
import { CouponTemplateType, FormControl, FormControlItemStyled, PromotionCategoryType, PromotionSourceType, calendarAfter, downIcon, promotionSourceTypeList } from './helpers';
import { IBodyCreatePromotion, IPromotionCouponItem } from './types';
type AddNewPromotionFormValue = {
  base64: string;
  categoryType: string;
  title: string;
  description: string;
  startDate: Dayjs;
  endDate: Dayjs;
  sourceType: string;
  minValue: number;
  promotionValue: number;
  promotionType: string;
  couponId?: string;
  mediaLink?: string;
};
const regexURL = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
const AddPromotionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<IPromotionCouponItem[]>([]);
  const { id: shopId = '' } = useParams();
  const [form] = Form.useForm();

  const getCouponsByType = async () => {
    try {
      const res: AxiosResponse<{ data: IPromotionCouponItem[] }> = await accountApis.getCouponsByType(shopId, CouponTemplateType.APPOINTMENT);
      if (res.data.data) {
        setCoupons(res.data.data);
      }
    } catch (error) { }
  };

  const init = () => {
    getCouponsByType();
  };

  useEffect(() => {
    init();
    const removeEventListener = appendListenerPromotionLayoutSubmitAction(() => form.submit());
    return () => removeEventListener();
  }, []);

  const onFinish = async (values: any) => {
    const formData = values as AddNewPromotionFormValue;

    const body: IBodyCreatePromotion = {
      base64: formData.base64?.replace(/^data:image\/\w+;base64,/, ''),
      mediaLink: formData.mediaLink || '',
      categoryType: formData.categoryType,
      couponId: formData.couponId || '',
      description: formData.description,
      startDate: formData.startDate.format(FORMAT_DATE_REQUEST),
      endDate: formData.endDate.format(FORMAT_DATE_REQUEST),
      minValue: +(formData.minValue || 0),
      promotionType: formData.promotionType,
      promotionValue: formData.promotionValue,
      sourceType: formData.sourceType,
      shopId,
      title: formData.title,
    };

    dispatch(setLoading(true));
    try {
      const res: AxiosResponse<{ data: boolean }> = await accountApis.createPromotion(body);
      if (res.data.data) {
        navigate(`/promotions/${shopId}`);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <FormStyled layout='vertical' form={form} onFinish={onFinish} initialValues={{ categoryType: 'NORMAL', couponId: '', promotionType: 'dollar' }}>
        <Row justify={'center'} align={'middle'} style={{ marginBottom: '24px' }}>
          <Form.Item name={'base64'} noStyle>
            <BannerPromotionUpload />
          </Form.Item>
        </Row>
        <FormControl mb0 label='Or Media Link'>
          <Form.Item name={'mediaLink'} noStyle>
            <Input />
          </Form.Item>
        </FormControl>
        <Form.Item
          shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const base64 = getFieldValue('base64');
            const mediaLink = getFieldValue('mediaLink');
            return (
              <Form.Item className='blank-controller' name={'validMedia'} rules={[{
                validator() {
                  if (!base64 && !mediaLink) return Promise.reject('Please enter media link or banner image');

                  if (mediaLink) {
                    if (regexURL.test(mediaLink)) return Promise.resolve();

                    return Promise.reject('Media link invalid');
                  }

                  return Promise.resolve();
                },
              }]} ></Form.Item>
            )
          }}
        </Form.Item>
        <div className='content-container'>
          <div className="content-groups">
            <FormControl name={'categoryType'} label='Display Method' required>
              <Radio.Group size='large'>
                <Radio value={PromotionCategoryType.NORMAL} >Normal</Radio>
                <Radio value={PromotionCategoryType.SPECIAL} >Special</Radio>
              </Radio.Group>
            </FormControl>
            <FormControl label='Title' name={'title'} required>
              <Input placeholder='Please enter...' />
            </FormControl>
            <FormControl name={'description'} label='Description'>
              <Input.TextArea className='text-area' rows={5} placeholder='Please enter...' />
            </FormControl>
          </div>
          <div className="content-groups">
            <FormControl name={'startDate'} label='Start Date' required>
              <DatePicker placeholder='mm-dd-yyyy' format={'MM-DD-YYYY'} suffixIcon={calendarAfter} />
            </FormControl>
            <FormControl name={'endDate'} label='Expires Date' required>
              <DatePicker placeholder='mm-dd-yyyy' format={'MM-DD-YYYY'} suffixIcon={calendarAfter} />
            </FormControl>
            <FormControl name={'sourceType'} label='Type' required>
              <Select options={promotionSourceTypeList} suffixIcon={downIcon} />
            </FormControl>

            <PromotionSourceTypeStyled>
              <div className="header-top">
                <div className="require-label">
                  <span>PROMOTION <span className='require-symbol'>(*)</span></span>
                </div>
              </div>
              <div className='content-body'>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const sourceType = getFieldValue('sourceType');
                    switch (sourceType) {
                      case PromotionSourceType.SHARING:
                      case PromotionSourceType.APPOINTMENT:
                        return (
                          <FormControl name={'couponId'} label='Coupon' >
                            <CouponInput coupons={coupons} refreshList={getCouponsByType} />
                          </FormControl>
                        );
                      case PromotionSourceType.GIFT_CARD:
                        return (
                          <>
                            <FormControl name={'minValue'} label='Min Value Gift Card'>
                              <CurrencyInputPrecision height={46} />
                            </FormControl>
                            <FormControl label='Promotion Value'>
                              <InputPercentDollar valueFieldName={'promotionValue'} typeFieldName={'promotionType'} />
                            </FormControl>
                          </>
                        );
                      case PromotionSourceType.VIP:
                        return (
                          <>
                            <FormControl label='Promotion Value'>
                              <InputPercentDollar valueFieldName={'promotionValue'} typeFieldName={'promotionType'} />
                            </FormControl>
                          </>
                        );
                      default:
                        return <div className="text-please-select">Please select type to enter promotion</div>;
                    }
                  }}
                </Form.Item>
              </div>
            </PromotionSourceTypeStyled>
          </div>
        </div>
      </FormStyled>
    </div>
  );
};

export default AddPromotionPage;


const FormStyled = styled(Form)`
  background: #fff;
  padding: 24px 16px;
  padding-bottom: 40px;
  .content-container {
    display: flex;
    gap: 16px;
  }
  .content-groups {
    flex: 1;
  }

  .ant-radio-group {
    width: 100%;
    display: flex;
    align-items: center;
    .ant-radio-wrapper {
      flex: 1;
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .ant-input {
    padding: 0;
    display: flex;
    height: 46px;
    padding: 5px 16px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #DADADA;
    background: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    &.text-area {
      height: auto;
      min-height: 46px;
    }
  }


  .ant-picker {
    width: 100%;
    display: flex;
    height: 46px;
    padding: 5px 16px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #DADADA;
    background: #fff;
  }
  .ant-select.ant-select-single {
    height: auto;
  }
  .ant-select .ant-select-arrow {
    height: auto;
    transform: translateY(-50%);
    margin-top: 0;
  }
  .ant-select .ant-select-selector {
    display: flex;
    height: 46px;
    padding: 5px 16px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #DADADA;
    background: #fff;
  }

  .ant-form-item.blank-controller .ant-form-item-control-input {
    display: none;
  }
`;



const PromotionSourceTypeStyled = styled(FormControlItemStyled)`
display: flex;
padding-bottom: 8px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
background: #F6F7FC;
.header-top {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #E3E9ED;
}
.content-body {
  padding: 16px;
  padding-top: 0px;
  width: 100%;
}
.text-please-select {
  color: #566A7F;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-select .ant-select-arrow {
  height: auto;
  transform: translateY(-50%);
  margin-top: 0;
}
.ant-select.ant-select-single {
  height: auto;
}
.ant-select .ant-select-selector {
  display: flex;
  height: 46px;
  padding: 5px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #DADADA;
  background: #fff;
}
`;
