import colorTheme from '../../constant/colors';
import { generateCssSpacing, ISpacing } from '../../constant/spacing';
import React from 'react';
import styled, { css } from 'styled-components';

const TEXT_VARIANT_TYPES = {
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
  H7: 'H7',
  H8: 'H8',
  H9: 'H9',
  CONTENT_1: 'CONTENT_1',
  CONTENT_2: 'CONTENT_2',
  BODY_1: 'BODY_1',
  BODY_2: 'BODY_2',
  CAPTION_1: 'CAPTION_1',
  CAPTION_2: 'CAPTION_2',
  CAPTION_3: 'CAPTION_3',
  CAPTION_4: 'CAPTION_4',
};

interface IBoxOGProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant: keyof typeof TEXT_VARIANT_TYPES;
  color?: keyof typeof colorTheme;
  textDecorate?: string;
  textAlign?: React.CSSProperties['textAlign'];
  width?: string;
  textTransform?: React.CSSProperties['textTransform'];
}

type ITextCusProps = IBoxOGProps & ISpacing

const stylesMap = {
  [TEXT_VARIANT_TYPES.H1]: css`
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H2]: css`
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H3]: css`
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H4]: css`
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H5]: css`
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H6]: css`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H7]: css`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H8]: css`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H9]: css`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    text-transform: capitalize;
  `,
  [TEXT_VARIANT_TYPES.CONTENT_1]: css`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.CONTENT_2]: css`
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.BODY_1]: css`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.BODY_2]: css`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.CAPTION_1]: css`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.21875rem; /* 150% */
  `,
  [TEXT_VARIANT_TYPES.CAPTION_2]: css`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.21875rem; /* 150% */
  `,
  [TEXT_VARIANT_TYPES.CAPTION_3]: css`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.CAPTION_4]: css`
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
};

const Text: React.FC<ITextCusProps> = ({
  variant,
  color = 'text_3',
  children = null,
  textDecorate,
  textAlign,
  style,
  width,
  textTransform,
  ...props
}) => {
  const styles: React.CSSProperties = {
    ...generateCssSpacing(props),
    ...(style ?? {}),
  };

  return (
    <TextCusStyled
      variant={variant}
      color={colorTheme[color]}
      textDecorate={textDecorate}
      textAlign={textAlign}
      style={styles}
      width={width}
      textTransform={textTransform}
      {...props}
    >
      {children}
    </TextCusStyled>
  );
};

export default Text;
type TextCusStyledProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  variant: keyof typeof TEXT_VARIANT_TYPES;
  color: string;
  textDecorate?: string;
  textAlign?: React.CSSProperties['textAlign'];
  width?: string;
  textTransform?: React.CSSProperties['textTransform'];
};
const TextCusStyled = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'variant',
      'textDecorate',
      'textAlign',
      'textTransform',
    ].includes(prop),
}) <TextCusStyledProps>`
  ${({ variant }) => stylesMap[variant] || null}
  color: ${({ color }) => color};
  ${({ textDecorate }) => textDecorate && `text-decoration: ${textDecorate};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
`;
