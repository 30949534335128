import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { BanlistState, IPagingListState } from "./types";
import actions from './actions';
import { IApiGetBlogsParams } from "./types/blog";
import { IBanlistPagingResData } from "./types/paging";

export const generatePagingReducerActions = (key: keyof typeof actions, builder: ActionReducerMapBuilder<BanlistState>) => {
  builder
    .addCase(actions[key].fetchList.pending, (state, action) => {
      const storeParams = state[key].params;
      state[key].params = convertParams(storeParams, action.meta.arg);
      state[key].loading = true;
    })
    .addCase(actions[key].fetchList.fulfilled, (state, action) => {
      const { content = [], totalElements = 0, totalPages = 0 } = action.payload || {};
      state[key].paging.totalElements = totalElements;
      state[key].paging.totalPages = totalPages;
      state[key].data = content;
      state[key].loading = false;
    })
    .addCase(actions[key].fetchList.rejected, (state) => {
      state[key].loading = false;
    })
};

export const pagingReducerActions = {
  pending: (state: IPagingListState, action: any) => {
    const storeParams = state.params;
    const param = action.meta.arg as IApiGetBlogsParams;
    state.params = convertParams(storeParams, param);
    state.loading = true;
  },

  fulfilled: (state: IPagingListState, action: any) => {
    const { content = [], totalElements = 0, totalPages = 0 } = (action.payload || {}) as IBanlistPagingResData;
    state.paging.totalElements = totalElements;
    state.paging.totalPages = totalPages;
    state.data = content;
    state.loading = false;
  },

  rejected: (state: IPagingListState) => {
    state.loading = false;
  }
};

export const generatePagingInitialState = () => ({
  params: { page: 1, size: 10 },
  data: [],
  paging: {
    totalElements: 0,
    totalPages: 0,
  },
  loading: false,
});

export const convertParams = (storeParams: IApiGetBlogsParams, _params: IApiGetBlogsParams) => {
  const params = { ...storeParams || {}, ..._params || {} };

  if (params.keySearch === '' || params.keySearch === undefined || params.keySearch === null) {
    delete params.keySearch;
  };

  return params;
};
