import { api } from "../helpers/api";
import { IApiGetBlogsParams } from "../store/banlist/types/blog";
import { IApiGetUsersParams } from "../store/banlist/types/user";


const banlistApis = {
  blog: {
    dashboard: async () => {
      return await api.get('/api/v1/admin/ban/dashboard');
    },
    list: async (_params: IApiGetBlogsParams) => {
      const params = _params;
      params.size = 10;
      return await api.get('/api/v1/admin/ban/blogs', { params });
    },
    delete: async (blogId: string) => {
      return await api.delete(`/api/v1/admin/ban/delete-blog/${blogId}`);
    },
    addCustomer: async (formData: FormData) => {
      return await api.post('/api/v1/admin/ban/add-customer', formData, {
        headers: {
          'accept': '*/*',
          'Content-Type': 'multipart/form-data',
        }
      });
    },
    detail: async (blogId: string) => {
      return await api.get(`/api/v1/admin/ban/blog-detail/${blogId}`)
    },
    reports: async (blogId: string) => {
      return await api.get(`/api/v1/admin/ban/report-blogs/${blogId}`)
    },
    searchCustomer: async (keySearch: string) => {
      const params = { keySearch };
      return await api.get('/api/v1/admin/ban-customers/search', { params });
    }
  },
  user: {
    list: async (_params: IApiGetUsersParams) => {
      const params = _params;
      params.size = 10;
      return await api.get('/api/v1/admin/ban-users/list', { params });
    },
    delete: async (userId: string) => {
      return await api.delete(`/api/v1/admin/ban-users/block/${userId}`);
    },
    detail: async (userId: string) => {
      return await api.get(`/api/v1/admin/ban-users/detail/${userId}`);
    },
    userBlogs: async (userId: string, params: IApiGetBlogsParams) => {
      return await api.get(`/api/v1/admin/ban-users/${userId}/blogs`, { params });
    },
  },
  customer: {
    list: async (_params: IApiGetUsersParams) => {
      const params = _params;
      params.size = 10;
      return await api.get('/api/v1/admin/ban-customers/list', { params });
    },
    delete: async (customerId: string) => {
      return await api.delete(`/api/v1/admin/ban-customers/delete/${customerId}`);
    },
    detail: async (userId: string) => {
      return await api.get(`/api/v1/admin/ban-customers/detail/${userId}`);
    },
    customerBlogs: async (customerId: string, params: IApiGetBlogsParams) => {
      return await api.get(`/api/v1/admin/ban-customers/${customerId}/blogs`, { params });
    },
  }
};

export default banlistApis;
