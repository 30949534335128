import { api } from "../helpers/api";

const filterRequestSupport = async (
  shopId: string | null,
  status: string | null,
  page: number,
  size: number) => {
  const params = { shopId, status, size, page };
  return await api.get('/api/v1/admin/request-support/all', { params }).then((response: any) => {
    return response;
  });
};

const updateStatus = async (
  supportIds: string[],
  status: string,
  shopId: string | null) => {
  const payload = { supportIds, status, shopId };
  return await api.post('/api/v1/admin/request-support/update-status', payload).then((response: any) => {
    return response;
  });
};

const updateNote = async (
  supportId: string,
  note: string) => {
  const payload = { supportId, note };
  return await api.post('/api/v1/admin/request-support/update-note-reply', payload).then((response: any) => {
    return response;
  });
};

const requestSupportApis = {
  filterRequestSupport,
  updateStatus,
  updateNote
};

export default requestSupportApis;
