import { createSlice } from '@reduxjs/toolkit';
import { generatePagingInitialState } from '../pagingServices';
import { BanlistState } from '../types';
import blogCaseHandler from './blog';
import userCaseHandler from './user';
import customerCaseHandler from './customer';
const initialState: BanlistState = {
  loading: false,
  blogExtra: {
    dashboard: null,
    customers: [],
    loadingSearch: false,
    detail: null,
    openReport: false,
    reports: [],
  },
  blog: generatePagingInitialState(),
  user: generatePagingInitialState(),
  userDetail: {
    blog: generatePagingInitialState(),
    data: null,
    id: null,
  },
  customer: generatePagingInitialState(),
  customerDetail: {
    blog: generatePagingInitialState(),
    data: null,
    id: null,
  },
};

export const Slice = createSlice({
  name: 'banlist',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    closeBlogDetail: (state) => {
      state.blogExtra.detail = null;
    },
    closeReportDetail: (state) => {
      state.blogExtra.openReport = false;
      state.blogExtra.reports = [];
    },
    setDetailCustomer: (state, action) => {
      state.customerDetail.data = action.payload;
      state.customerDetail.id = action.payload.id;
    }
  },
  extraReducers: builder => {
    blogCaseHandler(builder);
    userCaseHandler(builder);
    customerCaseHandler(builder);
  },
});
export const banlistUIActions = Slice.actions;
const banlistReducer = Slice.reducer;
export default banlistReducer;
