import { Col, Form, Input, Radio, Row, Select, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { formatPhoneNumber } from '../../../helpers/FunctionUtils';
import accountApis from '../../../services/accountApis';
import { setLoading } from '../../../store/loading/actions';
import BannerPromotionUpload from './BannerPromotionUpload';
import { appendListenerPromotionLayoutSubmitAction } from './Layout';
import { downIcon } from './helpers';
import { IPromotionPushNotificationBody, IPromotionReportDetail } from './types';
import { TableEvenOdd } from '../styles';

const PromotionPushNotificationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: shopId = '', promotionId = '' } = useParams();
  const [form] = Form.useForm();
  const [typeCustomer, setTypeCustomer] = useState<any>('all');
  const [lstGroup, setLstGroup] = useState<any>(null);
  const [lstCustomer, setLstCustomer] = useState<any[]>([]);

  const initData = async () => {
    if (!promotionId) return;
    dispatch(setLoading(true));
    try {
      const res: AxiosResponse<{ data: IPromotionReportDetail }> = await accountApis.getReportPromotionDetail(promotionId);
      const _details = res?.data?.data?.promotion;
      if (_details) {
        form.setFieldsValue({
          base64: _details.bannerUrl,
          title: _details.title,
          subDescription: '',
          description: _details.description,
        });
      } else {
        navigate(-1);
        message.error('Not Found Promotion Data');
      }
    } catch (error) {
      navigate(-1);
      message.error('Not Found Promotion Data');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const handleOnchangeSelectGroupCampaign = async () => {
    const currentValue = form.getFieldValue('campaignCustomerSelectGroup') || '';
    if (currentValue == '') {
      setLstCustomer([]);
    } else {
      try {
        dispatch(setLoading(true));
        const res = await accountApis.getLstCustomerByGroup(currentValue);
        if (res.data.data) {
          setLstCustomer(res.data.data);
        } else {
          message.error({ content: res.data.message, duration: 2 });
        }
      } catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const init = async () => {
    try {
      const res = await accountApis.getLstGroupByShopId(shopId);
      if (res.data.data) {
        setLstGroup(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    init();
    const removeEventListener = appendListenerPromotionLayoutSubmitAction(() => form.submit());
    return () => removeEventListener();
  }, []);

  const removeCustomerByPhone = (phoneToRemove: string): void => {
    setLstCustomer((prevCustomers) =>
      prevCustomers.filter((customer) => customer.phone !== phoneToRemove)
    );
  };

  const handleFinish = async (values: any) => {
    console.log('handleFinish', values);
    const body: IPromotionPushNotificationBody = {
      allCustomer: typeCustomer === 'all',
      customers: typeCustomer === 'all' ? [] : lstCustomer.map((customer) => ({
        customerId: customer.id,
        customerName: customer.name,
        customerPhone: customer.phone,
      })),
      description: values.description || '',
      subDescription: values.subDescription || '',
      title: values.title || '',
      promotionId,
      shopId,
    };
    dispatch(setLoading(true));
    try {
      const res: AxiosResponse<{ data: boolean }> = await accountApis.pushPromotionNotification(body);
      if (res.data.data) {
        message.success('Push Notification Successful');
        navigate(`/promotions/${shopId}`);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const columnsCustomerSelect: ColumnsType<any> = [
    {
      title: <p>NAME</p>,
      dataIndex: 'name',
      className: 'name',
      render: (name: string) => <a>{name}</a>,
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      className: 'phone',
      render: (phone: string) =>
        <span>{formatPhoneNumber(phone)}</span>,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      className: 'email',
      render: (email: string) =>
        <span>{email}</span>,
    },
    {
      title: <p>ACTION</p>,
      render: (item: any) =>
        <Button className='btn-delete' icon="delete" onClick={() => removeCustomerByPhone(item.phone)}></Button>
    },
  ];

  return (
    <Container layout='vertical' form={form} onFinish={handleFinish}>
      <Form.Item name={'base64'}>
        <BannerPromotionUpload disabled />
      </Form.Item>
      <BoxCard>
        <div className="header">
          <div className="header-title"><span>Notification Information</span></div>
        </div>
        <div className="body">
          <Row style={{ width: '100%', gap: 24 }}>
            <Col flex={1} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <Form.Item name={'title'} label='Title'>
                <Input placeholder='Please enter...' />
              </Form.Item>
              <Form.Item name={'subDescription'} label='Sub Description'>
                <Input placeholder='Please enter...' />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item name={'description'} label='Description' className='description'>
                <Input.TextArea rows={5} placeholder='Please enter...' />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </BoxCard>
      <BoxCard>
        <div className="header">
          <div className="header-title"><span>Customer’s Group</span></div>
        </div>
        <div className="body">
          <Row style={{ alignSelf: 'stretch' }}>
            <Radio.Group value={typeCustomer} onChange={e => setTypeCustomer(e.target.value)}>
              <Row style={{ marginTop: '10px', gap: 24 }}>
                <Radio value="all">All</Radio>
                <Radio value="selectGroupCampaign">Group’s Campaign</Radio>
              </Row>
            </Radio.Group>
          </Row>
          {typeCustomer === 'selectGroupCampaign' && (
            <Form.Item label='Group' name='campaignCustomerSelectGroup' style={{ width: '100%' }}>
              <Select placeholder='Select group' size='large'
                onChange={handleOnchangeSelectGroupCampaign}
                suffixIcon={downIcon}
              >
                {lstGroup?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.groupName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <TableStyled
            columns={columnsCustomerSelect}
            dataSource={lstCustomer}
            rowKey="id"
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
          />
        </div>
      </BoxCard>
    </Container>
  );
};

export default PromotionPushNotificationPage;

const Container = styled(Form)`
border-radius: 5px;
border: 1px solid #DADADA;
background: #FFF;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 24px 16px;
gap: 24px;
.banner {
  width: 324px;
  height: 199.26px;
  flex-shrink: 0;
  border-radius: 10px;
}
.ant-form-item {
  margin: 0;
}
.ant-form-item.description {
  height: 100%;
  .ant-form-item-row {
    height: 100%;
    .ant-form-item-control .ant-form-item-control-input {
      height: 100%;
      .ant-form-item-control-input-content {
        height: 100%;
      }
    }
  }
}
.ant-form-item-label {
  padding: 0;
}
.ant-form-item-label label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-input {
  display: flex;
  height: 46px;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #DADADA;
  background: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
textarea.ant-input {
  height: 100%;
}

.ant-select.ant-select-single {
  height: auto;
}
.ant-select .ant-select-arrow {
  height: auto;
  transform: translateY(-50%);
  margin-top: 0;
}
  
.ant-select .ant-select-selector {
  display: flex;
  height: 46px !important;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #DADADA;
  background: #FFF;
}
`;

const BoxCard = styled.div`
  display:flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #DADADA;
  background: #F6F7FC;
  .header {
    display: flex;
    padding: 12px 0px 20px 15px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    background: #FFF;
    .header-title {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      span {
        flex: 1 0 0;
        color: #1D2129;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
      }
    }
  }

  .body {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 5px;
  }
`;

export const TableStyled = styled(TableEvenOdd)`
width: 100%;
  thead > tr > th.ant-table-cell {
    padding: 8px 16px;
    p {
        margin: 0;
    }
  } 
`;
