import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import AccountPage from './pages/account/AcountList';
import { SalonInfoPage } from './pages/account/SalonInfo';
import { SalonCustomersPage } from './pages/account/SalonCustomers';
import { SalonGiftCardPage } from './pages/account/SalonGiftCard';
import { SalonGiftCardCreatePage } from './pages/account/SalonGiftCardCreate';
import { SalonSmsCampCreatePage } from './pages/account/SalonSmsCampCreate';
import { SalonSmsCampUpdatePage } from './pages/account/SalonSmsCampUpdate';
import { CouponListPage } from './pages/account/CouponList';
import { CouponCreatePage } from './pages/account/CouponCreate';
import { CouponDetailPage } from './pages/account/CouponDetail';
import { SalonMarketingPage } from './pages/account/SalonMarketing';
import { SalonCreditCardPage } from './pages/account/SalonCreditCard';
import { AdminDashboardPage } from './pages/dashboard';
import { NotFoundPage } from './pages/NotFoundPage';
import { SalonEmailCampCreatePage } from './pages/account/SalonEmailCampCreate';
import { SalonEmailCampUpdatePage } from './pages/account/SalonEmailCampUpdate';
import { SalonSettingPage } from './pages/account/SalonSetting';
import { ListRequestSupportPage } from './pages/request-support/ListRequestSupport';
import BanlistBlogs from './pages/banlist/Blogs';
import BanlistLayout from './pages/banlist/BanlistLayout';
import BanListCustomers from './pages/banlist/Customers';
import BanListUsers from './pages/banlist/Users';
import BanListUserDetailPage from './pages/banlist/Users/UserDetail';
import BanListCustomerDetailPage from './pages/banlist/Customers/CustomerDetail';
import { SalonEmployeesPage } from './pages/account/SalonEmployees';
import PromotionsPage from './pages/account/Promotion/PromotionsPage';
import PromotionLayout from './pages/account/Promotion/Layout';
import AddPromotionPage from './pages/account/Promotion/AddPromotionPage';
import DetailPromotionPage from './pages/account/Promotion/DetailPromotionPage';
import PromotionPushNotificationPage from './pages/account/Promotion/PushNotificationPage';
import ReportAdminPage from './pages/report/ReportPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={
          <PrivateRoute children={null} />
        } />

        <Route path='/dashboard' element={
          <PrivateRoute children={<AdminDashboardPage />} />
        } />

        <Route path='/login' element={
          <PrivateRoute children={null} />
        } />

        <Route path='/accounts' element={
          <PrivateRoute children={<AccountPage />} />
        } />

        <Route path='/coupons/:id' element={
          <PrivateRoute children={<CouponListPage />} />
        } />

        <Route path='/coupons/:id/create' element={
          <PrivateRoute children={<CouponCreatePage />} />
        } />

        <Route path='/coupons/:shopId/detail/:id' element={
          <PrivateRoute children={<CouponDetailPage />} />
        } />

        <Route path='/salon/:id' element={
          <PrivateRoute children={<SalonInfoPage />} />
        } />
        <Route path='/employees/:id' element={
          <PrivateRoute children={<SalonEmployeesPage />} />
        } />

        <Route path='/customers/:id' element={
          <PrivateRoute children={<SalonCustomersPage />} />
        } />

        <Route path='/gift-card/:id' element={
          <PrivateRoute children={<SalonGiftCardPage />} />
        } />

        <Route path='/gift-card/:id/create' element={
          <PrivateRoute children={<SalonGiftCardCreatePage />} />
        } />

        <Route path='/marketing/:id' element={
          <PrivateRoute children={<SalonMarketingPage />} />
        } />

        <Route path='/marketing/:id/sms-campaign/create' element={
          <PrivateRoute children={<SalonSmsCampCreatePage />} />
        } />

        <Route path='/marketing/:id/sms-campaign/:campaignId/update' element={
          <PrivateRoute children={<SalonSmsCampUpdatePage />} />
        } />

        <Route path='/marketing/:id/email-campaign/create' element={
          <PrivateRoute children={<SalonEmailCampCreatePage />} />
        } />

        <Route path='/marketing/:id/email-campaign/:campaignId/update' element={
          <PrivateRoute children={<SalonEmailCampUpdatePage />} />
        } />

        <Route path='/credit-card/:id' element={
          <PrivateRoute children={<SalonCreditCardPage />} />
        } />

        <Route path='/setting/:id' element={
          <PrivateRoute children={<SalonSettingPage />} />
        } />

        <Route path='/promotions/:id' element={<PrivateRoute children={<PromotionLayout />} />} >
          <Route path='' element={<PromotionsPage />} />
          <Route path='create' element={<AddPromotionPage />} />
          <Route path='detail/:promotionId' element={<DetailPromotionPage />} />
          <Route path='push-notification/:promotionId' element={<PromotionPushNotificationPage />} />
        </Route>


        <Route path='/request-support' element={
          <PrivateRoute children={<ListRequestSupportPage />} />
        } />
        <Route path='/report' element={
          <PrivateRoute children={<ReportAdminPage />} />
        } />
        <Route path='/banlist' element={<PrivateRoute noneFooter children={<BanlistLayout />} />}>
          <Route path='' element={<BanlistBlogs />} />
          <Route path='users' element={<BanListUsers />} />
          <Route path='users/:id' element={<BanListUserDetailPage />} />
          <Route path='customers' element={<BanListCustomers />} />
          <Route path='customers/:id' element={<BanListCustomerDetailPage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
}

export default App;
