import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import accountApis from '../../../services/accountApis';
import { setLoading } from '../../../store/loading/actions';
import { formatCurrency, formatNumber } from '../../../utils/formatCurrency';
import { formatPhone } from '../../../utils/formatPhone';
import { TableStyled } from './helpers';
import amountDashboardIcon from './icons/detail-dashboard/amount-dashboard.svg';
import clickDashboardIcon from './icons/detail-dashboard/click-dashboard.svg';
import profitDashboardIcon from './icons/detail-dashboard/profit-dashboard.svg';
import sendDashboardIcon from './icons/detail-dashboard/send-dashboard.svg';
import shareDashboardIcon from './icons/detail-dashboard/share-dashboard.svg';
import usedDashboardIcon from './icons/detail-dashboard/used-dashboard.svg';
import { IPromotionReportDetail, IPromotionReportDetailTrackingItem } from './types';

enum TOTAL_ITEM_TYPE {
  SEND = 'SEND', //totalSendCustomer
  CLICK = 'CLICK', //totalClick
  USED = 'USED', //totalUsed
  AMOUNT = 'AMOUNT', //totalAmount
  PROFIT = 'PROFIT', //profit
  SHARE = 'SHARE', //totalSharePromotion,
}
const DetailPromotionPage = () => {
  const dispatch = useDispatch();
  const { promotionId = '' } = useParams();
  const [data, setData] = useState<IPromotionReportDetail | null>(null);

  const detail = useMemo(() => data?.promotion || null, [data]);

  const totals = useMemo(() => {
    const { profit = 0, totalAmount = 0, totalClick = 0, totalSendCustomer = 0, totalSharePromotion = 0, totalUsed = 0 } = data || {};
    return ([
      { type: TOTAL_ITEM_TYPE.SEND, value: formatNumber(totalSendCustomer) },
      { type: TOTAL_ITEM_TYPE.CLICK, value: formatNumber(totalClick) },
      { type: TOTAL_ITEM_TYPE.USED, value: formatNumber(totalUsed) },
      { type: TOTAL_ITEM_TYPE.AMOUNT, value: formatCurrency(totalAmount) },
      { type: TOTAL_ITEM_TYPE.PROFIT, value: formatCurrency(profit) },
      { type: TOTAL_ITEM_TYPE.SHARE, value: formatNumber(totalSharePromotion) },
    ]);
  }, [data]);

  const date = useMemo(() => {
    if (!detail) return null;
    const { startDate, endDate } = detail;
    const _startDate = dayjs(startDate);
    const _endDate = dayjs(endDate);
    const dayLeft = _endDate.diff(_startDate, 'days');
    return ({
      startDate: _startDate.format('MM-DD-YYYY'),
      dayLeft: dayLeft > 0 ? dayLeft : 0,
    });
  }, [detail]);

  const initData = async () => {
    if (!promotionId) return;
    dispatch(setLoading(true));
    try {
      const res: AxiosResponse<{ data: IPromotionReportDetail }> = await accountApis.getReportPromotionDetail(promotionId);
      if (res.data.data) {
        setData(res.data.data);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    initData();
  }, []);


  const columns: ColumnsType<IPromotionReportDetailTrackingItem> = [
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      render(value) {
        return `#${value}`;
      },
    },
    {
      title: 'CUSTOMER',
      render({ customerName, phone }: IPromotionReportDetailTrackingItem) {
        return <>{customerName || '--'} - {formatPhone(phone)}</>;
      },
    },
    { title: 'AMOUNT', dataIndex: 'amount' },
    {
      title: 'PROMOTION',
      render({ promotionType, promotionValue }: IPromotionReportDetailTrackingItem) {
        return promotionType === 'PERCENT' ? `${promotionValue || 0}%` : formatCurrency(promotionValue);
      },
    },
    { title: 'Created Date', dataIndex: 'createdDate' },
  ];

  return (
    <Container>
      <div className="top-content">
        <div>
          <div className="promotion-card-info">
            <img className='promotion-card-banner' src={detail?.bannerUrl} />
            <div className="promotion-card-title">{detail?.title}</div>
            <div>
              <div className="promotion-card-date">Start date: {date?.startDate || '--'}</div>
              <div className="promotion-card-date">Expires date: {date?.dayLeft || 0} days left</div>
            </div>
            <div className="promotion-info-items">
              {detail?.description.split('\r\n').map(o => (
                <div key={o} className="promotion-info-item">
                  {doneIcon}
                  <span>{o}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="top-content-item">
          <TotalItems data={totals} />
        </div>
      </div>
      <div>
        <WrapTabled>
          <div className="table-title">Tracking Promotions</div>
          <Table columns={columns} dataSource={data?.tracking || []} pagination={false} />
        </WrapTabled>
      </div>
    </Container>
  );
};

export default DetailPromotionPage;

const WrapTabled = styled(TableStyled)`
  .table-title {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;  
    margin-bottom: 16px;
  }
`;
const Container = styled.div`
min-height: 100vh;
  .top-content {
    display:flex;
    padding: 8px 8px;
    background: #FFF;
    border-radius: 5px;
    gap: 16px;
    margin-bottom: 16px;

    .top-content-item {
      flex: 1;
    }
  }

  .promotion-card-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    

    .promotion-card-banner {
      width: 327px;
      height: 200px;
      border-radius: 10px;
      background-color: #8E9BA9;
    }

    .promotion-card-title {
      overflow: hidden;
      color: #1D2129;
      max-width: 327px;
      text-align: left;
      text-overflow: ellipsis;
      font-family: Blinker;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      align-self: stretch;
    }

    .promotion-card-date {
      color: #1D2129;
      text-align: left;
      font-family: Blinker;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
    }

    .promotion-info-items {
      display: flex;
      flex-direction: column;
      .promotion-info-item {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
          color: #566A7F;
          font-family: Blinker;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 137.5%; /* 19.25px */
          letter-spacing: 0.28px;
        }
      }
    }
  }
`;


const doneIcon = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.00016 1.69971C4.32683 1.69971 1.3335 4.69304 1.3335 8.36637C1.3335 12.0397 4.32683 15.033 8.00016 15.033C11.6735 15.033 14.6668 12.0397 14.6668 8.36637C14.6668 4.69304 11.6735 1.69971 8.00016 1.69971ZM11.1868 6.83304L7.40683 10.613C7.3135 10.7064 7.18683 10.7597 7.0535 10.7597C6.92016 10.7597 6.7935 10.7064 6.70016 10.613L4.8135 8.72637C4.62016 8.53304 4.62016 8.21304 4.8135 8.01971C5.00683 7.82637 5.32683 7.82637 5.52016 8.01971L7.0535 9.55304L10.4802 6.12637C10.6735 5.93304 10.9935 5.93304 11.1868 6.12637C11.3802 6.31971 11.3802 6.63304 11.1868 6.83304Z" fill="#008BFF" />
  <path d="M6.98963 10.7991C6.84734 10.7991 6.71216 10.7422 6.61256 10.6426L4.59908 8.62916C4.39275 8.42283 4.39275 8.08133 4.59908 7.87501C4.8054 7.66868 5.14691 7.66868 5.35323 7.87501L6.98963 9.51137L10.6466 5.85445C10.8529 5.64813 11.1944 5.64813 11.4007 5.85445C11.607 6.06077 11.607 6.40228 11.4007 6.6086L7.36671 10.6426C7.2671 10.7422 7.13192 10.7991 6.98963 10.7991Z" fill="white" />
</svg>
  ;
type TotalItemProps = {
  data: {
    type: TOTAL_ITEM_TYPE;
    value: string;
  }[]
};
const totalIcons = {
  [TOTAL_ITEM_TYPE.SEND]: sendDashboardIcon,
  [TOTAL_ITEM_TYPE.AMOUNT]: amountDashboardIcon,
  [TOTAL_ITEM_TYPE.CLICK]: clickDashboardIcon,
  [TOTAL_ITEM_TYPE.PROFIT]: profitDashboardIcon,
  [TOTAL_ITEM_TYPE.SHARE]: shareDashboardIcon,
  [TOTAL_ITEM_TYPE.USED]: usedDashboardIcon,
};
const totalLabels = {
  [TOTAL_ITEM_TYPE.SEND]: 'TOTAL SENT CUSTOMERS',
  [TOTAL_ITEM_TYPE.AMOUNT]: 'TOTAL AMOUNT',
  [TOTAL_ITEM_TYPE.CLICK]: 'TOTAL CLICKED',
  [TOTAL_ITEM_TYPE.PROFIT]: 'PROFIT',
  [TOTAL_ITEM_TYPE.SHARE]: 'TOTAL SHARED\nPROMOTION',
  [TOTAL_ITEM_TYPE.USED]: 'TOTAL USED',
};
const TotalItems = ({ data }: TotalItemProps) => {
  return <TotalItemsStyled>
    {data.map(o => (
      <div key={o.type} className={`total-item ${o.type}`}>
        <div className="icon">
          <img src={totalIcons[o.type]} />
        </div>
        <div className="total-item-label">
          {totalLabels[o.type]}
        </div>
        <div className="total-item-value">
          {o.value}
        </div>
      </div>
    ))}
  </TotalItemsStyled>;
};

const TotalItemsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 14px;

  .total-item {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    border-radius: 6px;
    background: #DBEFFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);

    .icon {
      display: flex;
      width: 80px;
      height: 80px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 61px;
      background: #fff;
      img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
    }

    .total-item-label {
      align-self: stretch;
      color: #7B7B7B;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .total-item-value {
      color: #1D2129;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.${TOTAL_ITEM_TYPE.SEND} {
      background: #DBEFFF;
    }
    &.${TOTAL_ITEM_TYPE.CLICK} {
      background: #E2E3FF;
    }
    &.${TOTAL_ITEM_TYPE.USED} {
      background: #FFF6D9;
    }
    &.${TOTAL_ITEM_TYPE.AMOUNT} {
      background: #FFF1F6;
    }
    &.${TOTAL_ITEM_TYPE.PROFIT} {
      background: #F2FFF6;
    }
    &.${TOTAL_ITEM_TYPE.SHARE} {
      background: #F1EAFF;
    }
  }
`;