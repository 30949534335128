import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FORMAT_DATE_REQUEST } from "../constants";
import { IApiGetUsersParams, IBanlistUserDetailResData, IBanlistUsersResData } from "../types/user";
import { RootState } from "../types";
import { convertParams } from "../pagingServices";
import banlistApis from "../../../services/banlistApis";
import { IApiGetBlogsParams, IBanlistBlogsResData } from "../types/blog";
const PREFIX_ACTION = 'BANLIST_USER_';
const user = {
  init: createAsyncThunk(
    PREFIX_ACTION + 'INIT',
    (arg, thunkAPI) => {
      const startDate = dayjs().startOf('month').format(FORMAT_DATE_REQUEST);
      const endDate = dayjs().endOf('month').format(FORMAT_DATE_REQUEST);
      thunkAPI.dispatch(user.fetchList({
        page: 1, size: 10, startDate, endDate
      }));
    }),
  fetchList: createAsyncThunk<IBanlistUsersResData, IApiGetUsersParams>(
    PREFIX_ACTION + 'FETCH_LIST_DATA',
    async (_params, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const storeParams = state.banlist.user.params;
      const response = await banlistApis.user.list(convertParams(storeParams, _params));
      return response?.data?.data;
    },
  ),
  fetchBlogsByUser: createAsyncThunk<IBanlistBlogsResData, IApiGetBlogsParams>(
    PREFIX_ACTION + 'FETCH_BLOGS_BY_USER',
    async (args, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const storeParams = state.banlist.userDetail.blog.params;
      const userId = state.banlist.userDetail.id || '';
      const response = await banlistApis.user.userBlogs(userId, convertParams(storeParams, args));
      return response?.data?.data;
    }
  ),
  detail: createAsyncThunk<IBanlistUserDetailResData, string>(
    PREFIX_ACTION + 'FETCH_DETAIL_USER',
    async (userId, thunkAPI) => {
      const startDate = dayjs().startOf('month').format(FORMAT_DATE_REQUEST);
      const endDate = dayjs().endOf('month').format(FORMAT_DATE_REQUEST);
      thunkAPI.dispatch(user.fetchBlogsByUser({ startDate, endDate }));

      const response = await banlistApis.user.detail(userId);
      return response?.data?.data;
    }
  ),
};

export default user;
