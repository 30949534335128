import { Checkbox, Form, Input, Select } from 'antd';
import Box from '../../../components/Box';
import FormItemNoMarginStyled from '../../../components/FormItem/FormItemNoMarginStyled';
import Text from '../../../components/Text';
import colorTheme from '../../../constant/colors';
import { initialAccountDevices } from './constants';
import {
  Device,
  AddAccountFormProps,
} from './constants';
import React from 'react';
import styled from 'styled-components';
type ISelectDevicesProps = {
  watchDevices: Device[];
};
const SelectDevices: React.FC<ISelectDevicesProps> = ({ watchDevices }) => {
  const form = Form.useFormInstance<AddAccountFormProps>();
  const selectItem = watchDevices
    ?.filter((item) => item.enable)
    ?.map((item) => item.feature);

  const handleDeselect = (value: string) => {
    const updatedDevices = watchDevices?.map((device) => {
      if (device.feature === value) {
        return {
          ...device,
          enable: !device.enable,
        };
      }
      return device;
    });
    form.setFieldsValue({ devices: updatedDevices });
  };
  return (
    <FormItemNoMarginStyled>
      <Text variant="BODY_1" mb={0.5}>
        Devices
      </Text>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        value={selectItem}
        onDeselect={handleDeselect}
        optionLabelProp="label"
        size='large'
        dropdownRender={() => (
          <>
            <Box>
              <Form.List name="devices">
                {(fields) => (
                  <Box>
                    {fields.map(({ key, name, ...restField }, index) => {
                      return (
                        <ItemOptionBoxStyled key={key}>
                          <FormItemNoMarginStyled
                            {...restField}
                            valuePropName="checked"
                            name={[name, 'enable']}
                          >
                            <Checkbox>
                              {initialAccountDevices[index].label}
                            </Checkbox>
                          </FormItemNoMarginStyled>
                          <FormItemNoMarginStyled
                            {...restField}
                            name={[name, 'numberDevice']}
                          >
                            <Input style={{ maxWidth: '100px' }} placeholder={'0 device'} />
                          </FormItemNoMarginStyled>
                        </ItemOptionBoxStyled>
                      );
                    })}
                  </Box>
                )}
              </Form.List>
            </Box>
          </>
        )}
      />
    </FormItemNoMarginStyled>
  );
};

export default SelectDevices;
const ItemOptionBoxStyled = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${colorTheme.fill_3};
  }
`;
