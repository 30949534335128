import styled from 'styled-components';

import appointmentIcon from './icons/source-type/calendar.svg';
import VIPIcon from './icons/source-type/VIP.svg';
import giftCardIcon from './icons/source-type/giftCard.svg';
import shareIcon from './icons/source-type/share.svg';
import { capitalize } from 'lodash';

import deleteActionIcon from './icons/actions/delete.svg';
import editActionIcon from './icons/actions/edit.svg';
import notifyActionIcon from './icons/actions/notify.svg';
import notifyActiveActionIcon from './icons/actions/notifyActive.svg';
import { Form } from 'antd';
import { FileType } from './types';


export enum PromotionSourceType {
  GIFT_CARD = 'GIFT_CARD',
  APPOINTMENT = 'APPOINTMENT',
  VIP = 'VIP',
  SHARING = 'SHARING',
}

export enum PromotionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum CouponTemplateType {
  DISCOUNT = 'DISCOUNT',
  GIFT_CARD = 'GIFT_CARD',
  LOYALTY_POINT = 'LOYALTY_POINT',
  SHARE_REFERRAL = 'SHARE_REFERRAL',
  MARKETING = 'MARKETING',
  REVIEW = 'REVIEW',
  TICKET = 'TICKET',
  APPOINTMENT = 'APPOINTMENT',
  BIRTHDAY = 'BIRTHDAY',
  NO_VISIT = 'NO_VISIT',
  BEST_SPEND_MONEY = 'BEST_SPEND_MONEY',
  BEST_LOYALTY = 'BEST_LOYALTY',
}

export enum PromotionCategoryType {
  SPECIAL = 'SPECIAL',
  NORMAL = 'NORMAL',
}

export const promotionSourceTypeList = Object.values(PromotionSourceType).map(o => ({
  label: o?.replace('_', ' '),
  value: o,
}));

export const promotionStatusList = Object.values(PromotionStatus).map(o => ({
  label: capitalize(o),
  value: o,
}));



export const SearchPrefixIcon = ({ fill }: { fill?: string }) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_7493_8493)">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill={fill || '#8E9BA9'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.487 17.9555C17.7796 17.6622 18.2544 17.6616 18.5477 17.9541L22.0717 21.469C22.365 21.7615 22.3656 22.2363 22.0731 22.5296C21.7806 22.8229 21.3057 22.8235 21.0125 22.531L17.4884 19.0161C17.1952 18.7236 17.1945 18.2487 17.487 17.9555Z" fill={fill || '#8E9BA9'} />
  </g>
  <defs>
    <clipPath id="clip0_7493_8493">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>;
export const AddIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V18.75C12.75 19.1642 12.4142 19.5 12 19.5C11.5858 19.5 11.25 19.1642 11.25 18.75V5.25C11.25 4.83579 11.5858 4.5 12 4.5Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M4.5 12C4.5 11.5858 4.83579 11.25 5.25 11.25H18.75C19.1642 11.25 19.5 11.5858 19.5 12C19.5 12.4142 19.1642 12.75 18.75 12.75H5.25C4.83579 12.75 4.5 12.4142 4.5 12Z" fill="white" />
</svg>
  ;
export const DropdownIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <g clipPath="url(#clip0_7550_5115)">
    <path d="M6.5 9L12.5 15L18.5 9" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0_7550_5115">
      <rect width="24" height="24" fill="white" transform="translate(0.5)" />
    </clipPath>
  </defs>
</svg>;


export const FilterControlsStyled = styled.div`
    .ant-input-affix-wrapper {
      padding: 0 11px;
      width: auto;
      flex: 1;
      .ant-input {
        display: flex;
        height: 40px;
        padding: 0px;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
  
        &::placeholder {
          color: #566A7F;
          opacity: 1; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #566A7F;
        }
      }
    }
  
  
    .ant-select {
      flex: 1;
      &.ant-select-single {
        height: auto;
      }
      .ant-select-selector {
        display: flex;
        height: 40px;
        padding: 8px;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
  
        .ant-select-selection-item {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  
    .ant-btn {
      display: flex;
      height: 40px;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .ant-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
      }
      span {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 137.5%; /* 24.75px */
      }
      &.ant-btn-primary {
        border-radius: 5px;
        background: #008BFF;
        span {
          color: #fff;
        }
      }
      &.btn-add {
        border-radius: 5px;
        background: #FF8890;
        border-color: #FF8890;
        &:hover {
          background: #F5767F;
          border-color: #F5767F;
          span {
            color: #fff;
          }
        }
        &:disabled {
          background: #FFC3C7;
          border-color: #FFC3C7;
        }

        span {
          color: #fff;
        }
      }
    }
  `;


export const TableSourceTypeIcon = ({ type }: { type: PromotionSourceType }) => {
  switch (type) {
    case PromotionSourceType.VIP:
      return <img src={VIPIcon} style={{ width: 16, height: 16 }} />;
    case PromotionSourceType.SHARING:
      return <img src={shareIcon} style={{ width: 16, height: 16 }} />;
    case PromotionSourceType.GIFT_CARD:
      return <img src={giftCardIcon} style={{ width: 16, height: 16 }} />;
    case PromotionSourceType.APPOINTMENT:
      return <img src={appointmentIcon} style={{ width: 16, height: 16 }} />;
    default:
      return null;
  }
};

export const getSourceTypeLabel = (type: PromotionSourceType) => {
  switch (type) {
    case PromotionSourceType.VIP:
      return 'VIP';
    case PromotionSourceType.SHARING:
      return 'Sharing';
    case PromotionSourceType.GIFT_CARD:
      return 'E-Gift';
    case PromotionSourceType.APPOINTMENT:
      return 'Appt';
    default:
      return null;
  }
};



type ButtonActionProps = {
  type: 'delete' | 'edit' | 'notify' | 'notify-active';
  onClick?: () => void;
};

const getActionIcon = (type: ButtonActionProps['type']) => {
  switch (type) {
    case 'notify-active':
      return notifyActiveActionIcon;
    case 'notify':
      return notifyActionIcon;
    case 'delete':
      return deleteActionIcon;
    default:
      return editActionIcon;
  }
};
export const ButtonAction = ({ type, onClick }: ButtonActionProps) => {
  return <ButtonActionStyled onClick={onClick}>
    <img src={getActionIcon(type)} className="icon" />
  </ButtonActionStyled>;
};
const ButtonActionStyled = styled.button`
  background: transparent;
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #E3E9ED;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;
  }
`;


export const plusIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8 3.5C8.27614 3.5 8.5 3.72386 8.5 4V13C8.5 13.2761 8.27614 13.5 8 13.5C7.72386 13.5 7.5 13.2761 7.5 13V4C7.5 3.72386 7.72386 3.5 8 3.5Z" fill="#008BFF" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3 8.5C3 8.22386 3.22386 8 3.5 8H12.5C12.7761 8 13 8.22386 13 8.5C13 8.77614 12.7761 9 12.5 9H3.5C3.22386 9 3 8.77614 3 8.5Z" fill="#008BFF" />
</svg>;

export const calendarAfter = <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.84349 4.42574V3.41699H8.86092V4.42574H16.9306V3.41699H18.9481V4.42574H20.9655C21.8011 4.42574 22.4786 5.10319 22.4786 5.93887V21.0701C22.4786 21.9058 21.8011 22.5832 20.9655 22.5832H4.82606C3.99042 22.5832 3.31299 21.9058 3.31299 21.0701V5.93887C3.31299 5.10319 3.99041 4.42574 4.82606 4.42574H6.84349ZM6.84349 6.44324H5.33042V10.9826H20.4611V6.44324H18.9481V7.45199H16.9306V6.44324H8.86092V7.45199H6.84349V6.44324ZM20.4611 13.0001H5.33042V20.5657H20.4611V13.0001Z" fill="#8E9BA9" />
</svg>;

export const downIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clipPath="url(#clip0_7607_11336)">
    <path d="M6 9L12 15L18 9" stroke="#8E9BA9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0_7607_11336">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>;

export const FormControlsStyled = styled.div`

.ant-radio-group {
  width: 100%;
  display: flex;
  align-items: center;
  .ant-radio-wrapper {
    flex: 1;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ant-input {
  padding: 0;
  display: flex;
  height: 46px;
  padding: 5px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #DADADA;
  background: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  &.text-area {
    height: auto;
    min-height: 46px;
  }
}
  .ant-input-affix-wrapper.coupon-description textarea.ant-input {
    height: 100px;
  }


.ant-picker {
  width: 100%;
  display: flex;
  height: 46px;
  padding: 5px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #DADADA;
  background: #fff;
}
.ant-select.ant-select-single {
  height: auto;
}
.ant-select .ant-select-arrow {
  height: auto;
  transform: translateY(-50%);
  margin-top: 0;
}
.ant-select .ant-select-selector {
  display: flex;
  height: 46px;
  padding: 5px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #DADADA;
  background: #fff;
}
`;

export const FormControl = ({ children, label, required, name, mb0 }: { children?: any, label?: string, name?: string, required?: boolean, mb0?: boolean }) => {
  return (
    <FormControlItemStyled>
      <Form.Item name={name} style={mb0 ? { marginBottom: 4 } : {}} label={
        <div className="require-label">
          <span>{label} {required && <span className='require-symbol'>(*)</span>}</span>
        </div>
      }
        rules={[{ required: required }]}
      >
        {children}
      </Form.Item>
    </FormControlItemStyled>
  );
};



export const FormControlItemStyled = styled.div`
  .require-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span.require-symbol {
      color: #F53F3F;
      font-family: Poppins;
      font-size: 16px;
    }
  }
 `;

export const MAX_SIZE_IMAGE = 800000;
export const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const TableStyled = styled.div`
  table {
    tbody .ant-table-cell {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.profit {
        font-weight: 500;
      }
      &.actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

  }
`;