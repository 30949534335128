import React from 'react'

const AddBanlistIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.38367 1.33337H10.7513C12.0522 1.5261 13.2644 2.2741 13.9852 3.37892C14.3959 3.97808 14.6239 4.68267 14.7365 5.39536C14.8763 6.49541 14.6282 7.64651 14.0248 8.5796C13.2758 9.76933 11.9478 10.5736 10.5467 10.6843C10.1007 10.6771 9.65082 10.7153 9.20908 10.639C8.26359 10.4754 7.37248 10.0117 6.70128 9.32569C5.86265 8.47608 5.38322 7.29684 5.36033 6.10615C5.31262 4.49519 6.15317 2.88614 7.52227 2.02842C8.08327 1.66492 8.72584 1.43928 9.38367 1.33337Z" fill={fill || "white"} />
      <path d="M12.7588 17.2667C12.7588 17.989 12.9004 18.6759 13.1837 19.3274C13.4669 19.9789 13.8423 20.5454 14.3096 21.0269C14.777 21.5085 15.3435 21.8873 16.0092 22.1635C16.6748 22.4397 17.3618 22.5778 18.0699 22.5778C18.778 22.5778 19.465 22.4397 20.1306 22.1635C20.7963 21.8873 21.3628 21.5085 21.8302 21.0269C22.2975 20.5454 22.6729 19.9789 22.9561 19.3274C23.2394 18.6759 23.381 17.989 23.381 17.2667C23.381 16.5444 23.2394 15.8575 22.9561 15.206C22.6729 14.5545 22.2975 13.988 21.8302 13.5064C21.3628 13.0249 20.7998 12.6496 20.1412 12.3805C19.4827 12.1114 18.7922 11.9697 18.0699 11.9556C17.3476 11.9556 16.6607 12.0972 16.0092 12.3805C15.3577 12.6637 14.7912 13.039 14.3096 13.5064C13.8281 13.9738 13.4528 14.5403 13.1837 15.206C12.9146 15.8716 12.773 16.5585 12.7588 17.2667ZM14.0866 17.2667C14.0866 16.4311 14.3344 15.6663 14.8301 14.9723L20.3749 20.5171C19.6739 21.0057 18.9055 21.25 18.0699 21.25C17.3476 21.25 16.6819 21.073 16.0729 20.7189C15.4639 20.3648 14.9788 19.8833 14.6177 19.2743C14.2565 18.6653 14.0795 17.9961 14.0866 17.2667ZM15.7649 14.0269C16.4659 13.5312 17.2343 13.2833 18.0699 13.2833C18.7922 13.2833 19.4579 13.4639 20.0669 13.8251C20.6759 14.1862 21.161 14.6678 21.5221 15.2697C21.8833 15.8716 22.0603 16.5373 22.0532 17.2667C22.0532 18.1094 21.8054 18.8777 21.3097 19.5717L15.7649 14.0269Z" fill={fill || "white"} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3946 11.1835C14.1261 11.0439 13.8501 10.9182 13.5679 10.8075C13.4989 10.7756 13.4351 10.8144 13.3748 10.8511C13.3558 10.8627 13.3372 10.874 13.3189 10.8829C12.5714 11.3671 11.7031 11.6342 10.8283 11.7687C10.5791 11.8003 10.3277 11.7992 10.0767 11.7982C9.99369 11.7978 9.91074 11.7975 9.8279 11.7983C9.5059 11.8012 9.18438 11.7649 8.87239 11.6872C8.07478 11.5398 7.31534 11.2173 6.63222 10.7822C5.30796 11.2908 4.10916 12.1246 3.16891 13.1865C2.10082 14.3705 1.36141 15.8317 0.959269 17.3701C0.935887 17.5002 0.904128 17.6285 0.87237 17.7569C0.830264 17.9271 0.788162 18.0972 0.765592 18.2713C0.748075 18.4202 0.726096 18.5686 0.704118 18.717C0.670433 18.9445 0.63675 19.1719 0.619141 19.4014C0.70246 19.42 0.785773 19.4387 0.869089 19.4573C1.4412 19.5856 2.01349 19.7139 2.58979 19.8226C2.77975 19.8421 2.96701 19.879 3.15424 19.9158C3.36008 19.9564 3.56589 19.9969 3.77523 20.0144C3.94764 20.0307 4.11856 20.058 4.28945 20.0853C4.48171 20.1159 4.67391 20.1466 4.86813 20.1618C5.15676 20.1827 5.44436 20.2147 5.73191 20.2468C6.17339 20.2961 6.61477 20.3453 7.05964 20.354C7.18048 20.368 7.30249 20.369 7.42443 20.3701C7.6029 20.3716 7.78123 20.3731 7.95552 20.4151H12.1778C12.3262 20.3791 12.4773 20.3728 12.629 20.3709C11.7622 19.3214 11.2414 17.9755 11.2414 16.508C11.2414 14.2128 12.5153 12.2151 14.3946 11.1835Z" fill={fill || "white"} />
    </svg>
  )
}

export default AddBanlistIcon;
