export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOG_OUT = 'LOG_OUT';

export interface AuthenticatedUser {
    id: 0;
    firstName: string;
    lastName: string;
    phone: string
    email: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    status: string;
}

interface LoginRequest {
    type: typeof LOGIN_REQUEST;
    payload: {
        userName: string;
        password: string;
    };
}

interface LoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: {
        token: string;
        user: AuthenticatedUser;
    };
}

interface LoginFailure {
    type: typeof LOGIN_FAILURE;
    payload: {
        error: string;
    };
}

interface Logout {
    type: typeof LOG_OUT;
    payload: {
        token: string;
        user: AuthenticatedUser | null;
    };
}

export interface AccountState {
    user: AuthenticatedUser | null;
    loading: boolean;
    error: string | null;
    token: string | null;
}


export type AccountActionTypes =
    | LoginRequest
    | LoginSuccess
    | LoginFailure
    | Logout