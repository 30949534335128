
import { Col, Row, message, Breadcrumb } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Text from '../../components/Text';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import giftCardApis from '../../services/giftCardApis';
import Swal from 'sweetalert2';
import accountApis from '../../services/accountApis';
import { LeftOutlined } from '@ant-design/icons';
import Table from 'antd/es/table';
import { Content } from 'antd/es/layout/layout';
import { PageStyled } from '../styles';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';

export const SalonGiftCardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [lstGiftCardOfShop, setLstGiftCardOfShop] = useState<any[]>([]);
    const [listCardTemplate, setListCardTemplate] = useState<any[]>([]);
    const [sizeListGiftCard, setSizeListGiftCard] = useState(6);
    const [totalTemplateGiftCard, setTotalListGiftCard] = useState(0);
    const TEMS_PER_MORE = 6;
    const [shopDate, setShopDate] = useState<any>(null);

    const feshData = async (shopId: any, sizeListGiftCard: number) => {
        const response = await giftCardApis.getCardByShop(shopId);
        if (response.status === 200) {
            setLstGiftCardOfShop(response.data.data);
        } else {
            message.error({ content: response.data.message, duration: 2 });
        }
        const response1 = await giftCardApis.getListCardTemplate(shopId, 1, sizeListGiftCard);
        if (response1.status === 200) {
            setListCardTemplate(response1.data.data.content);
            setTotalListGiftCard(response1.data.data.totalElements);
        } else {
            message.error({ content: response1.data.message, duration: 2 });
        }
    };

    const loadMore = async () => {
        dispatch(setLoading(true));
        setSizeListGiftCard(sizeListGiftCard + TEMS_PER_MORE);
        const response1 = await giftCardApis.getListCardTemplate(id, 1, sizeListGiftCard + TEMS_PER_MORE);
        if (response1.status === 200) {
            setListCardTemplate(response1.data.data.content);
            setTotalListGiftCard(response1.data.data.totalElements);
        } else {
            message.error({ content: response1.data.message, duration: 2 });
        }
        dispatch(setLoading(false));
    };

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                if (id) {
                    await feshData(id, sizeListGiftCard);

                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const handleDeleteTemplate = async (templateId: string, giftName: string) => {
        const result = await Swal.fire({
            title: giftName,
            html: 'Are you sure want to delete Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await giftCardApis.deleteTemplateById(templateId);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleDeleteAllTemplate = async () => {
        const result = await Swal.fire({
            html: 'Are you sure want to delete All Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete All',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await giftCardApis.deleteAllTemplateByShopId(id);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handAddTemplate = async (templateId: string, giftName: string) => {
        const result = await Swal.fire({
            title: giftName,
            html: 'Are you sure want to add Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Add it',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await giftCardApis.assignTemplateById(templateId, id);
                if (response.status === 200) {
                    message.success('Add gift card successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handAddAllTemplate = async () => {
        const result = await Swal.fire({
            html: 'Are you sure want to add All Gift Card?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Add them',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await giftCardApis.assignAllTemplateByShopId(id);
                if (response.status === 200) {
                    message.success('Add gift card successful');
                    await feshData(id, sizeListGiftCard);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <PageStyled>
            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate('/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>

            <ContentStyled>
                <Box className="d-between">
                    <Text variant="H7">Fozito’s Gift Cards ({lstGiftCardOfShop?.length})</Text>
                    <Box display="flex" gap={1}>
                        <Button icon="delete" onClick={handleDeleteAllTemplate}>Delete</Button>
                    </Box>
                </Box>

                <Box mt={1}>
                    <Row gutter={10}>
                        {lstGiftCardOfShop?.map((item: any, index) => (
                            <Col key={index} span={4}>
                                <BoxTemplateStyled>
                                    <Box p={0.2}>
                                        <img src={item.urlImage} width={'100%'} />
                                        <BtnDeleteTemplateStyled variant="PRIMARY" icon="delete" onClick={() => handleDeleteTemplate(item.id, item.giftName)}></BtnDeleteTemplateStyled>
                                    </Box>
                                    <Box p={0.75} pt={0.5}>

                                        <Text variant="CONTENT_1" style={{ fontWeight: 'bold' }}>
                                            {item.giftName}
                                        </Text>
                                        <Text variant="CONTENT_1">
                                            Expires Date: {item.numberDayExpired} Days
                                        </Text>
                                    </Box>
                                </BoxTemplateStyled>
                            </Col>
                        ))}
                    </Row>
                </Box>

                <Box className="d-between" mt={3}>
                    <Text variant="H7">Library Gift Card ({totalTemplateGiftCard})</Text>
                    <Box display="flex" gap={1}>
                        <Button variant="SECONDARY" icon="add" onClick={() => navigate(`/gift-card/${id}/create`)}>
                            Create Gift Cards
                        </Button>
                        <Button variant="PRIMARY" icon="giftCardIcon" onClick={handAddAllTemplate}>
                            Add All Gift Cards
                        </Button>
                    </Box>
                </Box>
                <Box mt={1}>
                    <Row gutter={10}>
                        {listCardTemplate?.map((item: any, index) => (
                            <Col key={index} span={4}>
                                <BoxTemplateStyled>
                                    <Box p={0.2}>
                                        <img src={item.urlImage} width={'100%'} />
                                        <BtnDeleteTemplateStyled variant="SECONDARY" icon="add" onClick={() => handAddTemplate(item.id, item.giftName)}>
                                        </BtnDeleteTemplateStyled>
                                    </Box>
                                    <Box p={0.75} pt={0.5}>
                                        <Text variant="CONTENT_1" style={{ fontWeight: 'bold' }}>
                                            {item.giftName}
                                        </Text>
                                        <Text variant="CONTENT_1">
                                            Expires Date: {item.numberDayExpired} Days
                                        </Text>
                                    </Box>
                                </BoxTemplateStyled>
                            </Col>
                        ))}
                    </Row>

                    {listCardTemplate.length < totalTemplateGiftCard ?
                        (<Box justifyContent='center' display='flex'>
                            <Button mt={1.5} variant='SECONDARY' onClick={() => loadMore()}>
                                Load More
                            </Button>
                        </Box>) : null
                    }
                </Box>
            </ContentStyled>
        </PageStyled>
    );
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;
`;

const TableContentStyled = styled(Table)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
`;

const CustomerTypeStyled = styled(Box)`
  padding: 8px 0px 8px 0px;
  border-radius: 20px;
  text-align: center;
`;

const BoxTemplateStyled = styled(Box)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
`;

const BtnDeleteTemplateStyled = styled(Button)`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 7px;
    position: absolute;
    top: 55%;
    right: 5%;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 8px;
`;