/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import { IPagingListState, RootState } from "../../../store/banlist/types";
import { get } from "lodash";
import { BanListBlogExtraState,  IBanlistBlogItem, IBlogDashboardResData } from "../../../store/banlist/types/blog";

type BanListBlogState = IPagingListState<IBanlistBlogItem>;
const selector = (key: keyof BanListBlogState) => useSelector((state: RootState) => get(state.banlist.blog, key));

const blogSelectors = {
  state: () => useSelector((state: RootState) => state.banlist.blog) as BanListBlogState,
  dashboard: () => useSelector((state: RootState) => state.banlist.blogExtra.dashboard) as IBlogDashboardResData | null,
  params: () => selector('params') as BanListBlogState['params'],
  customers: () => useSelector((state: RootState) => state.banlist.blogExtra.customers) as BanListBlogExtraState['customers'],
  detailBlog: () => useSelector((state: RootState) => state.banlist.blogExtra.detail) as BanListBlogExtraState['detail'],
  blogExtraState: () => useSelector((state: RootState) => state.banlist.blogExtra) as BanListBlogExtraState,
};

export default blogSelectors;
