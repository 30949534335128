import React from 'react'
import UsersTableData from './UsersTableData';
import UsersTableFilter from './UsersTableFilter';
import { useThunkDispatch } from '../../../store';
import { Form } from 'antd';
import banlistActions from '../../../store/banlist/actions';
import { FORMAT_DATE_REQUEST } from '../../../store/banlist/constants';
import { Dayjs } from 'dayjs';

const BanListUsers = () => {
  const dispatch = useThunkDispatch();
  const [form] = Form.useForm();
  const handleFinish = (values: { endDate: Dayjs, startDate: Dayjs, keyword: string }) => {
    dispatch(banlistActions.user.fetchList({
      startDate: values.startDate.format(FORMAT_DATE_REQUEST),
      endDate: values.endDate.format(FORMAT_DATE_REQUEST),
      keySearch: values.keyword,
    }))
  };

  return (
    <div>
      <Form form={form} onFinish={handleFinish}>
        <UsersTableFilter />
      </Form>
      <UsersTableData />
    </div>
  )
}

export default BanListUsers;
