import React from 'react'

const SidebarCustomer = ({ fill }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4161 2.5H2.60792C2.09905 2.5 1.6665 2.91379 1.6665 3.4569V17.5H18.3321V3.4569C18.3575 2.93966 17.925 2.5 17.4161 2.5ZM2.60792 2.88793H17.4161C17.7215 2.88793 17.9504 3.14655 17.9504 3.4569V4.51724H2.0736V3.4569C2.0736 3.14655 2.3026 2.88793 2.60792 2.88793ZM2.0736 17.1121V4.87931H17.9759V17.1121H2.0736Z" fill={fill || "#566A7F"} stroke={fill || "#566A7F"} stroke-width="0.5" />
      <path d="M14.3468 3.33337H14.7292V3.73062H14.3468V3.33337ZM15.3156 3.33337H15.698V3.73062H15.3156V3.33337ZM16.2843 3.33337H16.6667V3.73062H16.2843V3.33337ZM13.0721 9.92765C12.2053 9.92765 11.4405 10.3249 10.9051 10.9605L9.68139 10.5103L8.48318 12.8408H8.04978L6.87706 10.4838L5.52588 10.987C5.16897 11.1194 4.86304 11.3842 4.65909 11.7285C4.48063 12.0463 4.37865 12.3906 4.37865 12.7084C4.35316 12.8408 4.25118 13.8207 4.2002 14.9859V15.1978H11.3385C11.8229 15.595 12.4347 15.8334 13.0976 15.8334C14.6782 15.8334 15.9529 14.5092 15.9529 12.8673C15.9274 11.2518 14.6527 9.92765 13.0721 9.92765ZM13.0721 10.3249C13.6839 10.3249 14.2448 10.5632 14.6782 10.934L11.211 14.5357C10.8541 14.0855 10.6247 13.5029 10.6247 12.8673C10.6247 11.4637 11.7209 10.3249 13.0721 10.3249ZM4.76106 12.7613V12.7349C4.76106 12.4171 4.88853 12.1257 4.99051 11.9404C5.14347 11.6755 5.37292 11.4902 5.65335 11.3842L6.67311 11.0135L7.82034 13.238H8.68713L9.83436 11.0135L10.6247 11.3048C10.3442 11.755 10.1658 12.3111 10.1658 12.8938C10.1658 13.6353 10.4207 14.2974 10.8541 14.827H4.6081C4.65909 13.6618 4.76106 12.7613 4.76106 12.7613ZM13.0721 15.4361C12.4602 15.4361 11.8994 15.1978 11.466 14.827L14.9331 11.2253C15.2901 11.6755 15.5195 12.2582 15.5195 12.8938C15.545 14.2709 14.4233 15.4361 13.0721 15.4361ZM8.27923 10.1925C9.27349 10.1925 10.0893 9.34503 10.0893 8.31219C10.0893 7.27935 9.27349 6.43189 8.27923 6.43189C7.28496 6.43189 6.46916 7.27935 6.46916 8.31219C6.46916 9.34503 7.28496 10.1925 8.27923 10.1925ZM8.27923 6.82914C9.06954 6.82914 9.70689 7.49121 9.70689 8.31219C9.70689 9.13316 9.06954 9.79524 8.27923 9.79524C7.48891 9.79524 6.85157 9.13316 6.85157 8.31219C6.85157 7.49121 7.51441 6.82914 8.27923 6.82914Z" fill={fill || "#566A7F"} stroke={fill || "#566A7F"} stroke-width="0.5" />
    </svg>
  );
}

export default SidebarCustomer;