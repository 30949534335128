import {
    ShopStateTypes,
    ShopState,
    SET_SELECT_SHOP,
    UN_SELECT_SHOP,
} from './types';

const initialState: ShopState = {
    shopInfo: null
};

const shopReducer = (
    state: ShopState = initialState,
    action: ShopStateTypes
): ShopState => {
    switch (action.type) {
        case SET_SELECT_SHOP: {
            return {
                ...state,
                shopInfo: action.payload.shopSelected
            };
        }

        case UN_SELECT_SHOP: {
            return {
                ...state,
                shopInfo: null
            };
        }
        
        default:
            return state;
    }
};
export { shopReducer };