export const SET_SELECT_SHOP = 'SET_SELECT_SHOP';
export const UN_SELECT_SHOP = 'UN_SELECT_SHOP';

export interface ShopInfo {
    id: string;
    accountId: string;
    address: string;
    email: string;
    shopName: string;
    phone: string;
}

export interface ShopState {
    shopInfo: ShopInfo | null;
}

interface SetShopState {
    type: typeof SET_SELECT_SHOP;
    payload: {
        shopSelected: ShopInfo | null;
    };
}

interface UnSelectShop {
    type: typeof UN_SELECT_SHOP;
    payload: {
        shopSelected: null;
    };
}
export type ShopStateTypes =
    | SetShopState
    | UnSelectShop