import { useDispatch } from "react-redux";
import { banlistUIActions } from "../../store/banlist/reducer";

/* eslint-disable react-hooks/rules-of-hooks */
const useLoading = () => {
  const dispatch = useDispatch();
  return (value: boolean) => {
    dispatch(banlistUIActions.setLoading(value));
  };
};

export default useLoading;
