import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Box from '../../../components/Box';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { initialAccountDevices } from './constants';
import FormItemNoMarginStyled from '../../../components/FormItem/FormItemNoMarginStyled';
import SelectDevices from './SelectDevices';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

type IAddAccountButtonProps = {
  handleAddAccount: (values: any) => Promise<boolean>;
  lstTimeZone: any[],
};

const AddAccountButton: React.FC<IAddAccountButtonProps> = ({ handleAddAccount, lstTimeZone }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const watchDevices = Form.useWatch('devices', form);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const boolean = await handleAddAccount(values);
      if (boolean) {
        hideModal();
      }
    } catch (error) {
    }
  };

  return (
    <AddAccountButtonStyled>
      <Button variant="PRIMARY" icon="add" onClick={showModal}>
        Add Account
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={hideModal}
        onOk={onFinish}
        title={'Add Account'}
        closeIcon={null}
        width={800}
        centered>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ devices: initialAccountDevices }}>
          <Box px={1}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="salon_name" label="Salon’s Name"
                  rules={
                    [
                      { required: true }
                    ]
                  }
                >
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="street" label="Street"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemNoMarginStyled
                      name="firsName"
                      label="Firs Name"
                      rules={
                        [
                          { required: true }
                        ]
                      }>
                      <Input size='large' autoComplete='off' placeholder='Please enter...' />
                    </FormItemNoMarginStyled>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemNoMarginStyled
                      name="lastName"
                      label="Last Name"
                      rules={
                        [
                          { required: true }
                        ]
                      }>
                      <Input size='large' autoComplete='off' placeholder='Please enter...' />
                    </FormItemNoMarginStyled>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="city" label="City" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled
                  name="phone"
                  label="Phone"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Input size='large' type="number" autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="state" label="State" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="email" label="Email" rules={
                  [
                    { required: true },
                    {
                      type: 'email',
                      message: 'The input is not valid email',
                    }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="zip" label="Zip Code" rules={
                  [
                    { required: true }
                  ]
                }>
                  <Input size='large' autoComplete='off' placeholder='Please enter...' />
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="password" label="Password"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value || value.length >= 6) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Password must be at least 6 characters long.');
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Please enter..."
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </FormItemNoMarginStyled>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <FormItemNoMarginStyled name="time_zone" label="Timezone"
                  rules={
                    [
                      { required: true }
                    ]
                  }>
                  <Select size='large' showSearch placeholder="Select">
                    {lstTimeZone.map((tz, index) => (
                      <Option key={index} value={tz.value}>
                        {tz.name}
                      </Option>
                    ))}
                  </Select>
                </FormItemNoMarginStyled>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <SelectDevices watchDevices={watchDevices} />
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemCheckBoxStyled name="bookingOnline" valuePropName="checked" label="Booking Online">
                      <Checkbox>Booking Online</Checkbox>
                    </FormItemCheckBoxStyled>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <FormItemCheckBoxStyled name="giftOnline" valuePropName="checked" label="Gift Cards Online">
                      <Checkbox>Gift Cards Online</Checkbox>
                    </FormItemCheckBoxStyled>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Form>
      </Modal>
    </AddAccountButtonStyled >
  );
};

export default AddAccountButton;
const AddAccountButtonStyled = styled.div``;

const FormItemCheckBoxStyled = styled(Form.Item)`
  margin: 10px;
  && .ant-form-item-label label {
    color: transparent;
  }
`;
