import {
    AccountActionTypes,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOG_OUT,
} from './types';

import { publicApis } from '../../services/publicApis';
export const login = (userName: string, password: string) => {
    return async (dispatch: React.Dispatch<AccountActionTypes>) => {
        dispatch({
            type: LOGIN_REQUEST,
            payload: {
                userName: userName,
                password: password,
            },
        });

        try {
            const response = await publicApis.authenticate(userName, password, true);
            if (response.statusCode === 200) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        token: response.data.accessToken,
                        user: response.data.adminAccountDto
                    },
                });
            } else {
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: {
                        error: response.data.message
                    },
                });
            }
        } catch (error: any) {
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.toString();
            dispatch({
                type: LOGIN_FAILURE,
                payload: {
                    error: message
                },
            });
        }
    };
};

export const changeInfoLogin = (): any => {
    return async (dispatch: React.Dispatch<AccountActionTypes>) => {
        dispatch({
            type: LOGIN_FAILURE,
            payload: {
                error: ''
            },
        });
    };
};

export const logout = (): AccountActionTypes => {
    return {
        type: LOG_OUT, payload: {
            token: '',
            user: null
        },
    };
};