import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInput = (props: any) => {
  return (
    <MaskedInputStyled
      {...props}
      className={'phone-input ' + props.className}
      mask={
        ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
      placeholder={props.placeholder || '(000) 000-0000'}
    />
  );
};

export default PhoneInput;

const MaskedInputStyled = styled(MaskedInput)`
  outline: none;
  flex:1;
  padding: 0 16px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #E7EDF6;
  background: #F2F3F8;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &::placeholder {
    color: #808080;
    opacity: 1; /* Firefox */
  }
  
  &::-ms-input-placeholder { /* Edge 12 -18 */
  color: #808080;
  }
`;
