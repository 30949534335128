import { Dispatch } from 'react';
import {
    ShopStateTypes,
    SET_SELECT_SHOP,
    UN_SELECT_SHOP,
} from './types';

export const setShopState = (shopSelected: any) : any => {
    return async (dispatch: React.Dispatch<ShopStateTypes>) => {
        dispatch({
            type: SET_SELECT_SHOP,
            payload: {
                shopSelected: shopSelected
            },
        });
    };
};

export const unSelectShopState = () : any => {
    return async (dispatch: React.Dispatch<ShopStateTypes>) => {
        dispatch({
            type: UN_SELECT_SHOP,
            payload: {
                shopSelected: null
            },
        });
    };
};