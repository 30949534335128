import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ButtonAnthUI, { ButtonProps as ButtonAntProps } from 'antd/es/button';
import Icon, { IconType } from '../../components/Icon';
import { generateCssSpacing, ISpacing } from '../../constant/spacing';

export enum BUTTON_VARIANT_TYPES {
  DEFAULT = 'DEFAULT',
  SECONDARY = 'SECONDARY',
  PRIMARY = 'PRIMARY',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}
type ButtonVariant = 'DEFAULT' | 'SECONDARY' | 'PRIMARY' | 'ORANGE' | 'GREEN';

const BUTTON_SIZES = {
  large: '4rem',
  middle: '3.5rem',
  small: '2.5rem',
};
type ButtonSize = keyof typeof BUTTON_SIZES;
interface IBoxOGProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariant;
  width?: string;
  icon?: IconType;
  size?: ButtonSize;
}

type IButtonCusProps = IBoxOGProps & ButtonAntProps & ISpacing;

const stylesMap = {
  [BUTTON_VARIANT_TYPES.DEFAULT]: {
    normal: css`
      color: var(--text-text-2, #566a7f);
      border: 1px solid var(--line-line-3, #86909c);
      background: var(--fill-fill-2, #e3e9ed);
    `,
    hover: css`
      color: var(--text-text-2, #566a7f) !important;
      border: 1px solid var(--line-line-3, #86909c) !important;
      background: var(--line-line-3, #86909c);
    `,
    disabled: css`
      color: var(--text-text-2, #566a7f);
      border: 1px solid var(--line-line-3, #86909c);
      opacity: 0.5;
      background: var(--fill-fill-2, #e3e9ed);
    `,
  },
  [BUTTON_VARIANT_TYPES.SECONDARY]: {
    normal: css`
      color: var(--text-text-1, #fff);
      background: var(--blue, #008bff);
    `,
    hover: css`
      color: var(--text-text-1, #fff) !important;
      background: #008bffab !important;
    `,
    disabled: css`
      color: var(--text-text-1, #fff);
      opacity: 0.5;
      background: var(--blue, #008bff);
    `,
  },
  [BUTTON_VARIANT_TYPES.PRIMARY]: {
    normal: css`
      color: var(--text-text-1, #fff);
      background: var(--primary-primary-active, #ff8890);
    `,
    hover: css`
      color: var(--text-text-1, #fff) !important;
      background: var(--primary-primary-hover, #f5767f) !important;
      border-color: var(--primary-primary-hover, #f5767f) !important;
    `,
    disabled: css`
      color: var(--text-text-1, #fff);
      opacity: 0.5;
      background: var(--primary-primary-active, #ff8890);
    `,
  },
  [BUTTON_VARIANT_TYPES.ORANGE]: {
    normal: css`
      color: var(--text-text-1, #fff);
      background: #FF7D00;
    `,
    hover: css`
      color: var(--text-text-1, #fff) !important;
      background: #ff7d00b8 !important;;
      border-color: #ff7d00b8 !important;;
    `,
    disabled: css`
      color: var(--text-text-1, #fff);
      opacity: 0.5;
      background: #FF7D00;
    `,
  },
  [BUTTON_VARIANT_TYPES.GREEN]: {
    normal: css`
      color: var(--text-text-1, #fff);
      background: #00B52B;
    `,
    hover: css`
      color: var(--text-text-1, #fff) !important;
      background: #00B52Bb8 !important;;
      border-color: #00B52Bb8 !important;;
    `,
    disabled: css`
      color: var(--text-text-1, #fff);
      opacity: 0.5;
      background: #00B52B;
    `,
  },
};

const Button: React.FC<IButtonCusProps> = ({
  variant = 'DEFAULT',
  icon,
  children,
  size,
  width,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isActive =
    variant !== BUTTON_VARIANT_TYPES.DEFAULT ||
    (variant === BUTTON_VARIANT_TYPES.DEFAULT && isHovered);

  const styles: React.CSSProperties = {
    ...generateCssSpacing(rest),
    ...(rest.style ?? {}),
  };

  return (
    <ButtonCusStyled
      style={styles}
      variant={variant}
      size={size}
      width={width}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {icon && <Icon active={isActive} type={icon} />}
      <div>{children}</div>
    </ButtonCusStyled>
  );
};

export default Button;

const ButtonCusStyled = styled(ButtonAnthUI) <IButtonCusProps>`
  display: inline-flex;
  padding: 1rem;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: ${({ size }) => BUTTON_SIZES[size || 'small']};
  width: ${({ width }) =>
    width ? width : 'auto'}; // defaulting to auto if width is not provided

  ${({ variant }) =>
    variant &&
    css`
      ${stylesMap[variant].normal}
      &:hover, &:active {
        ${stylesMap[variant].hover}
      }
      &:disabled {
        ${stylesMap[variant].disabled}
      }
    `}
`;
