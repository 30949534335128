import React from 'react'

const SearchInputIcon = ({ fill }: { fill?: string; }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7207_5656)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill={fill || "#8E9BA9"} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.487 17.9555C17.7796 17.6622 18.2544 17.6616 18.5477 17.9541L22.0717 21.469C22.365 21.7615 22.3656 22.2363 22.0731 22.5296C21.7806 22.8229 21.3057 22.8235 21.0125 22.531L17.4884 19.0161C17.1952 18.7236 17.1945 18.2487 17.487 17.9555Z" fill={fill || "#8E9BA9"} />
      </g>
      <defs>
        <clipPath id="clip0_7207_5656">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SearchInputIcon;
