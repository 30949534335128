import { Avatar, Button, Modal, Pagination, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { useThunkDispatch } from '../../../store';
import banlistActions from '../../../store/banlist/actions';
import { formatPhone } from '../../../utils/formatPhone';
import blogSelectors from './selectors';
import dayjs from 'dayjs';
import banlistApis from '../../../services/banlistApis';
import useLoading from '../useLoading';
import { IBanlistBlogItem } from '../../../store/banlist/types/blog';
import { useDetailBlog } from './DetailBlog';
import { useDetailBlogReports } from './DetailBlogReports';
import { useBanListUserDetail } from '../Users/UserDetail';
import { useBanListCustomerDetail } from '../Customers/CustomerDetail';
import { TableEvenOdd } from '../../account/styles';

const ITEMS_PER_PAGE = 15;

const BlogsTableData = () => {
  const dispatch = useThunkDispatch();
  const [modal, contextHolder] = Modal.useModal();
  const setLoading = useLoading();
  const openDetailBlog = useDetailBlog();
  const openDetailBlogReport = useDetailBlogReports();
  const goDetailUser = useBanListUserDetail();
  const goDetailCustomer = useBanListCustomerDetail();

  const { data: dataSource = [], paging, params, loading = false } = blogSelectors.state();
  const onPageChanged = async (pageNumber: number) => {
    dispatch(banlistActions.blog.fetchList({ page: pageNumber }));
  };

  const handleDeleteBlog = async (o: IBanlistBlogItem) => {
    setLoading(true);
    try {
      const res = await banlistApis.blog.delete(o.id);
      if (res.data.data) {
        dispatch(banlistActions.blog.fetchList({}));
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onDeleteItem = (o: IBanlistBlogItem) => async () => {
    const confirmed = await modal.confirm({ title: 'Are you sure?' });
    if (confirmed) return handleDeleteBlog(o);
  };

  const columns: ColumnsType<IBanlistBlogItem> = [
    {
      title: 'ACCOUNT',
      dataIndex: 'userName',
      className: 'account',
      width: '12rem',
      render: (accountName, data) =>
        <div className='account-container'>
          <div>
            <Avatar size={'small'} src={data.avatar || 'fail'} >
              <IconUser />
            </Avatar>
          </div>
          <Button onClick={() => data.userId ? goDetailUser(data.userId) : undefined} type='link' style={{ textAlign: 'left', paddingLeft: '0' }}>
            <div className='account-name'>{accountName ? accountName : '--'}</div>
          </Button>
        </div>,
    },
    {
      title: 'CUSTOMER BAN',
      dataIndex: 'customerName',
      className: 'customerBan',
      width: '10rem',
      render: (customerName, data) =>
        <div className='customer-container'>
          <Button onClick={() => data.customerId ? goDetailCustomer(data.customerId, {
            id: data.customerId,
            name: data.customerName,
            phone: data.customerPhone,
          }) : undefined} type='link' style={{ textAlign: 'left', paddingLeft: '0' }}>
            <div className='customer-name'>{customerName}</div>
          </Button>
          <div className='customer-phone'>{formatPhone(data.customerPhone)}</div>
        </div>,
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      className: 'note-col',
      render: (note) =>
        <div className='note-text'>{note ? note : '--'}</div>,
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'createdDate',
      className: 'dateTime',
      width: '12rem',
      render: (text) =>
        <span>{text ? dayjs(text, 'MM-DD-YYYY HH:mm:ss').format('MM-DD-YYYY hh:mm A') : '--'}</span>,
    },
    {
      title: 'LIKE',
      dataIndex: 'totalLike',
      className: 'like',
      width: '5rem',
      render: (text) =>
        <span>{text || 0}</span>,
    },
    {
      title: 'DISLIKE',
      dataIndex: 'totalDisLike',
      className: 'dislike',
      width: '5rem',
      render: (text) =>
        <span>{text || 0}</span>,
    },
    {
      title: 'COMMENT',
      dataIndex: 'totalComment',
      className: 'comment',
      width: '5rem',
      render: (text) =>
        <span>{text || 0}</span>,
    },
    {
      title: 'REPORT',
      dataIndex: 'totalReport',
      className: 'report',
      width: '5rem',
      render: (text, data) =>
        <Button onClick={() => openDetailBlogReport(data.id)} type='link' style={{ textAlign: 'left', paddingLeft: '0' }}>{text}</Button>,
    },
    {
      title: 'DETAIL',
      width: '10rem',
      render: (text, data) =>
        <>
          <Button onClick={() => openDetailBlog(data.id)} type='link' style={{ textAlign: 'left', paddingLeft: '0' }}>View detail</Button>
        </>,
    },
    {
      title: 'ACTIONS',
      width: '6rem',
      render: (text, data) =>
        <>
          <Button type='text' onClick={onDeleteItem(data)}><IconTrash /></Button>
        </>,
    },
  ];

  return (
    <>
      <TableContentStyled
        //@ts-ignore
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        loading={loading}
        pagination={false} />

      <PagingStyled>
        <Pagination
          current={params.page}
          total={paging.totalElements}
          pageSize={ITEMS_PER_PAGE}
          onChange={onPageChanged}
          showSizeChanger={false}
        />
      </PagingStyled>
      <div>{contextHolder}</div>
    </>
  )
}

export default BlogsTableData;

export const TableContentStyled = styled(TableEvenOdd)`
    && .status {
        text-align: center;
    }
    .ant-table-cell {
      padding: 8px !important;
    }
    && .account {
      .account-container {
        display: flex;
        align-items: center;
        gap: 8px;
        .ant-avatar.ant-avatar-sm {
          background: rgb(227, 233, 237);
          display: flex;
          display: flex;
          width: 26px;
          height: 25px;
          justify-content: center;
          align-items: center;
          .ant-avatar-string {
            transform: translateY(2px) !important;
          }
        }
        .account-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1 0 0;
          overflow: hidden;
          color: #008BFF;
          text-overflow: ellipsis;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }

    && .customerBan {
      .customer-container {
        .customer-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: #008BFF;
          text-overflow: ellipsis;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
        .customer-phone {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: #566A7F;
          text-overflow: ellipsis;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    && .note-col {
      .note-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        overflow: hidden;
        color: #1D2129;
        text-overflow: ellipsis;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.ant-table-wrapper .ant-table {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
`;

const PagingStyled = styled(Content)`
    text-align: right;
    margin-top: 10px;
`;

const IconTrash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path d="M5.00151 0.969733C5.00197 0.448015 5.47494 -0.00995403 5.99526 0.000827224C8.01135 0.000358474 10.0274 -0.00198533 12.0431 0.00223342C12.5507 0.0102022 12.996 0.462077 12.9979 0.969733C13.0031 1.64661 12.9974 2.32348 13.0007 3.00036C14.3249 2.99989 15.6492 3.00036 16.9729 2.99989C17.3081 2.99005 17.6409 3.15927 17.827 3.43864C18.0412 3.74708 18.0562 4.17833 17.8645 4.5013C17.6868 4.81489 17.3301 5.0113 16.9701 5.00005C11.6568 5.00005 6.34307 5.00005 1.02979 5.00005C0.734943 5.00801 0.440568 4.88145 0.247912 4.65786C0.00838075 4.3902 -0.0652129 3.98895 0.0622871 3.6538C0.171506 3.35098 0.436818 3.11098 0.749006 3.03223C0.901818 2.99051 1.06166 3.00083 1.21869 2.99989C2.47869 3.00036 3.73916 2.99989 4.99916 3.00036C5.00197 2.32348 4.99682 1.64661 5.00151 0.969733ZM6.99979 2.00051C7.00166 2.33426 6.99697 2.66755 7.00213 3.00083C8.33479 2.99895 9.66697 3.00036 10.9992 3.00036C11.001 2.66708 10.9992 2.3338 11.0001 2.00098C9.66651 1.99958 8.33338 1.99958 6.99979 2.00051Z" fill="#F53F3F" />
    <path d="M1.65801 6.05993C2.13051 5.87618 2.71129 6.12133 2.91051 6.5868C3.01036 6.79258 3.00239 7.02555 3.00004 7.24868C3.00004 10.8749 3.00004 14.5016 3.00004 18.1279C3.00661 18.5718 3.17817 19.0106 3.47161 19.3434C3.82036 19.7446 4.34161 19.9935 4.87504 19.9996C7.60926 20.0006 10.3435 19.9996 13.0777 20.0006C13.5821 20.0052 14.0836 19.7976 14.4389 19.4399C14.7849 19.0959 14.993 18.6159 14.9996 18.1274C15.0005 14.4229 14.9996 10.7179 15.0005 7.01337C14.9935 6.67727 15.1693 6.3454 15.4524 6.16399C15.7289 5.97837 16.1002 5.94883 16.4035 6.08524C16.725 6.22352 16.959 6.54321 16.9932 6.89149C17.0044 7.02649 16.9993 7.16196 17.0002 7.29743C17.0002 10.8285 16.9997 14.3596 17.0002 17.8912C17.0607 19.1259 16.5061 20.3723 15.534 21.1387C14.858 21.6862 13.9936 21.9946 13.1236 21.9998C10.3735 22.0002 7.62333 22.0002 4.8727 21.9998C3.91645 21.9946 2.97051 21.6173 2.26833 20.969C1.47754 20.2504 1.00317 19.1966 1.00036 18.127C0.999888 14.4224 0.999888 10.7174 1.00036 7.01243C0.988638 6.59711 1.26708 6.19821 1.65801 6.05993Z" fill="#F53F3F" />
    <path d="M6.76916 8.02718C7.08228 7.95124 7.42916 8.03843 7.66728 8.2564C7.88478 8.44812 8.00666 8.7378 7.99963 9.02749C8.00009 11.3426 8.00009 13.6578 8.00009 15.973C8.00994 16.3198 7.82713 16.6644 7.53041 16.8462C7.199 17.0605 6.74009 17.0478 6.42134 16.8148C6.15134 16.6269 5.99009 16.2997 5.99994 15.9716C6.00088 13.6414 5.99806 11.3112 6.00134 8.98155C5.99994 8.53671 6.33603 8.1228 6.76916 8.02718Z" fill="#F53F3F" />
    <path d="M10.7568 8.03C11.069 7.95031 11.4177 8.03328 11.6582 8.24796C11.8818 8.44015 12.0069 8.73453 11.9999 9.02796C11.9999 11.3408 11.9999 13.6536 11.9999 15.9664C12.0098 16.2852 11.8607 16.6039 11.6048 16.7952C11.3493 16.993 10.9954 17.052 10.6893 16.9498C10.3701 16.8477 10.1123 16.5744 10.0321 16.2491C9.99038 16.0962 10.0007 15.9364 9.99975 15.7798C10.0012 13.5139 9.99835 11.2484 10.0012 8.9825C9.99975 8.54187 10.3288 8.13031 10.7568 8.03Z" fill="#F53F3F" />
  </svg>
);

const IconUser = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.45843 0.425293H8.54425C9.57704 0.5783 10.5394 1.17215 11.1117 2.04928C11.4377 2.52497 11.6188 3.08435 11.7082 3.65017C11.8191 4.52352 11.6222 5.4374 11.1431 6.17819C10.5485 7.12274 9.4941 7.76128 8.38177 7.84915C8.02766 7.84346 7.67052 7.87376 7.31981 7.81317C6.56917 7.68326 5.86171 7.31514 5.32883 6.77052C4.66303 6.09601 4.28241 5.15979 4.26423 4.21448C4.22635 2.93551 4.89367 1.65806 5.98063 0.977102C6.42601 0.68851 6.93616 0.509371 7.45843 0.425293Z" fill="#8E9BA9" />
    <path d="M2.52431 9.83568C3.27078 8.99262 4.22253 8.33061 5.27388 7.92688C5.81622 8.27228 6.41916 8.5283 7.0524 8.64533C7.30009 8.70706 7.55535 8.73585 7.81099 8.73357C8.07572 8.73092 8.34197 8.74342 8.60519 8.71009C9.29978 8.60329 9.98906 8.3912 10.5825 8.00679C10.6435 7.97725 10.7083 7.91362 10.7802 7.94695C12.2766 8.53398 13.5491 9.65048 14.3528 11.0393C14.7213 11.6407 14.9811 12.3027 15.1818 12.9765C15.3507 13.5616 15.4341 14.1657 15.5003 14.7698C14.9784 14.8811 14.4607 15.0091 13.9354 15.1042C13.6192 15.1383 13.312 15.2292 12.995 15.2564C12.6663 15.2814 12.3459 15.3682 12.016 15.3833C11.4725 15.4257 10.9336 15.5166 10.3875 15.5261C10.1512 15.5537 9.90953 15.5181 9.67661 15.5746H6.32448C6.09156 15.5185 5.84955 15.5534 5.61322 15.5261C5.02998 15.5147 4.45431 15.4155 3.87334 15.3735C3.5821 15.3507 3.29654 15.2841 3.00567 15.2564C2.6883 15.2299 2.38115 15.1367 2.06453 15.1042C1.54037 15.0053 1.02037 14.886 0.5 14.7698C0.523102 14.4687 0.581048 14.1721 0.61627 13.8726C0.647704 13.6302 0.726859 13.3972 0.770034 13.1571C1.0893 11.9357 1.67633 10.7757 2.52431 9.83568ZM11.5627 10.3504C11.5581 10.9302 11.5631 11.5104 11.5604 12.0903C10.9802 12.0929 10.4004 12.0884 9.82015 12.0921C9.81788 12.4194 9.81788 12.747 9.82015 13.0742C10.3996 13.0818 10.9791 13.07 11.5581 13.0799C11.5665 13.6608 11.5559 14.2418 11.5634 14.8228C11.902 14.8326 12.2414 14.8319 12.5803 14.8232C12.5856 14.2668 12.5788 13.7101 12.5826 13.1537C12.575 13.0931 12.6496 13.0704 12.6974 13.0761C13.2393 13.0742 13.7809 13.0799 14.3229 13.0742C14.3255 12.7466 14.3255 12.4194 14.3229 12.0921C13.743 12.0884 13.1628 12.0925 12.5826 12.0906C12.58 11.5104 12.5845 10.9302 12.5807 10.35C12.2414 10.3477 11.902 10.3477 11.5627 10.3504Z" fill="#8E9BA9" />
    <path d="M14.375 11.75L14.75 14L13.25 14.75L11.75 15.125L10.625 14.75L9.125 13.625L8.375 11.75L10.625 9.5L12.875 9.875L14.375 11.75Z" fill="#8E9BA9" />
  </svg>
);
