import axios from 'axios';
import { logout } from '../store/account/actions';
import { store } from '../store/index';
import { API_URL } from '../helpers/constants';
import { get } from 'lodash';

const api = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});
/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

api.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    try {
      if (err.response.status === 401) {
        store.dispatch(logout());
        const errorObject = {
          message: 'Access is denied',
          code: 1
        };
        return Promise.reject(errorObject);
      } else if (err.response.status === 403 && err.response.data.detail === 'Access is denied') {
        store.dispatch(logout());
      } else if (err.response.status === 400) {
        let message = "Dữ liệu gửi lên không hợp lệ";
        try {
          message = err.response.data.title;
        } catch (error) {
        }
        const errorObject = {
          message: message,
          code: 1
        };
        return Promise.reject(errorObject);
      } else {
        const errorObject = {
          message: err.response.data.message,
          code: 1
        };
        return Promise.reject(errorObject);
      }
    } catch (error) {
      const status = get(error, 'response.status');
      const detail = get(error, 'response.data.detail');
      if (status === 401 || (status === 403 && detail === 'Access is denied')) {
        store.dispatch(logout());
        return;
      }
      const errorObject = {
        message: "Kiểm tra mạng",
        code: 1
      };
      return Promise.reject(errorObject);
    }
  }
);
export { api };