import { Col, Form, InputNumber, Row, Switch, message } from 'antd';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Box from '../../components/Box';
import Text from '../../components/Text';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import accountApis from '../../services/accountApis';
import { formatNumber } from '../../helpers/FunctionUtils';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { TableContainer, TableContentStyled } from '../dashboard';
import Modal from '../../components/Modal';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

export const SalonSettingEmailCampPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [objSetting, setObjSetting] = useState<any>(null);
    const columns: ColumnsType<any> = [
        {
            title: <span>Date & Time</span>,
            dataIndex: 'createdDate',
            className: 'createdDate',
            render: (createdDate: any) => <span>{dayjs(createdDate).format('MM-DD-YYYY HH:mm:ss')}</span>,
        },
        {
            title: <span>Who Extended</span>,
            dataIndex: 'updatedBy',
            render: (updatedBy: any) => <span> {updatedBy}</span>,
        },
        {
            title: <span>Total Extend</span>,
            dataIndex: 'totalExtend',
            render: (totalExtend: any) =>
                <span>{formatNumber(totalExtend)}</span>,
        },
        {
            title: <span>Old Quantity</span>,
            dataIndex: 'oldQuantity',
            render: (oldQuantity: any) =>
                <span>{formatNumber(oldQuantity)}</span>,
        },
        {
            title: <span>Old Used</span>,
            dataIndex: 'oldUsed',
            render: (oldUsed: any) =>
                <span>{formatNumber(oldUsed)}</span>,
        },
    ];

    useEffect(() => {
        const initData = async () => {
            try {
                dispatch(setLoading(true));
                if (id) {
                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        dispatch(setShopState(resShopById.data.data));
                    }
                    const resSetting = await accountApis.getSettingPackage(id, 'EMAIL');
                    if (resSetting.status === 200) {
                        setObjSetting(resSetting.data.data);
                    }
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const [showExtendEmail, setShowExtendEmail] = useState(false);
    const [formExtentEmail] = Form.useForm();
    const onSubmitExtendEmail = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const response = await accountApis.settingExtend(id, values.quantity, 'EMAIL');
            if (response.status === 200) {
                message.success(response.data.message);
                const resSetting = await accountApis.getSettingPackage(id, 'EMAIL');
                if (resSetting.status === 200) {
                    setObjSetting(resSetting.data.data);
                }
                setShowExtendEmail(false);
                formExtentEmail.resetFields();
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
                return false;
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onSubmitUpdateActive = async () => {
        try {
            const result = await Swal.fire({
                title: 'Confirm update',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(setLoading(true));
                const response = await accountApis.onSettingUpdateActice(id, objSetting.id, !objSetting.active);
                if (response.status === 200) {
                    message.success(response.data.message);
                    const resSetting = await accountApis.getSettingPackage(id, 'EMAIL');
                    if (resSetting.status === 200) {
                        setObjSetting(resSetting.data.data);
                    }
                } else {
                    message.error({ content: response.data.message as string, duration: 2 });
                    return false;
                }
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onExportExcel = async () => {
        try {
            const result = await Swal.fire({
                title: 'Confirm export excel',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                dispatch(setLoading(true));
                const headerRow = ['Date & Time', 'Who Extended', 'Total Extend', 'Old Quantity', 'Old Used'];
                const filteredData = objSetting?.history.map((item: any) =>
                    [
                        dayjs(item.createdDate).format('MM-DD-YYYY HH:mm:ss'),
                        item.updatedBy,
                        item.totalExtend,
                        item.oldQuantity,
                        item.oldUsed
                    ]);
                const reversedData = filteredData.reverse();
                const dataWithHeader: string[][] = [headerRow, ...reversedData];
                const ws = XLSX.utils.aoa_to_sheet(dataWithHeader);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, `${dayjs().format('DDMMYYYYHHmmss')}.xlsx`);
            }
        } catch (error) {
            message.error({ content: 'Tham số không hợp lệ, hãy kiểm tra lại', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ContentStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showExtendEmail}
                onCancel={() => setShowExtendEmail(false)}
                onOk={() => formExtentEmail.submit()}
                title='Extend EMAIL Quantity'
                closeIcon={null}
                centered>
                {/* Hiển thị trình soạn thảo trong modal */}
                <Form form={formExtentEmail}
                    layout="vertical"
                    onFinish={onSubmitExtendEmail}>
                    <Box px={1}>
                        <FormItemNoMarginStyled name='quantity'
                            rules={
                                [
                                    { required: true, message: 'Quantity required!' }
                                ]
                            }
                            initialValue={0}>
                            <InputNumber
                                style={{ width: '100%' }}
                                min={0}
                                size='large'
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </FormItemNoMarginStyled>
                    </Box>
                </Form>
            </Modal>

            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Box style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }} p={1}>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Text variant="H7">About</Text>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                <Box className='d-center' justifyContent='end'>
                                    <Switch style={{ textAlign: 'right' }} title=''
                                        value={objSetting?.active} onChange={onSubmitUpdateActive} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                    <Text variant="H9" ml={1}>Active Send Email</Text>
                                </Box>
                            </Col>
                        </Row>
                        {/* <Row style={{ marginTop: '20px' }}>
                            <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                                <Checkbox style={{ fontSize: '18px', fontWeight: '500' }}>
                                    Auto Renew
                                </Checkbox>
                            </Col>
                        </Row> */}
                        <Row style={{ marginTop: '20px' }} gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <BoxStyled>
                                    <TitelStyled>
                                        {objSetting && (formatNumber(objSetting.total))}
                                    </TitelStyled><br />
                                    <Titel1Styled>EMAIL CAMPAIGN</Titel1Styled>
                                </BoxStyled>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <BoxStyled>
                                    <TitelStyled>
                                        {objSetting && (formatNumber(objSetting.used))}
                                    </TitelStyled><br />
                                    <Titel1Styled>EMAIL SENT</Titel1Styled>
                                </BoxStyled>
                            </Col>

                            <Col xs={{ span: 24 }}>
                                <BoxStyled>
                                    <TitelStyled>
                                        {objSetting && (formatNumber(objSetting.remain))}
                                    </TitelStyled><br />
                                    <Titel1Styled>EMAIL REMAIN</Titel1Styled>
                                </BoxStyled>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col xs={{ span: 24 }}>
                                <BoxExtendEmailStyled onClick={() => setShowExtendEmail(true)}>
                                    <span><PlusSquareOutlined /> Extend Email Quantity</span>
                                </BoxExtendEmailStyled>
                            </Col>
                        </Row>
                    </Box>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                    <Box p={1}>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Text variant="H7">Extend History</Text>
                            </Col>
                            <Col style={{ textAlign: 'end' }} xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <TitelStyled onClick={onExportExcel} style={{ cursor: 'pointer' }}> <FaDownload /> Export Excel</TitelStyled>
                            </Col>
                        </Row>
                    </Box>

                    <TableContainer>
                        <TableContentStyled
                            columns={columns}
                            dataSource={objSetting?.history}
                            rowKey="id"
                            bordered />
                    </TableContainer>
                </Col>
            </Row>
        </ContentStyled>
    )
}

const ContentStyled = styled.div`
    background: rgba(246, 247, 252, 1);
    padding: 1rem;

    && .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    && .ant-checkbox .ant-checkbox-inner:after {
        inset-inline-start: 30.5%;
    }
`;

const BoxStyled = styled(Box)`
    background: rgba(227, 233, 237, 1);
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
`;

const BoxExtendEmailStyled = styled(Box)`
    background: rgba(245, 248, 255, 1);
    padding: 16px 8px 16px 8px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid rgba(0, 139, 255, 1);
    color: rgba(0, 139, 255, 1);
    font-size: 18px;
    cursor: pointer;
`;

const TitelStyled = styled.span`
    font-size: 18px;
    font-weight: 500;
`;

const Titel1Styled = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: rgba(86, 106, 127, 1);
`;