import { Form, InputRef, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CurrencyInputPrecision from './CurrencyInputPrecision';
import PercentDecimalInputPrecision from './PercentDecimalInputPrecision';
type Props = {
  valueFieldName?: any;
  typeFieldName?: any;
};
const InputPercentDollar = ({ valueFieldName = 'discountValue', typeFieldName = 'discountType' }: Props) => {
  const [type, setType] = useState('MONEY');
  const inputPerRef = useRef<InputRef>(null);
  const inputDolRef = useRef<InputRef>(null);
  const form = Form.useFormInstance();
  useEffect(() => {
    form.setFieldValue(typeFieldName, 'MONEY');
    form.setFieldValue(valueFieldName, '0.00');
  }, []);
  const handleChangeType = (e: string) => {
    setType(e);
    form.setFieldValue(valueFieldName, '0.00');
    setTimeout(() => {
      if (e === 'dollar') {
        inputDolRef.current?.focus();
      } else {
        inputPerRef.current?.focus();
      }
    }, 100);
  };

  return (
    <InputPercentDollarStyled>
      <Form.Item noStyle name={valueFieldName}>
        {type === 'MONEY' && <CurrencyInputPrecision inputRef={inputDolRef} className='ant-input-flex' width={'auto'} height={'46px'} />}
        {type === 'PERCENT' && <PercentDecimalInputPrecision inputRef={inputPerRef} maxValue={100} className='ant-input-flex' width={'auto'} height={'46px'} />}
      </Form.Item>
      <Form.Item noStyle name={typeFieldName}>
        <Select defaultValue="dollar" onChange={handleChangeType} style={{ width: '120px' }}>
          <Select.Option value={'MONEY'}>Money</Select.Option>
          <Select.Option value={'PERCENT'}>Percent</Select.Option>
        </Select>
      </Form.Item>
    </InputPercentDollarStyled>
  );
};

const InputPercentDollarStyled = styled.div`
  display: flex;
  .percent-decimal-input-precision.ant-input-flex {
    flex: 1;
    border-radius: 0;
    border: none;
    padding: 0;
    padding-right: 16px;
    border: 1px solid #DADADA;
    height: 46px;
    .ant-input {
      padding: 0 16px;
      width: auto;
      flex: 1;
      
    }
  }

  .ant-input.ant-input-flex {
    padding: 0 16px;
    flex: 1;
    height: 46px;
  }

  .ant-select {
    align-self: stretch;
    .ant-select-selector {
      padding: 0 16px;
      height: 100%;
      border-left: 0;
    }
  }
`;

export default InputPercentDollar;