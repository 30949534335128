import { AccountState } from '../store/account/types';
import { AppState } from '../store';
import { useSelector } from 'react-redux';
import SignInPage from '../pages/login';
import LayoutAdmin from '../pages/LayoutAdmin';
import AccountPage from '../pages/account/AcountList';
import { AdminDashboardPage } from '../pages/dashboard';

export const PrivateRoute = ({ children, noneFooter }: { children: React.ReactNode, noneFooter?: boolean }) => {
    const account: AccountState = useSelector((state: AppState) => state.account);
    if (account.token) {
        if (children === null) {
            return <LayoutAdmin> {<AdminDashboardPage />} </LayoutAdmin>
        } else {
            return <LayoutAdmin noneFooter={noneFooter}> {children} </LayoutAdmin>
        }
    } else {
        return <SignInPage />
    }
};