import React, { useState } from 'react';
import { Select, Modal, DatePicker, Calendar } from 'antd';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
const { Option } = Select;

const noLimitDate = '10950';

const optionLabels = {
    '30': '30 Days',
    '60': '60 Days',
    '90': '90 Days',
    [noLimitDate]: 'No limit',
};

interface SelectOrDatePickerProps {
    onChange: (value: string | null, date: Date | null) => void;
    disabled: boolean,
    valueDf: string,
    startDate?: Dayjs | null,
}

const SelectOrDatePicker: React.FC<SelectOrDatePickerProps> = ({ startDate, onChange, disabled, valueDf }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<string[]>([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | undefined>(valueDf);

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
        if (value === 'addDate') {
            setShowDatePicker(true);
        } else {
            onChange(value, null); // Gọi prop onChange khi giá trị Select thay đổi
        }
    };

    const handleDatePickerChange = (date: any) => {
        setOpen(false);
        if (date) {
            setOptions([...options, date.format('MM-DD-YYYY')]);
            setSelectedOption(date.format('MM-DD-YYYY'));
            onChange(date.format('MM-DD-YYYY'), date.toDate()); // Gọi prop onChange khi giá trị DatePicker thay đổi
        }
        setShowDatePicker(false);
    };

    const handleCloseEditorModal = () => {
        setShowDatePicker(false);
    };

    return (
        <div>
            <Select
                onChange={handleSelectChange}
                value={selectedOption}
                size="large"
                open={open}
                onDropdownVisibleChange={setOpen}
                optionLabelProp="label"
                dropdownRender={(menu) => (
                    <>
                        <MenuWrap className='MenuWrap'>{menu}</MenuWrap>
                        <Calendar disabledDate={(current) => {
                            if (!startDate) return false;
                            return current && current < startDate;
                        }}
                            fullscreen={false} onSelect={handleDatePickerChange} />
                    </>
                )}
                disabled={disabled}>
                <Option value="30" label={optionLabels['30']}>30 Days</Option>
                <Option value="60" label={optionLabels['60']}>60 Days</Option>
                <Option value="90" label={optionLabels['90']}>90 Days</Option>
                <Option value={noLimitDate} label={optionLabels[noLimitDate]}>No limit</Option>
            </Select>

            <Modal
                closable={false}
                maskClosable={false}
                title="Select Date"
                open={showDatePicker} // Sử dụng visible thay vì open
                onCancel={handleCloseEditorModal}
                footer={null}>
                <DatePicker size='large' style={{ width: '100%' }} className={'datePicker'} onChange={handleDatePickerChange} />
            </Modal>
        </div>
    );
};

export default SelectOrDatePicker;

const MenuWrap = styled.div`
    .rc-virtual-list .rc-virtual-list-holder {
        div {
            width: 100%;
        }
        .rc-virtual-list-holder-inner {
            display: flex;
            align-items: center;
            flex-direction: row !important;
            gap: 8px;
            width: 100%;
        
            .ant-select-item {
                flex: 1;
                background-color: rgba(0, 0, 0, 0.04);
                &.ant-select-item-option-selected {
                    background-color: #e6f4ff;
                }
                .ant-select-item-option-content {
                    text-align: center;
                }
            }
        }
    }
`;