import React from 'react'

const SidebarBlog = ({ fill }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7393_5091)">
        <path d="M19.375 5C19.0312 5 18.75 5.28125 18.75 5.625V14.375C18.75 14.7187 18.4688 15 18.125 15H1.875C1.53125 15 1.25 14.7187 1.25 14.375V4.0625C1.25 3.71875 1.53125 3.4375 1.875 3.4375H14.3438L12.0312 5.75C11.9375 5.8125 11.875 5.9375 11.875 6.03125L11.5 7.5H3.75C3.40625 7.5 3.125 7.78125 3.125 8.125C3.125 8.46875 3.40625 8.75 3.75 8.75H11.875C12.0625 8.75 12.2187 8.65625 12.3438 8.53125L13.9688 8.125C14.0938 8.09375 14.1875 8.03125 14.25 7.96875L19.5 2.65625C19.8125 2.375 20 2 20 1.5625C20 1.125 19.8437 0.71875 19.5625 0.4375C19.2812 0.125 18.9063 -0.03125 18.5 -0.03125C18.0938 -0.03125 17.6562 0.125 17.3438 0.4375L15.5938 2.1875H1.875C0.84375 2.1875 0 3.03125 0 4.0625V14.375C0 15.4062 0.84375 16.25 1.875 16.25H7.5V17.5C7.5 17.625 7.46875 18.75 5.9375 18.75C5.59375 18.75 5.3125 19.0312 5.3125 19.375C5.3125 19.7187 5.59375 20 5.9375 20H14.0625C14.4063 20 14.6875 19.7187 14.6875 19.375C14.6875 19.0312 14.4063 18.75 14.0625 18.75C12.625 18.75 12.5 17.7812 12.5 17.5V16.25H18.125C19.1562 16.25 20 15.4062 20 14.375V5.625C20 5.28125 19.7188 5 19.375 5ZM18.2187 1.3125C18.3125 1.21875 18.4062 1.21875 18.4375 1.21875C18.5 1.21875 18.5625 1.21875 18.625 1.3125C18.75 1.40625 18.75 1.5 18.75 1.5625C18.75 1.625 18.75 1.6875 18.6563 1.75L13.5 6.96875L12.9062 7.125L13.0625 6.5L18.2187 1.3125ZM11.625 18.75H8.375C8.65625 18.2812 8.75 17.7812 8.75 17.5V16.25H11.25V17.5C11.25 17.7812 11.3438 18.2812 11.625 18.75Z" fill={fill || "#566A7F"} />
        <path d="M3.75 6.5625H6.875C7.21875 6.5625 7.5 6.28125 7.5 5.9375C7.5 5.59375 7.21875 5.3125 6.875 5.3125H3.75C3.40625 5.3125 3.125 5.59375 3.125 5.9375C3.125 6.28125 3.40625 6.5625 3.75 6.5625ZM3.75 9.6875C3.40625 9.6875 3.125 9.96875 3.125 10.3125C3.125 10.6563 3.40625 10.9375 3.75 10.9375H11.25C11.5938 10.9375 11.875 10.6563 11.875 10.3125C11.875 9.96875 11.5938 9.6875 11.25 9.6875H3.75ZM13.75 12.1875C13.4062 12.1875 13.125 12.4687 13.125 12.8125C13.125 13.1563 13.4062 13.4375 13.75 13.4375H16.25C16.5938 13.4375 16.875 13.1563 16.875 12.8125C16.875 12.4687 16.5938 12.1875 16.25 12.1875H13.75Z" fill={fill || "#566A7F"} />
      </g>
      <defs>
        <clipPath id="clip0_7393_5091">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

export default SidebarBlog;
