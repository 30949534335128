import React, { Fragment, useEffect, useState } from 'react'
import { PageStyled } from '../styles'
import Box from '../../components/Box'
import Text from '../../components/Text'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../store/loading/actions'
import { setShopState } from '../../store/shop/actions'
import { Button, Col, Form, Image, Input, Pagination, Row, Select, message } from 'antd'
import { SearchOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import accountApis from '../../services/accountApis'
import requestSupportApis from '../../services/requestSupportApis'
import { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import { Content } from 'antd/es/layout/layout'
import TextTruncate from '../../components/Modal/TextTruncate'
import Swal from 'sweetalert2'
import Modal from '../../components/Modal'
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled'
import { TableEvenOdd } from '../account/styles'

export const ListRequestSupportPage = () => {
    const dispatch = useDispatch();
    const [listSalon, setLstSalon] = useState<any[]>([]);
    const ITEMS_PER_PAGE = 15;
    const [listRequestSupport, setLstRequestSupport] = useState<any[]>([]);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [form] = Form.useForm();
    const getRequestSupport = async (page: number, size: number, shopId: string | null, status: string | null) => {
        try {
            const response = await requestSupportApis.filterRequestSupport(shopId, status, page, size);
            if (response.status === 200) {
                setLstRequestSupport(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                dispatch(setShopState(null));
                const response = await accountApis.filterSalon(null, null, 1, 10000);
                if (response.status === 200) {
                    setLstSalon(response.data.data.content);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
                await getRequestSupport(currentPage, ITEMS_PER_PAGE, null, null);
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const handleUpdateStaus = async (shopId: string, supportId: string, status: any) => {
        const result = await Swal.fire({
            html: `Are you sure want to change status ${status} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const supportIds: string[] = [];
                supportIds.push(supportId);
                const response = await requestSupportApis.updateStatus(supportIds, status, shopId);
                if (response.status === 200) {
                    await onPageChanged(currentPage);
                    message.success({ content: 'Update success' as string, duration: 2 });
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns: ColumnsType<any> = [
        {
            title: 'SALON NAME',
            dataIndex: 'businessName',
            className: 'businessName',
            render: (businessName: string) =>
                <span>{businessName ? businessName : '--'}</span>,
        },
        {
            title: 'FEATURE',
            dataIndex: 'categoryName',
            className: 'categoryName',
            render: (categoryName: string) =>
                <span>{categoryName ? categoryName : '--'}</span>,
        },
        {
            title: 'DESCRIBE',
            dataIndex: 'requestContent',
            className: 'requestContent',
            render: (requestContent: string) =>
                <TextTruncate text={requestContent} maxWords={50} />,
        },
        {
            title: 'IMAGES',
            dataIndex: 'images',
            render: (images: string[]) =>
                <Fragment>
                    <Container>
                        <TextContainer>
                            {images ? images.length : '0'}
                        </TextContainer>
                        <IconContainer>
                            <Image.PreviewGroup
                                items={images}>
                                <Image width={20} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAwCAYAAAC13uL+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAOrSURBVHja7NpbiFVVGAfw31yOZjoPpTHdy2qiLBrDh8qKkCCjGxSBFUVCBVZUUr3Zow9doMGwh+73O0FUaJhIqVEaiDEEJVEPhiVDlFI5TuX0cL4Tu8M5Z++zz5yZs8U/rIe919prrf9a37fW//vOobNxAm7CKxjG57hDQdGLc/EA1uE3jNcoy4tC6AgsxuP4Cn/XIZQsP+LwTiTTjblYijexMwOZWmVR2kBdGSYzFyswiJ7ouBX04BT0tdjPTuyOxapgHD/gMWxN6+Bo7Mi5slNZ9mJBbwq5pRjAGN7Brxl3e6owDdeHHz+Y1vjFWIkvCnTiPh9z3tqd0rDiX2MFIre/MvfejB/kNcUZuAynYQTrsWsSTuP/Ls12YTDMen7i3W7ch7cm686p4BjciFWY02K/R+LdKmLQH1LqvMkkdw+243UsQ6nFfpfg1Dp1pdi9SdFyl+OJxLu9E3BRz0+pPyv8eLzdO3dLG/rdk1L/ewqxRbgrlExL5NohQD9MqX+/Qd3t2IAnsQnzWiH3WRvIbcRQnbpPY+K1cAlWJ56PxduYnZfcuggzJhr3x+H0JX7Gt3gYV4dZVuMkvIrpNfzz5bzXVm/EURXRORKCGV6Id5taJDq7xqSTOAybU8TwqoxjPRXtt3THrq1v86n8S0IW1cJqXJjSx724O889t3YKteBy3Jax7VBE7U2R29KC5puDlXgExzf57WI82kT7UiicM5ud5Bs5fG5WZKQqfjHchHQbiIMmTzA6HBIv1ecqWJMjAngW5yeez8ZLGU62vljM/pzWkmmcJLlPMBo5jixYGRqyGldEDiPtRFvQoq9elWGc/2Ez9mUwy1szmM6yOmOsmOB8yZ31zLJ64IfwVwq5i/FnhkH349Kq/q/FgQkmNxYBcUOfE2plT5W5Jv3w5PCVGRmTNa8FwT5ciefakGAqhbI5o/rMqHbIYXxdQ02MYqZyEvW4Jgbux8f4KXRiu3BURPcXhFvVJLcv1Mq0qvfT8UzOCLqrzcQqOCdMcrRRDmVNskFgYQ1T7UTcjD8akdteg0gRiFUwsxG5f6IUHt0OYhwid4hcAckVmXxX2uRHC0xuLI3c2gKT+yiLdBpSvJ+N38OsrAp9YUS/JW3O77eIA/g+opuDG1l2bgA3aC4r3RORRCmE7HfKafRdnbYAp+e0+2+Uk63XRAzY06k7vCEDmRF8oJwVnlck870o0g/VhHbgaVyXyLsUyucqGFT+O+CJ2Bbpg23JsL7T8O8AnyuMvBNej/cAAAAASUVORK5CYII=' />
                            </Image.PreviewGroup>
                        </IconContainer>
                    </Container>
                </Fragment>
        },
        {
            title: 'REQUEST DATE',
            dataIndex: 'requestDate',
            render: (requestDate: string) =>
                <span>{requestDate ? requestDate : '--'}</span>,
        },
        {
            title: 'RESOLVED DATE',
            dataIndex: 'completedDate',
            render: (completedDate: string) =>
                <span>{completedDate ? completedDate : '--'}</span>,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            className: 'status',
            render: (status: string, record: any) => {
                let selectStyle = {};
                switch (status) {
                    case 'NEW':
                        selectStyle = { border: '2px solid rgba(0, 139, 255, 1)', borderRadius: '10px', color: 'rgba(0, 139, 255, 1)' };
                        break;
                    case 'IN_PROGRESS':
                        selectStyle = { border: '2px solid rgba(255, 125, 0, 1)', borderRadius: '10px', color: 'rgba(255, 125, 0, 1)' };
                        break;
                    case 'RESOLVED':
                        selectStyle = { border: '2px solid rgba(0, 181, 43, 1)', borderRadius: '10px', color: 'rgba(0, 181, 43, 1)' };
                        break;
                    case 'REJECT':
                        selectStyle = { border: '2px solid rgba(119, 125, 124, 1)', borderRadius: '10px', color: 'rgba(119, 125, 124, 1)' };
                        break;
                    case 'DELETED':
                        selectStyle = { border: '2px solid rgba(210, 70, 79, 1)', borderRadius: '10px', color: 'rgba(210, 70, 79, 1)' };
                        break;
                    default:
                        selectStyle = {};
                        break;
                }
                return (
                    <SelectContainer
                        size='large'
                        style={{ width: '100%', ...selectStyle }}
                        value={status}
                        onChange={(value) => handleUpdateStaus(record.shopId, record.id, value)}
                    >
                        <Select.Option value={'NEW'} label={'NEW'} style={{ color: 'rgba(0, 139, 255, 1)' }}>
                            NEW
                        </Select.Option>
                        <Select.Option value={'IN_PROGRESS'} label={'IN PROGRESS'} style={{ color: 'rgba(255, 125, 0, 1)' }}>
                            IN PROGRESS
                        </Select.Option>
                        <Select.Option value={'RESOLVED'} label={'RESOLVED'} style={{ color: 'rgba(0, 181, 43, 1)' }}>
                            RESOLVED
                        </Select.Option>
                        <Select.Option value={'REJECT'} label={'REJECT'} style={{ color: 'rgba(119, 125, 124, 1)' }}>
                            REJECT
                        </Select.Option>
                        <Select.Option value={'DELETED'} label={'DELETED'} style={{ color: 'rgba(210, 70, 79, 1)' }}>
                            DELETED
                        </Select.Option>
                    </SelectContainer>
                );
            },
        },
        {
            title: 'NOTE',
            dataIndex: 'noteReply',
            render: (noteReply: string, record: any) => {
                if (noteReply === '') {
                    return (
                        <ButtonUpdateNote onClick={() => handleShowUpdatNote(record.id, noteReply)}>Enter note</ButtonUpdateNote>
                    );
                } else {
                    return (
                        <Fragment>
                            <span>{noteReply}</span><br />
                            <ButtonUpdateNote onClick={() => handleShowUpdatNote(record.id, noteReply)}>Edit</ButtonUpdateNote>
                        </Fragment>

                    );
                }
            },
        }
    ];

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await form.validateFields();
            await getRequestSupport(pageNumber, ITEMS_PER_PAGE, values.shopId, values.status);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [showModalNote, setShowModalActive] = useState(false);
    const [formNote] = Form.useForm();
    const handleShowUpdatNote = async (supportId: any, note: string) => {
        dispatch(setLoading(true));
        try {
            formNote.setFieldsValue({
                supportId: supportId,
                note: note
            });
            setShowModalActive(true);
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onSubmitUpdateNote = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response = await requestSupportApis.updateNote(values.supportId, values.note);
            if (response.status === 200) {
                await onPageChanged(currentPage);
                message.success({ content: 'Update success' as string, duration: 2 });
                setShowModalActive(false);
                formNote.resetFields();
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleMultipleUpdateStatus = async (status: string) => {
        if (selectedRowKeys.length <= 0) {
            message.warning({ content: 'Not been selected yet', duration: 2 });
            return;
        }
        const result = await Swal.fire({
            html: `Are you sure want to change status ${status} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change All',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const supportIds: string[] = selectedRowKeys.map(key => key.toString());
                const response = await requestSupportApis.updateStatus(supportIds, status, null);
                if (response.status === 200) {
                    await onPageChanged(currentPage);
                    message.success('Update multiple successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    return (
        <PageStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showModalNote}
                onCancel={() => setShowModalActive(false)}
                onOk={() => formNote.submit()}
                title='Note'
                closeIcon={null}
                centered>
                {/* Hiển thị trình soạn thảo trong modal */}
                <Form form={formNote} name="formNote"
                    layout="vertical"
                    onFinish={onSubmitUpdateNote}>
                    <Box px={1}>
                        <FormItemNoMarginStyled
                            name="note">
                            <Input placeholder='Please enter' size='large' autoComplete='off' />
                        </FormItemNoMarginStyled>
                        <FormItemNoMarginStyled name='supportId'>
                            <Input type='hidden' />
                        </FormItemNoMarginStyled>
                    </Box>
                </Form>
            </Modal>

            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                    <Box className="d-between">
                        <Text variant="H5">Request Support ({totalItems})</Text>
                    </Box>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                    <Form form={form} name="search">
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item
                                    name="shopId">
                                    <Select size='large' defaultValue={''} style={{ width: '100%' }} showSearch optionFilterProp="label">
                                        <Select.Option value={''} label={'All Salon - Zipcode'}>
                                            All Salon - Zipcode
                                        </Select.Option>
                                        {listSalon.map((item: any) => (
                                            <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.zipCode}`}>
                                                {item.shopName} - {item.zipCode}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="status">
                                    <Select size='large' style={{ width: '100%' }} defaultValue={''}>
                                        <Select.Option value={''} label={'All'}>
                                            ALL
                                        </Select.Option>

                                        <Select.Option value={'NEW'} label={'NEW'}>
                                            NEW
                                        </Select.Option>

                                        <Select.Option value={'IN_PROGRESS'} label={'IN PROGRESS'}>
                                            IN PROGRESS
                                        </Select.Option>

                                        <Select.Option value={'RESOLVED'} label={'RESOLVED'}>
                                            RESOLVED
                                        </Select.Option>

                                        <Select.Option value={'REJECT'} label={'REJECT'}>
                                            REJECT
                                        </Select.Option>

                                        <Select.Option value={'DELETED'} label={'DELETED'}>
                                            DELETED
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Button onClick={() => onPageChanged(1)} type="primary" style={{ width: '100%' }} icon={<SearchOutlined />} size='large'>
                                Search
                            </Button>
                        </Col>
                        <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <ButtonDelete icon={<DeleteOutlined />} size='large' onClick={() => handleMultipleUpdateStatus('DELETE')}>
                                Delete
                            </ButtonDelete>
                        </Col>
                        <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <ButtonResolved icon={<CheckOutlined />} size='large' onClick={() => handleMultipleUpdateStatus('RESOLVED')}>
                                Resolved
                            </ButtonResolved>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <TableContentStyled
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listRequestSupport}
                        rowKey="id"
                        bordered
                        pagination={false} />

                    <PagingStyled>
                        <Pagination
                            current={currentPage}
                            total={totalItems}
                            pageSize={ITEMS_PER_PAGE}
                            onChange={onPageChanged}
                            showSizeChanger={false}
                        />
                    </PagingStyled>
                </Col>
            </Row>
        </PageStyled>
    );
}

export const ButtonDelete = styled(Button)`
    background: rgba(227, 233, 237, 1);
    color: rgba(134, 144, 156, 1);
    width: 100%;
`;

export const ButtonResolved = styled(Button)`
    background: rgba(0, 181, 43, 1);
    color: #fff;
    width: 100%;
`;

const TableContentStyled = styled(TableEvenOdd)`
    && .status {
        text-align: center;
    }
`;

const PagingStyled = styled(Content)`
    text-align: right;
    margin-top: 10px;
`;


const Container = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
`;

const IconContainer = styled.span`
    padding-bottom: 4%;
`;

const TextContainer = styled.span`
    /* Điều chỉnh kiểu dáng hoặc margin cho phần text nếu cần */
`;

const SelectContainer = styled(Select)`
    && .ant-select-selector:hover {
        border-color: transparent !important;
    };

    && .ant-select-selector:focus {
        border-color: transparent !important;
    }

    && .ant-select-selector:target {
        border-color: transparent !important;
    }

    && .ant-select-selector:visited {
        border-color: transparent !important;
    }
`;

export const ButtonUpdateNote = styled.span`
    border: none;
    color: #008BFF;
    font-weight: 400;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
`;