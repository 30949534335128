/* eslint-disable react-hooks/rules-of-hooks */
import { get } from "lodash";
import { useSelector } from "react-redux";
import { IPagingListState, RootState } from "../../../store/banlist/types";
import { IBanlistCustomerDetailState, IBanlistCustomerItem } from "../../../store/banlist/types/customer";

type BanListCustomerState = IPagingListState<IBanlistCustomerItem>;

const selector = (key: keyof BanListCustomerState) => useSelector((state: RootState) => get(state.banlist.user, key));

const customerSelectors = {
  state: () => useSelector((state: RootState) => state.banlist.customer) as BanListCustomerState,
  params: () => selector('params') as BanListCustomerState['params'],
  customerDetail: {
    data: () => useSelector((state: RootState) => state.banlist.customerDetail.data) as IBanlistCustomerDetailState['data'],
    blog: () => useSelector((state: RootState) => state.banlist.customerDetail.blog) as IBanlistCustomerDetailState['blog'],
    blogParams: () => useSelector((state: RootState) => state.banlist.customerDetail.blog.params) as IBanlistCustomerDetailState['blog']['params'],
  },
};

export default customerSelectors;
