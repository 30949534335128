import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FORMAT_DATE_REQUEST } from "../constants";
import { IBanlistCustomerDetailResData, IBanlistCustomersResData } from "../types/customer";
import { IApiGetUsersParams } from "../types/user";
import { RootState } from "../types";
import banlistApis from "../../../services/banlistApis";
import { convertParams } from "../pagingServices";
import { IApiGetBlogsParams, IBanlistBlogsResData } from "../types/blog";
const PREFIX_ACTION = 'BANLIST_CUSTOMER_';
const customer = {
  init: createAsyncThunk(
    PREFIX_ACTION + 'INIT',
    (arg, thunkAPI) => {
      const startDate = dayjs().startOf('month').format(FORMAT_DATE_REQUEST);
      const endDate = dayjs().endOf('month').format(FORMAT_DATE_REQUEST);
      thunkAPI.dispatch(customer.fetchList({
        page: 1, size: 10, startDate, endDate
      }));
    }),
  fetchList: createAsyncThunk<IBanlistCustomersResData, IApiGetUsersParams>(
    PREFIX_ACTION + 'FETCH_LIST_DATA',
    async (_params, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const storeParams = state.banlist.customer.params;
      const response = await banlistApis.customer.list(convertParams(storeParams, _params));
      return response?.data?.data;
    },
  ),
  fetchBlogsByCustomer: createAsyncThunk<IBanlistBlogsResData, IApiGetBlogsParams>(
    PREFIX_ACTION + 'FETCH_BLOGS_BY_CUSTOMER',
    async (args, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const storeParams = state.banlist.customerDetail.blog.params;
      const customerId = state.banlist.customerDetail.id || '';
      const response = await banlistApis.customer.customerBlogs(customerId, convertParams(storeParams, args));
      return response?.data?.data;
    }
  ),
};

export default customer;
