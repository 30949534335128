import React, { useEffect, useState } from 'react'
import { PageStyled } from '../styles'
import Box from '../../components/Box'
import Text from '../../components/Text'
import { Card, Col, Divider, Row, message } from 'antd'
import { FaRegUser, FaRegCreditCard, FaCashRegister, FaGift, FaMoneyBill, FaMoneyCheck } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { MdLoyalty } from "react-icons/md";
import { useDispatch } from 'react-redux'
import { setLoading } from '../../store/loading/actions'
import accountApis from '../../services/accountApis'
import { currencyFormat, formatNumber } from '../../helpers/FunctionUtils'
import styled from 'styled-components'
import { ColumnsType } from 'antd/es/table'
import { setShopState } from '../../store/shop/actions'
import { TableEvenOdd } from '../account/styles'

export const AdminDashboardPage = () => {
    const dispatch = useDispatch();
    const [dataObject, setDataObject] = useState<any>(null);
    const [lstData, setLstData] = useState<any[]>([]);

    const columns: ColumnsType<any> = [
        {
            title: <span>Business name</span>,
            dataIndex: 'businessName',
            render: (businessName: any) =>
                <span>
                    {businessName} <br />
                </span>,
        },
        {
            title: <span>Email</span>,
            dataIndex: 'email',
            render: (email: any) => <span>{email}</span>,
        },
        {
            title: <span>Transactions</span>,
            dataIndex: 'transactions',
            render: (transactions: any) => <span>{formatNumber(transactions)}</span>,
        },
        {
            title: <span>Incomes</span>,
            dataIndex: 'incomes',
            render: (incomes: any) => <span>{currencyFormat(incomes, 'USD')}</span>,
        },
        {
            title: <span>Credit cards</span>,
            dataIndex: 'creditCards',
            render: (creditCards: any) => <span> {currencyFormat(creditCards, 'USD')}</span>,
        },
        {
            title: <span>Cash</span>,
            dataIndex: 'cash',
            render: (cash: any) => <span> {currencyFormat(cash, 'USD')}</span>,
        },
        {
            title: <span>Gift cards</span>,
            dataIndex: 'giftCards',
            render: (giftCards: any) => <span> {currencyFormat(giftCards, 'USD')}</span>,
        },
        {
            title: <span>Loyalty redeems</span>,
            dataIndex: 'loyaltyRedeems',
            render: (loyaltyRedeems: any) => <span> {currencyFormat(loyaltyRedeems, 'USD')}</span>,
        },
        {
            title: <span>Others</span>,
            dataIndex: 'totalOther',
            render: (totalOther: any) => <span> {currencyFormat(totalOther, 'USD')}</span>,
        },
    ];

    useEffect(() => {
        const initData = async () => {
            try {
                dispatch(setLoading(true));
                dispatch(setShopState(null));

                const resAccounts = await accountApis.getDashboard();
                if (resAccounts.status === 200) {
                    setDataObject(resAccounts.data.data);
                    setLstData(resAccounts.data.data.top10Best);
                } else {
                    message.error({ content: resAccounts.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: 'System error: ' + error.message as string, duration: 3 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    return (
        <PageStyled>
            <Box className="d-between">
                <Text variant="H5">Report current month</Text>
            </Box>

            <Box mt={1} ml={1}>
                <Row gutter={[20, 20]} style={{ marginBottom: '10px' }}>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#11c26d' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {formatNumber(dataObject?.totalAccount)}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Account
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaRegUser size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#ff4c52' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {formatNumber(dataObject?.totalTransaction)}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Transaction
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <GrTransaction size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#ff8040' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalIncomes, "USD")}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Incomes
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaMoneyBill size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#5965ee' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalCreditCards, 'USD')}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Credit Cards
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaRegCreditCard size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#ffcd17' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalCash, 'USD')}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Cash
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaCashRegister size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#00c1c1' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalGiftCard, 'USD')}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Total Gift Card
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaGift size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#3e8ef7' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalLoyaltyRedeem, 'USD')}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Loyalty Redeem
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <MdLoyalty size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Card style={{ backgroundColor: '#8080c0' }}>
                            <Row gutter={[20, 20]}>
                                <Col span={20}>
                                    <Text variant='H4' style={{ color: '#ffffff' }}>
                                        {currencyFormat(dataObject?.totalOther, 'USD')}
                                    </Text>
                                    <Text variant='H4' style={{ color: '#ffffff', fontSize: '14px' }}>
                                        Others
                                    </Text>
                                </Col>
                                <Col span={4}>
                                    <FaMoneyCheck size={40} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <DividerStyled orientation="left" orientationMargin="0" dashed style={{ fontSize: '18px', marginTop: '20px' }}>
                    Top 10 Best Salon Income
                </DividerStyled>

                <TableContainer>
                    <TableContentStyled
                        columns={columns}
                        dataSource={lstData}
                        rowKey="id"
                        bordered
                        pagination={false} />
                </TableContainer>
            </Box>
        </PageStyled>
    )
}

export const DividerStyled = styled(Divider)`
  
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const TableContentStyled = styled(TableEvenOdd)`
  && .action-dots {
    text-align: center;
  }

  && .center-data {
    text-align: center;
  }
`;