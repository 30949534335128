import {
    PageLoadActionTypes,
    LoadingState,
    START_LOADING,
    END_LOADING,
} from './types';

const initialState: LoadingState = {
    loading: false,
};

const loadReducer = (
    state: LoadingState = initialState,
    action: PageLoadActionTypes
): LoadingState => {
    switch (action.type) {
        case START_LOADING: {
            return { ...state, loading: true };
        }
        case END_LOADING: {
            return { ...state, loading: false, };
        }
        default: return state;
    }
};
export { loadReducer };