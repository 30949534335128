import { DeleteOutlined } from '@ant-design/icons';
import { Button as ButtonAnt, GetProp, Upload, UploadFile, UploadProps, notification } from 'antd';
import styled from 'styled-components';
import uploadImage from './icons/upload-Image.svg';
import ImgCrop from 'antd-img-crop';
import { debounce } from 'lodash';
import { useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const MAX_SIZE_IMAGE = 800000;
type Props = {
  value?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
};
const BannerPromotionUpload = ({ disabled = false, onChange: setImageUrl = () => undefined, value: imageUrl = '' }: Props) => {
  const [value, onChange] = useState<UploadFile[]>([]);
  const openAlert = debounce(() => {
    notification.error({
      message: 'max size of 800KB',
      duration: 3,
    });
  }, 100);

  const onModalOk = async (_value: any) => {
    const file = _value as FileType;
    getBase64(file as FileType, (url) => {
      setImageUrl(url);
    });
  };

  return (
    <UploadStyled>
      <ImgCrop
        rotationSlider={false} cropShape='rect'
        showGrid aspectSlider={false} showReset
        onModalOk={onModalOk}
        // @ts-ignore
        cropperProps={{ restrictPosition: false, cropSize: { width: 375, height: 227 } }}

      >
        <Upload
          name="avatar"
          disabled={disabled}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={() => true}
          fileList={value}
          onChange={({ fileList: newFileList, file }) => {
            if ((file.size || 0) > MAX_SIZE_IMAGE) return openAlert();
            onChange(newFileList);
          }}
          accept="image/*"
        >
          {imageUrl ?
            <div className='image-upload-preview'>
              <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} />
              <ButtonAnt onClick={e => [e.stopPropagation(), setImageUrl('')]} type='dashed' className='btn-trash'><DeleteOutlined style={{ fontSize: 20 }} /></ButtonAnt>
            </div>
            : <UploadUI disabled={disabled} />}
        </Upload>
      </ImgCrop>
    </UploadStyled>
  );
};

export default BannerPromotionUpload;
const UploadStyled = styled.div`
display: inline-flex;
height: 220px;
padding: 12px 8px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8.12px;
flex-shrink: 0;
border-radius: 8.12px;
background: #FFF;
width: 324px;
border-top: 1px solid rgba(0, 76, 116, 0.10);
box-shadow: 0px 3.248px 6.496px 0px rgba(0, 76, 116, 0.20);
.ant-upload-wrapper {
  height: 100%;
  .ant-upload.ant-upload-select {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 8.12px;
    overflow: hidden;
    border: 1.5px dashed #C5D1E2;
    background: #F4F8FB;

    .image-upload-preview {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-trash {
        position: absolute;

      }
    }
  }
}
`;

const UploadUI = ({ disabled }: { disabled?: boolean }) => {
  if (disabled) return (
    <UploadUIStyled>
      <img src={uploadImage} style={{ height: 32, width: 32 }} />
      <div className="text-empty">Promotion Banner</div>
    </UploadUIStyled>
  );
  return <UploadUIStyled>
    <img src={uploadImage} style={{ height: 32, width: 32 }} />
    <div>
      <div className="text-empty">Drag your banner here to</div>
      <div className="text-empty">start uploading.</div>
    </div>

    <div className="text-or">
      <span className="divider" />
      <span>OR</span>
      <span className="divider" />
    </div>

    <div className="btn-choose-image"><span>Choose Image</span></div>
  </UploadUIStyled>;
};
const UploadUIStyled = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 12.992px;
flex-shrink: 0;
padding: 12px;

.text-empty {
  color: #566A7F;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-or {
  display: flex;
  align-items: center;
  gap: 4.872px;
  span {
    color: #8E9BA9;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  span.divider {
    background-color: #8E9BA9;
    width: 36.18px;
    height: 0.669px;
  }
}
.btn-choose-image {
  display: flex;
  height: 40px;
  padding: 12.992px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4.06px;
  background: #008BFF;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.489px; /* 121.804% */
  }
}
`;