import { Button, Form, Input, Select } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react';
import styled from 'styled-components';
import { AddIcon, ButtonAction, DropdownIcon, FilterControlsStyled, PromotionSourceType, PromotionStatus, SearchPrefixIcon, TableSourceTypeIcon, TableStyled, getSourceTypeLabel, promotionSourceTypeList, promotionStatusList } from './helpers';
import { AxiosResponse } from 'axios';
import { debounce, set } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import accountApis from '../../../services/accountApis';
import { formatCurrency, formatNumber } from '../../../utils/formatCurrency';
import { IApiPromotionListResData, IPromotionListParams, IPromotionTableDataItem } from './types';
import { TableEvenOdd } from '../styles';
type PromotionTableRef = {
  init: () => void;
};
const ITEMS_PER_PAGE = 10;
const PromotionTable = forwardRef<PromotionTableRef>(({ }, ref) => {
  const navigate = useNavigate();
  const { id: shopId = '' } = useParams();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [data, setData] = useState<IPromotionTableDataItem[]>([]);
  const [, startTransition] = useTransition();

  const onDetailPromotion = (data: IPromotionTableDataItem) => {
    console.log('data', data);

    navigate('detail/' + data.promotionId);
  };

  const paginationRef = useRef<{ totalItems: number, currentPage: number }>({ totalItems: 0, currentPage: 1 });

  const onPageChanged = async (pageNumber: number) => {
    const values = form.getFieldsValue();
    setLoading(true);
    try {
      await fetchingData({ page: pageNumber, size: ITEMS_PER_PAGE, title: values?.title || '', type: values?.type || '' });
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onFilter = async (values: any) => {
    setLoading(true);
    try {
      await fetchingData({ page: 1, size: ITEMS_PER_PAGE, title: values?.title || '', type: values?.type || '' });
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onDeleteItem = async (record: IPromotionTableDataItem) => {
    const result = await Swal.fire({
      html: 'Are you sure want to delete?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it',
      confirmButtonColor: '#d42b3c',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (!result.isConfirmed) return;

    setLoading(true);
    try {
      const res: AxiosResponse<{ data: boolean }> = await accountApis.deleteAccountPromotion(record.promotionId);
      if (res.data.data) {
        const values = form.getFieldsValue();
        await fetchingData({ page: paginationRef.current.currentPage, size: ITEMS_PER_PAGE, title: values?.title || '', type: values?.type || '' });
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onChangeStatus = async (record: IPromotionTableDataItem, status: string) => {
    const result = await Swal.fire({
      title: '',
      html: 'Are you sure?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#6FABB6',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });
    if (!result.isConfirmed) return;
    setLoading(true);
    try {
      const res: AxiosResponse<{ data: IPromotionTableDataItem }> = await accountApis.updateAccountPromotionStatus(shopId, record.promotionId, status as PromotionStatus);
      const resData = res?.data?.data;
      if (resData) {
        const temp = [...data];
        const idx = temp.findIndex(o => o.promotionId === resData.promotionId);
        if (idx !== -1) {
          set(temp, idx, resData);
          setData(temp);
        }
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const onAddPromotion = () => navigate('create');
  const onPushNotification = (record: IPromotionTableDataItem) => navigate('push-notification/' + record.promotionId);

  const fetchingData = async (params: IPromotionListParams) => {
    paginationRef.current.currentPage = params.page || 1;
    const _params = { ...params };
    if (_params.title == '' || _params.title === null) {
      delete _params.title;
    }
    if (_params.type == 'all' || _params.type === null || _params.type === '') {
      delete _params.type;
    }
    try {
      const res: AxiosResponse<{ data: IApiPromotionListResData }> = await accountApis.getAccountPromotions(shopId, _params);
      const resData = res?.data?.data;
      if (resData.content) {
        setData(resData.content);
        paginationRef.current.totalItems = resData.totalElements;
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };

  const init = async () => {
    startTransition(() => {
      setData([]);
      paginationRef.current = { currentPage: 1, totalItems: 0 };
      setLoading(true);
    });
    try {
      await fetchingData({ page: 1, size: ITEMS_PER_PAGE });
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    init: debounce(init, 200),
  }));

  const pagination: TablePaginationConfig = {
    total: paginationRef.current.totalItems,
    pageSize: ITEMS_PER_PAGE,
    current: paginationRef.current.currentPage,
    showSizeChanger: false,
    onChange: onPageChanged
  };


  return (
    <div>
      <Form initialValues={{ type: 'all' }} form={form} onFinish={onFilter}>
        <PromotionFilter
          onAddPromotion={onAddPromotion}
        />
      </Form>
      <PromotionTableData
        loading={loading}
        data={data}
        pagination={pagination}
        onChangeStatus={onChangeStatus}
        onDeleteItem={onDeleteItem}
        onDetailPromotion={onDetailPromotion}
        onPushNotification={onPushNotification}
      />
    </div>
  );
});

PromotionTable.displayName = 'AccountPromotionTableData';

const usePromotionTableContext = (): [React.RefObject<PromotionTableRef>, JSX.Element] => {
  const ref = useRef<PromotionTableRef>(null);
  const component = <PromotionTable ref={ref} />;

  return [ref, component];
};
export default usePromotionTableContext;
type PromotionFilterProps = {
  onAddPromotion: () => void;
};
const PromotionFilter = ({ onAddPromotion }: PromotionFilterProps) => {
  return <PromotionFilterStyled>
    <Form.Item name={'title'} noStyle>
      <Input prefix={<SearchPrefixIcon />} placeholder='Title' />
    </Form.Item>
    <Form.Item name={'type'} noStyle>
      <Select>
        <Select.Option value={'all'}>All</Select.Option>
        {promotionSourceTypeList.map(o => (
          <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Button htmlType='submit' icon={<SearchPrefixIcon fill='#fff' />} type='primary' >Search</Button>
    {/* <Button htmlType='button' onClick={onPushNotification} icon={<img src={notifyIcon} style={{ height: 24, width: 24 }} />} type='primary' >Push Notification</Button> */}
    <Button htmlType='button' onClick={onAddPromotion} icon={<AddIcon />} className='btn-add' >Add Promotion</Button>
  </PromotionFilterStyled>;
};
const PromotionFilterStyled = styled(FilterControlsStyled)`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
`;

type PromotionTableDataProps = {
  loading: boolean;
  data: IPromotionTableDataItem[];
  onChangeStatus: (record: IPromotionTableDataItem, status: string) => void;
  pagination: TablePaginationConfig;
  onDeleteItem: (record: IPromotionTableDataItem) => void;
  onDetailPromotion: (record: IPromotionTableDataItem) => void;
  onPushNotification: (record: IPromotionTableDataItem) => void;
};
const PromotionTableData = ({ loading, data, onChangeStatus, pagination, onDeleteItem, onDetailPromotion, onPushNotification }: PromotionTableDataProps) => {

  const columns: ColumnsType<IPromotionTableDataItem> = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      render(value, record) {
        return <div onClick={() => onDetailPromotion(record)} className='title-promotion'>{value}</div>;
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      render(value) {
        return <div className='source-type'>
          <TableSourceTypeIcon type={value} />
          <span className={value}>{getSourceTypeLabel(value)}</span>
        </div>;
      },
    },
    {
      title: 'PROMOTION',
      dataIndex: 'promotionValue',
      render(value) {
        return formatCurrency(value);
      },
    },
    {
      title: 'USED',
      dataIndex: 'totalUsed',
      render(value) {
        return formatNumber(value);
      },
    },
    {
      title: 'PROFIT',
      className: 'profit',
      dataIndex: 'profit',
      render(value) {
        return formatCurrency(value);
      },
    },
    {
      title: 'START DATE',
      dataIndex: 'startDate',
    },
    {
      title: 'EXPIRES DATE',
      dataIndex: 'endDate',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render(value, record) {
        return <PromotionStatusDropDown value={value} onChange={(status) => onChangeStatus(record, status)} />;
      },
    },
    {
      title: 'ACTION',
      className: 'actions',
      render(record) {
        return <>
          <ButtonAction onClick={() => onDeleteItem(record)} type='delete' />
          <ButtonAction onClick={() => onDetailPromotion(record)} type='edit' />
          {record.pushNotification ? <ButtonAction onClick={() => onPushNotification(record)} type='notify-active' /> : <ButtonAction onClick={() => onPushNotification(record)} type='notify' />}
        </>;
      },
    },
  ];

  return <PromotionTableDataStyled>
    {/* @ts-ignore */}
    <TableEvenOdd loading={loading} columns={columns} dataSource={data} pagination={pagination} />
  </PromotionTableDataStyled>;
};
const PromotionTableDataStyled = styled(TableStyled)`
  table {
    .title-promotion {
      color: #008BFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .source-type {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
          &.${PromotionSourceType.APPOINTMENT} {
            color: #347AB6;
          }
          &.${PromotionSourceType.GIFT_CARD} {
            color: #F96969;
          }
          &.${PromotionSourceType.SHARING} {
            color: #8968D1;
          }
          &.${PromotionSourceType.VIP} {
            color: #FF7D00;
          }
      }
    }
  }
`;

const PromotionStatusDropDown = ({ value, onChange }: { value: string, onChange: (value: string) => void; }) => {
  const handleOnChange = (_value: string) => {
    onChange(_value);
  };
  return (
    <DropDownStatus className={value}>
      <Select
        options={promotionStatusList}
        value={value}
        onChange={handleOnChange}
        style={{ width: '130px' }}
        suffixIcon={<DropdownIcon />}
      />
    </DropDownStatus>
  );
};

const DropDownStatus = styled.div`
.ant-select .ant-select-selector {
    .ant-select-selection-item {
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
    &.${PromotionStatus.ACTIVE} {
        .ant-select .ant-select-selector {
            background: #E8F3FF;       
            border-color: #E8F3FF;
        }
    }
 
    &.${PromotionStatus.PENDING} {
        .ant-select .ant-select-selector {
            background: #FFF7E8;       
            border-color: #FFF7E8;
        }
    }
`;