import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import actions from '../actions';
import { pagingReducerActions } from "../pagingServices";
import { BanlistState } from "../types";

const userCaseHandler = (builder: ActionReducerMapBuilder<BanlistState>) => {
  builder
    .addCase(actions.user.detail.pending, (state, action) => {
      state.loading = true;
      state.userDetail.data = null;
      state.userDetail.id = action.meta.arg;
    })
    .addCase(actions.user.detail.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetail.data = action.payload;
    })
    .addCase(actions.user.detail.rejected, (state) => {
      state.loading = false;
    })
  builder
    .addCase(actions.user.fetchBlogsByUser.pending, (state, action) => pagingReducerActions.pending(state.userDetail.blog, action))
    .addCase(actions.user.fetchBlogsByUser.fulfilled, (state, action) => pagingReducerActions.fulfilled(state.userDetail.blog, action))
    .addCase(actions.user.fetchBlogsByUser.rejected, (state) => pagingReducerActions.rejected(state.userDetail.blog));

  builder
    .addCase(actions.user.fetchList.pending, (state, action) => pagingReducerActions.pending(state.user, action))
    .addCase(actions.user.fetchList.fulfilled, (state, action) => pagingReducerActions.fulfilled(state.user, action))
    .addCase(actions.user.fetchList.rejected, (state) => pagingReducerActions.rejected(state.user));
};

export default userCaseHandler;
