import { Col, Input, Row, Select, Layout, message, Pagination, Form, Checkbox, Button as AntBtn } from 'antd';
import Box from '../../components/Box';
import Text from '../../components/Text';
import React, { Fragment, useEffect, useState } from 'react';
import AddAccountButton from './components/AddAccountButton';
import AddSalonButton from './components/AddSalonButton';
import SalonItem, { ButtonEditStyled, ButtonSetingStyled, GroupButtonStyled, TextLinkStyled } from './components/SalonItem';
import accountApis from '../../services/accountApis';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Modal from '../../components/Modal';
import { initialAccountDevices } from './components/constants';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import SelectDevices from './components/SelectDevices';
import { setLoading } from '../../store/loading/actions';
import { useDispatch } from 'react-redux';
import { setShopState, unSelectShopState } from '../../store/shop/actions';
import { ButtonDeleteStyled, PageStyled } from '../styles';
import IconList from './components/IconList';
import IconGeneral from './components/IconGeneral';
import { ColumnsType } from 'antd/es/table';
import { SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { formatPhone } from '../../utils/formatPhone';
import Button from '../../components/Button';
import { TableEvenOdd } from './styles';
const { Content } = Layout;

enum AccountPageLayout {
    LIST = 'LIST',
    GRID = 'GRID',
};
const AccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [layout, setLayout] = useState(AccountPageLayout.LIST);
    const { Option } = Select;
    const [form] = Form.useForm();
    const [lstAccount, setLstAccount] = useState<any[]>([]);
    const [lstTimezone, setLstTimezone] = useState<any[]>([]);
    const [listSalon, setLstSalon] = useState<any[]>([]);
    const SALON_MAX_ITEMS_PER_PAGE = 12;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getDataShop = async (accountId: string | null, shopName: string | null, page: number, size: number) => {
        try {
            const response = await accountApis.filterSalon(accountId, shopName, page, size);
            if (response.status === 200) {
                setLstSalon(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                dispatch(setLoading(true));
                const resAccounts = await accountApis.getAll();
                if (resAccounts.status === 200) {
                    setLstAccount(resAccounts.data.data);
                } else {
                    message.error({ content: resAccounts.data.message, duration: 2 });
                }

                const resTimeZone = await accountApis.getTimeZone();
                if (resTimeZone.status === 200) {
                    setLstTimezone(resTimeZone.data.data);
                }

                await getDataShop(null, null, currentPage, SALON_MAX_ITEMS_PER_PAGE);

                dispatch(unSelectShopState());
            } catch (error: any) {
                message.error({ content: 'System error: ' + error.message as string, duration: 3 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            const values = await form.validateFields();
            setCurrentPage(pageNumber);
            await getDataShop(values.accountId, values.shopName, pageNumber, SALON_MAX_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    const handleFormSearchFinish = async (values: any) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(1);
            await getDataShop(values.accountId, values.shopName, 1, SALON_MAX_ITEMS_PER_PAGE);
        } catch (error) { } finally {
            dispatch(setLoading(false));
        }
    };

    const handleDeleteSalon = async (shopId: string, shopName: string) => {
        const result = await Swal.fire({
            title: shopName,
            html: 'Are you sure want to delete this Salon ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await accountApis.deleteShop(shopId);
                if (response.status === 200) {
                    await handleSearch();
                    message.success('Delete Salon successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleAddSalon = async (values: any): Promise<boolean> => {
        try {
            dispatch(setLoading(true));
            const deviceFeatures = values?.devices?.map((item: any) => ({
                feature: item.feature,
                numberDevice: Number(item.numberDevice) || 0,
            }));
            const response = await accountApis.addSalon(values.accountId, values.salon_name, values.time_zone, values.phone,
                values.email, values.street, values.city, values.state, values.zip,
                values.password ? values.password : '123456789',
                values.bookingOnline, values.giftOnline, deviceFeatures);
            if (response.status === 200) {
                await onPageChanged(1);
                message.success({ content: 'Add Salon success' as string, duration: 2 });
                return true;
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
                return false;
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
            return false;
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleAddAccount = async (values: any): Promise<boolean> => {
        try {
            dispatch(setLoading(true));
            const deviceFeatures = values?.devices?.map((item: any) => ({
                feature: item.feature,
                numberDevice: Number(item.numberDevice) || 0,
            }));
            const response = await accountApis.addAccount(values.salon_name, values.time_zone, values.phone, values.firsName,
                values.lastName, values.email, values.street, values.city, values.state, values.zip,
                values.password ? values.password : '123456789',
                values.bookingOnline, values.giftOnline, deviceFeatures);
            if (response.status === 200) {
                await onPageChanged(1);
                message.success({ content: 'Add Account success' as string, duration: 2 });
                return true;
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
                return false;
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
            return false;
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleGenLink = async (shopId: string, type: string, shopName: string) => {
        const result = await Swal.fire({
            title: shopName,
            html: `Are you sure want to generate ${type === 'BOOKING' ? 'Booking' : 'Gift Card'} link?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, generate it',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await accountApis.genLinkShop(shopId, type);
                if (response.status === 200) {
                    await onPageChanged(currentPage);
                    message.success('Generate successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const [formUpdateSalon] = Form.useForm();
    const [showModalActive, setShowModalActive] = useState(false);
    const [listDevice, setListDevice] = useState<any>(null);
    const watchDevices = Form.useWatch('devices', formUpdateSalon);

    const handleUpdateSalon = async (shopId: string) => {
        dispatch(setLoading(true));
        const response = await accountApis.getShopById(shopId);
        if (response.status === 200) {
            const data = response.data.data;
            const updatedDevices = initialAccountDevices.map(device => {
                const feature = data.deviceFeatures.find((f: any) => f.feature === device.feature);
                if (feature) {
                    return {
                        ...device,
                        numberDevice: feature.numberDevice,
                        enable: true,
                        id: feature.id,
                        shopId: feature.shopId
                    };
                }
                return device;
            });

            setListDevice(updatedDevices);
            formUpdateSalon.setFieldsValue({
                shopId: data.id,
                shopName: data.shopName,
                address: data.address,
                email: data.email,
                phone: data.phone,
                zip: data.zip,
                city: data.city,
                state: data.state,
                bookingOnline: data.bookingLink ? true : false,
                giftOnline: data.giftOnlineLink ? true : false,
                devices: updatedDevices,
                timeZone: data.timeZone
            });
            setShowModalActive(true);
            dispatch(setLoading(false));
        } else {
            message.error({ content: response.data.message as string, duration: 2 });
        }
    };
    const handleCloseEditorModal = () => {
        setShowModalActive(false);
    };

    const onSubmitUpdateSalon = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const deviceFeatures = values?.devices?.filter((item: any) => item.enable)?.map((item: any) => ({
                id: item.id,
                feature: item.feature,
                shopId: item.shopId,
                numberDevice: Number(item.numberDevice) || 0,
            }));

            const response = await accountApis.updateSalon(
                values.shopId, values.shopName, values.timeZone, values.phone, values.email, values.address,
                values.city, values.state, values.zip, deviceFeatures, values.giftOnline, values.bookingOnline
            );
            if (response.status === 200) {
                await onPageChanged(currentPage);
                message.success({ content: 'Update Salon success' as string, duration: 2 });
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 3 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleEditShop = async (id: string) => {
        dispatch(setLoading(true));
        try {
            const response = await accountApis.getShopById(id);
            if (response.status === 200) {
                dispatch(setShopState(response.data.data));
                navigate(`/salon/${id}`)
            } else {
                message.error({ content: response.data.message, duration: 5 });
            }
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 5 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <span>Owner Name</span>,
            dataIndex: 'accountName',
            render: (text) => <span>{text}</span>,
        },
        {
            title: <span>Store Name</span>,
            dataIndex: 'shopName',
            render: (shopName: any, salonData) => <AntBtn type='link' onClick={() => handleEditShop(salonData.shopId)}>{shopName}</AntBtn>,
        },
        {
            title: <span>Email</span>,
            dataIndex: 'email',
            render: (email: any) => <span>{email}</span>,
        },
        {
            title: <span>Phone</span>,
            dataIndex: 'phoneShop',
            render: (text: any) => <span>{formatPhone(text)}</span>,
        },
        {
            title: <span>Address</span>,
            dataIndex: 'address',
            render: (text: any, salonData) => <span>{salonData?.address || ''}, {salonData?.city || ''}, {salonData?.state || ''}, {salonData?.zipCode || ''}</span>,
        },
        {
            title: <span>Created Date</span>,
            dataIndex: 'createdDate',
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: <span>Actions</span>,
            width: '100px',
            render: (text: any, salonData) => (
                <GroupButtonStyled className='d-between only-icon-ui'>
                    <ButtonSetingStyled className='ButtonSettingStyled' variant="SECONDARY" onClick={() => handleEditShop(salonData.shopId)}>
                        <SettingOutlined style={{ fontSize: '18px' }} />
                    </ButtonSetingStyled>
                    <ButtonEditStyled className='ButtonSettingStyled' variant="SECONDARY" icon="editV2" onClick={() => handleUpdateSalon(salonData.shopId)}>
                    </ButtonEditStyled>
                    <ButtonDeleteStyled className='ButtonSettingStyled' icon="delete" onClick={() => handleDeleteSalon(salonData.shopId, salonData.shopName)}>
                    </ButtonDeleteStyled>
                </GroupButtonStyled>
            ),
        },
    ];


    return (
        <PageStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showModalActive}
                onCancel={handleCloseEditorModal}
                onOk={() => formUpdateSalon.submit()}
                title='Update info Salon'
                width={800}
                closeIcon={null}
                centered>
                <Form
                    form={formUpdateSalon}
                    layout="vertical"
                    onFinish={onSubmitUpdateSalon}
                    initialValues={{ devices: listDevice }}>
                    <FormItemNoMarginStyled name="shopId">
                        <Input type='hidden' />
                    </FormItemNoMarginStyled>
                    <Box px={1}>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="shopName" label="Salon’s Name"
                                    rules={
                                        [
                                            { required: true }
                                        ]
                                    }
                                >
                                    <Input size='large' autoComplete='off' />
                                </FormItemNoMarginStyled>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="address" label="address"
                                    rules={
                                        [
                                            { required: true }
                                        ]
                                    }>
                                    <Input size='large' autoComplete='off' />
                                </FormItemNoMarginStyled>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="email" label="Email" rules={
                                    [
                                        { required: true },
                                        {
                                            type: 'email',
                                            message: 'The input is not valid email',
                                        }
                                    ]
                                }>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </FormItemNoMarginStyled>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled
                                    name="phone"
                                    label="Phone"
                                    rules={
                                        [
                                            { required: true }
                                        ]
                                    }>
                                    <Input size='large' type="number" autoComplete='off' placeholder='Please enter...' />
                                </FormItemNoMarginStyled>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="zip" label="Zip Code" rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </FormItemNoMarginStyled>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="city" label="City" rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </FormItemNoMarginStyled>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="state" label="State" rules={
                                    [
                                        { required: true }
                                    ]
                                }>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </FormItemNoMarginStyled>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <SelectDevices watchDevices={watchDevices} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <FormItemNoMarginStyled name="timeZone" label="Timezone"
                                    rules={
                                        [
                                            { required: true }
                                        ]
                                    }>
                                    <Select size='large' showSearch placeholder="Select">
                                        {lstTimezone?.map((tz, index) => (
                                            <Option key={index} value={tz.value}>
                                                {tz.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItemNoMarginStyled>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemCheckBoxStyled name="bookingOnline" valuePropName="checked" label="Booking Online">
                                            <Checkbox>Booking Online</Checkbox>
                                        </FormItemCheckBoxStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemCheckBoxStyled name="giftOnline" valuePropName="checked" label="Gift Cards Online">
                                            <Checkbox>Gift Cards Online</Checkbox>
                                        </FormItemCheckBoxStyled>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </Modal>
            {lstAccount ?
                (
                    <Fragment>
                        <Box className="d-between">
                            <Text variant="H5">Accounts ({totalItems || 0})</Text>
                            <Box display="flex" gap={1} alignItems='center'>
                                {/* <Button icon="delete">Delete</Button> */}
                                <GroupLayoutButtons>
                                    <button onClick={() => setLayout(AccountPageLayout.LIST)}><IconList active={layout === AccountPageLayout.LIST} /></button>
                                    <button onClick={() => setLayout(AccountPageLayout.GRID)}><IconGeneral active={layout === AccountPageLayout.GRID} /></button>
                                </GroupLayoutButtons>
                                <Form form={form} name="search" onFinish={handleFormSearchFinish}>
                                    <Box display="flex" gap={1}>
                                        <Form.Item name="accountId" noStyle>
                                            <Select size='large' style={{ width: '15vw' }} placeholder="All Account"
                                                showSearch optionFilterProp="label">
                                                <Select.Option value={''} label={'All Accounts'}>
                                                    All Accounts
                                                </Select.Option>
                                                {lstAccount.map((item: any) => (
                                                    <Select.Option key={item.id} value={item.id} label={`${item.firstName} ${item.lastName} - ${item.email}`}>
                                                        {item.firstName} {item.lastName} - {item.email}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="shopName" noStyle>
                                            <Input style={{ width: '20vw' }} size='large' placeholder='Search by shop name, phone, zip' autoComplete='off' />
                                        </Form.Item>
                                        <ButtonAddStyled variant="SECONDARY" onClick={() => form.submit()}>
                                            Search
                                        </ButtonAddStyled>
                                    </Box>
                                </Form>
                                <AddSalonButton lstAccount={lstAccount} handleAddSalon={handleAddSalon} lstTimeZone={lstTimezone} />
                                <AddAccountButton handleAddAccount={handleAddAccount} lstTimeZone={lstTimezone} />
                            </Box>
                        </Box>
                        <Box mt={1}>
                            {
                                layout === AccountPageLayout.GRID ? <>
                                    <Row gutter={[16, 16]}>
                                        {listSalon?.map((item: any, index) => (
                                            <Col key={item.shopId} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
                                                <Box>
                                                    <SalonItem salonData={item} handleDeleteSaLon={handleDeleteSalon} handleGenLink={handleGenLink} handleUpdateSalon={handleUpdateSalon} />
                                                </Box>
                                            </Col>
                                        ))}
                                    </Row>
                                </> :
                                    layout === AccountPageLayout.LIST ? <>
                                        <TableContentStyled
                                            columns={columns}
                                            dataSource={listSalon}
                                            rowKey="shopId"
                                            bordered
                                            pagination={false} />
                                    </> : null
                            }


                            <PagingStyled>
                                <Pagination
                                    current={currentPage}
                                    total={totalItems}
                                    pageSize={SALON_MAX_ITEMS_PER_PAGE}
                                    onChange={onPageChanged}
                                    showSizeChanger={false}
                                />
                            </PagingStyled>
                        </Box>
                    </Fragment>
                )
                :
                (
                    <Fragment>
                        <ContentImgStyled>
                            <img src="/images/account_bg.png" alt="Logo" />
                            <p>Accounts</p>
                            <AddAccountButton handleAddAccount={handleAddAccount} lstTimeZone={lstTimezone} />
                        </ContentImgStyled>
                    </Fragment>
                )
            }
        </PageStyled>
    );
};

const ContentImgStyled = styled(Content)`
  text-align: center;
    && img {
      margin-top: 100px;
    }

    && p {
      font-size: 24px;
      font-weight: 500;
      margin: 10px 0px 10px 0px;
    }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FormItemCheckBoxStyled = styled(Form.Item)`
  margin: 10px;
  && .ant-form-item-label label {
    color: transparent;
  }
`;

const GroupLayoutButtons = styled.div`
  display: flex;
  align-items: center;
  button { 
    border: none;
    background: none;
    &:hover {
        cursor: pointer;
    }
  }
`;
const TableContentStyled = styled(TableEvenOdd)`
  && .action-dots {
    text-align: center;
  }

  && .center-data {
    text-align: center;
  }
`;
const ButtonAddStyled = styled(Button)`
  &&:hover {
    background: #4096ff !important;
  }
`;
export default AccountPage;
