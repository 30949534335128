import { Form } from 'antd';
import { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { useThunkDispatch } from '../../../store';
import banlistActions from '../../../store/banlist/actions';
import { FORMAT_DATE_REQUEST } from '../../../store/banlist/constants';
import BlogsTableData from './BlogsTableData';
import BlogsTableFilter from './BlogsTableFilter';

const BlogsTable = () => {
  const dispatch = useThunkDispatch();
  const [form] = Form.useForm();
  const handleFinish = (values: { endDate: Dayjs, startDate: Dayjs, keyword: string }) => {
    dispatch(banlistActions.blog.fetchList({
      startDate: values.startDate.format(FORMAT_DATE_REQUEST),
      endDate: values.endDate.format(FORMAT_DATE_REQUEST),
      keySearch: values.keyword,
    }))
  };

  return (
    <BlogsTableStyled>
      <Form form={form} onFinish={handleFinish}>
        <BlogsTableFilter />
      </Form>
      <BlogsTableData />
    </BlogsTableStyled>
  )
}

export default BlogsTable;

const BlogsTableStyled = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
