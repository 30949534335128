
import { DownloadOutlined, LeftOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button as AntButton, Breadcrumb, Form, Input, InputNumber, Pagination, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Box from '../../components/Box';
import Button from '../../components/Button';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import Modal from '../../components/Modal';
import Text from '../../components/Text';
import { currencyFormat, formatPhoneNumber } from '../../helpers/FunctionUtils';
import accountApis from '../../services/accountApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import { PageStyled } from '../styles';
import { TableEvenOdd } from './styles';
import ModalUploadCustomer, { ModalUploadCustomerProps, useModalUploadCustomerRef } from '../../components/ModalUploadCustomer';

export const SalonCustomersPage = () => {
    const modalUpload = useModalUploadCustomerRef();
    const navigate = useNavigate();
    const [formSearch] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [lstGroupByShopId, setLstGroupByShopId] = useState<any>(null);
    const [lstCustomer, setLstCustomer] = useState<any[]>([]);
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shopDate, setShopDate] = useState<any>(null);

    const handleUploadCustomers: ModalUploadCustomerProps['onSubmit'] = async (file, list) => {
        if (!id) return true;
        try {
            const res = await accountApis.importShopCustomers(id, file);
            if (res.data.data) {
                await getDataCustomer(id, null, null, null, null, null, null, null, 1, COUPON_ITEMS_PER_PAGE);
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const getDataCustomer = async (shopId: any, groupId: string | null, pointFrom: string | null, pointTo: string | null,
        sendingAmountFrom: string | null, sendingAmountTo: string | null, types: any, lastPhoneNumber: string | null, page: number, size: number) => {
        try {
            const response = await accountApis.filterCustomerByShopId(
                shopId, groupId, pointFrom, pointTo, sendingAmountFrom, sendingAmountTo, types, lastPhoneNumber, page, size
            );
            if (response.status === 200) {
                setLstCustomer(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !lstGroupByShopId) {
                    dispatch(setLoading(true));
                    const response = await accountApis.allCustomerGroupListByShopId(id);
                    if (response.status === 200) {
                        setLstGroupByShopId(response.data.data);
                    }
                    await getDataCustomer(id, null, null, null, null, null, null, null, currentPage, COUPON_ITEMS_PER_PAGE);

                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }
                    dispatch(setLoading(false));
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataCustomer(id, values.groupId, values.pointFrom, values.pointTo, values.sendingAmountFrom
                , values.sendingAmountTo, values.types, values.lastPhoneNumber, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>NAME</p>,
            dataIndex: 'name',
            className: 'name',
            render: (name: string) => <a>{name}</a>,
        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
            className: 'phone',
            render: (phone: string) =>
                <span>{formatPhoneNumber(phone)}</span>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            className: 'email',
            render: (email: string) =>
                <span>{email ? email : '--'}</span>,
        },
        {
            title: 'DOB',
            dataIndex: 'birthday',
            className: 'birthday',
            render: (birthday: string) =>
                <span>{birthday ? birthday.slice(0, 5) : '--'}</span>
        },
        {
            title: 'POINT',
            dataIndex: 'point',
            className: 'point',
            render: (point: number) =>
                <span>{point}</span>,
        },
        {
            title: <p>AMOUNT SPENT</p>,
            dataIndex: 'amountSpent',
            className: 'amountSpent',
            render: (amountSpent: string) =>
                <span>{currencyFormat(amountSpent, 'USD')}</span>
        },
        {
            title: <p>CREATED DATE</p>,
            dataIndex: 'createdDate',
            className: 'createdDate',
            render: (createdDate: string) =>
                <span>{createdDate}</span>
        },
        {
            title: 'VISITED',
            dataIndex: 'numberVisit',
            className: 'numberVisit',
            render: (numberVisit: string) =>
                <span>{numberVisit}</span>,
        },
        {
            title: <p>LAST VISITED</p>,
            dataIndex: 'lastVisitedDate',
            className: 'lastVisitedDate',
            render: (lastVisitedDate: string) =>
                <span>{lastVisitedDate}</span>,
        },
        {
            title: 'TYPE',
            dataIndex: 'status',
            className: 'status',
            render: (status: string) =>
                <CustomerTypeStyled
                    style={{
                        backgroundColor:
                            status === 'NEW'
                                ? 'rgba(254, 237, 191, 1)'
                                : status === 'REGULAR'
                                    ? 'rgba(198, 233, 239, 1)'
                                    : 'rgba(255, 229, 231, 1)',
                    }}
                >
                    {status}
                </CustomerTypeStyled>

        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const resetRowSelection = () => {
        setSelectedRowKeys([]);
    };

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    const handleFilter = async (values: any) => {
        const pageNumber = 1;
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            await getDataCustomer(id, values.groupId, values.pointFrom, values.pointTo, values.sendingAmountFrom
                , values.sendingAmountTo, values.types, values.lastPhoneNumber, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [showModalAddGroup, setModalAddGroup] = useState(false);
    const [formNewGroup] = Form.useForm();
    const onSubmitAddGroup = async (values: any) => {
        try {
            if (selectedRowKeys.length == 0) {
                message.error({ content: 'Please select the Customers', duration: 2 });
                return;
            }
            dispatch(setLoading(true));
            const response = await accountApis.addGroupCustomerByShopId(id, selectedRowKeys, values.groupName);
            if (response.status === 200) {
                message.success('Add new group successful');
                resetRowSelection();
                formNewGroup.resetFields();
                const response = await accountApis.allCustomerGroupListByShopId(id);
                if (response.status === 200) {
                    setLstGroupByShopId(response.data.data);
                }
                setModalAddGroup(false);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const exportCustomer = async () => {
        try {
            dispatch(setLoading(true));
            const values = await formSearch.validateFields();
            const response = await accountApis.exportExcelCustomer(id, values.groupId, values.pointFrom, values.pointTo,
                values.sendingAmountFrom, values.sendingAmountTo, values.types, values.lastPhoneNumber);
            if (response.status === 200) {
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
                const fileName = `export_${timestamp}.xlsx`;

                anchorElement.download = fileName;
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                message.success('Export successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <PageStyled>
            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate('/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>
            <ContentStyled>
                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    open={showModalAddGroup}
                    onCancel={() => setModalAddGroup(false)}
                    onOk={() => formNewGroup.submit()}
                    title='Group’s Name'
                    closeIcon={null}
                    centered>
                    {/* Hiển thị trình soạn thảo trong modal */}
                    <Form form={formNewGroup}
                        layout="vertical"
                        onFinish={onSubmitAddGroup}>
                        <Box px={1}>
                            <FormItemNoMarginStyled
                                name="groupName"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input size='large' placeholder='Please enter' autoComplete='off' />
                            </FormItemNoMarginStyled>
                        </Box>
                    </Form>
                </Modal>

                <Box className="d-between" mb={2}>
                    <Text variant="H5">Customer List ({totalItems})</Text>
                    <Box display="flex" gap={1}>
                        <Button variant="SECONDARY" icon="add" onClick={() => setModalAddGroup(true)}>
                            Add Group
                        </Button>
                        <Button onClick={() => modalUpload.current?.open()}>
                            <Row style={{ gap: 8 }}>
                                <UploadOutlined />
                                <span style={{ fontSize: 16 }}>Import Customers</span>
                            </Row>
                        </Button>
                        <Button variant="GREEN" onClick={() => exportCustomer()}>
                            <Row style={{ gap: 8 }}>
                                <DownloadOutlined />
                                <span style={{ fontSize: 16 }}>Export Customer</span>
                            </Row>
                        </Button>
                    </Box>
                </Box>

                <Form form={formSearch} onFinish={handleFilter}>
                    <Row style={{ gap: 16 }}>
                        <Form.Item name="groupId" style={{ flex: 0.5 }}>
                            <Select size='large' style={{ width: '100%' }}
                                showSearch optionFilterProp="label"
                                defaultValue={''}
                            >
                                <Select.Option key={'0'} value=''>{'All Groups'}</Select.Option>
                                {lstGroupByShopId?.map((item: any) => (
                                    <Select.Option key={item.id} value={item.id} label={`${item.groupName}`}>
                                        {item.groupName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Input.Group compact style={{ flex: 1 }}>
                            <InputNumber
                                name="sendingAmountFrom" // Thêm name cho ô nhập từ
                                style={{ width: '50%' }}
                                placeholder="Sending Amount From"
                                min={0}
                                size='large'
                            />
                            <InputNumber
                                name="sendingAmountTo" // Thêm name cho ô nhập đến
                                style={{ width: '50%' }}
                                placeholder="Sending Amount To"
                                size='large'
                                min={0}
                            />
                        </Input.Group>
                        <Input.Group compact style={{ flex: 1 }}>
                            <InputNumber
                                name="pointFrom" // Thêm name cho ô nhập từ
                                style={{ width: '50%' }}
                                placeholder="Point From"
                                min={0}
                                size='large'
                            />
                            <InputNumber
                                name="pointTo" // Thêm name cho ô nhập đến
                                style={{ width: '50%' }}
                                placeholder="Point To"
                                size='large'
                                min={0}
                            />
                        </Input.Group>
                        <Form.Item name="types" style={{ flex: 0.5 }}>
                            <Select size='large' style={{ width: '100%' }}
                                showSearch
                                mode="multiple"
                                placeholder="Types"
                            >
                                <Select.Option key={'VIP'} value='VIP'>
                                    VIP
                                </Select.Option>
                                <Select.Option key={'REGULAR'} value='REGULAR'>
                                    REGULAR
                                </Select.Option>
                                <Select.Option key={'NEW'} value='NEW'>
                                    NEW
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="lastPhoneNumber" style={{ flex: 0.5 }}>
                            <Input size='large' placeholder='Last Phone Number' />
                        </Form.Item>
                        <AntButton htmlType='submit' type="primary" style={{ width: 'auto' }} icon={<SearchOutlined />} size='large'>
                            Search
                        </AntButton>
                    </Row>
                </Form>

                <TableContentStyled
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={lstCustomer}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
                <ModalUploadCustomer ref={modalUpload} onSubmit={handleUploadCustomers} />
            </ContentStyled>


        </PageStyled>
    );
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;
`;

const TableContentStyled = styled(TableEvenOdd)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }

  thead > tr > th.ant-table-cell {
    padding: 8px 16px;
    p {
        margin: 0;
    }
  } 
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
`;

const CustomerTypeStyled = styled(Box)`
  padding: 8px 0px 8px 0px;
  border-radius: 20px;
  text-align: center;
`;
