import blog from './blog';
import user from './user';
import customer from './customer';
const banlistActions = {
  blog,
  user,
  customer,
};

export default banlistActions;
