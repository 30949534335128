
import { Col, Form, Input, Row, Select, Switch, message, Pagination, Breadcrumb, DatePicker, Button as AntButton } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import Modal from '../../components/Modal';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import { TableRowSelection } from 'antd/es/table/interface';
import { Content } from 'antd/es/layout/layout';
import { PageStyled } from '../styles';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import SelectOrDatePicker from '../../components/Modal/SelectOrDatePicker';
import couponApis from '../../services/couponApis';
import dayjs from 'dayjs';
import accountApis from '../../services/accountApis';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import { get } from 'lodash';
import { TableEvenOdd } from './styles';

const ObjectTypeCouponLabel = {
    GIFT_CARD: 'GIFT CARD',
    LOYALTY_POINT: 'LOYALTY POINT',
    SHARE_REFERRAL: 'SHARE REFERRAL',
    MARKETING: 'MARKETING',
    REVIEW: 'REVIEW',
    TICKET: 'TICKET',
};

interface DataType {
    key: React.Key;
    shopName: string;
    title: string;
    counponName: string;
    promotion: string;
    quantity: number;
    couponUsed: string;
    daysExpired: any;
    status: string;
    action: string;
    discountType: string;
    totalPrint: number;
    couponId: string;
    startTime: any;
    templateType: any;
}

export const CouponListPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [form] = Form.useForm();
    const [formActive] = Form.useForm();
    const [lstCounpon, setLstCounpon] = useState<any[]>([]);
    const [shopDate, setShopDate] = useState<any>(null);

    const getDataCounpon = async (type: string | null, status: string | null, couponName: string | null, page: number, size: number) => {
        try {
            const response = await couponApis.filterCoupon(id, type ? type : null, status ? status : null, couponName, page, size);
            if (response.status === 200) {
                setLstCounpon(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id) {
                    dispatch(setLoading(true));
                    await getDataCounpon(null, null, null, currentPage, COUPON_ITEMS_PER_PAGE);
                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }
                }
            } catch (error) {
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);


    const handleFinish = async (values: any) => {
        await onPageChanged(1, values);
    };

    const onPageChanged = async (pageNumber: number, _values?: any) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = _values || await form.validateFields();
            await getDataCounpon(values.type, values.status, values.couponName, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: 'TITLE',
            dataIndex: 'title',
            className: 'title',
            render: (title: number) =>
                <span>{title ? title : '--'}</span>,
        },
        {
            title: 'TYPE',
            dataIndex: 'templateType',
            className: 'templateType',
            render: (templateType) =>
                <span style={{ whiteSpace: 'nowrap' }}>{get(ObjectTypeCouponLabel, templateType, '') || '--'}</span>,
        },
        {
            title: 'PROMOTION',
            dataIndex: 'discountValue',
            className: 'discountValue',
            render: (discountValue: string, record: DataType) =>
                <span>{record.discountType == 'PERCENT' ? discountValue + '%' : '$' + discountValue}</span>,
        },
        {
            title: 'PRINTED QUANTITY',
            dataIndex: 'totalPrint',
            className: 'totalPrint',
            render: (totalPrint: number, record: DataType) =>
                <div className="progress">
                    <div style={{ width: `${(totalPrint / record.quantity) * 100}%` }}
                        className="progress-bar bg-warning" role="progressbar" />
                    <span>{totalPrint ? totalPrint : 0} | {record.quantity}</span>
                </div>,
        },
        {
            title: 'COUPON USED',
            dataIndex: 'couponUsed',
        },
        {
            title: 'START DATE',
            dataIndex: 'startTime',
            render: (startTime: string, record: DataType) => <span id={`startTime_${record.couponId}`}>
                <span>{dayjs(startTime).format('MM-DD-YYYY')}</span>
            </span>,
            className: 'startTime',
        },
        {
            title: 'EXPIRED DATE',
            dataIndex: 'endTime',
            render: (endTime: string, record: DataType) =>
                <span id={`endTime_${record.couponId}`}>
                    <span>
                        {endTime ? dayjs(endTime).format('MM-DD-YYYY') : '--'}
                    </span>
                </span>,
            className: 'endTime',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (status: string, record: DataType) =>
                <Fragment>
                    <span id={'status_' + record.couponId} >
                        <span className='coupon-label-status'
                            style={{
                                backgroundColor: status === 'ACTIVE' ? 'rgba(232, 243, 255, 1)' : status === 'PENDING' ? 'rgba(255, 247, 232, 1)' : 'rgba(206, 206, 206, 1)',
                            }}>
                            {status === 'ACTIVE' ? 'Active' : status === 'PENDING' ? 'Pending' : 'Expired'}
                        </span>
                    </span>
                </Fragment>
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            dataIndex: 'status',
            render: (status: string, item: DataType) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={item.couponId}
                                onChange={(checked) => handleSwitchChange(checked, item.couponId, item.templateType)}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                checked={switchStates[item.couponId] === undefined ? status === 'ACTIVE' : switchStates[item.couponId]} />

                            <Button className='btn-delete' icon="delete" onClick={() => handleDelete(item.couponId, item.shopName)}></Button>
                            <Button className='btn-edit' icon="edit" onClick={() => navigate(`/coupons/${id}/detail/${item.couponId}`)}></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDeleteMultiple = async () => {
        if (selectedRowKeys.length <= 0) {
            message.warning({ content: 'Coupon has not been selected yet', duration: 2 });
            return;
        }
        const result = await Swal.fire({
            title: 'Delete multiple Coupons',
            html: 'Are you sure want to delete Coupons?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete them',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await couponApis.deleteMultiple(selectedRowKeys);
                if (response.status === 200) {
                    await onPageChanged(currentPage);
                    message.success('Delete multiple Coupons successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleDelete = async (couponId: string, shopName: string) => {
        const result = await Swal.fire({
            title: shopName,
            html: 'Are you sure want to delete Coupon?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const couponIds: any[] = [
                    couponId
                ];
                const response = await couponApis.deleteMultiple(couponIds);
                if (response.status === 200) {
                    await onPageChanged(currentPage);
                    message.success('Delete coupon successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleCloseEditorModal = () => {
        setShowModalActive(false);
    };

    const [showModalActive, setShowModalActive] = useState(false);
    const initialSwitchStateRef = useRef<any>({});
    const [switchStates, setSwitchStates] = useState<any>({});

    // Hàm để cập nhật trạng thái của Switch
    const updateSwitchState = (itemId: string, checked: any) => {
        setSwitchStates((prevStates: any) => ({
            ...prevStates,
            [itemId]: checked,
        }));
    };

    const [templateSelected, setTemplateSelected] = useState<any>();
    const handleSwitchChange = async (checked: any, itemId: any, templateType: any) => {
        setTemplateSelected(templateType);
        if (checked) {
            formActive.setFieldsValue({
                couponId: itemId,
                startDate: dayjs()
            });
            setStartDateEntered(dayjs());
            setShowModalActive(true);
        } else {
            const response = await couponApis.updateStatusCoupon(itemId, 'PENDING');
            if (response.status === 200) {
                message.success('Change Pending successful');
                const element = document.getElementById('status_' + itemId);
                if (element) {
                    element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(255, 247, 232);">Pending</span>';
                }
                updateSwitchState(itemId, checked);
            } else {
                updateSwitchState(itemId, initialSwitchStateRef.current[itemId]);
                message.error({ content: response.data.message, duration: 2 });
            }
        }
    };

    const [startDate, setStartDateEntered] = useState<any>(null);
    const handleStartDateChange = (date: any) => {
        setStartDateEntered(date);
    };

    const [expireDate, setExpireDate] = useState('');
    const handleChangeExpireDate = (value: any) => {
        if (!isNaN(value)) {
            value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
        }
        setExpireDate(value);
    };

    const onSubmitUpdateAcvite = async (values: any) => {
        try {
            const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
            const dateStartCheck = dayjs(startDate.format('MM-DD-YYYY'), { format: 'MM-DD-YYYY' });
            if (dateCheck.isBefore(dateStartCheck)) {
                message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
                return;
            }
            dispatch(setLoading(true));
            const itemId = values.couponId;
            const response = await couponApis.updateTime(values.couponId, startDate.format('MM-DD-YYYY'), expireDate);
            if (response.status === 200) {
                message.success('Update time successful');

                // update ui status
                let element = document.getElementById('status_' + itemId);
                if (element) {
                    element.innerHTML = '<span class="coupon-label-status" style="background-color: rgb(232, 243, 255);">Active</span>';
                }

                // update ui startTime
                element = document.getElementById('startTime_' + itemId);
                if (element) {
                    element.innerHTML = `<span>${startDate.format('MM-DD-YYYY')}</span>`;
                }

                element = document.getElementById('endTime_' + itemId);
                if (element) {
                    element.innerHTML = `<span>${expireDate}</span>`;
                }

                formActive.resetFields();
                handleCloseEditorModal();
                updateSwitchState(itemId, !initialSwitchStateRef.current[itemId]);
            } else {
                if (initialSwitchStateRef.current[itemId] !== undefined) {
                    updateSwitchState(itemId, initialSwitchStateRef.current[itemId]);
                }
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <PageStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showModalActive}
                onCancel={handleCloseEditorModal}
                onOk={() => formActive.submit()}
                title='Active Coupon'
                closeIcon={null}
                centered>
                {/* Hiển thị trình soạn thảo trong modal */}
                <Form form={formActive} name="formActive"
                    layout="vertical"
                    onFinish={onSubmitUpdateAcvite}>
                    <Box px={1}>
                        <FormItemNoMarginStyled
                            name="startDate" // Tên của trường trong form
                            label='Start Date'
                            rules={[
                                {
                                    required: true
                                }
                            ]}>
                            <DatePicker
                                onChange={handleStartDateChange}
                                style={{ width: '100%' }}
                                size="large"
                                format="MM-DD-YYYY"
                                showTime={false}
                                inputReadOnly={true} />
                        </FormItemNoMarginStyled>

                        {templateSelected !== 'LOYALTY_POINT' ? (
                            <FormItemNoMarginStyled
                                name="expiresDate" // Tên của trường trong form
                                label='Expires Date'
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf={''} />
                            </FormItemNoMarginStyled>
                        ) : null}

                        <FormItemNoMarginStyled name='couponId'>
                            <Input type='hidden' />
                        </FormItemNoMarginStyled>
                    </Box>
                </Form>
            </Modal>

            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button icon="delete" onClick={handleDeleteMultiple}>Delete</Button>
                    <Button variant="PRIMARY" icon="add" onClick={() => navigate(`/coupons/${id}/create`)}>
                        Create Coupon
                    </Button>
                </Box>
            </Box>

            <CouponsContentStyled>
                <Form form={form} name="search" onFinish={handleFinish}>
                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item name="type">
                                <Select defaultValue={''} size="large">
                                    <Select.Option key={'0'} value=''>{'All Promotion'}</Select.Option>
                                    <Select.Option key={'SHARE_REFERRAL'} value='SHARE_REFERRAL'>{'Share Referral'}</Select.Option>
                                    <Select.Option key={'MARKETING'} value='MARKETING'>{'Marketing'}</Select.Option>
                                    <Select.Option key={'REVIEW'} value='REVIEW'>{'Review'}</Select.Option>
                                    <Select.Option key={'TICKET'} value='TICKET'>{'Ticket'}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col flex={1}>
                            <Form.Item name="status">
                                <Select defaultValue={''} size="large">
                                    <Select.Option key={'0'} value=''>{'All Status'}</Select.Option>
                                    <Select.Option key={'ACTIVE'} value={'ACTIVE'}>{'Active'}</Select.Option>
                                    <Select.Option key={'PENDING'} value={'PENDING'}>{'Pending'}</Select.Option>
                                    <Select.Option key={'EXPIRED'} value={'EXPIRED'}>{'Expired Date'}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col flex={1}>
                            <Form.Item name="couponName">
                                <Input size='large' placeholder='Coupon Name' />
                            </Form.Item>
                        </Col>
                        <Col>
                            <AntButton htmlType='submit' type="primary" style={{ width: 'auto' }} icon={<SearchOutlined />} size='large'>
                                Search
                            </AntButton>
                        </Col>
                    </Row>
                </Form>

                <TableContentStyled
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={lstCounpon}
                    rowKey="couponId"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={COUPON_ITEMS_PER_PAGE}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </CouponsContentStyled>
        </PageStyled>
    );
}

const CouponsContentStyled = styled.div`
  background: rgba(255, 255, 255, 1);
  margin-top: 2rem;
  padding: 1.5rem;
`;

const TableContentStyled = styled(TableEvenOdd)`
  && .shopName {
    width: 15%;
  };

  && .title {
    width: 15%;
  }

  && .discountValue {
    width: 5%;
  }

  && .startTime {
    width: 15%;
  }

  && .endTime {
    width: 15%;
  }

  && .progress {
    background-color: #eee;
    border-radius: 10px;
    display: inline-flex;
    font-size: .75rem;
    height: 20px;
    line-height: 0;
    overflow: hidden;
    position: relative;
    width: 104px;
  }

  && .progress span {
    color: rgba(245, 118, 127, 1);
    font-size: 12px;
    font-weight: 700;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    text-align: center;
  }

  && .progress-bar {
    background-color: rgba(255, 229, 231, 1);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: width .6s ease;
    white-space: nowrap;
  }

  && .coupon-label-status {
    border-radius: 2px;
    color: #1d2129;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    height: 28px;
    padding: 4px 8px;
    width: 60px;
  }

  && .btn-delete, .btn-edit {
    background: transparent;
    border: none;
    padding: 8px;
  }

  && .btn-delete:hover {
    background: rgba(245, 118, 127, 1) !important;
    border: none !important;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }


  thead > tr > th.ant-table-cell {
    padding: 8px 16px;
    p {
        margin: 0;
    }
  } 
`;


const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
`;
