import React, { forwardRef, useImperativeHandle, useState } from 'react'
import styled from 'styled-components';

export type MessageErrorSizeImageRef = {
  open: () => void;
};
const MessageErrorSizeImage = forwardRef<MessageErrorSizeImageRef>(({ }, ref) => {
  const [visible, setVisible] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => {
      setVisible(true);
      setTimeout(() => setVisible(false), 5000);
    }
  }));
  if (!visible) return null;
  return (
    <MessageErrorSizeImageStyled>
      <div className="icon"><WarningIcon /></div>
      <div className='message'>Please choose an image/video that is at least 632 pixels tall. Having trouble? Contact us</div>
      <button className="close-icon" onClick={() => setVisible(false)}><CloseIcon /></button>
    </MessageErrorSizeImageStyled>
  )
});

export default MessageErrorSizeImage;

const MessageErrorSizeImageStyled = styled.div`
  display: flex;
  width: 451px;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 32px;
  border-radius: 5px;
  background: #DF0D0D;
  box-shadow: 0px 6px 12px -4px rgba(223, 13, 13, 0.20);
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  .message {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  button.close-icon {
    background: none;
    border: none;
    outline: none;
    width: 24px;
    height: 22px;
    &:hover {
      cursor: pointer;
    }
  }
`;
const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path d="M23.6763 18.084L14.0986 1.49414C13.667 0.746768 12.8629 0.282471 11.9998 0.282471C11.1367 0.282471 10.3333 0.746768 9.90209 1.49414L0.323648 18.0833C-0.107883 18.8306 -0.107883 19.7588 0.323648 20.5062C0.75518 21.2536 1.55899 21.7176 2.42168 21.7176H21.5783C22.441 21.7176 23.2455 21.2536 23.6764 20.5062C24.1079 19.7588 24.1079 18.8306 23.6763 18.084ZM12.0002 18.9893C11.172 18.9893 10.4996 18.3175 10.4996 17.4891C10.4996 16.6609 11.172 15.9892 12.0002 15.9892C12.8283 15.9892 13.5 16.6609 13.5 17.4891C13.5 18.3176 12.8283 18.9893 12.0002 18.9893ZM12.9244 14.188C12.9244 14.6985 12.5103 15.1119 12.0002 15.1119C11.4893 15.1119 11.0759 14.6985 11.0759 14.188L10.4996 7.4041C10.4996 6.57553 11.172 5.90386 12.0002 5.90386C12.8283 5.90386 13.5 6.57558 13.5 7.4041L12.9244 14.188Z" fill="white" />
  </svg>
);

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4551 0.54487C11.7564 0.84613 11.7564 1.33457 11.4551 1.63583L1.63651 11.4545C1.33525 11.7557 0.846807 11.7557 0.545547 11.4545C0.244286 11.1532 0.244286 10.6648 0.545546 10.3635L10.3642 0.54487C10.6654 0.243609 11.1539 0.243609 11.4551 0.54487Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.545828 0.54487C0.847089 0.243609 1.33553 0.243609 1.63679 0.54487L11.4554 10.3635C11.7567 10.6648 11.7567 11.1532 11.4554 11.4545C11.1542 11.7557 10.6657 11.7557 10.3645 11.4545L0.545828 1.63583C0.244568 1.33457 0.244568 0.84613 0.545828 0.54487Z" fill="white" />
  </svg>
);
