
import { Col, Form, Input, Row, Select, Switch, Button as ButtonAnt, message, Breadcrumb, AutoComplete, Radio, Divider, Space, DatePicker, InputRef, Tag } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Text from '../../components/Text';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Dragger from 'antd/es/upload/Dragger';
import accountApis from '../../services/accountApis';
import { CheckOutlined, CloseOutlined, SaveOutlined, UploadOutlined, VerticalAlignBottomOutlined, } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import { formatPhoneNumber } from '../../helpers/FunctionUtils';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import TextArea from 'antd/es/input/TextArea';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { CouponDescriptionFormItem, CouponUpload, CouponUploadFormItem, EXCEL_TEMPLATE_URL, TableStyled } from './SalonSmsCampCreate';

export const SalonSmsCampUpdatePage = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const navigate = useNavigate();
    const [formUpdate] = Form.useForm();
    const { id } = useParams();
    const { campaignId } = useParams();
    const [lstCouponTemplate, setLstCouponTemplate] = useState<any>(null);
    const [lstGroup, setLstGroup] = useState<any>(null);
    const [lstCustomer, setLstCustomer] = useState<any[]>([]);
    const [typeCustomer, setTypeCustomer] = useState<any>('all');
    const [campaignDetail, setCampaignDetail] = useState<any>(null);
    const [shopDate, setShopDate] = useState<any>(null);

    const selectedTypeCustomer = (e: any) => {
        setTypeCustomer(e.target.value);
        setLstCustomer([]);
        formUpdate.setFieldsValue({
            campaignCustomerSelectGroup: ''
        });
    };

    const [isCoupon, SetIsCoupon] = useState<any>(false);
    const onChangeIsCoupon = (value: boolean) => {
        SetIsCoupon(value);
    };

    const responsive = {
        0: { items: 1 },
        600: { items: 2 },
        1024: { items: 3 },
    };

    useEffect(() => {
        const initData = async () => {
            try {
                dispatch(setLoading(true));
                if (id && !lstCouponTemplate && campaignId) {
                    const response = await accountApis.getLstCouponTemplateByType('MARKETING');
                    if (response.status === 200) {
                        setLstCouponTemplate(response.data.data);
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }

                    const response1 = await accountApis.getLstGroupByShopId(id);
                    if (response1.status === 200) {
                        setLstGroup(response1.data.data);
                    } else {
                        message.error({ content: response1.data.message, duration: 2 });
                    }

                    const response3 = await accountApis.getCampaignById(campaignId);
                    if (response3.status === 200) {
                        const campaignDetailTemp = response3.data.data;
                        const newLstCustomer = campaignDetailTemp?.customers?.map((row: any) => {
                            return {
                                name: row.customerName,
                                phone: row.customerPhone,
                                email: row.email,
                            };
                        });
                        setLstCustomer(newLstCustomer);
                        setCampaignDetail(campaignDetailTemp);
                        setTypeCustomer(!campaignDetailTemp.customers ? 'all' : 'custom');

                        formUpdate.setFieldsValue({
                            title: campaignDetailTemp.title,
                            message: campaignDetailTemp.message,
                            sendTime: dayjs(campaignDetailTemp.sendTime, { format: 'MM-DD-YYYY HH:mm:ss' }),
                        });

                        if (campaignDetailTemp.coupon && campaignDetailTemp.coupon.couponId) {
                            SetIsCoupon(true);
                            formUpdate.setFieldsValue({
                                couponTitle: campaignDetailTemp.coupon.title,
                                description: campaignDetailTemp.coupon.description,
                                discountValue: campaignDetailTemp.coupon.discountValue,
                                expiresDate: campaignDetailTemp.coupon.totalExpireDate,
                                isCoupon: true,
                                couponImage: campaignDetailTemp.coupon.urlImage,
                                couponSubTitle: campaignDetailTemp.coupon.subTitle,
                                couponVisibleTemplate: !!campaignDetailTemp.coupon.urlImage ? 'uploadFile' : 'template',

                            });
                            handleChangeDiscountType(campaignDetailTemp.coupon.discountType);
                            handAddTemplate(campaignDetailTemp.coupon.templateCouponId);
                            const sortedArray = response.data.data.sort((a: any, b: any) => {
                                if (a.id === campaignDetailTemp.coupon.templateCouponId) return -1;
                                if (b.id === campaignDetailTemp.coupon.templateCouponId) return 1;
                                return 0;
                            });
                            setLstCouponTemplate(sortedArray);
                        }
                    } else {
                        message.error({ content: response3.data.message, duration: 2 });
                    }

                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }

        };
        initData();
    }, []);

    const onSubmit = async (values: any) => {
        try {
            if (lstCustomer?.length == 0) {
                message.error({ content: 'Please choose customer', duration: 2 });
                return;
            }
            dispatch(setLoading(true));
            const formattedDate = values.sendTime.format('MM-DD-YYYY HH:mm:ss');
            const customers = lstCustomer?.map((customer) => ({
                customerId: customer.id,
                customerName: customer.name,
                customerPhone: customer.phone,
            }));

            let response;
            if (isCoupon == false) {
                response = await accountApis.updateSmsCampaign(campaignId, id, values.title, values.message, formattedDate, null, customers, 'SMS', typeCustomer === 'all');
            } else {
                if (templateCouponId == null && !values.couponImage) {
                    message.error({ content: 'Please choose image coupon', duration: 2 });
                    dispatch(setLoading(false));
                    return;
                }
                const coupon = {
                    couponId: campaignDetail.coupon.couponId,
                    discountType: discountType,
                    discountValue: values.discountValue,
                    description: values.description,
                    title: values.couponTitle,
                    totalExpireDate: values.expiresDate,
                    templateCouponId: values.couponVisibleTemplate === "uploadFile" ? null : templateCouponId,
                    base64: values.couponVisibleTemplate === "uploadFile" ? String(values?.couponImage).startsWith('data:image') ? values?.couponImage?.replace(/^data:image\/\w+;base64,/, '') : null : null,
                    subTitle: values.couponSubTitle,
                };
                response = await accountApis.updateSmsCampaign(campaignId, id, values.title, values.message, formattedDate, coupon, customers, 'SMS');
            }
            if (response.status === 200) {
                message.success('Create successful');
                navigate(`/marketing/${id}#SMS_CAMPAIGN`);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            dispatch(setLoading(false));
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const handleTagClick = (tagValue: string) => {
        const currentTextAreaValue = formUpdate.getFieldValue('message') || '';
        const newTextAreaValue = currentTextAreaValue + `${tagValue}`;
        formUpdate.setFieldsValue({ message: newTextAreaValue });
        setTextAreaValue(newTextAreaValue);
    };

    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };
    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };
    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value={'MONEY'}>Dollar</Option>
            <Option value={'PERCENT'}>Percent</Option>
        </Select>
    );

    const inputRef = useRef<InputRef>(null);
    const [expirItems, setExpirItems] = useState<any>(['30', '60', '90', '365']);
    const [newExpirDaysItems, setNewExpirDaysItems] = useState('');
    const onExpirDaysItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewExpirDaysItems(event.target.value);
    };
    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (!newExpirDaysItems) {
            return;
        }
        expirItems.unshift(newExpirDaysItems);
        setExpirItems([...expirItems]);
        setNewExpirDaysItems('');
    };

    const excelFileAccept = '.xls, .xlsx';
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: excelFileAccept
    };

    const columnsCustomerSelect: ColumnsType<any> = [
        {
            title: <p>NAME</p>,
            dataIndex: 'name',
            className: 'name',
            render: (name: string) => <a>{name}</a>,
        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
            className: 'phone',
            render: (phone: string) =>
                <span>{formatPhoneNumber(phone)}</span>,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            className: 'email',
            render: (email: string) =>
                <span>{email}</span>,
        },
        {
            title: <p>ACTION</p>,
            render: (item: any) =>
                <Button className='btn-delete' icon="delete" onClick={() => removeCustomerByPhone(item.phone)}></Button>
        },
    ];

    const [templateCouponId, setTemplateCouponId] = useState<any>(null);
    const handAddTemplate = async (templateId: string) => {
        setTemplateCouponId(templateId);
    };

    const handleOnchangeSelectGroupCampaign = async () => {
        const currentValue = formUpdate.getFieldValue('campaignCustomerSelectGroup') || '';
        if (currentValue == '') {
            setLstCustomer([]);
        } else {
            try {
                dispatch(setLoading(true));
                const response = await accountApis.getLstCustomerByGroup(currentValue);
                if (response.status === 200) {
                    setLstCustomer(response.data.data);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleBeforeUpload = (file: File) => {
        setLstCustomer([]);
        dispatch(setLoading(true));
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>): void => {
            const data = e.target?.result;
            if (data) {
                const workbook = XLSX.read(data as string, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                const newLstCustomer = excelData.slice(1).filter((row: any) => !!row[0]).map((row: any) => {
                    return {
                        name: row[0], // Assuming the name is in the second column (index 1)
                        phone: row[1], // Assuming the phone is in the third column (index 2)
                        email: row[2] == '' ? '--' : row[2], // Assuming the point is in the fifth column (index 4)
                    };
                });
                setLstCustomer(newLstCustomer);
            }
        };
        reader.readAsBinaryString(file);
        dispatch(setLoading(false));
        return false;
    };

    const removeCustomerByPhone = (phoneToRemove: string): void => {
        setLstCustomer((prevCustomers) =>
            prevCustomers.filter((customer) => customer.phone !== phoneToRemove)
        );
    };

    const addCustomerIfNotExists = (data: any, option: any): void => {
        const parts = option.label.split(' - ');
        const customer = {
            name: parts[0], // Assuming the name is in the label property of the option
            phone: parts[1], // Assuming the phone is in the value property of the option
            email: parts[2], // Assuming point is a property of the option, adjust accordingly
        };

        if (!lstCustomer.some((c) => c.phone === customer.phone)) {
            // Nếu số điện thoại chưa tồn tại, thêm customer vào mảng
            setLstCustomer((prevCustomers) => [...prevCustomers, customer]);
        } else {
            // Nếu số điện thoại đã tồn tại, có thể xử lý theo mong muốn của bạn, ví dụ: thông báo lỗi
            console.error(`Customer with phone ${customer.phone} already exists.`);
        }
    };

    const [optionsAutoComplete, setOptionsAutoComplete] = useState<{ value: string; label: string }[]>([]);
    const handleSearchAutoComplete = async (value: string) => {
        try {
            setOptionsAutoComplete([]);
            const response = await accountApis.filterCustomerByShopId(
                id, null, null, null, null, null, null, null, 1, 1000
            );

            if (response.status === 200) {
                const res = response.data.data.content.map((item: any) => ({
                    value: item.phone, // Assuming item has a property called 'value'
                    label: `${item.name} - ${item.phone} - ${item.email}`,
                }));
                setOptionsAutoComplete(res);
            } else {
                setOptionsAutoComplete([]);
            }
        } catch (error) {
            console.error('Error fetching customer data:', error);
            setOptionsAutoComplete([]);
        }
    };

    const onDownloadTemplate = () => {
        //@ts-ignore
        window?.open(EXCEL_TEMPLATE_URL, '_blank')?.focus();
    };

    return (
        <CreateGiftCardPageStyled>
            <Form form={formUpdate}
                layout="vertical"
                initialValues={{ couponVisibleTemplate: 'template', sendTimeType: 'now' }}
                onFinish={onSubmit}>
                <Box className="d-between">
                    <Breadcrumb style={{ marginBottom: '10px' }}>
                        <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                        <BreadcrumbItemStyled>Update SMS</BreadcrumbItemStyled>
                    </Breadcrumb>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate(`/marketing/${id}#SMS_CAMPAIGN`)}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formUpdate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <ContentStyled style={{ background: 'white', minHeight: '300px' }}>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Box p={1.5}>
                                <Form.Item
                                    label={'Title'}
                                    name={'title'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Title is required',
                                        }
                                    ]}>
                                    <Input size='large' placeholder='Please enter...'></Input>
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                    label="Message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Message is required',
                                        }
                                    ]}>
                                    <TextArea placeholder='Enter message..'
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        {...(values: any) => ({
                                            value: values.message,
                                            dangerouslySetInnerHTML: { __html: values.message },
                                        })}
                                        showCount count={{ max: 500 }} maxLength={500} />

                                </Form.Item>
                                <Box mb={1}>
                                    <TagStyled onClick={() => handleTagClick('${customerName}')}>Customer name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${businessName}')}>Business name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${serviceName}')}>Service name</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${linkCoupon}')}>Link coupon</TagStyled>
                                    <TagStyled onClick={() => handleTagClick('${birthday}')}>Birthday</TagStyled>
                                </Box>
                                <Form.Item
                                    name="sendTime" // Tên của trường trong form
                                    label='Send Time'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Send time is requied'
                                        }
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        size="large"
                                        showTime={true}
                                        inputReadOnly={true} />
                                </Form.Item>
                            </Box>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <>

                                <Row>
                                    <Box style={{ display: 'flex', alignItems: 'center', padding: '16px 0' }}>
                                        <Form.Item name="isCoupon" noStyle>
                                            <Switch style={{ textAlign: 'right' }} title='Coupon'
                                                defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                                onChange={onChangeIsCoupon} />
                                        </Form.Item>
                                        <Text variant="H6" style={{ fontWeight: 'bold' }} ml={0.5}>Coupon</Text>
                                    </Box>
                                </Row>

                                {isCoupon == true && (
                                    <Fragment>
                                        <Form.Item noStyle name={'couponVisibleTemplate'}>
                                            <Radio.Group defaultValue='template'>
                                                <Row style={{ marginTop: '10px', gap: 16, }}>
                                                    <Radio value="template">Template</Radio>
                                                    <Radio value="uploadFile">Upload Image</Radio>
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '200px' }}>
                                            <Form.Item shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    const couponVisibleTemplate = getFieldValue('couponVisibleTemplate');
                                                    if (couponVisibleTemplate === 'template') return (
                                                        <AliceCarousel
                                                            responsive={responsive}
                                                            disableDotsControls={true}>
                                                            {lstCouponTemplate?.map((item: any) => (
                                                                <Fragment key={item.id} >
                                                                    <img src={item.urlImage}
                                                                        onClick={() => handAddTemplate(item.id)}
                                                                    />
                                                                    {templateCouponId === item.id && (<CheclTemplateStyled variant="PRIMARY"><CheckOutlined /></CheclTemplateStyled>)}
                                                                </Fragment>
                                                            ))}
                                                        </AliceCarousel>
                                                    )
                                                    return (
                                                        <CouponUploadFormItem name={'couponImage'} rules={[{ required: true, message: 'Please upload image' }]}>
                                                            <CouponUpload />
                                                        </CouponUploadFormItem>
                                                    )
                                                }}
                                            </Form.Item>
                                        </Box>

                                        <Row>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingRight: '5px' }}>
                                                <div>
                                                    <Form.Item
                                                        name={'couponTitle'}
                                                        label={'Title'}
                                                        style={{ marginBottom: 16 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Coupon title is required'
                                                            }
                                                        ]}>
                                                        <TextArea placeholder='Enter Title..'
                                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                                            showCount count={{ max: 155 }} maxLength={155} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={'couponSubTitle'}
                                                        label={'Sub Title'}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Coupon title is required'
                                                            }
                                                        ]}>
                                                        <TextArea placeholder='Enter Title..'
                                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                                            showCount count={{ max: 155 }} maxLength={155} />
                                                    </Form.Item>
                                                </div>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '5px', display: 'flex' }}>
                                                <CouponDescriptionFormItem
                                                    name={'description'}
                                                    label={'Text Coupon'}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Coupon description is requied'
                                                        }
                                                    ]}>
                                                    <TextArea placeholder='Enter Title..'
                                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                                        style={{ height: '100%' }}
                                                        showCount count={{ max: 255 }} maxLength={255} />
                                                </CouponDescriptionFormItem>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingRight: '5px' }}>
                                                <Form.Item
                                                    name="discountValue"
                                                    label="Promotion"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} placeholder='0' type='number' size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '5px' }}>
                                                <Form.Item
                                                    name="expiresDate"
                                                    label="Expires Date (Choose days)" rules={[
                                                        {
                                                            required: true,
                                                            message: 'Expires Date is requied'
                                                        }
                                                    ]}>
                                                    <Select
                                                        placeholder="Select Days" size="large"
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider style={{ margin: '8px 0' }} />
                                                                <Space style={{ padding: '0 8px 4px', textAlign: 'center' }}>
                                                                    <Input
                                                                        size="large"
                                                                        placeholder="Please enter item"
                                                                        ref={inputRef}
                                                                        value={newExpirDaysItems}
                                                                        onChange={onExpirDaysItems}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    <ButtonAnt className='btn-info' type="text" size="large" onClick={addItem}>
                                                                        <i className="fas fa-plus mr-2"></i>Add item
                                                                    </ButtonAnt>
                                                                </Space>
                                                            </>
                                                        )}>
                                                        <Select.Option key={''} value={''}>{''}</Select.Option>
                                                        {expirItems?.map((item: any) => (
                                                            <Select.Option key={item} value={item} label={item}>{item}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                )}
                            </>

                        </Col>
                    </Row>
                    <Box p={1.5}>
                        <Text variant="H5">Group’s Campaign</Text>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Choose customer!'
                                }
                            ]}
                        >
                            <Radio.Group style={{ width: '100%' }} value={typeCustomer} onChange={selectedTypeCustomer}>
                                <Row style={{ marginTop: '10px', gap: 24 }}>
                                    <Radio value="all">All</Radio>
                                    <Radio value="uploadFile">
                                        Upload File
                                    </Radio>

                                    <Radio value="selectGroupCampaign">Group’s Campaign</Radio>
                                    <Radio value="custom">Custom</Radio>
                                </Row>
                            </Radio.Group>
                        </Form.Item>

                        {/* Upload File */}
                        {typeCustomer === 'uploadFile' && (
                            <Form.Item name='campaignCustomerSelectFile'>
                                <Dragger {...uploadProps}
                                    beforeUpload={handleBeforeUpload}
                                    maxCount={1}
                                    onRemove={() => setLstCustomer([])}
                                    name='campaignCustomerSelectFile'>
                                    <p className='ant-upload-drag-icon'>
                                        <UploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Upload file customer</p>
                                    <div style={{ position: 'absolute', top: 8, right: 8 }}>
                                        <ButtonAnt type='text' onClick={e => [e.stopPropagation(), onDownloadTemplate()]} className='btn-download'>
                                            <Row align={'middle'} style={{ gap: 4 }}>
                                                <VerticalAlignBottomOutlined style={{ fontSize: 20 }} />
                                                <span>Download template</span>
                                            </Row>
                                        </ButtonAnt>
                                    </div>
                                </Dragger>
                            </Form.Item>
                        )}

                        {/* Select Group’s Campaign */}
                        {typeCustomer === 'selectGroupCampaign' && (
                            <Form.Item name='campaignCustomerSelectGroup'>
                                <Select placeholder='Select' size='large'
                                    onChange={handleOnchangeSelectGroupCampaign}
                                >
                                    <Select.Option key='' value=''>{''}</Select.Option>
                                    {lstGroup?.map((item: any) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.groupName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        {/* Custom */}
                        {typeCustomer === 'custom' && (
                            <Form.Item name='campaignCustomerSelectCustomer'>
                                <AutoComplete
                                    size='large'
                                    onSearch={handleSearchAutoComplete}
                                    placeholder="Customer Phone/ Email"
                                    options={optionsAutoComplete}
                                    onSelect={addCustomerIfNotExists}
                                />

                            </Form.Item>
                        )}

                        <TableStyled
                            columns={columnsCustomerSelect}
                            dataSource={lstCustomer}
                            rowKey="id"
                            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
                        />
                    </Box>
                </ContentStyled>
            </Form>
        </CreateGiftCardPageStyled>
    );
}

const CreateGiftCardPageStyled = styled.div`
  padding: 1.5rem;

  && .btn-delete {
    background: transparent;
    border: none;
    padding: 8px;
  }

  && .btn-delete:hover {
    background: rgba(245, 118, 127, 1) !important;
    border: none !important;
  }
`;

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;
`;

const CheclTemplateStyled = styled(Button)`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    position: absolute;
    top: 1%;
    right: 15%;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 8px;
`;

const TagStyled = styled(Tag)`
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    padding: 5px 13px;
    background: rgba(197, 221, 248, 1);
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    cursor: alias;
`;
