
import { Col, Form, Input, Row, Select, Switch, message, Breadcrumb, DatePicker, Divider } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import { PageStyled } from '../styles';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import SelectOrDatePicker from '../../components/Modal/SelectOrDatePicker';
import couponApis from '../../services/couponApis';
import dayjs from 'dayjs';
import accountApis from '../../services/accountApis';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import Text from '../../components/Text';
import TextArea from 'antd/es/input/TextArea';

export const CouponDetailPage = () => {
    const { id } = useParams();
    const { shopId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Option } = Select;
    const [formUpdate] = Form.useForm();
    const [couponDetail, setCouponDetail] = useState<any>(null);
    const [shopDate, setShopDate] = useState<any>(null);
    const [activePrint, setActivePrint] = useState(false);

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                if (id) {
                    const responDetail = await couponApis.getCouponDetail(id);
                    if (responDetail.status === 200) {
                        const data = responDetail.data.data;
                        setCouponDetail(data);
                        const strEndTime = dayjs(data.endTime).format('MM-DD-YYYY');
                        formUpdate.setFieldsValue({
                            title: data.couponName,
                            subTitle: data.title,
                            promotionValue: data.discountValue,
                            description: data.description,
                            startDate: dayjs(data.startTime),
                            expiresDate: strEndTime,
                            couponQuantity: data.quantity,
                            activePrint: data.activePrint,
                            shopIds: data.shopId,
                            templateType: data.templateType,
                        });
                        setActivePrint(!!data.activePrint);
                        setExpireDate(strEndTime);
                        setStartDateEntered(dayjs(data.startTime));
                        setDescription(data.description);
                        setDiscountType(data.discountType);
                        setDiscountValue(data.discountValue);
                        setTitle(data.title);
                    } else {
                        message.error({ content: responDetail.data.message, duration: 2 });
                    }

                    const resShopById = await accountApis.getShopById(shopId);
                    if (resShopById.status === 200) {
                        setShopDate(resShopById.data.data);
                        dispatch(setShopState(resShopById.data.data));
                    }

                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };

    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };

    const [title, setTitle] = useState('Title');
    const handleChangeTitle = (event: any) => {
        const value = event.target.value;
        setTitle(value);
    };

    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value='MONEY'>Dollar</Option>
            <Option value='PERCENT'>Percent</Option>
        </Select>
    );

    const [description, setDescription] = useState('');
    const handleChangeDescription = (event: any) => {
        const value = event.target.value;
        setDescription(value);
    };

    const [startDate, setStartDateEntered] = useState<any>(null);
    const handleStartDateChange = (date: any) => {
        setStartDateEntered(date);
    };

    const [expireDate, setExpireDate] = useState('');
    const handleChangeExpireDate = (value: any) => {
        if (!isNaN(value)) {
            value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
        }
        setExpireDate(value);
    };

    const onSubmit = async (values: any) => {
        try {
            const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
            if (dateCheck.isBefore(startDate)) {
                message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
                return;
            }
            dispatch(setLoading(true));
            const response = await couponApis.updateCoupon(couponDetail.id, couponDetail.shopId, discountType,
                discountValue, description, title, null, values.couponQuantity, activePrint,
                startDate.format('MM-DD-YYYY'), expireDate, values.subTitle);
            if (response.status === 200) {
                message.success('Update successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <PageStyled>
            <Form form={formUpdate}
                layout="vertical"
                onFinish={onSubmit}>
                <Box className="d-between">
                    <Breadcrumb style={{ marginBottom: '10px' }}>
                        <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                        <BreadcrumbItemStyled>Update Counpon</BreadcrumbItemStyled>
                    </Breadcrumb>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate(`/coupons/${shopId}`)}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formUpdate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <CouponsContentStyled>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                            <Box style={{ background: 'white', borderRadius: '5px', textAlign: 'left' }} p={1.5}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled label='Type' name="templateType">
                                            <Select defaultValue={''} size="large">
                                                <Select.Option key={'0'} value=''>{'All Promotion'}</Select.Option>
                                                <Select.Option key={'SHARE_REFERRAL'} value='SHARE_REFERRAL'>{'Share Referral'}</Select.Option>
                                                <Select.Option key={'MARKETING'} value='MARKETING'>{'Marketing'}</Select.Option>
                                                <Select.Option key={'REVIEW'} value='REVIEW'>{'Review'}</Select.Option>
                                                <Select.Option key={'TICKET'} value='TICKET'>{'Ticket'}</Select.Option>
                                            </Select>
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled label='   ' name="activePrint" valuePropName="checked">
                                            <Box className='d-center' justifyContent='start'>
                                                <Switch
                                                    checked={activePrint}
                                                    onChange={setActivePrint}
                                                    style={{ textAlign: 'right' }}
                                                    checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                <Text variant="H9" ml={1}>Activate Print Coupon</Text>
                                            </Box>
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="title" // Tên của trường trong form
                                            label='Title'
                                            rules={[
                                                {
                                                    required: true
                                                },
                                                {
                                                    max: 50,
                                                    message: 'Max: 50 characters',
                                                },
                                            ]}>
                                            <Input size="large" placeholder='Please enter..' onChange={handleChangeTitle} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="subTitle" // Tên của trường trong form
                                            label='Sub Title'
                                            rules={[
                                                {
                                                    required: true
                                                },
                                                {
                                                    max: 50,
                                                    message: 'Max: 50 characters',
                                                },
                                            ]}>
                                            <Input size="large" placeholder='Please enter..' />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="description"
                                            label="Text Coupon">
                                            <TextArea placeholder='Please enter..'
                                                rows={3}
                                                showCount maxLength={255}
                                                onChange={handleChangeDescription}
                                                value={description} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="promotionValue"
                                            label="Promotion"
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}>
                                            <Input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} placeholder='0' type='number' min={0} size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                </Row>
                            </Box>
                            <Box p={0.5} style={{ backgroundColor: 'rgba(246, 247, 252, 1)' }}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold', borderRadius: '5px' }}>Coupon Quantity</Text>
                                                <DividerStyled />
                                                <Form.Item
                                                    name="couponQuantity"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} placeholder='0' min={0} type='number' size="large" />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold' }}>Start Date</Text>
                                                <DividerStyled />
                                                <Form.Item name="startDate"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Start Date is required'
                                                        }
                                                    ]}>
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        onChange={handleStartDateChange}
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        showTime={false}
                                                        inputReadOnly={true} />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold', borderRadius: '5px' }}>Expires Date</Text>
                                                <DividerStyled />
                                                {expireDate && (
                                                    <Form.Item
                                                        name="expiresDate" // Tên của trường trong form
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}>
                                                        <SelectOrDatePicker disabled={false} onChange={handleChangeExpireDate} valueDf={expireDate} />
                                                    </Form.Item>
                                                )}
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Box pl={1} pr={1}>
                                <Text variant="H7">Template Image</Text>
                                <img width={'100%'} src={couponDetail?.urlImage} />
                            </Box>
                            <Box style={{ borderRadius: '5px', borderTopLeftRadius: '0px', textAlign: 'left', minHeight: '320px' }} pl={1} pr={1} pt={1} >
                                <Box className='d-between' p={1} style={{
                                    background: 'rgba(255, 229, 231, 1)', borderTopLeftRadius: '15px', borderTopRightRadius: '15px'
                                }}>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>Coupon Quantity</span>
                                    </Text>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>{couponDetail?.quantity}</span>
                                    </Text>
                                </Box>

                                <Box className='d-between' p={1} style={{ background: 'rgba(255, 229, 231, 1)', marginTop: '1.5px', marginBottom: '1.5px' }}>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>Printed Quantity</span>
                                    </Text>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>{couponDetail?.totalPrint}</span>
                                    </Text>
                                </Box>

                                <Box className='d-between' p={1} style={{ background: 'rgba(255, 229, 231, 1)', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>Coupon Used</span>
                                    </Text>
                                    <Text variant="CONTENT_1">
                                        <span style={{ marginRight: '5px' }}>{couponDetail?.couponUsed}</span>
                                    </Text>
                                </Box>

                            </Box>
                        </Col>
                    </Row>
                </CouponsContentStyled>
            </Form>
        </PageStyled>
    );
};

const CouponsContentStyled = styled.div`
  background: rgba(238, 238, 238, 1);
  margin-top: 1rem;
  padding: 1.5rem;

  && .ant-qrcode-borderless {
    margin-bottom: -8px;
    padding-bottom: 0!important;
    padding-top: 0!important;
  }
`;

const DividerStyled = styled(Divider)`
    border: 0.5px solid rgba(218, 218, 218, 1);
`;

const BoxInfoFix = styled(Box)`
    background: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(218, 218, 218, 1);
`;