import styled from "styled-components";

type DashboardItemProps = {
  type?: 'report' | 'ban' | 'article' | 'account' | 'image' | 'comment',
  children?: any
};
const DashboardItem = ({ type, children, }: DashboardItemProps) => {
  const Icon = () => {
    switch (type) {
      case 'account':
        return <AccountIcon />;
      case 'report':
      case 'comment':
      case 'article':
        return <ArticleIcon />;
      case 'ban':
        return <BanIcon />;
      case 'image':
        return <ImageIcon />;
      default:
        return null;
    };
  };


  return (
    <DashboardItemStyled type={type}>
      <div className='children'>{children}</div>
      {type && <div className="icon"><Icon /></div>}
    </DashboardItemStyled>
  )
};

export default DashboardItem;

const getContainerDashboardItemStyle = (type: DashboardItemProps['type']) => {
  switch (type) {
    case 'comment':
    case 'account':
      return `
        background: #8968D1;
      `;
    case 'article':
      return `
        background: #347AB6;
      `;
    case 'ban':
      return `
        background: #F96969;
      `;
    case 'report':
    case 'image':
      return `
        background: #47BD89;
      `
    default:
      return '';
  };
};

const DashboardItemStyled = styled.div<{ type?: DashboardItemProps['type'] }>`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  height: 130px;
  border-radius: 5px;
  background: #47BD89;

  p {
    margin: 0;
    padding: 0;
  }

  .children {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.4);
  }

  .title-item {
    color: #FFE5E7;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .number-item {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }


  ${({ type }) => getContainerDashboardItemStyle(type)}
`;

const ImageIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.5239 4.5H6.47627C5.46626 4.50116 4.49794 4.92296 3.78375 5.67285C3.06956 6.42275 2.66785 7.43949 2.66675 8.5V24.5C2.66785 25.5605 3.06956 26.5773 3.78375 27.3271C4.49794 28.077 5.46626 28.4988 6.47627 28.5H25.5239C26.5339 28.4988 27.5022 28.077 28.2164 27.3271C28.9306 26.5773 29.3323 25.5605 29.3334 24.5V8.5C29.3323 7.43949 28.9306 6.42275 28.2164 5.67285C27.5022 4.92296 26.5339 4.50116 25.5239 4.5ZM20.762 8.5C21.3271 8.5 21.8795 8.67595 22.3493 9.00559C22.8192 9.33524 23.1854 9.80377 23.4016 10.3519C23.6179 10.9001 23.6745 11.5033 23.5642 12.0853C23.454 12.6672 23.1819 13.2018 22.7823 13.6213C22.3827 14.0409 21.8736 14.3266 21.3194 14.4424C20.7652 14.5581 20.1907 14.4987 19.6686 14.2716C19.1465 14.0446 18.7003 13.6601 18.3864 13.1667C18.0724 12.6734 17.9048 12.0933 17.9048 11.5C17.9056 10.7046 18.2069 9.94202 18.7426 9.37959C19.2782 8.81716 20.0045 8.50083 20.762 8.5ZM6.47627 26.5C5.9711 26.5 5.48661 26.2893 5.1294 25.9142C4.77219 25.5391 4.57151 25.0304 4.57151 24.5V20.2731L10.2167 15.0044C10.7614 14.4972 11.4698 14.2271 12.1975 14.2491C12.9252 14.2712 13.6175 14.5838 14.1334 15.1231L17.9995 19.1737L11.0221 26.5H6.47627ZM27.4287 24.5C27.4287 25.0304 27.228 25.5391 26.8708 25.9142C26.5136 26.2893 26.0291 26.5 25.5239 26.5H13.7162L20.9435 18.9112C21.4552 18.4543 22.1046 18.2026 22.7763 18.2008C23.448 18.1989 24.0986 18.4471 24.6126 18.9013L27.4287 21.365V24.5Z" fill="white" />
  </svg>
);
const AccountIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0368 3.03381H16.9671C18.8032 3.30583 20.514 4.36155 21.5314 5.92091C22.1111 6.76657 22.4329 7.76103 22.5918 8.76693C22.7891 10.3196 22.439 11.9442 21.5873 13.2612C20.5302 14.9404 18.6557 16.0756 16.6783 16.2318C16.0487 16.2217 15.4138 16.2755 14.7903 16.1678C13.4559 15.9369 12.1982 15.2824 11.2508 14.3142C10.0672 13.1151 9.39051 11.4507 9.35819 9.77015C9.29086 7.49642 10.4772 5.22539 12.4096 4.01481C13.2014 3.50175 14.1083 3.18329 15.0368 3.03381Z" fill="white" />
    <path d="M6.26527 19.7633C7.59234 18.2645 9.28434 17.0876 11.1534 16.3699C12.1176 16.9839 13.1895 17.4391 14.3152 17.6471C14.7555 17.7569 15.2093 17.808 15.6638 17.804C16.1345 17.7993 16.6078 17.8215 17.0757 17.7622C18.3105 17.5724 19.5359 17.1953 20.591 16.5119C20.6994 16.4594 20.8145 16.3463 20.9425 16.4056C23.6027 17.4492 25.8649 19.434 27.2937 21.903C27.9488 22.9722 28.4107 24.1491 28.7675 25.3469C29.0678 26.3872 29.2159 27.4611 29.3338 28.535C28.406 28.7329 27.4856 28.9605 26.5517 29.1295C25.9895 29.1901 25.4435 29.3517 24.8799 29.4002C24.2955 29.4446 23.7259 29.5988 23.1394 29.6257C22.1733 29.7011 21.2152 29.8627 20.2443 29.8796C19.8241 29.9287 19.3946 29.8654 18.9805 29.9657H13.0211C12.6071 29.8661 12.1768 29.928 11.7567 29.8796C10.7198 29.8594 9.69639 29.683 8.66356 29.6082C8.14579 29.5678 7.63813 29.4493 7.12103 29.4002C6.55681 29.3531 6.01077 29.1874 5.44789 29.1295C4.51605 28.9538 3.59161 28.7417 2.6665 28.535C2.70758 27.9997 2.81059 27.4725 2.87321 26.94C2.92909 26.509 3.06981 26.095 3.14656 25.6681C3.71415 23.4967 4.75776 21.4344 6.26527 19.7633ZM22.3335 20.6783C22.3254 21.7091 22.3342 22.7406 22.3295 23.7714C21.298 23.7761 20.2672 23.7681 19.2357 23.7748C19.2316 24.3565 19.2316 24.9389 19.2357 25.5206C20.2658 25.5341 21.2959 25.5132 22.3254 25.5307C22.3402 26.5636 22.3214 27.5964 22.3348 28.6293C22.9368 28.6468 23.54 28.6454 24.1426 28.6299C24.1521 27.6409 24.14 26.6511 24.1467 25.662C24.1332 25.5543 24.2659 25.5139 24.3507 25.524C25.3142 25.5206 26.277 25.5307 27.2405 25.5206C27.2452 24.9382 27.2452 24.3565 27.2405 23.7748C26.2097 23.7681 25.1782 23.7755 24.1467 23.7721C24.142 22.7406 24.1501 21.7091 24.1433 20.6776C23.54 20.6736 22.9368 20.6736 22.3335 20.6783Z" fill="white" />
    <path d="M27.3332 23.1666L27.9998 27.1666L25.3332 28.5L22.6665 29.1666L20.6665 28.5L17.9998 26.5L16.6665 23.1666L20.6665 19.1666L24.6665 19.8333L27.3332 23.1666Z" fill="white" />
  </svg>
);
const ArticleIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.31592 14.4999H17.0159L21.2759 10.2402L21.3159 10.2001V9.49988H7.31592V14.4999Z" fill="white" />
    <path d="M23.8157 25.9999H4.81595V6.99991H23.8157V7.70502L26.3158 5.83975V4.49988H2.31592V28.5H26.3158V18.2748L23.8157 20.7748V25.9999Z" fill="white" />
    <path d="M14.576 17.5H7.31592V19H13.7558C14.001 18.4702 14.2859 17.9599 14.576 17.5Z" fill="white" />
    <path d="M7.31592 21.5V23H13.2458C13.0708 22.5298 13.0159 22.02 13.0508 21.5H7.31592Z" fill="white" />
    <path d="M17.8677 17.1836C17.0391 18.0122 14.895 21.5005 15.7237 22.3296C16.5528 23.1587 20.0406 21.0142 20.8697 20.1856L24.2776 16.7768L21.2759 13.7754L17.8677 17.1836Z" fill="white" />
    <path d="M29.0628 8.99025C28.2337 8.16161 26.8899 8.16161 26.0613 8.99025L21.9624 13.0894L24.9642 16.0908L29.0628 11.9917C29.8914 11.1631 29.8914 9.81932 29.0628 8.99025ZM23.7063 13.6328L23.1912 13.1182L26.0608 10.2486L26.575 10.7627L23.7063 13.6328Z" fill="white" />
  </svg>
);
const BanIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5111 2.27783H14.3346C16.0692 2.5348 17.6854 3.53213 18.6464 5.00522C19.1941 5.8041 19.4981 6.74355 19.6482 7.69382C19.8346 9.16055 19.5038 10.6953 18.6992 11.9395C17.7006 13.5258 15.9299 14.5982 14.0618 14.7457C13.4671 14.7362 12.8673 14.7871 12.2783 14.6853C11.0176 14.4671 9.82948 13.8489 8.93456 12.9342C7.81638 11.8014 7.17714 10.2291 7.14661 8.64153C7.08301 6.49359 8.20373 4.34818 10.0292 3.20456C10.7772 2.71989 11.634 2.41904 12.5111 2.27783Z" fill="white" />
    <path d="M17.0112 23.5223C17.0112 24.4854 17.2001 25.4012 17.5777 26.2699C17.9554 27.1386 18.4559 27.8939 19.079 28.536C19.7022 29.178 20.4576 29.6832 21.3451 30.0514C22.2326 30.4196 23.1485 30.6038 24.0927 30.6038C25.0369 30.6038 25.9528 30.4196 26.8403 30.0514C27.7279 29.6832 28.4832 29.178 29.1064 28.536C29.7296 27.8939 30.23 27.1386 30.6077 26.2699C30.9854 25.4012 31.1742 24.4854 31.1742 23.5223C31.1742 22.5592 30.9854 21.6433 30.6077 20.7747C30.23 19.906 29.7296 19.1506 29.1064 18.5086C28.4832 17.8665 27.7326 17.3661 26.8545 17.0073C25.9764 16.6485 25.0558 16.4597 24.0927 16.4408C23.1296 16.4408 22.2138 16.6296 21.3451 17.0073C20.4764 17.385 19.7211 17.8854 19.079 18.5086C18.437 19.1318 17.9365 19.8871 17.5777 20.7747C17.219 21.6622 17.0301 22.5781 17.0112 23.5223ZM18.7816 23.5223C18.7816 22.4081 19.1121 21.3884 19.773 20.4631L27.1661 27.8561C26.2313 28.5076 25.2069 28.8334 24.0927 28.8334C23.1296 28.8334 22.2421 28.5973 21.4301 28.1252C20.6181 27.6531 19.9713 27.0111 19.4897 26.1991C19.0082 25.3871 18.7722 24.4948 18.7816 23.5223ZM21.0193 19.2026C21.9541 18.5416 22.9786 18.2112 24.0927 18.2112C25.0558 18.2112 25.9433 18.4519 26.7553 18.9335C27.5674 19.415 28.2141 20.0571 28.6957 20.8596C29.1772 21.6622 29.4133 22.5498 29.4038 23.5223C29.4038 24.6459 29.0734 25.6703 28.4124 26.5956L21.0193 19.2026Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1925 15.4112C18.8344 15.2251 18.4665 15.0576 18.0902 14.9099C17.9982 14.8673 17.9132 14.9191 17.8327 14.9681C17.8074 14.9835 17.7826 14.9986 17.7582 15.0104C16.7615 15.656 15.6039 16.0122 14.4373 16.1916C14.1051 16.2336 13.77 16.2322 13.4353 16.2308C13.3246 16.2304 13.214 16.2299 13.1035 16.231C12.6742 16.2348 12.2455 16.1865 11.8295 16.0828C10.7661 15.8863 9.75346 15.4563 8.84263 14.8762C7.07695 15.5543 5.47855 16.6661 4.22489 18.0819C2.80077 19.6606 1.81489 21.6088 1.2787 23.6601C1.24752 23.8335 1.20518 24.0046 1.16283 24.1758C1.10669 24.4027 1.05056 24.6295 1.02046 24.8616C0.997108 25.0602 0.967802 25.2581 0.938499 25.4559C0.893585 25.7592 0.848675 26.0624 0.825195 26.3684C0.936293 26.3932 1.04738 26.4181 1.15847 26.443C1.92128 26.6141 2.68434 26.7851 3.45273 26.93C3.70601 26.9561 3.95568 27.0052 4.20533 27.0544C4.47978 27.1084 4.75419 27.1624 5.03332 27.1857C5.26319 27.2076 5.49109 27.2439 5.71894 27.2803C5.97528 27.3212 6.23156 27.3621 6.49051 27.3823C6.87536 27.4101 7.25882 27.4529 7.64223 27.4957C8.23087 27.5614 8.81937 27.627 9.41253 27.6386C9.57364 27.6572 9.73632 27.6586 9.89892 27.66C10.1369 27.662 10.3747 27.6641 10.607 27.72H16.2367C16.4346 27.6721 16.6361 27.6637 16.8384 27.6611C15.6826 26.2618 14.9882 24.4672 14.9882 22.5105C14.9882 19.4503 16.6867 16.7867 19.1925 15.4112Z" fill="white" />
  </svg>
);
