import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Image, Upload, UploadFile, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { debounce } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

const MAX_SIZE_IMAGE = 800000;
type Props = {
  onSubmit: (value: UploadFile[]) => void;
  children?: any;
};
const UploadGallery = ({ onSubmit }: Props) => {
  const [value, onChange] = useState<UploadFile[]>([]);
  const openAlert = debounce(() => {
    notification.error({
      message: 'max size of 800KB',
      duration: 3,
    });
  }, 100);

  const _onSubmit = () => {
    onSubmit(value);
    onChange([]);
  };

  return (
    <UploadAvatarStyled>
      <ImgCrop
        rotationSlider={false} cropShape='rect'
        showGrid aspectSlider={false} showReset
        // @ts-ignore
        cropperProps={{ restrictPosition: false, cropSize: { width: 343, height: 343 } }}
      >
        <Upload
          name='file'
          accept="image/png, image/jpeg"
          listType="picture-card"
          fileList={value}
          showUploadList
          onChange={({ fileList: newFileList, file }) => {
            if ((file.size || 0) > MAX_SIZE_IMAGE) return openAlert();
            onChange(newFileList);
          }}
        >
          <div style={{ flexDirection: 'column', display: 'flex', gap: 8 }}>
            <Button type="dashed" size='large'>
              Add New
            </Button>
            {value.length > 0 ? <Button onClick={e => [e.stopPropagation(), _onSubmit()]} type="primary" size='large'>
              Upload
            </Button> : null}
          </div>
        </Upload>
      </ImgCrop>
    </UploadAvatarStyled>
  );
};

export default UploadGallery;

const UploadAvatarStyled = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  .ant-avatar {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dde6f4;
    .ant-avatar-string {
      transform: scale(1.5) translateY(3px) !important;
    }
  }
  .title {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #808080; 
  }
  .ant-upload-wrapper {
    .ant-upload.ant-upload-select {
      border: none;
      border-radius: 8px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btn-avatar-upload {
    padding: 4px;
    height: unset;
    position: relative;
    border-radius: 8px;
    .image-url {
      border-radius: 8px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .avatar-overlay-icon {
      border-radius: 8px;
      opacity: 0;
      position: absolute;
      inset: 0;
      background: rgba(0,0,0,0.4);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      .avatar-overlay-icon {
        opacity: 1;
      }
    }
  }
`;
