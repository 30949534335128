import { api } from "../helpers/api";

const reportSummary = async (
  shopId: string | null,
  startDate: string | null,
  endDate: string | null,
  page: number,
  size: number) => {
  const params = { shopId, startDate, endDate, size, page };
  return await api.get('/api/v1/admin/report/summary-report', { params }).then((response: any) => {
    return response;
  });
};

export type SMSReportParams = {
  shopId?: string,
  startDate: string,
  endDate: string,
  size?: number,
  page?: number
};

const getSMSSummaryReportDashboard = async (params: SMSReportParams) => {
  if (!params.shopId) delete params.shopId;
  return await api.get('/api/v1/admin/report/sms-summary', { params });
}

const getSMSReport = async (params: SMSReportParams) => {
  if (!params.shopId) delete params.shopId;
  return await api.get('/api/v1/admin/report/sms-detail', { params });
}

const reportApis = {
  reportSummary,
  getSMSSummaryReportDashboard,
  getSMSReport,
};

export default reportApis;
