import { Checkbox, Col, Form, InputNumber, Radio, Row, Select, Input, message, Button as ButtonAnt, Space, Divider, InputRef, Switch } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { MinusCircleOutlined, PlusOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Box from '../../components/Box';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import accountApis from '../../services/accountApis';
import { formatNumber } from '../../helpers/FunctionUtils';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { TableContainer, TableContentStyled } from '../dashboard';
import Modal from '../../components/Modal';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import TextArea from 'antd/es/input/TextArea';

export const SalonSettingReviewPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { Option } = Select;

    const [lstNotify, setLstNotify] = useState<any>(null);
    const getListNotify = async (id: any) => {
        const res = await accountApis.getListNotifyByShopId(id);
        if (res.status === 200) {
            setLstNotify(res.data.data);
        } else {
            message.error({ content: res.data.message, duration: 2 });
        }
    };

    const [lstSocial, setLstSocial] = useState<any>(null);

    const notiColumns: ColumnsType<any> = [
        {
            title: <span>CASE</span>,
            dataIndex: 'notifyType',
            className: 'notifyType',
            render: (notifyType: any) => <span>{notifyType}</span>,
        },
        {
            title: <span>STARS</span>,
            dataIndex: 'rateStars',
            render: (rateStars: any) => <span> {rateStars === 'STARS_4_5' ? '4-5 Stars' : '1-3 Stars'}</span>,
        },
        {
            title: <span>OWNER</span>,
            dataIndex: 'enableOwner',
            render: (enableOwner: any) =>
                <span>{<Checkbox checked={enableOwner}></Checkbox>}</span>,
        },
        {
            title: <span>CUSTOMER</span>,
            dataIndex: 'enableCustomer',
            render: (enableCustomer: any) =>
                <span>{<Checkbox checked={enableCustomer}></Checkbox>}</span>,
        },
        {
            title: <span>INFOR</span>,
            dataIndex: 'valueNotify',
            render: (valueNotify: any) =>
                <span> {valueNotify.split(',').map((value: string) => (
                    <React.Fragment key={value}>
                        {value} <br />
                    </React.Fragment>
                ))}</span>,
        },
        {
            title: <span>COUPON</span>,
            dataIndex: 'oldUsed',
            render: (oldUsed: any) =>
                <span>{ }</span>,
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            dataIndex: 'active',
            render: (active: boolean, record: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={record.id}
                                onChange={(checked) => handleSwitchChange(checked, record.id)}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                checked={switchStates[record.id] === undefined ? active === true : switchStates[record.id]} />
                            <Button className='btn-delete' icon="delete" onClick={() => handleDelete(record.id)}></Button>
                            <Button className='btn-edit' icon="edit"></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const handleDelete = async (notifyId: string) => {
        const result = await Swal.fire({
            html: 'Are you sure want to delete it?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const response = await accountApis.deleteNotification(notifyId);
                if (response.status === 200) {
                    await getListNotify(id);
                    message.success('Delete coupon successful');
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const [switchStates, setSwitchStates] = useState<any>({});
    const updateSwitchState = (itemId: string, checked: any) => {
        setSwitchStates((prevStates: any) => ({
            ...prevStates,
            [itemId]: checked,
        }));
    };

    const handleSwitchChange = async (checked: any, itemId: any) => {
        if (checked) {
        } else {
            // const response = await couponApis.updateStatusCoupon(itemId, 'PENDING');
            // if (response.status === 200) {
            //     updateSwitchState(itemId, checked);
            // } else {
            //     updateSwitchState(itemId, initialSwitchStateRef.current[itemId]);
            //     message.error({ content: response.data.message, duration: 2 });
            // }
        }
    };

    const socialColumns: ColumnsType<any> = [
        {
            title: <span>Active</span>,
            dataIndex: 'createdDate',
            className: 'createdDate',
            render: (createdDate: any) => <span>{dayjs(createdDate).format('MM-DD-YYYY HH:mm:ss')}</span>,
        },
        {
            title: <span>Name</span>,
            dataIndex: 'updatedBy',
            render: (updatedBy: any) => <span> {updatedBy}</span>,
        },
        {
            title: <span>URL (Required)</span>,
            dataIndex: 'totalExtend',
            render: (totalExtend: any) =>
                <span>{formatNumber(totalExtend)}</span>,
        },
        {
            title: <span>CUSTOMER</span>,
            dataIndex: 'oldQuantity',
            render: (oldQuantity: any) =>
                <span>{formatNumber(oldQuantity)}</span>,
        },
        {
            title: <span>INFOR</span>,
            dataIndex: 'oldUsed',
            render: (oldUsed: any) =>
                <span>{formatNumber(oldUsed)}</span>,
        },
        {
            title: <span>COUPON</span>,
            dataIndex: 'oldUsed',
            render: (oldUsed: any) =>
                <span>{formatNumber(oldUsed)}</span>,
        },
        {
            title: <span>ACTION</span>,
            dataIndex: 'oldUsed',
            render: (oldUsed: any) =>
                <span>{formatNumber(oldUsed)}</span>,
        },
    ];

    useEffect(() => {
        const initData = async () => {
            try {
                await getListNotify(id);

                dispatch(setLoading(true));
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            } finally {
                dispatch(setLoading(false));
            }
        };
        initData();
    }, []);

    const [showAddNotify, setShowAddNotify] = useState(false);
    const [formAddNotify] = Form.useForm();
    const [typeNotify, setTypeNotify] = useState<string>('SMS');
    const selectedTypeNotify = (e: any) => {
        setTypeNotify(e.target.value);
    };

    const [valueNotifyFields, setValueNotifyFields] = useState<string[]>([]);
    const removeValueNotifyItem = (index: number) => {
        const updatedValueNotifyList = [...valueNotifyFields];
        updatedValueNotifyList.splice(index, 1); // Xóa phần tử tại index
        setValueNotifyFields(updatedValueNotifyList);
    };
    const addValueNotifyItem = (value: string) => {
        const updatedValueNotifyList = [...valueNotifyFields, value];
        setValueNotifyFields(updatedValueNotifyList);
    };

    const [couponEnable, setCouponEnable] = useState(false);
    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };
    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };
    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value={'MONEY'}>Dollar</Option>
            <Option value={'PERCENT'}>Percent</Option>
        </Select>
    );

    const inputRef = useRef<InputRef>(null);
    const [newExpirDaysItems, setNewExpirDaysItems] = useState('');
    const onExpirDaysItems = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewExpirDaysItems(event.target.value);
    };

    const [expirItems, setExpirItems] = useState<any>(['30', '60', '90', '365']);
    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        if (!newExpirDaysItems) {
            return;
        }
        expirItems.unshift(newExpirDaysItems);
        setExpirItems([...expirItems]);
        setNewExpirDaysItems('');
    };

    const onSubmitAddNotify = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const valueNotify = [];
            // Lặp qua các trường và lấy giá trị
            if (values.valueNotifyFields) {
                for (let index = 0; index < values.valueNotifyFields.length; index++) {
                    const ipFieldValue = values.valueNotifyFields[index];
                    if (ipFieldValue.value !== '') {
                        valueNotify.push(ipFieldValue.value);
                    }
                }
            }
            const coupon = {
                couponId: null,
                discountType: discountType,
                discountValue: discountValue,
                description: values.description,
                templateCouponId: null,
                title: values.couponTitle,
                subTitle: values.couponSubtitle,
                totalExpireDate: values.expiresDate,
                base64: null
            }
            const response = await accountApis.addOrUpdateReviewNotification(
                id, null, values.enableOwner, values.enableCustomer, valueNotify, values.rateStars, typeNotify, couponEnable === true ? coupon : null
            );
            if (response.status === 200) {
                message.success('Create successful');
                setShowAddNotify(false);
                setValueNotifyFields([]);
                formAddNotify.resetFields();
                await getListNotify(id);
            } else {
                message.error({ content: response.data.message as string, duration: 2 });
                return false;
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Fragment>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={showAddNotify}
                onCancel={() => setShowAddNotify(false)}
                onOk={() => formAddNotify.submit()}
                title='Add More Notify'
                closeIcon={null}
                centered>
                <Form form={formAddNotify}
                    layout="vertical"
                    onFinish={onSubmitAddNotify}
                    initialValues={{ enableOwner: true }}>
                    <Box px={1.5}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Choose type!'
                                }
                            ]}>
                            <Radio.Group style={{ width: '100%' }} onChange={selectedTypeNotify} defaultValue="SMS">
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Radio value="SMS">Send SMS</Radio>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Radio value="EMAIL">Send Email</Radio>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Radio value="NOTIFICATION">Notification</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Stars!'
                                }
                            ]}
                            name="rateStars">
                            <Select size='large' placeholder="Select">
                                <Option key="STARS_1_2_3" value="STARS_1_2_3">
                                    1-3 Stars
                                </Option>
                                <Option key="STARS_4_5" value="STARS_4_5">
                                    4-5 Stars
                                </Option>
                            </Select>
                        </Form.Item>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="enableOwner" valuePropName="checked">
                                    <Checkbox>Owner</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="enableCustomer" valuePropName="checked">
                                    <Checkbox>Customer</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            typeNotify !== 'NOTIFICATION' &&
                            (
                                <Fragment>
                                    {valueNotifyFields && valueNotifyFields.map((field: any, index) => (
                                        <Row>
                                            <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 20 }}>
                                                <Form.Item
                                                    key={index}
                                                    name={['valueNotifyFields', index, 'value']}
                                                    rules={[
                                                        { required: true, message: 'Requied' },
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value) return Promise.resolve();
                                                                if (typeNotify === 'SMS') {
                                                                    // Kiểm tra xem giá trị là số điện thoại hay không
                                                                    const phoneRegex = /^[0-9]+$/;
                                                                    if (!phoneRegex.test(value)) {
                                                                        return Promise.reject('Please enter a valid phone number');
                                                                    }
                                                                } else if (typeNotify === 'EMAIL') {
                                                                    // Kiểm tra xem giá trị là email hay không
                                                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                                    if (!emailRegex.test(value)) {
                                                                        return Promise.reject('Please enter a valid email address');
                                                                    }
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                    initialValue={field.value}>
                                                    <Input placeholder={`Enter ${typeNotify === 'SMS' ? 'phone' : 'email'}`} />
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }} xs={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                                <MinusCircleOutlined
                                                    style={{ fontSize: '30px', color: '#bc0000' }}
                                                    onClick={() => removeValueNotifyItem(index)}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <ButtonAnt
                                            type="dashed"
                                            onClick={() => addValueNotifyItem('')}
                                            icon={<PlusOutlined />}>
                                            Add more {typeNotify === 'SMS' ? 'phone' : 'email'}
                                        </ButtonAnt>
                                    </Form.Item>
                                </Fragment>
                            )
                        }
                        <Form.Item>
                            <Checkbox defaultChecked={couponEnable} onClick={() => setCouponEnable(!couponEnable)}>Coupon</Checkbox>
                        </Form.Item>
                        {couponEnable === true &&
                            (
                                <Fragment>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name={'couponTitle'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Coupon title is requied'
                                                    }
                                                ]}>
                                                <Input placeholder='Title Coupon' size='large' />
                                            </Form.Item>
                                            <Form.Item
                                                name={'couponSubtitle'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Sub title is requied'
                                                    }
                                                ]}>
                                                <Input placeholder='Subtitle Coupon' size='large' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name={'description'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Coupon description is requied'
                                                    }
                                                ]}>
                                                <TextArea placeholder='Enter Text Coupon..'
                                                    autoSize={{ minRows: 4, maxRows: 11 }}
                                                    showCount />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name="discountValue"
                                                rules={[
                                                    {
                                                        required: true
                                                    }
                                                ]}>
                                                <Input onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} placeholder='Promotion' type='number' size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                            <Form.Item
                                                name="expiresDate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Expires Date is requied'
                                                    }
                                                ]}>
                                                <Select
                                                    placeholder="Expires date" size="large"
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <Space style={{ padding: '0 8px 4px', textAlign: 'center' }}>
                                                                <Input
                                                                    size="large"
                                                                    placeholder="Please enter item"
                                                                    ref={inputRef}
                                                                    value={newExpirDaysItems}
                                                                    onChange={onExpirDaysItems}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <ButtonAnt className='btn-info' type="text" size="large" onClick={addItem}>
                                                                    <i className="fas fa-plus mr-2"></i>Add item
                                                                </ButtonAnt>
                                                            </Space>
                                                        </>
                                                    )}>
                                                    <Select.Option key={''} value={''}>{''}</Select.Option>
                                                    {expirItems?.map((item: any) => (
                                                        <Select.Option key={item} value={item} label={item}>{item}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )
                        }
                    </Box>
                </Form>
            </Modal>

            <ContentStyled>
                <TableContainer>
                    <Box className="d-between" mb={1}>
                        <Box display="flex" gap={1}>
                            <Button variant="SECONDARY" icon="add" onClick={() => setShowAddNotify(true)}>
                                Add More Notify
                            </Button>
                        </Box>
                    </Box>
                    <TableContentStyled
                        columns={notiColumns}
                        dataSource={lstNotify}
                        rowKey="id"
                        bordered
                        pagination={{
                            pageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '30'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </TableContainer>
            </ContentStyled>

            <ContentStyled>
                <TableContainer>
                    <Box className="d-between" mb={1}>
                        <Box display="flex" gap={1}>
                            <Button variant="SECONDARY" icon="add">
                                Add More Social
                            </Button>
                        </Box>
                    </Box>
                    <TableContentStyled
                        columns={socialColumns}
                        dataSource={lstSocial}
                        rowKey="id"
                        bordered
                        pagination={{
                            pageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '30'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </TableContainer>
            </ContentStyled>
        </Fragment>

    )
}

const ContentStyled = styled.div`
    background: rgba(246, 247, 252, 1);
    padding: 1rem;
    margin-top: 15px;

    && .ant-checkbox .ant-checkbox-inner {
        width: 25px;
        height: 25px;
    }

    && .ant-checkbox .ant-checkbox-inner:after {
        inset-inline-start: 30.5%;
    }

    && .btn-delete, .btn-edit {
        background: transparent;
        border: none;
        padding: 8px;
      }
    
      && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
      }
    
      && .btn-edit:hover {
        background: var(--blue, #008bff) !important;
        border: none !important;
      }
`;