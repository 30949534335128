
import { Col, Form, Input, Row, Select, Switch, message, Breadcrumb, DatePicker, Divider } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import FormItemNoMarginStyled from '../../components/FormItem/FormItemNoMarginStyled';
import { PageStyled } from '../styles';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import SelectOrDatePicker from '../../components/Modal/SelectOrDatePicker';
import couponApis from '../../services/couponApis';
import dayjs, { Dayjs } from 'dayjs';
import accountApis from '../../services/accountApis';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import Text from '../../components/Text';
import TextArea from 'antd/es/input/TextArea';
import { generateRandomNumberString } from '../../helpers/FunctionUtils';
import Dragger from 'antd/es/upload/Dragger';

export const CouponCreatePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Option } = Select;
    const [formCreate] = Form.useForm();
    const [lstTemplate, setLstTemplate] = useState<any[]>([]);
    const [templateSelected, setTemplateSelected] = useState<any>();
    const [shopDate, setShopDate] = useState<any>(null);

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                const resCouponTemplate = await couponApis.getLstCouponTemplate();
                if (resCouponTemplate.status === 200) {
                    setLstTemplate(resCouponTemplate.data.data);
                    setTemplateSelected(resCouponTemplate.data.data[0]);
                } else {
                    message.error({ content: resCouponTemplate.data.message, duration: 2 });
                }

                const resShopById = await accountApis.getShopById(id);
                if (resShopById.status === 200) {
                    setShopDate(resShopById.data.data);
                    dispatch(setShopState(resShopById.data.data));
                }

                setDescription('* For your next visit only\n* Valid from Monday - Thursday\n* Cannot combine with any other offers')
                formCreate.setFieldsValue({
                    description: '* For your next visit only\n* Valid from Monday - Thursday\n* Cannot combine with any other offers'
                });

                setStartDateEntered(dayjs().startOf('day'));
                const numberString: string = generateRandomNumberString(8);
                setCouponCode(numberString);
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const [discountType, setDiscountType] = useState('MONEY');
    const handleChangeDiscountType = (value: any) => {
        setDiscountType(value);
    };

    const [discountValue, setDiscountValue] = useState(0.00);
    const handleChangeDiscountValue = (event: any) => {
        const value = event.target.value;
        setDiscountValue(value);
    };

    const [title, setTitle] = useState('');
    const handleChangeTitle = (event: any) => {
        const value = event.target.value;
        setTitle(value);
    };

    const [couponCode, setCouponCode] = useState<any>();
    const selectAfter = (
        <Select value={discountType} onChange={handleChangeDiscountType}>
            <Option value='MONEY'>Dollar</Option>
            <Option value='PERCENT'>Percent</Option>
        </Select>
    );

    const [description, setDescription] = useState('');
    const handleChangeDescription = (event: any) => {
        const value = event.target.value;
        setDescription(value);
    };

    const [startDate, setStartDateEntered] = useState<Dayjs>(dayjs());

    const handleStartDateChange = (date: any) => {
        setStartDateEntered(date);
    };

    const [expireDate, setExpireDate] = useState('mm-dd-yyyy');
    const handleChangeExpireDate = (value: any) => {
        if (!isNaN(value)) {
            value = (startDate.add(value, 'day')).format('MM-DD-YYYY');
        }
        setExpireDate(value);
    };

    const changeTemplateType = async (item: any) => {
        setTemplateSelected(item);
    };

    const onSubmit = async (values: any) => {
        try {
            const dateCheck = dayjs(expireDate, { format: 'MM-DD-YYYY' });
            if (dateCheck.isBefore(startDate)) {
                message.error({ content: 'Expires Date must to >= Start Date', duration: 2 });
                return;
            }

            dispatch(setLoading(true));

            let base64 = null;
            let tempateId = null;
            if (templateSelected.id === null) {
                base64 = templateSelected.urlImage.replace(/^data:image\/\w+;base64,/, '');
                tempateId = null;
            } else {
                base64 = null;
                tempateId = templateSelected.id;
            }

            let shopIds: any[] = [];
            shopIds = [...shopIds, id];

            const response = await couponApis.create(
                null, shopIds, title, discountType, discountValue, description, values.subTitle, null,
                null, values.couponQuantity, couponCode, values.activePrint ? values.activePrint : false, startDate.format('MM-DD-YYYY'),
                expireDate, values.templateType, values.amountSpend, values.giftCardTemplateId, base64, tempateId);
            if (response.status === 200) {
                message.success('Create successful');
                navigate(`/coupons/${id}`);
            } else {
                dispatch(setLoading(false));
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            dispatch(setLoading(false));
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const excelFileAccept = '.jpg, .jpeg, .png';
    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: excelFileAccept
    };

    const handleBeforeUpload = (file: File) => {
        dispatch(setLoading(true));
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target && event.target.result) {
                const base64 = event.target.result as string;
                const newItem = {
                    id: null,
                    templateType: 'DISCOUNT',
                    urlImage: base64,
                    base64: base64
                };
                setLstTemplate((prevTemplateSelected: any) => [...prevTemplateSelected, newItem]);
                changeTemplateType(newItem);
                dispatch(setLoading(false));
            }
        };
        reader.readAsDataURL(file);
        return false;
    };

    return (
        <PageStyled>
            <Form form={formCreate}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    startDate: dayjs().startOf('day'),
                    templateType: 'TICKET',
                    activePrint: true,
                }}>

                <Box className="d-between">
                    <Breadcrumb style={{ marginBottom: '10px' }}>
                        <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                        <BreadcrumbItemStyled>Create Counpon</BreadcrumbItemStyled>
                    </Breadcrumb>
                    <Box display="flex" gap={1}>
                        <Button onClick={() => navigate(`/coupons/${id}`)}>
                            Cancel
                        </Button>
                        <Button variant="PRIMARY" onClick={() => formCreate.submit()}>
                            <SaveOutlined style={{ marginRight: '5px' }} />
                            Save
                        </Button>
                    </Box>
                </Box>

                <CouponsContentStyled>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                            <Box style={{ background: 'white', borderRadius: '5px', textAlign: 'left' }} p={1.5}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled label='Type' name="templateType">
                                            <Select defaultValue={''} size="large">
                                                <Select.Option key={'0'} value=''>{'All Promotion'}</Select.Option>
                                                <Select.Option key={'SHARE_REFERRAL'} value='SHARE_REFERRAL'>{'Share Referral'}</Select.Option>
                                                <Select.Option key={'MARKETING'} value='MARKETING'>{'Marketing'}</Select.Option>
                                                <Select.Option key={'REVIEW'} value='REVIEW'>{'Review'}</Select.Option>
                                                <Select.Option key={'TICKET'} value='TICKET'>{'Ticket'}</Select.Option>
                                            </Select>
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled label='   ' name="activePrint" valuePropName="checked">
                                            <Box className='d-center' justifyContent='start'>
                                                <Switch
                                                    onChange={(checked) => {
                                                        formCreate.setFieldValue('activePrint', checked);
                                                    }}
                                                    style={{ textAlign: 'right' }} defaultChecked={true} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                <Text variant="H9" ml={1}>Activate Print Coupon</Text>
                                            </Box>
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="title" // Tên của trường trong form
                                            label='Title'
                                            rules={[
                                                {
                                                    required: true
                                                },
                                                {
                                                    max: 50,
                                                    message: 'Max: 50 characters',
                                                },
                                            ]}>
                                            <Input size="large" placeholder='Please enter..' onChange={handleChangeTitle} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="subTitle" // Tên của trường trong form
                                            label='Sub Title'
                                            rules={[
                                                {
                                                    required: true
                                                },
                                                {
                                                    max: 50,
                                                    message: 'Max: 50 characters',
                                                },
                                            ]}>
                                            <Input size="large" placeholder='Please enter..' />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="description"
                                            label="Text Coupon">
                                            <TextArea placeholder='Please enter..'
                                                rows={4}
                                                showCount maxLength={255}
                                                onChange={handleChangeDescription}
                                                value={description} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <FormItemNoMarginStyled
                                            name="promotionValue"
                                            label="Promotion"
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}>
                                            <Input onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} placeholder='0' type='number' min={0} size="large" addonAfter={selectAfter} onChange={handleChangeDiscountValue} />
                                        </FormItemNoMarginStyled>
                                    </Col>
                                </Row>
                            </Box>

                            <Box p={0.5} style={{ backgroundColor: 'rgba(246, 247, 252, 1)' }}>
                                <Row>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold' }}>Coupon Quantity</Text>
                                                <DividerStyled />
                                                <Form.Item
                                                    name="couponQuantity"
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <Input onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} placeholder='0' min={0} type='number' size="large" />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold' }}>Start Date</Text>
                                                <DividerStyled />
                                                <Form.Item name="startDate"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Start Date is required'
                                                        }
                                                    ]}>
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        onChange={handleStartDateChange}
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        showTime={false}
                                                        inputReadOnly={true} />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Box pl={0.5} pr={0.5}>
                                            <BoxInfoFix>
                                                <Text variant="H8" style={{ fontWeight: 'bold', borderRadius: '5px' }}>Expires Date</Text>
                                                <DividerStyled />
                                                <Form.Item
                                                    name="expiresDate" // Tên của trường trong form
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}>
                                                    <SelectOrDatePicker startDate={startDate} disabled={startDate ? false : true} onChange={handleChangeExpireDate} valueDf='' />
                                                </Form.Item>
                                            </BoxInfoFix>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Box style={{ textAlign: 'center', padding: '15px' }}>
                                <img style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} width={'100%'}
                                    src={lstTemplate?.find((item) => item.urlImage === templateSelected.urlImage)?.urlImage} />
                                <Row gutter={10} style={{ marginTop: '15px' }}>
                                    <Col span={8}>
                                        <DraggerStyled {...uploadProps}
                                            beforeUpload={handleBeforeUpload}
                                            maxCount={1}
                                            name='campaignCustomerSelectFile'>
                                            <p className='ant-upload-drag-icon' style={{ marginTop: '0px', marginBottom: '0px' }}>
                                                <UploadOutlined style={{ fontSize: '30px' }} />
                                            </p>
                                        </DraggerStyled>
                                    </Col>
                                    {lstTemplate?.map((item: any, index) => (
                                        item.templateType === 'DISCOUNT' && (
                                            <Col key={index} span={8}>
                                                <img className={templateSelected.urlImage === item.urlImage ? 'active' : ''}
                                                    width={'100%'}
                                                    src={item.urlImage} onClick={() => changeTemplateType(item)} />
                                            </Col>
                                        )
                                    ))}
                                </Row>
                            </Box>
                        </Col>
                    </Row>
                </CouponsContentStyled>
            </Form>
        </PageStyled >
    );
}

const CouponsContentStyled = styled.div`
  background: rgba(238, 238, 238, 1);
  margin-top: 1rem;
  padding: 1.5rem;

  && .ant-qrcode-borderless {
    margin-bottom: -8px;
    padding-bottom: 0!important;
    padding-top: 0!important;
  }

  && .active {
    border: 2px solid rgba(255, 136, 144, 1);
  }
`;

const DividerStyled = styled(Divider)`
    border: 0.5px solid rgba(218, 218, 218, 1);
    margin: 16px 0;
`;

const BoxInfoFix = styled(Box)`
    background: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(218, 218, 218, 1);
`;

const DraggerStyled = styled(Dragger)`
    && .ant-upload-list {
        display: none;
    }
`;