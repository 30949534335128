import { Button, FormInstance, Pagination, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { get } from 'lodash';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatNumber } from '../../helpers/FunctionUtils';
import accountApis from '../../services/accountApis';
import reportApis, { SMSReportParams } from '../../services/reportApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import { formatCurrency } from '../../utils/formatCurrency';
import { TableEvenOdd } from '../account/styles';
import otpIcon from './icons/OTP.svg';
import otherIcon from './icons/SMS.svg';
import appointmentIcon from './icons/appointment.svg';
import costIcon from './icons/cash.svg';
import chartAppointmentIcon from './icons/chartAppointment.png';
import chartCostIcon from './icons/chartCost.png';
import chartMarketingIcon from './icons/chartMarketing.png';
import chartOTPIcon from './icons/chartOTP.png';
import chartOtherIcon from './icons/chartOther.png';
import marketingIcon from './icons/marketing.svg';

type SMSDashboardData = {
  totalCost: number;
  totalOtp: number;
  totalMarketing: number;
  totalAppointment: number;
  totalOthers: number;
}
type IFormSearchValues = {
  endDate: Dayjs;
  startDate: Dayjs;
  shopId: string;
};
const ITEMS_PER_PAGE = 10;
const FORMAT_DATE = 'MM-DD-YYYY';

interface IReportSummaryItem {
  shopName: string;
  email: string;
  shopId: string;
  totalSent: number;
  totalSuccess: number;
  totalFail: number;
  totalCost: number;
}

type Ref = {
  init: (startDate: Dayjs, endDate: Dayjs) => void;
  search: (values: IFormSearchValues) => void;
};
type Props = {
  form: FormInstance<IFormSearchValues>;
};
export const useReportSMSAdminRef = () => useRef<Ref>(null);
const ReportSMSAdmin = forwardRef<Ref, Props>(({ form }, ref) => {
  const [loading, setTableLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState<SMSDashboardData | null>(null);
  const [listData, setListData] = useState<IReportSummaryItem[]>([]);
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getSummary = async (params: SMSReportParams) => {
    try {
      const res: AxiosResponse<{ data: SMSDashboardData }> = await reportApis.getSMSSummaryReportDashboard(params);
      if (res.data.data) {
        setDashboard(res.data.data);
      }
    } catch (error) { }
  };
  const getList = async (params: SMSReportParams) => {
    try {
      const res: AxiosResponse<{ data: { content: IReportSummaryItem[], totalElements: number, totalPages: number } }> = await reportApis.getSMSReport(params);
      if (res.data.data) {
        console.log('res.data.data', res.data.data);

        setListData(res.data.data.content);
        setTotal(res.data.data.totalElements);
      }
    } catch (error) { }
  };

  const getReportSummary = async (page: number, shopId: string | null, startDate: string, endDate: string) => {
    try {
      await getList({ page, shopId: shopId || '', startDate, endDate, size: ITEMS_PER_PAGE });
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    }
  };

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await form.validateFields();
      await getReportSummary(pageNumber, values.shopId, values.startDate?.format(FORMAT_DATE), values.endDate?.format(FORMAT_DATE) || '');
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFinishFormSearch = async (values: IFormSearchValues) => {
    setTableLoading(true);
    try {
      setCurrentPage(1);
      const params: SMSReportParams = { startDate: values?.startDate.format(FORMAT_DATE), endDate: values?.endDate.format(FORMAT_DATE) };
      await Promise.all([getSummary(params), getList(params)]);
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  };

  const handleViewDetail = (id: string) => async () => {
    dispatch(setLoading(true));
    try {
      const response = await accountApis.getShopById(id);
      if (response.status === 200) {
        dispatch(setShopState(response.data.data));
        navigate(`/salon/${id}`, { state: { path: '/report' } });
      } else {
        message.error({ content: response.data.message, duration: 5 });
      }
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const initData = async (startDate: Dayjs, endDate: Dayjs) => {
    setTableLoading(true);
    try {
      const params: SMSReportParams = { startDate: startDate.format(FORMAT_DATE), endDate: endDate.format(FORMAT_DATE) };
      await Promise.all([getSummary(params), getList(params)]);
    } catch (error: any) { } finally {
      setTableLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    init: initData,
    search: handleFinishFormSearch,
  }))

  const columns: ColumnsType<any> = [
    {
      title: <span>Business name</span>,
      dataIndex: 'shopName',
      render: (businessName: any, record: IReportSummaryItem) =>
        <Button type='link' onClick={handleViewDetail(record.shopId)}>{businessName}</Button>,
    },
    {
      title: <span>Email</span>,
      dataIndex: 'email',
    },
    {
      title: <span>TOTAL SENT</span>,
      dataIndex: 'totalSent',
      render: (value: any) => <span>{formatNumber(value)}</span>,
    },
    {
      title: <span>TOTAL COST</span>,
      dataIndex: 'totalCost',
      render: (value: any) => <span>{formatCurrency(value)}</span>,
    },
    {
      title: <span>Total OTP</span>,
      dataIndex: 'totalOtp',
      render: (value: any) => <span>{formatNumber(value)}</span>,
    },
    {
      title: <span>Appointments</span>,
      dataIndex: 'totalAppointment',
      render: (value: any) => <span>{formatNumber(value)}</span>,
    },
    {
      title: <span>Marketings</span>,
      dataIndex: 'totalMarketing',
      render: (value: any) => <span>{formatNumber(value)}</span>,
    },
    {
      title: <span>Others</span>,
      dataIndex: 'totalOthers',
      render: (value: any) => <span>{formatNumber(value)}</span>,
    },
  ];

  return (
    <>
      <DashboardContainer>
        <DashboardItem type='cost' value={formatCurrency(dashboard?.totalCost||0)} />
        <DashboardItem type='OTP' value={formatNumber(dashboard?.totalOtp||0)} />
        <DashboardItem type='marketing' value={formatNumber(dashboard?.totalMarketing||0)} />
        <DashboardItem type='appointment' value={formatNumber(dashboard?.totalAppointment||0)} />
        <DashboardItem type='others' value={formatNumber(dashboard?.totalOthers||0)} />
      </DashboardContainer>
      <TableContentStyled
        columns={columns}
        dataSource={listData}
        rowKey="email"
        bordered
        loading={loading}
        pagination={false} />

      <PagingStyled>
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={ITEMS_PER_PAGE}
          onChange={onPageChanged}
          showSizeChanger={false}
        />
      </PagingStyled>
    </>
  )
})
ReportSMSAdmin.displayName = 'ReportSMSAdminRef';
export default ReportSMSAdmin;

const TableContentStyled = styled(TableEvenOdd)`
margin-top: 16px;
    && .status {
        text-align: center;
    }

thead.ant-table-thead th.ant-table-cell span {
  text-transform: uppercase;
}
`;

const PagingStyled = styled(Content)`
    text-align: right;
    margin-top: 10px;
`;

const DashboardContainer = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`;

const dashboardUIConfigs = {
  'cost': {
    icon: costIcon,
    label: 'Sms Cost',
    chart: chartCostIcon,
  },
  'OTP': {
    icon: otpIcon,
    label: 'Sms OTP',
    chart: chartOTPIcon,
  },
  'marketing': {
    icon: marketingIcon,
    label: 'Sms Marketing',
    chart: chartMarketingIcon,
  },
  'appointment': {
    icon: appointmentIcon,
    label: 'Sms Appointment',
    chart: chartAppointmentIcon,
  },
  'others': {
    icon: otherIcon,
    label: 'Sms Others',
    chart: chartOtherIcon,
  },
};
const DashboardItem = ({ type, value }: { value: string, type: 'cost' | 'OTP' | 'marketing' | 'appointment' | 'others' }) => {
  const uiConfigs = useMemo(() => get(dashboardUIConfigs, type), [type]);
  if (!uiConfigs) return null;
  const { icon, label, chart } = uiConfigs;
  return <DashboardItemStyled>
    <div className='top-content'>
      <img src={icon} style={{ height: 32, width: 32 }} />
      <div>
        <p className='dashboard-value'>{value}</p>
        <p className='dashboard-label'>{label}</p>
      </div>
    </div>
    <img src={chart} alt="chartIcon" style={{ width: '100%' }} />
  </DashboardItemStyled>
};
const DashboardItemStyled = styled.div`
display: flex;
padding-top: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
overflow: hidden;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
.top-content {
  display: flex;
  padding: 0px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  p {
    margin: 0;
    &.dashboard-value {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 116.667% */
    }

    &.dashboard-label {
      color: #566A7F;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
  }
}
`;