import { LeftOutlined } from '@ant-design/icons';
import { Button as AntBtn, Breadcrumb, message } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Box from '../../../components/Box';
import accountApis from '../../../services/accountApis';
import { AppState } from '../../../store';
import { setShopState } from '../../../store/shop/actions';
import { ShopInfo } from '../../../store/shop/types';
import { PageStyled } from '../../styles';

export const PromotionLayoutSubmitButton = React.createRef<HTMLButtonElement>();

export const appendListenerPromotionLayoutSubmitAction = (onSubmit: () => void) => {
  PromotionLayoutSubmitButton.current?.addEventListener('click', onSubmit);
  return () => {
    PromotionLayoutSubmitButton.current?.removeEventListener('click', onSubmit);
  };
};

const PromotionLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const _location = useLocation();

  const onBack = () => {
    if (_location.pathname === `/promotions/${id}/create`) {
      navigate(`/promotions/${id}`);
      return;
    }
    if (_location.pathname.includes(`/promotions/${id}/detail/`)) {
      navigate(`/promotions/${id}`);
      return;
    }
    if (_location.pathname.includes(`/promotions/${id}/push-notification`)) {
      navigate(`/promotions/${id}`);
      return;
    }
    navigate(`/salon/${id}`);
  };

  useEffect(() => {
    const initData = async () => {
      try {
        if (id) {
          const response = await accountApis.getShopById(id);
          if (response.status === 200) {
            dispatch(setShopState(response.data.data));
          }
        }
      }
      catch (error: any) {
        message.error({ content: error.message as string, duration: 2 });
      }
    };
    initData();
  }, []);

  const Actions = () => {
    if (_location.pathname === `/promotions/${id}/create`) {
      return (
        <Box display="flex" gap={1} alignItems='center'>
          <AntBtn onClick={onBack} className='ant-btn btn-nav-back'>Cancel</AntBtn>
          <AntBtn ref={PromotionLayoutSubmitButton} htmlType='button' className='btn-add' >Add Promotion</AntBtn>
        </Box>
      );
    }
    if (_location.pathname.includes(`/promotions/${id}/push-notification`)) {
      return (
        <Box display="flex" gap={1} alignItems='center'>
          <AntBtn onClick={onBack} className='ant-btn btn-nav-back'>Cancel</AntBtn>
          <AntBtn ref={PromotionLayoutSubmitButton} htmlType='button' className='btn-add' >Push Notification</AntBtn>
        </Box>
      );
    }
    return (
      <Box display="flex" gap={1} alignItems='center'>
        <AntBtn onClick={onBack} icon={<LeftOutlined />} className='ant-btn btn-nav-back'>
          <span>Back</span>
        </AntBtn>
      </Box>
    );
  };

  return (
    <PageStyled style={{ background: '#EAEDED' }}>
      <Container className="d-between">
        <ShopName />
        <Actions />
      </Container>
      <ContentStyled>
        <Outlet />
      </ContentStyled>
    </PageStyled >
  );
};

export default PromotionLayout;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  .ant-btn {
    display: flex;
    height: 40px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    gap: 8px;
    .ant-btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
    }
    span {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 24.75px */
    }
    &.btn-nav-back {
      border: 1px solid #86909C;
      background: #E3E9ED;
      span {
        color: #566A7F;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid #86909C;
        background: #C9CDD4;
      }
    }
    &.ant-btn-primary {
      border-radius: 5px;
      background: #008BFF;
      span {
        color: #fff;
      }
    }
    &.btn-add {
      border-radius: 5px;
      background: #FF8890;
      border-color: #FF8890;
      &:hover {
        background: #F5767F;
        border-color: #F5767F;
        span {
          color: #fff;
        }
      }
      &:disabled {
        background: #FFC3C7;
        border-color: #FFC3C7;
      }

      span {
        color: #fff;
      }
    }
  }
`;

const ShopName = () => {
  const navigate = useNavigate();
  const shopSelect = useSelector<AppState>((state) => state.shopState.shopInfo) as ShopInfo;
  const { id } = useParams();
  const _location = useLocation();

  const items = useMemo(() => {
    if (_location.pathname === `/promotions/${id}/create`) {
      return [
        {
          title: shopSelect?.shopName || '--',
          onClick: () => navigate(`/salon/${id}`),
        },
        {
          title: 'Promotion',
          onClick: () => navigate(`/promotions/${id}`),
        },
        {
          title: 'Add Promotion',
        },
      ];
    }
    if (_location.pathname.includes(`/promotions/${id}/push-notification`)) {
      return [
        {
          title: shopSelect?.shopName || '--',
          onClick: () => navigate(`/salon/${id}`),
        },
        {
          title: 'Promotion',
          onClick: () => navigate(`/promotions/${id}`),
        },
        {
          title: 'Push Notification',
        },
      ];
    }
    return [
      {
        title: shopSelect?.shopName || '--',
      }
    ];
  }, [_location, shopSelect]);

  return (
    <BreadcrumbStyled
      separator="/"
      items={items}
    />
  );
};
const ContentStyled = styled.div`
  margin-top: 16px;
`;
const BreadcrumbStyled = styled(Breadcrumb)`
  &.ant-breadcrumb {
    .ant-breadcrumb-separator {
      color: #86909C;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    li {
      .ant-breadcrumb-link {
        color: #86909C;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0 8px;
        border-radius: 8px;
        &:hover {
          cursor: pointer;
          background: #C9CDD4;
        }
      }
      &:last-child {
        .ant-breadcrumb-link {
          color: #1D2129;
        }
      }
    
    }

  }
`;