import { UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button as ButtonAnt, Form, GetProp, Row, Spin, UploadProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Dragger from 'antd/es/upload/Dragger';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { TableEvenOdd } from '../pages/account/styles';
import Button from './Button';
import Modal from './Modal';
export const EXCEL_TEMPLATE_URL = 'https://bona-pos.s3.us-east-2.amazonaws.com/template6fab9062-c722-4576-8964-48b9498d0db8.xlsx';
export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
type ICustomerUploadItem = {
  name: string, phone: string, email: string, point: number, birthday: string,
};
type Ref = {
  open: () => void;
  close: () => void;
};
export type ModalUploadCustomerProps = {
  onSubmit: (file: FileType, list: ICustomerUploadItem[]) => Promise<boolean>;
};
export const useModalUploadCustomerRef = () => useRef<Ref>(null);
const ModalUploadCustomer = forwardRef<Ref, ModalUploadCustomerProps>(({ onSubmit }, ref) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
    form.resetFields();
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const completed = await onSubmit(values?.file?.file, values.list);
      if (completed) {
        setOpen(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }

  };

  const handleBeforeUpload = (file: File) => {
    form.setFieldValue('list', []);
    setLoading(true);
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>): void => {
      const data = e.target?.result;
      if (data) {
        const workbook = XLSX.read(data as string, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        const newLstCustomer = excelData.slice(1).filter((row: any) => !!row[0]).map((row: any) => {
          return {
            name: row[0], // Assuming the name is in the second column (index 1)
            phone: row[1], // Assuming the phone is in the third column (index 2)
            email: row[2] == '' ? '--' : row[2], // Assuming the point is in the fifth column (index 4)
            point: row[3] || 0,
            birthday: row[4] || '--',
          };
        });
        console.log('newLstCustomer', newLstCustomer);

        form.setFieldValue('list', newLstCustomer);
      }
    };
    reader.readAsBinaryString(file);
    setLoading(false);
    return false;
  };

  const onDownloadTemplate = () => {
    //@ts-ignore
    window?.open(EXCEL_TEMPLATE_URL, '_blank')?.focus();
  };


  useImperativeHandle(ref, () => ({
    open: () => onOpen(),
    close: () => onClose(),
  }));

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Point',
      dataIndex: 'point',
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
    },
  ];

  return (
    <Modal width={'900px'} onCancel={onClose} open={open} onOk={() => form.submit()} footer={<></>}>
      <Spin size='large' spinning={loading}>
        <Container form={form} onFinish={handleFinish}>
          <Form.Item name='file' rules={[{ required: true, message: 'please upload file' }]}>
            <Dragger
              multiple={false}
              accept='.xls, .xlsx'
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              onRemove={() => form.setFieldValue('list', [])}>
              <p className='ant-upload-drag-icon'>
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Upload file customer</p>
              <div style={{ position: 'absolute', top: 8, right: 8 }}>
                <ButtonAnt type='text' onClick={e => [e.stopPropagation(), onDownloadTemplate()]} className='btn-download'>
                  <Row align={'middle'} style={{ gap: 4 }}>
                    <VerticalAlignBottomOutlined style={{ fontSize: 20 }} />
                    <span>Download template</span>
                  </Row>
                </ButtonAnt>
              </div>
            </Dragger>
          </Form.Item>
          <Form.List name={'list'}>
            {(fields) => {
              return (
                <TableEvenOdd
                  columns={columns}
                  pagination={{ total: fields.length, pageSize: 20 }}
                  dataSource={fields.map(o => form.getFieldValue(['list', o.name]))}
                />
              )
            }}
          </Form.List>
        </Container>
        <Footer>
          <Button width="100%" onClick={onClose}>Close</Button>
          <Button width="100%" variant="PRIMARY" onClick={() => form.submit()}>Upload</Button>
        </Footer>
      </Spin>
    </Modal>
  )
});
ModalUploadCustomer.displayName = 'ModalUploadCustomer';
export default ModalUploadCustomer;

const Container = styled(Form)`
  padding: 16px;
`;

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 1.5rem 1.5rem;
`;