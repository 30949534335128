import React, { useCallback } from 'react';
import { Input, InputRef } from 'antd';
import { debounce } from 'lodash';
import numeral from 'numeral';

interface CurrencyInputProps {
  value?: string;
  onChange?: (value: number) => void;
  onChangeDebounce?: (value: number) => void;
  bordered?: boolean;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  tabIndex?: number;
  inputRef?: React.Ref<InputRef>;
  decimalLength?: number;
}

const CurrencyInputPrecision: React.FC<CurrencyInputProps> = ({
  value = '0.00',
  onChange,
  onChangeDebounce,
  bordered = true,
  size = 'medium',
  className = '',
  width,
  height,
  disabled,
  tabIndex,
  decimalLength = 2,
  inputRef,
}) => {
  const _debounce = useCallback(
    debounce(
      (inputValue: number) =>
        typeof onChangeDebounce === 'function' && onChangeDebounce(inputValue),
      200
    ),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let numericValue = 0.0;

    if (rawValue) {
      const intValue = parseInt(rawValue);
      numericValue = intValue / +('1'.padEnd(decimalLength + 1, '0'));
    }

    if (onChange) {
      onChange(numericValue);
    }
    _debounce(numericValue);
  };

  const displayValue = `${numeral((parseFloat(value).toFixed(decimalLength))).format('$0,0.'.padEnd(5 + decimalLength, '0'))}`;

  // Determine the height based on the size prop
  let heightBySize = '3.5rem'; // default for 'medium'
  switch (size) {
    case 'large':
      heightBySize = '4rem';
      break;
    case 'small':
      heightBySize = '3rem';
      break;
  }

  return (
    <Input
      ref={inputRef}
      className={className}
      value={displayValue}
      onChange={handleInputChange}
      style={{ width: width || '100%', height: height || heightBySize }}
      bordered={bordered}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

export default CurrencyInputPrecision;
