import { Avatar, Modal } from 'antd';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import UserIcon from '../../../../components/Icon/icons/banlist/UserIcon';
import { formatPhone } from '../../../../utils/formatPhone';
import userSelectors from '../selectors';
import DashboardItem from './DashboardItem';
import useLoading from '../../useLoading';
import banlistApis from '../../../../services/banlistApis';
import { useNavigate, useParams } from 'react-router-dom';
import { useThunkDispatch } from '../../../../store';
import banlistActions from '../../../../store/banlist/actions';

const UserInfo = () => {
  const { id = '' } = useParams();
  const dispatch = useThunkDispatch();
  const data = userSelectors.userDetail.data();
  const [modal, contextHolder] = Modal.useModal();
  const setLoading = useLoading();
  const navigate = useNavigate();

  const blockAccount = async () => {
    setLoading(true);
    try {
      const res = await banlistApis.user.delete(id);
      if (res.data.data) {
        dispatch(banlistActions.user.init());
        navigate('/banlist/users');
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const onBlockAccount = async () => {
    const confirmed = await modal.confirm({ title: 'Are you sure?' });
    if (confirmed) return blockAccount();
  };

  return (
    <Styled>
      <div className='user-info-box-container'>
        <UserInfoBoxStyled>
          <div className="user-detail-info">
            <Avatar size={'large'} src={data?.avatar || 'fail'} >
              <UserIcon />
            </Avatar>
            <div>
              <div className="user-detail-info-name">
                {data?.name || '--'}
              </div>
              <div className="user-detail-info-email">
                {data?.email || '--'}
              </div>
              <div className="user-detail-info-phone">
                {data ? formatPhone(data?.phone) : '--'}
              </div>
            </div>
          </div>
          <div className="user-detail-info-business">
            <div className="user-detail-info-business-name">
              {data?.businessName || '--'}
            </div>
            <div className="user-detail-info-business-address">
              {data?.address || ''}, {data?.city || ''}, {data?.state || ''}, {data?.zip || ''}
            </div>
          </div>
          <Button onClick={onBlockAccount} className='btn-block' block variant='ORANGE' icon={'addBanlist'} style={{ gap: 4, height: '46px' }}>
            <span>Block Account</span>
          </Button>
          <div className='user-detail-info-create-date'>
            Created account: {data?.createdDate}
          </div>
        </UserInfoBoxStyled>
      </div>
      <DashboardItem type='ban' >
        <p className="title-item">BAN CUSTOMERS</p>
        <p className="number-item">{data?.totalBanCustomer || 0}</p>
      </DashboardItem>
      <DashboardItem type='comment' >
        <p className="title-item">COMMENTS</p>
        <p className="number-item">{data?.totalComments || 0}</p>
      </DashboardItem>
      <DashboardItem type='article' >
        <p className="title-item">ARTICLES</p>
        <p className="number-item">{data?.totalBlogs || 0}</p>
      </DashboardItem>
      <DashboardItem type='report' >
        <p className="title-item">REPORT</p>
        <p className="number-item">{data?.totalReports || 0}</p>
      </DashboardItem>
      <div>{contextHolder}</div>
    </Styled>
  )
}

export default UserInfo;

const Styled = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr;
  border-radius: 5px;
  background: #F6F7FC;
  box-shadow: 0px 12px 24px -8px rgba(0, 107, 255, 0.10);
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  .user-info-box-container {
    grid-row: 1 / span 2;
    height: 100%;
  }
`;

const UserInfoBoxStyled = styled.div`
  background: #fff;
  height: 100%;  
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  border-bottom: 1px solid #D6D6D6;
  background: #FFF;
  .user-detail-info {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 16px;
    width: 100%;
    .ant-avatar {
      background: rgb(227, 233, 237);
      display: flex;
      display: flex;
      width: 80px;
      height: 80px;
      justify-content: center;
      align-items: center;
      .ant-avatar-string {
        transform: translateY(3px) scale(2) !important;
      }
    }

    .user-detail-info-name {
      color: #1D2129;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .user-detail-info-email {
      color: #566A7F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .user-detail-info-phone {
      color: #566A7F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .user-detail-info-business {
    display: flex;
    padding: 4px 8px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    background: #DBEFFF;
    .user-detail-info-business-name {
      color: #566A7F;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .user-detail-info-business-address {
      color: #566A7F;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .btn-block {
    display: flex;
    height: 46px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .user-detail-info-create-date {
    color: #566A7F;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
`;

