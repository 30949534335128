const IconListNormal = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1" width="34" height="34" rx="4.5" fill="#E5E5E5" />
    <rect x="0.5" y="1" width="34" height="34" rx="4.5" stroke="#ADADAD" />
    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M10 12.25H8V10.25H10V12.25ZM27 12.25H12V10.25H27V12.25Z" fill="#1D2129" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19.25H8V17.25H10V19.25ZM27 19.25H12V17.25H27V19.25ZM10 25.75H8V23.75H10V25.75ZM27 25.75H12V23.75H27V25.75Z" fill="#1D2129" />
  </svg>
);
const IconListActive = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="35" height="35" rx="5" fill="#FF8890" />
    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M10 12.25H8V10.25H10V12.25ZM27 12.25H12V10.25H27V12.25Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19.25H8V17.25H10V19.25ZM27 19.25H12V17.25H27V19.25ZM10 25.75H8V23.75H10V25.75ZM27 25.75H12V23.75H27V25.75Z" fill="white" />
  </svg>
);

const IconList = ({ active }: { active?: boolean }) => {
  if (active) return <IconListActive />;
  return <IconListNormal />;
};

export default IconList;
