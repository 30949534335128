import { api } from "../helpers/api";


const authenticate = async (userName: any, password: any, rememberMe: any) => {
    const payload = {
        email: userName,
        password: password,
        rememberMe
    };
    return await api.post('/api/v1/admin/auth/login', payload).then((response) => {
        return response.data;
    });
};


export const publicApis = {
    authenticate
};