import dayjs from "dayjs";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useThunkDispatch } from "../../../../store";
import banlistActions from "../../../../store/banlist/actions";
import { FORMAT_DATE_REQUEST } from "../../../../store/banlist/constants";
import { banlistUIActions } from "../../../../store/banlist/reducer";
import { IBanlistCustomerDetailResData } from "../../../../store/banlist/types/customer";
import BlogsTable from "./BlogsTable";

export const useBanListCustomerDetail = () => {
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  return (userId: string, data: IBanlistCustomerDetailResData) => {
    dispatch(banlistUIActions.setDetailCustomer(data));
    navigate('/banlist/customers/' + userId);
  };
};

const CustomerDetailPage = () => {
  const dispatch = useThunkDispatch();
  useEffect(() => {
    const startDate = dayjs().startOf('month').format(FORMAT_DATE_REQUEST);
    const endDate = dayjs().endOf('month').format(FORMAT_DATE_REQUEST);
    dispatch(banlistActions.customer.fetchBlogsByCustomer({ startDate, endDate }));
  }, []);
  return (
    <div>
      <BlogsTable />
    </div>
  )
}

export default CustomerDetailPage;
