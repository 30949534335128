import { DeleteOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Row, Select, Spin, Upload, UploadFile, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { debounce } from 'lodash';
import React, { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../utils/formatCurrency';
import InputPercentDollar from './InputPercentDollar';
import { CouponTemplateType, FormControl, FormControlsStyled, MAX_SIZE_IMAGE, calendarAfter, downIcon, getBase64, plusIcon } from './helpers';
import { FileType, IBodyCreatePromotionCoupon, IPromotionCouponItem } from './types';
import accountApis from '../../../services/accountApis';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { FORMAT_DATE_REQUEST } from '../../../store/banlist/constants';
type AddNewCouponFormData = {
  base64: string;
  title: string;
  subTitle: string;
  description: string;
  startDate: Dayjs;
  endDate: Dayjs;
  discountValue: number;
  discountType: string;
  quantity: number;
};
type CouponInputProps = {
  disabled?: boolean;
  value?: any;
  onChange?: (value: any) => void;
  coupons: IPromotionCouponItem[];
  refreshList?: () => any;
  templateType?: keyof typeof CouponTemplateType;
}
const CouponInput = ({ disabled, coupons = [], value, onChange = () => undefined, refreshList = () => undefined, templateType = 'APPOINTMENT' }: CouponInputProps) => {
  const addNewRef = useRef<AddNewCouponRef>(null);
  const onAfterAddNew = async (id: string) => {
    await refreshList();
    onChange(id);
  };
  return (
    <React.Fragment>
      <Select disabled={disabled} placeholder='Select coupon' value={value} onChange={onChange} suffixIcon={downIcon} dropdownRender={(menu) => <div>
        {menu}
        <Button size='large' style={{ width: '100%', marginTop: 4, border: 'none', background: '#F6F7FC', }} onClick={() => addNewRef.current?.open()}>
          <Row align={'middle'} style={{ gap: 4 }}>
            {plusIcon}
            <span style={{ color: '#008BFF', fontSize: 14, fontWeight: 500 }}>Create New Coupon</span>
          </Row>
        </Button>
      </div>}>
        {coupons.map(o => (
          <Select.Option key={o.couponId}>
            <Row justify={'space-between'}>
              <span>{o.couponName}</span>
              <span>{o.discountType === 'PERCENT' ? `${o.discountValue}%` : formatCurrency(o.discountValue)} OFF</span>
            </Row>
          </Select.Option>))}
      </Select>
      <AddNewCoupon ref={addNewRef} onAfterAddNew={onAfterAddNew} templateType={templateType} />
    </React.Fragment>
  );
};

export default memo(CouponInput);

type AddNewCouponRef = {
  open: () => void;
  close: () => void;
};
type AddNewCouponProps = {
  onAfterAddNew: (id: string) => void;
  templateType?: string;
};
const AddNewCoupon = forwardRef<AddNewCouponRef, AddNewCouponProps>(
  ({ onAfterAddNew = () => undefined, templateType = 'APPOINTMENT' }, ref) => {
    const [loading, setLoading] = useState(false);
    const { id: shopId = '' } = useParams();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onOpen: AddNewCouponRef['open'] = () => {
      form.resetFields();
      form.setFieldValue('discountType', 'MONEY');
      form.setFieldValue('discountValue', 0);
      setOpen(true);
    };
    const onClose: AddNewCouponRef['close'] = () => {
      setOpen(false);
    };
    const handleFinish = async (values: any) => {

      const data = values as AddNewCouponFormData;
      const body: IBodyCreatePromotionCoupon = {
        base64: data.base64?.replace(/^data:image\/\w+;base64,/, ''),
        couponName: data.title,
        title: data.subTitle,
        description: data.description,
        discountType: data.discountType,
        discountValue: +(data.discountValue || ''),
        shopIds: [shopId],
        endTime: data.endDate.format(FORMAT_DATE_REQUEST),
        startTime: data.startDate.format(FORMAT_DATE_REQUEST),
        templateType,
        quantity: +(data.quantity || ''),
      };
      setLoading(true);
      try {
        const res: AxiosResponse<{ data: any }> = await accountApis.createPromotionCoupon(body);
        if (res.data.data) {
          onAfterAddNew(res.data.data);
          onClose();
        }
      } catch (error) { }
      finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      open: onOpen,
      close: onClose,
    }));

    return (
      <Modal
        open={open} forceRender centered destroyOnClose={false} footer={null} closeIcon={null}
        styles={{ content: { padding: 0 } }}
        onCancel={onClose}
        width={'1000px'}
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={handleFinish}>
            <Container>
              <div className="header-title">Create New Coupon</div>
              <div className="card-body">
                <div className="card-body-item">
                  <FormControl label='Title' name={'title'} required>
                    <Input placeholder='Please enter...' style={{ width: '100%' }} />
                  </FormControl>
                  <FormControl label='Sub Title' name={'subTitle'} required>
                    <Input placeholder='Please enter...' style={{ width: '100%' }} />
                  </FormControl>
                  <FormControl label='Text Coupon' name={'description'}>
                    <Input.TextArea className='coupon-description' placeholder='Please enter...' style={{ width: '100%' }}
                      showCount maxLength={255}
                    />
                  </FormControl>
                  <FormControl name={'startDate'} label='Start Date'>
                    <DatePicker placeholder='mm-dd-yyyy' format={'MM-DD-YYYY'} suffixIcon={calendarAfter} />
                  </FormControl>
                  <FormControl name={'endDate'} label='Expires Date'>
                    <DatePicker placeholder='mm-dd-yyyy' format={'MM-DD-YYYY'} suffixIcon={calendarAfter} />
                  </FormControl>
                </div>
                <div className="card-body-item">
                  <Row style={{ marginBottom: 24 }} justify={'center'}>
                    <Form.Item noStyle name={'base64'}>
                      <CouponUpload />
                    </Form.Item>
                  </Row>
                  <FormControl label='Promotion' required>
                    <InputPercentDollar />
                  </FormControl>
                  <FormControl name='quantity' label='Coupon Quantity'>
                    <Input
                      style={{ width: '100%' }}
                      placeholder='0'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} />
                  </FormControl>
                </div>
              </div>
              <div className="card-footer">
                <button type='button' onClick={onClose}>
                  <span>Close</span>
                </button>
                <button type='submit' className="btn-submit">
                  <span>Done</span>
                </button>
              </div>
            </Container>
          </Form>
        </Spin>
      </Modal>
    );
  }
);
AddNewCoupon.displayName = 'PromotionAddNewCoupon';

const Container = styled(FormControlsStyled)`
  padding: 24px;
  .header-title {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }

  .card-body {
    display: flex;
    gap: 24px;
    .card-body-item {
      flex: 1;
    }
  }
  .card-footer {
    display: flex;
    gap: 24px;
    margin-top: 8px;
    button {
      display: flex;
      padding: 16px 24px;
      flex-direction: column;
      align-items: center;
      flex: 1 0 0;
      border-radius: 5px;
      border: 1px solid #86909C;
      background: #E3E9ED;
      span {
        color: #566A7F;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &.btn-submit {
        border-radius: 5px;
        background: #FF8890;
        border-color: #FF8890;
        span {
          color: #fff;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item-label {
      padding: 0px;
    }
    .ant-form-item-explain {
      display: none;
    }
  }
`;
type CouponUploadProps = {
  value?: string;
  onChange?: (value: string) => void;
};
const CouponUpload = ({ value: imageUrl = '', onChange: setImageUrl = () => undefined }: CouponUploadProps) => {
  const [value, onChange] = useState<UploadFile[]>([]);
  const openAlert = debounce(() => {
    notification.error({
      message: 'max size of 800KB',
      duration: 3,
    });
  }, 100);

  const onModalOk = async (_value: any) => {
    const file = _value as FileType;
    getBase64(file as FileType, (url) => {
      setImageUrl(url);
    });
  };

  return (
    <UploadStyled>
      <div>
        <div className="title-upload-text">Upload a coupon image or choose </div>
        <div className="title-upload-text">an available template below</div>
      </div>
      <ImgCrop
        rotationSlider={false} cropShape='rect'
        showGrid aspectSlider={false} showReset
        onModalOk={onModalOk}
        // @ts-ignore
        cropperProps={{ restrictPosition: false, cropSize: { width: 375, height: 227 } }}

      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={() => true}
          fileList={value}
          onChange={({ fileList: newFileList, file }) => {
            if ((file.size || 0) > MAX_SIZE_IMAGE) return openAlert();
            onChange(newFileList);
          }}
          accept="image/*"
        >
          {imageUrl ?
            <div className='image-upload-preview'>
              <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} />
              <Button onClick={e => [e.stopPropagation(), setImageUrl('')]} type='dashed' className='btn-trash'><DeleteOutlined style={{ fontSize: 20 }} /></Button>
            </div>
            : <UploadUI />}
        </Upload>
      </ImgCrop>
    </UploadStyled>
  );
};

const UploadUI = () => {
  return (
    <UploadUIStyled>
      {UploadIcon}
      <div className="upload-text">Upload Image</div>
    </UploadUIStyled>
  );
};

const UploadUIStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  .upload-text {
    color: #008BFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const UploadIcon = <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M5.30729 24.2368C6.22777 24.2368 6.97396 24.983 6.97396 25.9035V32.5702C6.97396 33.0122 7.14955 33.4361 7.46211 33.7487C7.77467 34.0612 8.1986 34.2368 8.64062 34.2368H31.974C32.416 34.2368 32.8399 34.0612 33.1525 33.7487C33.465 33.4361 33.6406 33.0122 33.6406 32.5702V25.9035C33.6406 24.983 34.3868 24.2368 35.3073 24.2368C36.2278 24.2368 36.974 24.983 36.974 25.9035V32.5702C36.974 33.8962 36.4472 35.168 35.5095 36.1057C34.5718 37.0434 33.3 37.5702 31.974 37.5702H8.64062C7.31454 37.5702 6.04277 37.0434 5.10509 36.1057C4.16741 35.168 3.64062 33.8962 3.64062 32.5702V25.9035C3.64062 24.983 4.38682 24.2368 5.30729 24.2368Z" fill="#008BFF" />
  <path fillRule="evenodd" clipRule="evenodd" d="M19.1299 4.72448C19.7808 4.07361 20.836 4.07361 21.4869 4.72448L29.8203 13.0578C30.4711 13.7087 30.4711 14.764 29.8203 15.4148C29.1694 16.0657 28.1141 16.0657 27.4632 15.4148L20.3084 8.26002L13.1536 15.4148C12.5027 16.0657 11.4474 16.0657 10.7966 15.4148C10.1457 14.764 10.1457 13.7087 10.7966 13.0578L19.1299 4.72448Z" fill="#008BFF" />
  <path fillRule="evenodd" clipRule="evenodd" d="M20.3073 4.23633C21.2278 4.23633 21.974 4.98252 21.974 5.90299V25.903C21.974 26.8235 21.2278 27.5697 20.3073 27.5697C19.3868 27.5697 18.6406 26.8235 18.6406 25.903V5.90299C18.6406 4.98252 19.3868 4.23633 20.3073 4.23633Z" fill="#008BFF" />
</svg>;


const UploadStyled = styled.div`
display: inline-flex;
height: 220px;
padding: 12px 8px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8.12px;
flex-shrink: 0;
border-radius: 8.12px;
background: #FFF;
width: 324px;
.title-upload-text {
  color: #1D2129;
  text-align: center;

  /* Content/Content 1 - 16 regular - 24 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-upload-wrapper {
  height: 100%;
  .ant-upload.ant-upload-select {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 8.12px;
    overflow: hidden;
    border: 1.5px dashed #86909C;
    background: #F6F7FC;
    border-radius: 3.953px;
    .image-upload-preview {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-trash {
        position: absolute;

      }
    }
  }
}
`;