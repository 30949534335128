
import { Col, Form, Input, Row, Select, Tabs, message, Breadcrumb, Switch } from 'antd';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import accountApis from '../../services/accountApis';
import { LeftOutlined } from '@ant-design/icons';
import { SaveOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import { PageStyled } from '../styles';
import Text from '../../components/Text';
import giftCardApis from '../../services/giftCardApis';
import Modal from '../../components/Modal';
import { ColumnsType } from 'antd/es/table';
import { TableEvenOdd } from './styles';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import Swal from 'sweetalert2';

const { Option } = Select;
export const SalonCreditCardPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tabDefaulf, setTabDefaulf] = useState<any>('SALON_INFORMATION');
    const onChange = (key: string) => {
        setTabDefaulf(key);
    };
    const { id } = useParams();
    const [shopDate, setShopDate] = useState<any>(null);

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !shopDate) {
                    const response = await accountApis.getShopById(id);
                    if (response.status === 200) {
                        setShopDate(response.data.data);
                        dispatch(setShopState(response.data.data));
                    }
                }
                const tabFromHash = window.location.hash.substring(1);
                if (tabFromHash) {
                    onChange(tabFromHash);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    return (
        <PageStyled>
            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate('/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>
            <ContentStyled>
                <Tabs
                    className='tab-credit-card'
                    tabPosition={'left'}
                    items={[
                        {
                            label: 'Fee',
                            key: '1',
                            children: <CREDIT_CARD_Fee />,
                        },
                        {
                            label: 'Payment',
                            key: '2',
                            children: <CREDIT_CARD_Payment />,
                        }
                    ]}
                />
            </ContentStyled>
        </PageStyled>
    );
}

export const CREDIT_CARD_Fee = () => {
    const dispatch = useDispatch();
    const [formFee] = Form.useForm();
    const { id } = useParams();
    const [cardFeeData, setCardFeeData] = useState<any>();
    const [enable, setEnable] = useState<boolean>(false);
    const handleSwitchChange = (checked: boolean) => {
        setEnable(checked);
    };

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                if (id) {
                    const response = await giftCardApis.getCardFeeData(id);
                    if (response.status === 200) {
                        setCardFeeData(response.data.data);
                        handleSwitchChange(response.data.data.enable);
                        formFee.setFieldsValue({
                            enable: response.data.data.enable,
                            feeType: response.data.data.feeType,
                            feeValue: response.data.data.feeValue,
                            tipFeeType: response.data.data.tipFeeType,
                            tipFeeValue: response.data.data.tipFeeValue
                        });
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const onSubmit = async (values: any) => {
        try {
            dispatch(setLoading(true));
            let feeValue = values.feeValue;
            let tipFeeType = values.tipFeeType;
            if (enable === false) {
                feeValue = 0;
                tipFeeType = 0;
            }
            const response = await giftCardApis.upodateCardFeeData(cardFeeData.id, id, enable, values.feeType, feeValue,
                tipFeeType, values.tipFeeValue);
            if (response.status === 200) {
                message.success('Update successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }

        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ContentCreditStyled>
            <Text variant="H7">Fee</Text>
            <Form form={formFee}
                layout="vertical" style={{ marginTop: '10px' }}
                onFinish={onSubmit}>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Box style={{ display: 'flex' }}>
                            {cardFeeData && (
                                <Form.Item name="enable" valuePropName="checked" style={{ marginBottom: '10px' }}>
                                    <Switch title='Have Fee'
                                        defaultChecked={cardFeeData.enable}
                                        onChange={handleSwitchChange}
                                        checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                    <span style={{ fontSize: '16px', marginLeft: '10px' }}>Have Fee</span>
                                </Form.Item>
                            )}
                        </Box>
                    </Col>
                </Row>
                {enable && (
                    <Fragment>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="feeType" label="Type Fee Credit Card" style={{ marginRight: '20px' }}>
                                    <Select placeholder='Select'>
                                        <Option value='MONEY'>Dollar</Option>
                                        <Option value='PERCENT'>Percent</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="tipFeeType" label="Type Fee Tip Credit Card">
                                    <Select placeholder='Select'>
                                        <Option value='MONEY'>Dollar</Option>
                                        <Option value='PERCENT'>Percent</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="feeValue" label="Fee Credit Card" style={{ marginRight: '20px' }}
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item name="tipFeeValue" label="Fee Tip Credit Card"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}>
                                    <Input size='large' autoComplete='off' placeholder='Please enter...' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Fragment>

                )}
                <Box style={{ textAlign: 'right' }}>
                    <Button variant="PRIMARY" onClick={() => formFee.submit()}>
                        <SaveOutlined style={{ marginRight: '5px' }} />
                        Save
                    </Button>
                </Box>
            </Form>
        </ContentCreditStyled>
    );
};

export const CREDIT_CARD_Payment = () => {
    const { id } = useParams();
    const [lstData, setLstData] = useState<any[]>([]);
    const [formCreatePayment] = Form.useForm();
    const [formEditPayment] = Form.useForm();
    const [lstStation, setLstStation] = useState<any[]>([]);
    const dispatch = useDispatch();

    const getDataList = async (shopId: any) => {
        try {
            const response = await giftCardApis.getListDataPayment(shopId);
            if (response.status === 200) {
                setLstData(response.data.data);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        dispatch(setLoading(true));
        const initData = async () => {
            try {
                if (id) {
                    await getDataList(id);
                    const response = await giftCardApis.getListStation(id);
                    if (response.status === 200) {
                        setLstStation(response.data.data);
                    } else {
                        message.error({ content: response.data.message, duration: 2 });
                    }
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                dispatch(setLoading(false)); // Set loading to false regardless of success or failure
            }
        };
        initData();
    }, []);

    const handleChangeOfflineMode = (record: any): SwitchChangeEventHandler => async (value) => {
        const result = await Swal.fire({
            title: 'Confirm update',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (!result.isConfirmed) return;
        dispatch(setLoading(true));
        try {
            const res = await accountApis.creditCardUpdateOfflineMode(record.id, value);
            if (res.data.data) {
                const temp = [...lstData];
                const idx = temp.findIndex(o => o.id === res.data.data.id);
                if (idx !== -1) {
                    temp[idx] = res.data.data;
                    setLstData(temp);
                }
            }
        } catch (error: any) {
            message.error(error?.message || '');
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>STATION</p>,
            dataIndex: 'stationName',
            className: 'stationName',
            render: (stationNumber: string) =>
                <span>{stationNumber ? 'Station ' + stationNumber : '--'}</span>,
        },
        {
            title: <p>DEVICE ID</p>,
            dataIndex: 'deviceId',
            className: 'deviceId',
            render: (deviceId: string) =>
                <span>{deviceId ? deviceId : '--'}</span>,
        },
        {
            title: <p>PAYMENT TYPE</p>,
            dataIndex: 'sourceType',
            className: 'sourceType',
            render: (sourceType: string) =>
                <span>{sourceType ? (sourceType === 'DE_JA_VOO' ? 'Dejavoo' : sourceType) : '--'}</span>,
        },
        {
            title: <p>AUTH KEY</p>,
            dataIndex: 'authKey',
            className: 'authKey',
            render: (authKey: string) =>
                <span>{authKey ? authKey : '--'}</span>,
        },
        {
            title: <p>TPN</p>,
            dataIndex: 'tpnAccount',
            className: 'tpnAccount',
            render: (tpnAccount: string) =>
                <span>{tpnAccount ? tpnAccount : '--'}</span>,
        },
        {
            title: <p>OFFLINE MODE</p>,
            dataIndex: 'offlineMode',
            width: '10rem',
            className: 'offlineMode',
            render: (offlineMode: boolean, record: any) => (
                <Switch
                    checkedChildren={'ON'} unCheckedChildren={'OFF'}
                    defaultChecked={offlineMode}
                    checked={offlineMode}
                    onChange={handleChangeOfflineMode(record)}
                />
            )
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            render: (item: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={item.couponId}
                                onChange={(checked) => handleSwitchChange(checked, item.id)}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                defaultChecked={item.active} />
                            <Button className='btn-edit' icon="edit" onClick={() => showEditPaymentMethod(item.id)}></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const hideModal = () => {
        setIsModalVisible(false);
        setIsModalVisibleEdit(false);
        formCreatePayment.resetFields();
        formEditPayment.resetFields();
    };

    const handleSwitchChange = async (checked: any, itemId: any) => {
        try {
            const response = await giftCardApis.updateStatusPaymentMethod(id, itemId, checked);
            if (response.status === 200) {
                message.success('Update successful');
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const showEditPaymentMethod = async (id: any) => {
        try {
            dispatch(setLoading(true));
            const response = await giftCardApis.getDetailPaymentMethod(id);
            if (response.status === 200) {
                setEditTitle(`Edit ${response.data.data.stationName}`);
                formEditPayment.setFieldsValue({
                    sourceType: response.data.data.sourceType,
                    tpn: response.data.data.tpnAccount,
                    authKey: response.data.data.authKey,
                    registerKey: response.data.data.registerId,
                    token: response.data.data.iposToken,
                    id: response.data.data.id,
                    stationId: response.data.data.stationId,
                    active: response.data.data.active,
                    enableGiftOnline: response.data.data.enableGiftOnline
                });
                setIsModalVisibleEdit(true);
                handleEnableGiftOnlineChange(response.data.data.enableGiftOnline);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        } finally {
            dispatch(setLoading(false));

        }
    };

    const onFinishCreate = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const response = await giftCardApis.createOrUpdatePaymentMethod(id, null, values.stationId, values.sourceType, values.tpn, values.authKey, values.registerKey, values.token, true, values.enableGiftOnline);
            if (response.status === 200) {
                await getDataList(id);
                message.success('Create successful');
                hideModal();
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false)); // Set loading to false regardless of success or failure
        }
    };

    const onFinishUpdate = async (values: any) => {
        try {
            dispatch(setLoading(true));
            const response = await giftCardApis.createOrUpdatePaymentMethod(id, values.id,
                values.stationId, values.sourceType, values.tpn, values.authKey, values.registerKey, values.token, values.active, values.enableGiftOnline);
            if (response.status === 200) {
                await getDataList(id);
                message.success('update successful');
                hideModal();
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false)); // Set loading to false regardless of success or failure
        }
    };
    const [enableGiftOnline, setEnableGiftOnline] = useState(false);
    const handleEnableGiftOnlineChange = (checked: boolean) => {
        setEnableGiftOnline(checked);
    };
    return (
        <ContentCreditStyled>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={isModalVisible}
                onCancel={hideModal}
                onOk={() => formCreatePayment.submit()}
                title={'Add New'}
                closeIcon={null}
                width={600}
                centered>
                <Form form={formCreatePayment}
                    layout="vertical"
                    onFinish={onFinishCreate}>
                    <Form.Item name="stationId" label="Station" style={{ marginRight: '20px', marginLeft: '20px' }}>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            {lstStation.map((item, index) => (
                                <Option key={index} value={item.id} label={`Station ${item.stationNumber}`}>
                                    {`Station ${item.stationNumber}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="sourceType" label="Payment Type" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            <Option key='DE_JA_VOO' value='DE_JA_VOO' label='Dejavoo'>
                                Dejavoo
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="tpn" label="TPN" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="registerKey" label="Register Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 12 }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="authKey" label="Auth Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 10 },
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="enableGiftOnline" label="Enable Gift Online" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                <Switch
                                    defaultChecked={false}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={handleEnableGiftOnlineChange} />
                            </Form.Item>
                        </Col>
                        {enableGiftOnline && (
                            <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                <Form.Item name="token" label="Token" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal>

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                open={isModalVisibleEdit}
                onCancel={hideModal}
                onOk={() => formEditPayment.submit()}
                title={editTitle}
                closeIcon={null}
                width={600}
                centered>
                <Form form={formEditPayment}
                    layout="vertical"
                    onFinish={onFinishUpdate}>

                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input type='hidden' />
                    </Form.Item>

                    <Form.Item name="active" style={{ display: 'none' }}>
                        <Input type='hidden' />
                    </Form.Item>

                    <Form.Item name="stationId" label="Station" style={{ marginRight: '20px', marginLeft: '20px' }}>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            {lstStation.map((item, index) => (
                                <Option key={index} value={item.id} label={`Station ${item.stationNumber}`}>
                                    {`Station ${item.stationNumber}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="sourceType" label="Payment Type" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Select size='large' showSearch placeholder="Select" optionFilterProp="label">
                            <Option key='DE_JA_VOO' value='DE_JA_VOO' label='Dejavoo'>
                                Dejavoo
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name="tpn" label="TPN" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="registerKey" label="Register Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 12 }
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item name="authKey" label="Auth Key" style={{ marginRight: '20px', marginLeft: '20px' }}
                        rules={
                            [
                                { required: true },
                                { max: 10 },
                            ]
                        }>
                        <Input size='large' />
                    </Form.Item>

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="enableGiftOnline" label="Enable Gift Online" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                <Switch
                                    defaultChecked={enableGiftOnline}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={handleEnableGiftOnlineChange} />
                            </Form.Item>
                        </Col>
                        {enableGiftOnline && (
                            <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                <Form.Item name="token" label="Token" style={{ marginRight: '20px', marginLeft: '20px' }}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal>

            <Box className="d-between">
                <Text variant="H7">Payment</Text>
                <Box display="flex" gap={1}>
                    <Button variant="SECONDARY" icon='add' onClick={() => setIsModalVisible(true)}>
                        Add New
                    </Button>
                </Box>
            </Box>
            <Box mt={1}>
                <TableContentStyled
                    className='custom-th'
                    columns={columns}
                    dataSource={lstData}
                    rowKey="couponId"
                    pagination={false} />
            </Box>
        </ContentCreditStyled>
    );
};

const ContentCreditStyled = styled(Box)`
    background: rgba(246, 247, 252, 1);
    padding: 8px 24px;
    
`;

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;

    .tab-credit-card .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 18px;
    }

    .tab-credit-card .ant-tabs-nav {
        background: rgba(246, 247, 252, 1);
        width: 235px;
        min-height: 500px;
    }

    .tab-credit-card .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
    }

    .tab-credit-card .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    .tab-marketing-child .ant-tabs-tab {
        min-width: 120px !important;
    }

    .tab-marketing-child .ant-tabs-tab .ant-tabs-tab-btn {
        line-height: 1;
    }

    .tab-marketing-child .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
        min-width: 150px;
        padding: 8px 24px;
        text-align: center;
    }

    .tab-marketing-child .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    && .btn-delete, .btn-edit {
        background: transparent;
        border: none;
        padding: 8px;
      }
    
    && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
    }

    && .coupon-label-status {
        border-radius: 2px;
        color: #1d2129;
        padding: 5px 20px 5px 20px;
    }
`;

const TableContentStyled = styled(TableEvenOdd)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell.offlineMode {
    text-align: center;
  }
`;