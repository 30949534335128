import { Button, Form, FormInstance, Pagination, Spin, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { currencyFormat, formatNumber } from '../../helpers/FunctionUtils';
import accountApis from '../../services/accountApis';
import reportApis from '../../services/reportApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import { TableEvenOdd } from '../account/styles';
type IFormSearchValues = {
  endDate: Dayjs;
  startDate: Dayjs;
  shopId: string;
};
const ITEMS_PER_PAGE = 10;
const FORMAT_DATE = 'MM-DD-YYYY';

interface IReportSummaryItem {
  shopId: string;
  businessName: string;
  email: string;
  transactions: number;
  incomes: number;
  creditCards: number;
  cash: number;
  giftCards: number;
  loyaltyRedeems: number;
  totalOther: number;
}
type Ref = {
  init: (startDate: Dayjs, endDate: Dayjs) => void;
  search: (values: IFormSearchValues) => void;
};
type Props = {
  form: FormInstance<IFormSearchValues>;
};
export const useReportSummaryAdminRef = () => useRef<Ref>(null);
const ReportSummaryAdmin = forwardRef<Ref, Props>(({ form }, ref) => {
  const [loading, setTableLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listReportSummary, setLstReportSummary] = useState<IReportSummaryItem[]>([]);
  const [totalItems, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getReportSummary = async (page: number, shopId: string | null, startDate: string, endDate: string) => {
    try {
      const response = await reportApis.reportSummary(shopId, startDate, endDate, page, ITEMS_PER_PAGE);
      if (response.status === 200) {
        setLstReportSummary(response.data.data.content);
        setTotal(response.data.data.totalElements);
      } else {
        message.error({ content: response.data.message, duration: 2 });
      }
    } catch (error: any) {
      message.error({ content: error.message as string, duration: 2 });
    }
  };

  const onPageChanged = async (pageNumber: number) => {
    dispatch(setLoading(true));
    try {
      setCurrentPage(pageNumber);
      const values = await form.validateFields();
      await getReportSummary(pageNumber, values.shopId, values.startDate?.format(FORMAT_DATE), values.endDate?.format(FORMAT_DATE) || '');
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 2 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFinishFormSearch = async (values: IFormSearchValues) => {
    setTableLoading(true);
    try {
      setCurrentPage(1);
      await getReportSummary(1, values.shopId, values.startDate?.format(FORMAT_DATE), values.endDate?.format(FORMAT_DATE) || '');
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  };

  const handleViewDetail = (id: string) => async () => {
    dispatch(setLoading(true));
    try {
      const response = await accountApis.getShopById(id);
      if (response.status === 200) {
        dispatch(setShopState(response.data.data));
        navigate(`/salon/${id}`, { state: { path: '/report' } });
      } else {
        message.error({ content: response.data.message, duration: 5 });
      }
    } catch (error) {
      message.error({ content: 'Page has been deleted', duration: 5 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const initData = async (startDate: Dayjs, endDate: Dayjs) => {
    console.log('initData 123');
    
    setTableLoading(true);
    try {
      await getReportSummary(currentPage, null, startDate.format(FORMAT_DATE), endDate.format(FORMAT_DATE));
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setTableLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    init: initData,
    search: handleFinishFormSearch,
  }))

  const columns: ColumnsType<any> = [
    {
      title: <span>Business name</span>,
      dataIndex: 'businessName',
      render: (businessName: any, record: IReportSummaryItem) =>
        <Button type='link' onClick={handleViewDetail(record.shopId)}>{businessName}</Button>,
    },
    {
      title: <span>Email</span>,
      dataIndex: 'email',
      render: (email: any, record: IReportSummaryItem) =>
        <Button type='link' onClick={handleViewDetail(record.shopId)}>{email}</Button>,
    },
    {
      title: <span>Transactions</span>,
      dataIndex: 'transactions',
      render: (transactions: any) => <span>{formatNumber(transactions)}</span>,
    },
    {
      title: <span>Incomes</span>,
      dataIndex: 'incomes',
      render: (incomes: any) => <span>{currencyFormat(incomes, 'USD')}</span>,
    },
    {
      title: <span>Credit cards</span>,
      dataIndex: 'creditCards',
      render: (creditCards: any) => <span> {currencyFormat(creditCards, 'USD')}</span>,
    },
    {
      title: <span>Cash</span>,
      dataIndex: 'cash',
      render: (cash: any) => <span> {currencyFormat(cash, 'USD')}</span>,
    },
    {
      title: <span>Gift cards</span>,
      dataIndex: 'giftCards',
      render: (giftCards: any) => <span> {currencyFormat(giftCards, 'USD')}</span>,
    },
    {
      title: <span>Loyalty redeems</span>,
      dataIndex: 'loyaltyRedeems',
      render: (loyaltyRedeems: any) => <span> {currencyFormat(loyaltyRedeems, 'USD')}</span>,
    },
    {
      title: <span>Others</span>,
      dataIndex: 'totalOther',
      render: (totalOther: any) => <span> {currencyFormat(totalOther, 'USD')}</span>,
    },
  ];

  return (
    <>
      <TableContentStyled
        columns={columns}
        dataSource={listReportSummary}
        rowKey="email"
        bordered
        loading={loading}
        pagination={false} />

      <PagingStyled>
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={ITEMS_PER_PAGE}
          onChange={onPageChanged}
          showSizeChanger={false}
        />
      </PagingStyled>
    </>
  )
})
ReportSummaryAdmin.displayName = 'ReportSummaryAdminRef';
export default ReportSummaryAdmin;

const TableContentStyled = styled(TableEvenOdd)`
margin-top: 16px;
    && .status {
        text-align: center;
    }

thead.ant-table-thead th.ant-table-cell span {
  text-transform: uppercase;
}
`;

const PagingStyled = styled(Content)`
    text-align: right;
    margin-top: 10px;
`;
