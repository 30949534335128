import { DatePicker, Form, Input } from 'antd';
import styled from 'styled-components';
import SearchInputIcon from '../../../components/Icon/icons/SearchInputIcon';
import userSelectors from './selectors';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { FORMAT_DATE_REQUEST } from '../../../store/banlist/constants';
const UsersTableFilter = () => {
  const form = Form.useFormInstance();
  const params = userSelectors.params();

  useEffect(() => {
    form.setFieldsValue({
      startDate: params.startDate ? dayjs(params.startDate, FORMAT_DATE_REQUEST) : null,
      endDate: params.endDate ? dayjs(params.endDate, FORMAT_DATE_REQUEST) : null,
      keyword: params.keySearch || '',
    });
  }, [params]);

  return (
    <UsersTableFilterStyled>
      <div>
        <FormStyled>
          <Form.Item noStyle name={'keyword'}>
            <Input
              prefix={<SearchInputIcon />}
              placeholder='Customer ban name or phone'
            />
          </Form.Item>
          <Form.Item noStyle name="startDate">
            <DatePicker
              style={{ width: '50%' }}
              format="MM-DD-YYYY"
              showTime={false}
              placeholder='Start date'
              inputReadOnly={true} />
          </Form.Item>
          <Form.Item noStyle name="endDate">
            <DatePicker
              style={{ width: '50%' }}
              format="MM-DD-YYYY"
              showTime={false}
              placeholder='End date'
              inputReadOnly={true}
            />
          </Form.Item>
          <button className='btn btn-search' type='submit'>
            <SearchInputIcon fill='#fff' />
            <span>Search</span>
          </button>
        </FormStyled>
      </div>
    </UsersTableFilterStyled>
  )
}

export default UsersTableFilter;

const UsersTableFilterStyled = styled.div`
  button.btn {
    display: flex;
    height: 40px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 24.75px */
    }
    &.btn-search {
      background: #008BFF;
      span {
        color: #FFF;
      }
    }
    &.btn-delete {
      border: 1px solid #86909C;
      background: #E3E9ED;
      span {
        color: #566A7F;
      }
    }
    &.btn-block-account {
      background: #FF7D00;
      span {
        color: #FFF;
        white-space: nowrap;
      }
    }
  }
`;
const FormStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .ant-input-affix-wrapper, .ant-picker {
    padding: 4px 11px;
    height: 40px;
  }
`;
