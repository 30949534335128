import { Avatar, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useThunkDispatch } from '../../../store';
import banlistActions from '../../../store/banlist/actions';
import { banlistUIActions } from '../../../store/banlist/reducer';
import { IBanlistBlogReportItem } from '../../../store/banlist/types/blog';
import { formatPhone } from '../../../utils/formatPhone';
import { TableContentStyled } from './BlogsTableData';
import blogSelectors from './selectors';
import UserIcon from '../../../components/Icon/icons/banlist/UserIcon';
export const useDetailBlogReports = () => {
  const dispatch = useThunkDispatch();
  return (blogId: string) => dispatch(banlistActions.blog.getReports(blogId));
};

const DetailBlogReports = () => {
  const dispatch = useThunkDispatch();
  const { openReport, reports = [] } = blogSelectors.blogExtraState();

  const onClose = () => dispatch(banlistUIActions.closeReportDetail());

  const columns: ColumnsType<IBanlistBlogReportItem> = [
    {
      title: 'ACCOUNT',
      dataIndex: 'name',
      className: 'account',
      width: '12rem',
      render: (accountName, data) =>
        <div className='account-container'>
          <div>
            <Avatar size={'small'} src={data.avatar || 'fail'} >
              <UserIcon />
            </Avatar>
          </div>
          <div className='account-name'>{accountName ? accountName : '--'}</div>
        </div>,
    },
    {
      title: 'BUSINESS NAME',
      dataIndex: 'businessName',
      className: 'customerBan',
      // render: (customerName, data) =>
      //   <div className='customer-container'>
      //     <div className='customer-name'>{customerName}</div>
      //     <div className='customer-phone'>{formatPhone(data.customerPhone)}</div>
      //   </div>,
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      className: 'note-col',
      render: (note) =>
        <div className='note-text'>{note ? formatPhone(note) : '--'}</div>,
    },
    {
      title: 'REASON',
      dataIndex: 'note',
      className: 'note-col',
      render: (note) =>
        <div className='note-text'>{note || '--'}</div>,
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'createdDate',
      className: 'dateTime',
      render: (text) =>
        <span>{text ? dayjs(text).format('MM-DD-YYYY') : '--'}</span>,
    },
  ];

  if (!openReport) return null;
  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      forceRender
      title="Report"
      width={1000}
    >
      <TableContentStyled
        //@ts-ignore
        columns={columns}
        dataSource={reports}
        rowKey={'id'}
        pagination={false}
      />
    </Modal>
  )
}

export default DetailBlogReports;
