import BlogsTable from './BlogsTable';
import Dashboard from './Dashboard';

function BanlistBlogs() {
  return (
    <div>
      <Dashboard />
      <BlogsTable />
    </div>
  )
}

export default BanlistBlogs;
