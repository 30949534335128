
import { styled } from 'styled-components';
import Button from '../components/Button';

export const PageStyled = styled.div`
  padding: 1.5rem;
  .title-tab {
    color: #1D2129;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ButtonDeleteStyled = styled(Button)`
  font-size: 14px;
  height: 35px;
  width: 100%;
  && svg {
    width: 20px;
    margin-right: 10px;
  }

  &&:hover {
    background: #4096ff !important;
  }
`;
