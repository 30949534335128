import { GetProp, UploadFile, UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import styled from 'styled-components';
import { MessageErrorSizeImageRef } from './MessageErrorSizeImage';
import uploadDragIcon from './upload-drag-icon.png';
import { remove } from 'lodash';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const MAX_SIZE_IMAGE = 3145728;
type BoxChooseImageProps = {
  msgErrorImageRef?: React.RefObject<MessageErrorSizeImageRef>
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
};

const BoxChooseImage = ({ msgErrorImageRef, value, onChange = () => undefined }: BoxChooseImageProps) => {
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <BoxChooseImageStyled>
      <Dragger
        name='file'
        accept="image/png, image/jpeg, video/*"
        beforeUpload={(file) => {
          if ((file.size || 0) > MAX_SIZE_IMAGE) {
            msgErrorImageRef?.current?.open();
            const newFileList = [...value || []];
            remove(newFileList, o => o.uid === file.uid);
            onChange(newFileList);
            return true;
          }
          return false;
        }}
        fileList={value}
        rootClassName={`upload-banlist-images ${!value?.length ? 'blank' : ''}`}
        listType='picture-card'
        onPreview={onPreview}
        maxCount={6}
        onChange={({ fileList: newFileList, file }) => {
          if ((file.size || 0) > MAX_SIZE_IMAGE) {
            msgErrorImageRef?.current?.open();
            return;
          }
          onChange(newFileList);
        }}
      >
        <div className="ant-upload-drag-icon">
          <img src={uploadDragIcon} alt='uploadDragIcon' />
        </div>
        <p className="ant-upload-text">Select or drag and drop an image</p>
        <p className="ant-upload-hint">High resolution images (PNG, JPG, JPEG). Max 3MB each image</p>
      </Dragger>
    </BoxChooseImageStyled >
  );
};
export default BoxChooseImage;

const BoxChooseImageStyled = styled.div`
  width: 350px;
  .upload-banlist-images {
    display: flex;
    flex-direction: column-reverse;

    .ant-upload-list.ant-upload-list-picture-card {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 12px;
      margin: 16px 0;
      &::before {
        content: none;
      }
      .ant-upload-list-item {
        padding: 0;
        overflow: hidden;
        border-radius: 0;
        &::before {
          height: 100%;
          width: 100%;
        } 
      }
      .ant-upload-wrapper.ant-upload-picture-card-wrapper,.ant-upload-list.ant-upload-list-picture-card, .ant-upload-list-item-container {
        height: unset;
        width: unset;
        margin:0;
      }
    }

    .ant-upload.ant-upload-drag {
      width: 216px;
      border-radius: 5px;
      border: 1px dashed #006BFF;
      opacity: 0.5;
      background: #F2F3F8;
      padding: 8px;
    }
  
    .ant-upload-drag-icon {
      img {
        width: 48px;
        height: 51.881px;
        flex-shrink: 0;
      }
    }
  
    .ant-upload-text {
      color: #0B3558;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .ant-upload-hint {
      color: #476788;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.blank {
      .ant-upload.ant-upload-drag {
        width: 100%;
        border-radius: 5px;
        background: #F2F3F8;
        padding: 8px;
        opacity: 1;
        height: 311px;
      }
    }
  }  
`;
