
import { Avatar, Form, Pagination, Row, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Box from '../../components/Box';
import Text from '../../components/Text';
import accountApis from '../../services/accountApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import { formatPhone } from '../../utils/formatPhone';
import { PageStyled } from '../styles';
import { TableEvenOdd } from './styles';
interface IStaffItemData {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    staffType: string;
    createdDate: string;
    birthday: string | null;
    staffSalaryType: string | null;
    status: string;
    urlImage: string;
    isSelected?: boolean;
    isBusy?: boolean;
    value?: string;
    label?: string;
    anyBody: boolean;
}
const columns: ColumnsType<IStaffItemData> = [
    {
        title: 'First name',
        dataIndex: 'firstName',
        key: 'firstName',
        align: 'left',
        render: (text, record) => (
            <Row align={'middle'} justify={'start'} style={{ gap: '10px' }} >
                <Avatar size={'small'} src={record.urlImage} style={{ backgroundColor: '#f56a00' }}>{text?.[0] || 'A'}</Avatar>
                <Text variant="BODY_1" color="text_3" textAlign="left">
                    {text}
                </Text>
            </Row>

        ),
    },
    {
        title: 'Last name',
        dataIndex: 'lastName',
        key: 'lastName',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {text}
            </Text>
        ),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {text || '--'}
            </Text>
        ),
    },
    {
        title: 'Pin Code',
        dataIndex: 'password',
        key: 'password',
        align: 'center',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="center">
                {text}
            </Text>
        ),
    },
    {
        title: 'DOB',
        dataIndex: 'birthday',
        key: 'DOB',
        align: 'left',
        render: (text) => {
            const date = dayjs(text);
            return (
                <Text variant="BODY_1" color="text_3" textAlign="left">
                    {date.isValid() ? date.format('MM/DD') : '--'}
                </Text>
            );
        },
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {formatPhone(text)}
            </Text>
        ),
    },
    {
        title: 'Salary',
        dataIndex: 'staffSalaryType',
        key: 'salary',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {text || '--'}
            </Text>
        ),
    },
    {
        title: 'Position',
        dataIndex: 'staffType',
        key: 'position',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {getStaffPositionLabel(text)}
            </Text>
        ),
    },
    {
        title: 'Create Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'left',
        render: (text) => (
            <Text variant="BODY_1" color="text_3" textAlign="left">
                {dayjs(text).format('MM-DD-YYYY')}
            </Text>
        ),
    },
];

export const SalonEmployeesPage = () => {
    const navigate = useNavigate();
    const [formSearch] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [lstGroupByShopId, setLstGroupByShopId] = useState<any>(null);
    const [data, setData] = useState<IStaffItemData[]>([]);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getDataCustomer = async (shopId: any, page: number) => {
        try {
            const response = await accountApis.getAllStaff(
                shopId, page,
            );
            if (response.status === 200) {
                setData(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !lstGroupByShopId) {
                    dispatch(setLoading(true));
                    const response = await accountApis.allCustomerGroupListByShopId(id);
                    if (response.status === 200) {
                        setLstGroupByShopId(response.data.data);
                    }
                    await getDataCustomer(id, currentPage);

                    const resShopById = await accountApis.getShopById(id);
                    if (resShopById.status === 200) {
                        dispatch(setShopState(resShopById.data.data));
                    }
                    dispatch(setLoading(false));
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataCustomer(id, pageNumber);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <PageStyled>
            <ContentStyled>
                <Box className="d-between" mb={2}>
                    <Text variant="H5">Employee List ({totalItems})</Text>
                </Box>
                <TableContentStyled
                    // @ts-ignore
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    pagination={false} />

                <PagingStyled>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={20}
                        onChange={onPageChanged}
                        showSizeChanger={false}
                    />
                </PagingStyled>
            </ContentStyled>


        </PageStyled>
    );
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;
`;

const TableContentStyled = styled(TableEvenOdd)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
`;

enum STAFF_POSITION_INFO {
    ADMIN = 'ADMIN',
    TECHNICAL = 'TECHNICAL',
    CASHIER = 'CASHIER',
    MANAGER = 'MANAGER',
}
const getStaffPositionLabel = (val: STAFF_POSITION_INFO) => {
    switch (val) {
        case STAFF_POSITION_INFO.ADMIN: return 'ADMIN';
        case STAFF_POSITION_INFO.CASHIER: return 'CASHIER';
        case STAFF_POSITION_INFO.MANAGER: return 'MANAGER';
        case STAFF_POSITION_INFO.TECHNICAL: return 'TECHNICIAN';
        default: return '';
    }
};
