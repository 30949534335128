import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import usePromotionDashboardContext from './Dashboard';
import { default as usePromotionTableContext } from './Table';

const PromotionsPage = () => {
  const { id } = useParams();
  const [dashboardRef, dashboardContext] = usePromotionDashboardContext();
  const [tableRef, tableContext] = usePromotionTableContext();
  useEffect(() => {
    if (!id) return;
    tableRef.current?.init();
    dashboardRef.current?.init();
  }, []);

  return (
    <Container>
      {dashboardContext}
      {tableContext}
    </Container>
  );
};

export default PromotionsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;