import { EditOutlined } from '@ant-design/icons';
import { Button, Image, Upload, UploadFile, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { debounce } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

const MAX_SIZE_IMAGE = 800000;
type Props = {
  value?: string;
  onSubmit: (value: any) => void;
};
const UpdateBanner = ({ value: imageUrl, onSubmit }: Props) => {
  const [value, onChange] = useState<UploadFile[]>([]);
  const openAlert = debounce(() => {
    notification.error({
      message: 'max size of 800KB',
      duration: 3,
    });
  }, 100);

  const onModalOk = async (_value: any) => {
    onSubmit(_value);
  };

  return (
    <UploadAvatarStyled>
      <ImgCrop
        rotationSlider={false} cropShape='rect'
        showGrid aspectSlider={false} showReset
        onModalOk={onModalOk}
        // @ts-ignore
        cropperProps={{ restrictPosition: false, cropSize: { width: 375, height: 227 } }}

      >
        <Upload
          name='file'
          accept="image/png, image/jpeg"
          listType="picture-card"
          fileList={value}
          showUploadList={false}
          onChange={({ fileList: newFileList, file }) => {
            if ((file.size || 0) > MAX_SIZE_IMAGE) return openAlert();
            onChange(newFileList);
          }}
        >
          <Button ghost type='text' className='btn-avatar-upload'>
            {imageUrl ?
              <>
                <Image
                  preview={false}
                  src={imageUrl}
                  className='image-url'
                />
                <div className="avatar-overlay-icon">
                  <EditOutlined style={{ color: '#fff', transform: 'scale(1.5)' }} />
                </div>
              </> : <EmptyStyled>
                <div className="empty-container">
                  {emptyImage}
                  <p className="empty-text">
                    Drag your banner here to start uploading.
                  </p>
                  <div className="or-text">
                    <span className="divider"></span>
                    <span>OR</span>
                    <span className="divider"></span>
                  </div>
                  <Button type='primary'>Choose Image</Button>
                </div>
              </EmptyStyled>}
          </Button>
        </Upload>
      </ImgCrop>
    </UploadAvatarStyled>
  );
};

export default UpdateBanner;

const UploadAvatarStyled = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  .ant-avatar {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dde6f4;
    .ant-avatar-string {
      transform: scale(1.5) translateY(3px) !important;
    }
  }
  .title {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-upload-wrapper {
    .ant-upload.ant-upload-select {
      border: none;
      border-radius: 8px;
      width: 375px;
      height: 227px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btn-avatar-upload {
    padding: 4px;
    height: unset;
    position: relative;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    background: #fff;
    .image-url {
      width: 375px;
      height: 227px;
      border-radius: 8px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .avatar-overlay-icon {
      border-radius: 8px;
      opacity: 0;
      position: absolute;
      inset: 0;
      background: rgba(0,0,0,0.4);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      .avatar-overlay-icon {
        opacity: 1;
      }
    }
  }
`;


const EmptyStyled = styled.div`
  flex-shrink: 0;
  border-radius: 13.319px;
  background: ##FFF;
  width: 100%;
  height: 100%;
  box-shadow: 0px 17.647px 14.317px 0px rgba(0, 76, 116, 0.10);
  padding: 8px;
  .empty-container {
    border-radius: 8.08px;
    border: 0.808px dashed #C5D1E2;
    background: #F4F8FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 100%;
    padding: 16px 0;
  }
  .empty-text {
    color: #566A7F;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .or-text {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100px;
    .divider {
      background: #8E9BA9;
      height: 1px;
      flex: 1;
    }
    span {
      color: #8E9BA9;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
`;

const emptyImage = <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
  <path d="M33.068 24.3007L28.0637 12.5973C27.1524 10.4549 25.7934 9.23981 24.2426 9.15987C22.7077 9.07993 21.2208 10.1511 20.0856 12.1976L17.0479 17.6496C16.4084 18.8007 15.4971 19.4882 14.5058 19.5682C13.4985 19.6641 12.4912 19.1365 11.6759 18.0973L11.3241 17.6496C10.189 16.2267 8.78201 15.5392 7.34308 15.6831C5.90415 15.827 4.67306 16.8182 3.85767 18.433L1.09172 23.9489C0.100462 25.9474 0.196391 28.2657 1.36352 30.1523C2.53065 32.0389 4.56114 33.1741 6.78349 33.1741H27.1844C29.3268 33.1741 31.3253 32.1029 32.5084 30.3122C33.7235 28.5215 33.9154 26.2672 33.068 24.3007Z" fill="#8E9BA9" />
  <path d="M9.00798 10.8078C11.9925 10.8078 14.4119 8.38841 14.4119 5.40386C14.4119 2.41933 11.9925 -0.00012207 9.00798 -0.00012207C6.02345 -0.00012207 3.604 2.41933 3.604 5.40386C3.604 8.38841 6.02345 10.8078 9.00798 10.8078Z" fill="#8E9BA9" />
</svg>;
