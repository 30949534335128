import { Checkbox, Form, Input, message } from 'antd';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderSignIn from './HeaderSignIn';
import LogoSvg from './LogoSvg';
import MailBoxSvg from './MailBoxSvg';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/loading/actions';
import { changeInfoLogin, login } from '../../../store/account/actions';
import { AppState } from '../../../store';
type IFormLoginValue = {
  'email': string,
  'password': string
}
type IContainerBoxProps = {};
const ContainerBox: React.FC<IContainerBoxProps> = () => {
  const dispatch = useDispatch();
  const [isForgotForm, setIsForgotForm] = useState(false);
  const [finishBox, setFinishBox] = useState(false);
  const [form] = Form.useForm();
  const error = useSelector<AppState>((state) => state.account.error);

  useEffect(() => {
    dispatch(changeInfoLogin());
  }, [dispatch]);

  const doLogin = async (values: IFormLoginValue) => {
    dispatch(setLoading(true));
    const loginResg = login(values.email, values.password);
    await loginResg(dispatch);
    dispatch(setLoading(false));
  };

  const onFinish = (values: IFormLoginValue) => {
    console.log('test', values);
    if (isForgotForm) {
      // call reset api
      setFinishBox(true);
      return;
    }
    doLogin(values);
  };

  const handleClickForgotPassword = () => {
    form.resetFields();
    setIsForgotForm(true);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsForgotForm(false);
  };
  const handleFinishResetMail = () => {
    form.resetFields();
    setFinishBox(false);
    setIsForgotForm(false);
  };

  return (
    <ContainerBoxStyled>
      <HeaderSignIn />

      {finishBox && (
        <Box
          className="d-center"
          flexDirection="column"
          gap={1}
          pt={4}
          pb={1.5}
          px={2}
        >
          <MailBoxSvg />
          <Text variant="BODY_1">Check email for reset link</Text>
          <Button
            onClick={handleFinishResetMail}
            variant="PRIMARY"
            width="100%"
            size="middle"
          >
            OK
          </Button>
        </Box>
      )}
      {!finishBox && (
        <>
          <LogoBoxStyled>
            <Box mb={1.5}>
              <LogoSvg />
            </Box>
            {!isForgotForm && <Text variant="H3">Sign in</Text>}

            {isForgotForm && (
              <>
                <Text variant="H3">Forgot Password</Text>
                <Text variant="BODY_1" color="text_2" px={7} mt={0.75}>
                  We will send you an email that will allow you to reset your
                  password
                </Text>
              </>
            )}
            <>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error.toString()}
                </div>
              )}
            </>
          </LogoBoxStyled>

          <Form form={form} layout="vertical" onFinish={onFinish}>

            <Box px={1.5}>
              <Box mb={1}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                  ]}
                >
                  <Input placeholder="Email"
                    onChange={() => dispatch(changeInfoLogin())}
                    size="large" autoComplete="off" />
                </Form.Item>
              </Box>
              <Box>
                {!isForgotForm && (
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}>
                    <Input.Password
                      size="large"
                      onChange={() => dispatch(changeInfoLogin())}
                      placeholder="Password"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                )}
              </Box>
              {!isForgotForm && (
                <Box className="d-between" mb={1}>
                  <Checkbox>
                    <Text variant="CONTENT_1">Remember me</Text>
                  </Checkbox>
                  <Box onClick={handleClickForgotPassword}>
                    <Text variant="CONTENT_1" color="blue">
                      Forgot Password
                    </Text>
                  </Box>
                </Box>
              )}
              <Box display="flex" width="100%" gap={0.5}>
                {isForgotForm && (
                  <Box width="100%">
                    <Button width="100%" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Box>
                )}
                <Box width="100%">
                  <FormItemNoMarginStyled>
                    <Button
                      variant="PRIMARY"
                      width="100%"
                      htmlType="submit"
                      mb={1.5}
                    >
                      Sign In
                    </Button>
                  </FormItemNoMarginStyled>
                </Box>
              </Box>
            </Box>
          </Form>
        </>
      )}
    </ContainerBoxStyled>
  );
};

export default ContainerBox;
const ContainerBoxStyled = styled.div`
  position: relative;
  border-radius: 20px;
  border: 1px solid var(--line-line-1, #dadada);
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  width: 33.5rem;
  // height: 33.5rem;
  .password-input {
    -webkit-text-security: disc;
    text-security: disc;
  }
`;
const LogoBoxStyled = styled.div`
  text-align: center;
  padding: 3rem 0 0;
`;
const FormItemNoMarginStyled = styled(Form.Item)`
  margin: 0;
`;
