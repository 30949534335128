import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { BanlistState } from "../types";
import actions from '../actions';
import { pagingReducerActions } from "../pagingServices";

const blogCaseHandler = (builder: ActionReducerMapBuilder<BanlistState>) => {
  builder
    .addCase(actions.blog.fetchList.pending, (state, action) => pagingReducerActions.pending(state.blog, action))
    .addCase(actions.blog.fetchList.fulfilled, (state, action) => pagingReducerActions.fulfilled(state.blog, action))
    .addCase(actions.blog.fetchList.rejected, (state) => pagingReducerActions.rejected(state.blog));

  builder
    .addCase(actions.blog.fetchDashboard.fulfilled, (state, action) => {
      state.blogExtra.dashboard = action.payload;
    })
    .addCase(actions.blog.fetchDashboard.rejected, (state) => {
      state.blogExtra.dashboard = null;
    });

  builder
    .addCase(actions.blog.searchCustomers.pending, (state) => {
      state.blogExtra.loadingSearch = true;
      state.blogExtra.customers = [];
    })
    .addCase(actions.blog.searchCustomers.fulfilled, (state, action) => {
      state.blogExtra.loadingSearch = false;
      state.blogExtra.customers = action.payload;
    })
    .addCase(actions.blog.searchCustomers.rejected, (state) => {
      state.blogExtra.loadingSearch = false;
      state.blogExtra.customers = [];
    });

  builder
    .addCase(actions.blog.detail.pending, (state) => {
      state.loading = true;
      state.blogExtra.detail = null;
    })
    .addCase(actions.blog.detail.fulfilled, (state, action) => {
      state.loading = false;
      state.blogExtra.detail = action.payload;
    })
    .addCase(actions.blog.detail.rejected, (state) => {
      state.loading = false;
      state.blogExtra.detail = null;
    })

  builder
    .addCase(actions.blog.getReports.pending, (state) => {
      state.loading = true;
      state.blogExtra.reports = [];
    })
    .addCase(actions.blog.getReports.fulfilled, (state, action) => {
      state.loading = false;
      state.blogExtra.reports = action.payload;
      state.blogExtra.openReport = true;

    })
    .addCase(actions.blog.getReports.rejected, (state) => {
      state.loading = false;
      state.blogExtra.reports = [];
    });
};

export default blogCaseHandler;
