import { AxiosResponse } from 'axios';
import { debounce, get, set } from 'lodash';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import accountApis from '../../../services/accountApis';
import { formatCurrency, formatNumber } from '../../../utils/formatCurrency';
import { PromotionSourceType } from './helpers';
import vipDashboardIcon from './icons/VIP-dashboard.svg';
import calendarDashboardIcon from './icons/calendar-dashboard.svg';
import giftCardDashboardIcon from './icons/giftCard-dashboard.svg';
import shareDashboardIcon from './icons/share-dashboard.svg';
import { IPromotionDashboardResDataItem } from './types';

type DashboardData = Record<PromotionSourceType, IPromotionDashboardResDataItem>;

type Ref = {
  init: () => void;
};
const PromotionDashboard = forwardRef<Ref>(({ }, ref) => {
  const { id: shopId = '' } = useParams();
  const [data, setData] = useState<DashboardData | null>(null);
  const initData = async () => {
    if (!shopId) return;
    try {
      const res: AxiosResponse<{ data: IPromotionDashboardResDataItem[] }> = await accountApis.getPromotionSummary(shopId);
      if (res.data.data) {
        // @ts-ignore
        const _data: DashboardData = {};
        res.data.data.map(o => {
          set(_data, o.type, o);
        });
        setData(_data);
      }
    } catch (error) { }
  };

  useImperativeHandle(ref, () => ({
    init: debounce(initData, 200),
  }));

  return (
    <Container>
      <DashBoardItem data={get(data, PromotionSourceType.GIFT_CARD, null)} type={PromotionSourceType.GIFT_CARD} />
      <DashBoardItem data={get(data, PromotionSourceType.APPOINTMENT, null)} type={PromotionSourceType.APPOINTMENT} />
      <DashBoardItem data={get(data, PromotionSourceType.VIP, null)} type={PromotionSourceType.VIP} />
      <DashBoardItem data={get(data, PromotionSourceType.SHARING, null)} type={PromotionSourceType.SHARING} />
    </Container>
  );
});

PromotionDashboard.displayName = 'AccountPromotionDashboard';

const usePromotionDashboardContext = (): [React.RefObject<Ref>, JSX.Element] => {
  const ref = useRef<Ref>(null);
  const component = <PromotionDashboard ref={ref} />;
  return [ref, component];
};

export default usePromotionDashboardContext;

const Container = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`;

const DashBoardConfigs = {
  [PromotionSourceType.APPOINTMENT]: { label: 'E-GIFT', icon: calendarDashboardIcon },
  [PromotionSourceType.GIFT_CARD]: { label: 'APPOINTMENT', icon: giftCardDashboardIcon },
  [PromotionSourceType.SHARING]: { label: 'VIP', icon: shareDashboardIcon },
  [PromotionSourceType.VIP]: { label: 'SHARING', icon: vipDashboardIcon },
};

type DashBoardItemProps = {
  type: PromotionSourceType;
  data: IPromotionDashboardResDataItem | null;
};
const DashBoardItem = ({ type, data }: DashBoardItemProps) => {
  const configs = useMemo(() => {
    return get(DashBoardConfigs, type || '') || null;
  }, [type]);
  if (!configs) return null;
  return (
    <DashBoardItemStyled className={type}>
      <div className="top-content">
        <div>
          <div className="title-dashboard">{configs.label}</div>
          <div className="value-dashboard">{formatNumber(data?.total || 0)}</div>
        </div>
        <div className="icon-dashboard-container">
          <img src={configs.icon} className="icon-dashboard" />
        </div>
      </div>
      <div className="bottom-content">
        <div className="bottom-content-item"><span>Used: {formatNumber(data?.used || 0)}</span></div>
        <div className="bottom-content-item"><span>Profit: {formatCurrency(data?.profit || 0)}</span></div>
      </div>
    </DashBoardItemStyled>
  );
};
const DashBoardItemStyled = styled.div`
display: flex;
flex-direction: column;
height: 123px;
padding: 12px 16px;
justify-content: space-between;
align-items: flex-start;
border-radius: 5px;
background: #F96969;

.top-content {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  .title-dashboard, .value-dashboard {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  .value-dashboard {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .icon-dashboard-container {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    background-color: rgba(255,255,255,0.4);
  }

  .icon-dashboard {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 3.2px 0px 2.918px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}

.bottom-content {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .bottom-content-item {
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    height: 25px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.60);

    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}


&.${PromotionSourceType.APPOINTMENT} {
  background: #347AB6;
}
&.${PromotionSourceType.GIFT_CARD} {
  background: #F96969;
}
&.${PromotionSourceType.SHARING} {
  background: #8968D1;
}
&.${PromotionSourceType.VIP} {
  background: #FF7D00;
}
`;