import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Select, Tabs, TabsProps, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Box from '../../components/Box';
import Text from '../../components/Text';
import accountApis from '../../services/accountApis';
import { setLoading } from '../../store/loading/actions';
import { setShopState } from '../../store/shop/actions';
import ReportSMSAdmin, { useReportSMSAdminRef } from './ReportSMSAdmin';
import ReportSummaryAdmin, { useReportSummaryAdminRef } from './ReportSummaryAdmin';
type IFormSearchValues = {
  endDate: Dayjs;
  startDate: Dayjs;
  shopId: string;
};

const ReportAdminPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<IFormSearchValues>();
  const [listSalon, setLstSalon] = useState<any[]>([]);
  const summaryRef = useReportSummaryAdminRef();
  const smsRef = useReportSMSAdminRef();
  const [activeKey, setActiveKey] = useState('summary-report');

  useEffect(() => {
    dispatch(setLoading(true));
    const initData = async () => {
      try {
        dispatch(setShopState(null));
        const response = await accountApis.filterSalon(null, null, 1, 10000);
        if (response.status === 200) {
          setLstSalon(response.data.data.content);
        } else {
          message.error({ content: response.data.message, duration: 2 });
        }
        const startDate = dayjs().subtract(7, 'day');
        const endDate = dayjs();
        form.setFieldsValue({ startDate, endDate });
        summaryRef.current?.init(startDate, endDate);
      } catch (error: any) {
        message.error(error.message);
      } finally {
        dispatch(setLoading(false));
      }
    };
    initData();
  }, []);

  const handleFinishFormSearch = async (values: any) => {
    try {
      const params = { shopId: values.shopId, startDate: values.startDate, endDate: values.endDate };
      if (activeKey === 'summary-report') {
        return summaryRef.current?.search(params);
      }
      if (activeKey === 'sms-report') {
        return smsRef.current?.search(params);
      }
    } catch (error) { }
  };

  const onChangeTab = (key: string) => {
    setActiveKey(key);
    const values = form.getFieldsValue();
    const params = { shopId: values.shopId, startDate: values.startDate, endDate: values.endDate };

    if (key === 'summary-report') {
      return summaryRef.current?.search(params);
    }
    if (key === 'sms-report') {
      return smsRef.current?.search(params);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'summary-report',
      label: 'Summary report',
      forceRender: true,
      children: <ReportSummaryAdmin ref={summaryRef} form={form} />,
    },
    {
      key: 'sms-report',
      label: 'SMS Report',
      forceRender: true,
      children: <ReportSMSAdmin ref={smsRef} form={form} />,
    },
  ];


  return (
    <PageStyled>
      <Box style={{ marginBottom: 32 }} className="d-between" alignItems='center'>
        <Text variant="H5">Report</Text>
        <FormStyled form={form} name="search" onFinish={handleFinishFormSearch}>
          <Form.Item
            name="startDate">
            <DatePicker
              style={{ width: '100%' }}
              size="large"
              format="MM-DD-YYYY"
              showTime={false}
              inputReadOnly={true} />
          </Form.Item>
          <Form.Item
            name="endDate">
            <DatePicker
              style={{ width: '100%' }}
              size="large"
              format="MM-DD-YYYY"
              showTime={false}
              inputReadOnly={true}
            />
          </Form.Item>
          <Form.Item
            name="shopId">
            <Select size='large' defaultValue={''} style={{ width: '100%' }} showSearch optionFilterProp="label">
              <Select.Option value={''} label={'All Salon - Zipcode'}>
                All Salon - Zipcode
              </Select.Option>
              {listSalon.map((item: any) => (
                <Select.Option key={item.shopId} value={item.shopId} label={`${item.shopName} - ${item.zipCode}`}>
                  {item.shopName} - {item.zipCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button htmlType='submit' type="primary" style={{ width: '100%' }} icon={<SearchOutlined />} size='large'>
            Search
          </Button>
        </FormStyled>
      </Box>
      <AntTabs defaultActiveKey="summary-report" activeKey={activeKey} items={items} onChange={onChangeTab} />
    </PageStyled>
  )
}

export default ReportAdminPage;

const PageStyled = styled.div`
  padding: 1.5rem;
`;
const FormStyled = styled(Form)`
  display: flex;
  gap: 16px;
  .ant-form-item  {
    margin-bottom: 0;
    min-width: 15vw;
  }
`;
const AntTabs = styled(Tabs)`
  &.ant-tabs {
    border-radius: 5px;
    border: 1px solid #DADADA;
    background: #FFF;

    .ant-tabs-nav-list {
        margin-left: 16px;
        .ant-tabs-tab-btn {
          color: #1D2129;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 166.667% */
          text-transform: uppercase;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #008BFF;
        }
    }

    .ant-tabs-content-holder {
      padding: 0 24px;
      padding-bottom: 24px;
    }
  }  
`;