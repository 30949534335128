import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal, ModalProps } from 'antd';
import Button from '../../components/Button';
import Text from '../../components/Text';

interface IModalProps extends ModalProps {
  doneButtonTitle?: string;
  cancelButtonTitle?: string;
}

const Modal: React.FC<IModalProps> = ({
  title,
  footer,
  doneButtonTitle = 'Done',
  cancelButtonTitle = 'Cancel',
  ...props
}) => {
  const defaultHeader = (
    <Header>
      <Text variant="H7">{title}</Text>
    </Header>
  );

  const defaultFooter = (
    <Footer>
      <Button width="100%" onClick={props.onCancel}>
        {cancelButtonTitle}
      </Button>
      <Button width="100%" variant="PRIMARY" onClick={props.onOk}>
        {doneButtonTitle}
      </Button>
    </Footer>
  );

  return (
    <StyledModal
      {...props}
      footer={footer || defaultFooter}
      title={defaultHeader}>
      {props.children}
    </StyledModal>
  );
};

export default Modal;

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    padding: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 1.5rem 1.5rem;
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
`;
