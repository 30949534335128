import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PageStyled } from '../styles';
import Box from '../../components/Box';
import { Breadcrumb, Tabs, message } from 'antd';
import BreadcrumbItemStyled from '../../components/FormItem/BreadcrumbItemStyled';
import Button from '../../components/Button';
import accountApis from '../../services/accountApis';
import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SalonMarketingSmsCampPage } from './SalonMarketingSmsCamp';
import { SalonMarketingEmailCamp } from './SalonMarketingEmailCamp';
import { useDispatch } from 'react-redux';
import { setShopState } from '../../store/shop/actions';
import { SalonMarketingAuto } from './SalonMarketingAuto';

export const SalonMarketingPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [shopDate, setShopDate] = useState<any>(null);
    const [tabDefaulf, setTabDefaulf] = useState<any>('SMS_CAMPAIGN');
    const onChange = (key: string) => {
        setTabDefaulf(key);
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !shopDate) {
                    const response = await accountApis.getShopById(id);
                    if (response.status === 200) {
                        setShopDate(response.data.data);
                        dispatch(setShopState(response.data.data));
                    }
                } 
                const tabFromHash = window.location.hash.substring(1);
                if (tabFromHash) {
                    onChange(tabFromHash);
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    return (
        <PageStyled>
            <Box className="d-between">
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <BreadcrumbItemStyled>Salon {shopDate?.shopName}</BreadcrumbItemStyled>
                </Breadcrumb>
                <Box display="flex" gap={1}>
                    <Button onClick={() => navigate('/accounts')}>
                        <LeftOutlined />
                        Back
                    </Button>
                </Box>
            </Box>
            <ContentStyled>
                <Tabs
                    activeKey={tabDefaulf}
                    onChange={onChange}
                    items={[
                        {
                            label: 'SMS CAMPAIGN',
                            key: 'SMS_CAMPAIGN',
                            children: <SalonMarketingSmsCampPage />,
                        },
                        {
                            label: 'EMAIL CAMPAIGN',
                            key: 'EMAIL_CAMPAIGN',
                            children: <SalonMarketingEmailCamp />,
                        },
                        {
                            label: 'AUTO MARKETING',
                            key: 'AUTO_MARKETING',
                            children: <SalonMarketingAuto />,
                        }
                    ]}
                />
            </ContentStyled>
        </PageStyled>
    );
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 2rem;
    padding: 1rem;

    .tab-credit-card .ant-tabs-tab .ant-tabs-tab-btn {
        font-size: 18px;
    }

    .tab-credit-card .ant-tabs-nav {
        background: rgba(246, 247, 252, 1);
        width: 235px;
        min-height: 500px;
    }

    .tab-credit-card .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
    }

    .tab-credit-card .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    .custom-th .ant-table-thead >tr>th {
        background: rgba(227, 233, 237, 1)
    }

    .tab-marketing-child .ant-tabs-tab {
        min-width: 120px !important;
    }

    .tab-marketing-child .ant-tabs-tab .ant-tabs-tab-btn {
        line-height: 1;
    }

    .tab-marketing-child .ant-tabs-tab-active {
        background: rgba(255, 136, 144, 1);
        min-width: 150px;
        padding: 8px 24px;
        text-align: center;
    }

    .tab-marketing-child .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }

    && .btn-delete, .btn-edit {
        background: transparent;
        border: none;
        padding: 8px;
      }
    
    && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
    }

    && .coupon-label-status {
        border-radius: 2px;
        color: #1d2129;
        padding: 5px 20px 5px 20px;
    }
`;