import type { MenuProps } from 'antd';
import { Layout, Menu, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SidebarBlog from '../../components/Icon/icons/banlist/SidebarBlog';
import SidebarCustomer from '../../components/Icon/icons/banlist/SidebarCustomer';
import SidebarUser from '../../components/Icon/icons/banlist/SidebarUser';
import colorTheme from '../../constant/colors';
import { first } from 'lodash';
import { useThunkDispatch } from '../../store';
import banlistActions from '../../store/banlist/actions';
import { LoadingPageStyled } from '../LoadingPage';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/banlist/types';
import DetailBlog from './Blogs/DetailBlog';
import DetailBlogReports from './Blogs/DetailBlogReports';

const { Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon: <span className='anticon anticon-desktop ant-menu-item-icon'>{icon}</span>,
    children,
    label,
  } as MenuItem;
}

const BanlistLayout = () => {
  const location = useLocation();
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(banlistActions.blog.init());
    dispatch(banlistActions.user.init());
    dispatch(banlistActions.customer.init());
  }, []);

  const getDefaultSelected = () => {
    if (location.pathname === '/banlist') return 'blogs';
    return location.pathname?.replace('/banlist/', '');
  };
  const [activeKey, setActiveKey] = useState(getDefaultSelected);
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();

  const getColorWithKey = (key: 'blogs' | 'users' | 'customers') => {
    if (activeKey === key) return colorTheme.primary_active;
    return colorTheme.text_2;
  }

  const items: MenuItem[] = [
    getItem('Blogs', 'blogs', <SidebarBlog fill={getColorWithKey('blogs')} />),
    getItem('Users', 'users', <SidebarUser fill={getColorWithKey('users')} />),
    getItem('Customers', 'customers', <SidebarCustomer fill={getColorWithKey('customers')} />)
  ];

  const onSelect: MenuProps['onSelect'] = ({ key, keyPath }) => {
    const mainPath = first(keyPath);
    setActiveKey(key);
    if (mainPath === 'blogs') return navigate('/banlist');
    navigate(`${mainPath}`);
  };

  return (
    <LayoutStyled >
      <Sider
        collapsedWidth={80}
        width={200}
        collapsible collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={<>{collapsed ? <IconCollapse /> : <IconExpand />}</>}
      >
        <Menu
          theme="light"
          defaultSelectedKeys={[getDefaultSelected()]}
          activeKey={activeKey}
          onSelect={onSelect} mode="inline" items={items} />
      </Sider>
      <ContentStyled>
        <div className='scroll-layout-children'>
          <Content>
            <Outlet />
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Fozito Design ©2023 Created by Fozito Tech
          </Footer>
        </div>
      </ContentStyled>
      <Loading />
      <DetailBlog />
      <DetailBlogReports />
    </LayoutStyled>
  )
}

export default BanlistLayout;

const ContentStyled = styled(Layout)`
  position: relative;
  .scroll-layout-children {
    position: absolute;
    inset: 0;
    overflow: auto;
    padding: 24px;
    background: #EAEDED;
  }
  .ant-layout-footer {
    background: transparent;
  }
`;

const Loading = () => {
  const loading = useSelector((state: RootState) => state.banlist.loading);
  if (!loading) return null;
  return <LoadingPageStyled> <Spin /></LoadingPageStyled >
}

const LayoutStyled = styled(Layout)`
  .ant-layout-sider {
    background: #fff;
    padding: 0;
    .ant-menu-title-content {
      color: #566A7F;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-layout-sider-trigger {
      position: absolute;
      top: 16px;
      width: 26px !important;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      right: 0;
      transform: translateX(16px);
      background: transparent;
    }
    .ant-menu.ant-menu-root.ant-menu {
      padding: 18px 16px !important;
    }

    &.ant-layout-sider-collapsed {
      .ant-menu.ant-menu-root.ant-menu {
        padding: 18px 8px !important;
        .anticon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    
    .ant-menu-item  {
      margin-bottom: 8px;
      padding: 0;
      padding-left: 16px !important;
      padding-right: 16px !important;
      &.ant-menu-item-selected {
        border-radius: 6px;
        background: #FFE5E7;
        .ant-menu-title-content {
          color: #FF8890;
        }
      }
    }
  }
`;

const IconCollapse = () => (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><path d="M15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5ZM10.625 14.0625L17.1125 14.0625L14.9625 11.9125C14.6 11.55 14.6 10.95 14.9625 10.5875C15.15 10.4 15.3875 10.3125 15.625 10.3125C15.8625 10.3125 16.1 10.4 16.2875 10.5875L20.0375 14.3375C20.4 14.7 20.4 15.3 20.0375 15.6625L16.2875 19.4125C15.925 19.775 15.325 19.775 14.9625 19.4125C14.6 19.05 14.6 18.45 14.9625 18.0875L17.1125 15.9375L10.625 15.9375C10.1125 15.9375 9.6875 15.5125 9.6875 15C9.6875 14.4875 10.1125 14.0625 10.625 14.0625Z" fill="#8E97A8" /></svg>);
const IconExpand = () => (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><path d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM19.375 15.9375H12.8875L15.0375 18.0875C15.4 18.45 15.4 19.05 15.0375 19.4125C14.85 19.6 14.6125 19.6875 14.375 19.6875C14.1375 19.6875 13.9 19.6 13.7125 19.4125L9.9625 15.6625C9.6 15.3 9.6 14.7 9.9625 14.3375L13.7125 10.5875C14.075 10.225 14.675 10.225 15.0375 10.5875C15.4 10.95 15.4 11.55 15.0375 11.9125L12.8875 14.0625H19.375C19.8875 14.0625 20.3125 14.4875 20.3125 15C20.3125 15.5125 19.8875 15.9375 19.375 15.9375Z" fill="#8E97A8" /></svg>);
