import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

interface MyQuillComponentProps {
    onChange: (content: string) => void;
    value?: string;
}

const MyQuillComponent: React.FC<MyQuillComponentProps> = ({ onChange, value = '' }) => {
    const handleQuillChange = (content: string) => {
        onChange(content);
    };
    return (
        <ContentReactQuillStyled
            value={value}
            onChange={handleQuillChange}
            modules={{
                toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link', 'image'],
                    ['clean'],
                ],
            }}
            formats={[
                'header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet',
                'link', 'image',
            ]}
            placeholder="Type your text here..."
        />
    );
};

export default MyQuillComponent;

const ContentReactQuillStyled = styled(ReactQuill)`
    && .ql-editor {
        min-height: 450px;
    }
`;