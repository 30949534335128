import { Table } from "antd";
import styled from "styled-components";

export const TableEvenOdd = styled(Table)`
  thead.ant-table-thead th.ant-table-cell {
    border-bottom: 2px solid #FFF;
    background: #37A0BC;
    padding: 12px 16px;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    p {
      margin: 0;
    }
  }

  .ant-table-tbody {
    .ant-table-row:nth-child(even) .ant-table-cell {
      border-bottom: 1px solid #fff;
      background: #E2EFF0;
      padding: 6px 16px;
    }
    .ant-table-row:nth-child(odd) .ant-table-cell {
      border-bottom: 1px solid #fff;
      background: #D1E7EB;
      padding: 6px 16px;
    }
  }
`;