import { DatePicker, Form, Input } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import userSelectors from '../selectors';
import { FORMAT_DATE_REQUEST } from '../../../../store/banlist/constants';
import SearchInputIcon from '../../../../components/Icon/icons/SearchInputIcon';
const BlogsTableFilter = () => {
  const form = Form.useFormInstance();
  const params = userSelectors.userDetail.blogParams();

  useEffect(() => {
    form.setFieldsValue({
      startDate: params.startDate ? dayjs(params.startDate, FORMAT_DATE_REQUEST) : null,
      endDate: params.endDate ? dayjs(params.endDate, FORMAT_DATE_REQUEST) : null,
      keyword: params.keySearch || '',
    });
  }, [params]);

  return (
    <BlogsTableFilterStyled>
      <div>
        <FormStyled>
          <Form.Item noStyle name={'keyword'}>
            <Input
              prefix={<SearchInputIcon />}
              placeholder='Customer ban name or phone'
            />
          </Form.Item>
          <Form.Item noStyle name="startDate">
            <DatePicker
              style={{ width: '50%' }}
              format="MM-DD-YYYY"
              showTime={false}
              placeholder='Start date'
              inputReadOnly={true} />
          </Form.Item>
          <Form.Item noStyle name="endDate">
            <DatePicker
              style={{ width: '50%' }}
              format="MM-DD-YYYY"
              showTime={false}
              placeholder='End date'
              inputReadOnly={true}
            />
          </Form.Item>
          <button className='btn btn-search' type='submit'>
            <SearchInputIcon fill='#fff' />
            <span>Search</span>
          </button>
        </FormStyled>
      </div>
    </BlogsTableFilterStyled>
  )
}

export default BlogsTableFilter;

const BlogsTableFilterStyled = styled.div`
  button.btn {
    display: flex;
    height: 40px;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 24.75px */
    }
    &.btn-search {
      background: #008BFF;
      span {
        color: #FFF;
      }
    }
    &.btn-delete {
      border: 1px solid #86909C;
      background: #E3E9ED;
      span {
        color: #566A7F;
      }
    }
    &.btn-block-account {
      background: #FF7D00;
      span {
        color: #FFF;
        white-space: nowrap;
      }
    }
  }
`;
const FormStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .ant-input-affix-wrapper, .ant-picker {
    padding: 4px 11px;
    height: 40px;
  }
`;

const IconTrash = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00151 1.96973C8.00197 1.44801 8.47494 0.990046 8.99526 1.00083C11.0113 1.00036 13.0274 0.998015 15.0431 1.00223C15.5507 1.0102 15.996 1.46208 15.9979 1.96973C16.0031 2.64661 15.9974 3.32348 16.0007 4.00036C17.3249 3.99989 18.6492 4.00036 19.9729 3.99989C20.3081 3.99005 20.6409 4.15927 20.827 4.43864C21.0412 4.74708 21.0562 5.17833 20.8645 5.5013C20.6868 5.81489 20.3301 6.0113 19.9701 6.00005C14.6568 6.00005 9.34307 6.00005 4.02979 6.00005C3.73494 6.00801 3.44057 5.88145 3.24791 5.65786C3.00838 5.3902 2.93479 4.98895 3.06229 4.6538C3.17151 4.35098 3.43682 4.11098 3.74901 4.03223C3.90182 3.99051 4.06166 4.00083 4.21869 3.99989C5.47869 4.00036 6.73916 3.99989 7.99916 4.00036C8.00197 3.32348 7.99682 2.64661 8.00151 1.96973ZM9.99979 3.00051C10.0017 3.33426 9.99697 3.66755 10.0021 4.00083C11.3348 3.99895 12.667 4.00036 13.9992 4.00036C14.001 3.66708 13.9992 3.3338 14.0001 3.00098C12.6665 2.99958 11.3334 2.99958 9.99979 3.00051Z" fill="#8E9BA9" />
    <path d="M4.65801 7.05993C5.13051 6.87618 5.71129 7.12133 5.91051 7.5868C6.01036 7.79258 6.00239 8.02555 6.00004 8.24868C6.00004 11.8749 6.00004 15.5016 6.00004 19.1279C6.00661 19.5718 6.17817 20.0106 6.47161 20.3434C6.82036 20.7446 7.34161 20.9935 7.87504 20.9996C10.6093 21.0006 13.3435 20.9996 16.0777 21.0006C16.5821 21.0052 17.0836 20.7976 17.4389 20.4399C17.7849 20.0959 17.993 19.6159 17.9996 19.1274C18.0005 15.4229 17.9996 11.7179 18.0005 8.01337C17.9935 7.67727 18.1693 7.3454 18.4524 7.16399C18.7289 6.97837 19.1002 6.94883 19.4035 7.08524C19.725 7.22352 19.959 7.54321 19.9932 7.89149C20.0044 8.02649 19.9993 8.16196 20.0002 8.29743C20.0002 11.8285 19.9997 15.3596 20.0002 18.8912C20.0607 20.1259 19.5061 21.3723 18.534 22.1387C17.858 22.6862 16.9936 22.9946 16.1236 22.9998C13.3735 23.0002 10.6233 23.0002 7.8727 22.9998C6.91645 22.9946 5.97051 22.6173 5.26833 21.969C4.47754 21.2504 4.00317 20.1966 4.00036 19.127C3.99989 15.4224 3.99989 11.7174 4.00036 8.01243C3.98864 7.59711 4.26708 7.19821 4.65801 7.05993Z" fill="#8E9BA9" />
    <path d="M9.76916 9.02718C10.0823 8.95124 10.4292 9.03843 10.6673 9.2564C10.8848 9.44812 11.0067 9.7378 10.9996 10.0275C11.0001 12.3426 11.0001 14.6578 11.0001 16.973C11.0099 17.3198 10.8271 17.6644 10.5304 17.8462C10.199 18.0605 9.74009 18.0478 9.42134 17.8148C9.15134 17.6269 8.99009 17.2997 8.99994 16.9716C9.00088 14.6414 8.99806 12.3112 9.00134 9.98155C8.99994 9.53671 9.33603 9.1228 9.76916 9.02718Z" fill="#8E9BA9" />
    <path d="M13.757 9.03C14.0692 8.95031 14.418 9.03328 14.6584 9.24796C14.882 9.44015 15.0072 9.73453 15.0002 10.028C15.0002 12.3408 15.0002 14.6536 15.0002 16.9664C15.01 17.2852 14.8609 17.6039 14.605 17.7952C14.3495 17.993 13.9956 18.052 13.6895 17.9498C13.3703 17.8477 13.1125 17.5744 13.0323 17.2491C12.9906 17.0962 13.0009 16.9364 13 16.7798C13.0014 14.5139 12.9986 12.2484 13.0014 9.9825C13 9.54187 13.3291 9.13031 13.757 9.03Z" fill="#8E9BA9" />
  </svg>
);
