import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useThunkDispatch } from '../../../../store';
import banlistActions from '../../../../store/banlist/actions';
import UserInfo from './UserInfo';
import BlogsTable from './BlogsTable';
export const useBanListUserDetail = () => {
  const navigate = useNavigate();
  return (userId: string) => {
    navigate('/banlist/users/' + userId);
  };
};
const UserDetailPage = () => {
  const { id = '' } = useParams();
  const dispatch = useThunkDispatch();
  useEffect(() => { dispatch(banlistActions.user.detail(id)); }, []);

  return (
    <UserDetailStyled>
      <UserInfo />
      <BlogsTable />
    </UserDetailStyled>
  )
}

export default UserDetailPage;
const UserDetailStyled = styled.div``;
