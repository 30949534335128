import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FORMAT_DATE_REQUEST } from "../constants";
import { IApiGetBlogsParams, IBanlistBlogDetailResData, IBanlistBlogReportItem, IBanlistBlogsResData, IBlogDashboardResData } from "../types/blog";
import banlistApis from "../../../services/banlistApis";
import { RootState } from "../types";
import { convertParams } from "../pagingServices";
import { IBanlistCustomerItem } from "../types/customer";
const PREFIX_ACTION = 'BANLIST_BLOG_';
const blog = {
  init: createAsyncThunk(
    PREFIX_ACTION + 'INIT',
    (arg, thunkAPI) => {
      thunkAPI.dispatch(blog.fetchDashboard());

      const startDate = dayjs().startOf('month').format(FORMAT_DATE_REQUEST);
      const endDate = dayjs().endOf('month').format(FORMAT_DATE_REQUEST);
      thunkAPI.dispatch(blog.fetchList({
        page: 1, size: 10, startDate, endDate
      }));
      thunkAPI.dispatch(blog.searchCustomers(''));
    }),

  fetchDashboard: createAsyncThunk<IBlogDashboardResData>(
    PREFIX_ACTION + 'FETCH_DASHBOARD',
    async () => {
      const response = await banlistApis.blog.dashboard();
      return response?.data?.data;
    },
  ),
  fetchList: createAsyncThunk<IBanlistBlogsResData, IApiGetBlogsParams>(
    PREFIX_ACTION + 'FETCH_LIST_DATA',
    async (_params, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const storeParams = state.banlist.blog.params;
      const response = await banlistApis.blog.list(convertParams(storeParams, _params));
      return response?.data?.data;
    },
  ),
  searchCustomers: createAsyncThunk<IBanlistCustomerItem[], string>(
    PREFIX_ACTION + 'SEARCH_CUSTOMER_DATA',
    async (_params,) => {
      const response = await banlistApis.blog.searchCustomer(_params);
      return response?.data?.data;
    },
  ),
  detail: createAsyncThunk<IBanlistBlogDetailResData, string>(
    PREFIX_ACTION + 'GET_DETAIL_BLOG',
    async (blogId) => {
      const response = await banlistApis.blog.detail(blogId);
      return response?.data?.data;
    }
  ),
  getReports: createAsyncThunk<IBanlistBlogReportItem[], string>(
    PREFIX_ACTION + 'GET_DETAIL_BLOG_REPORTS',
    async (blogId) => {
      const response = await banlistApis.blog.reports(blogId);
      return response?.data?.data;
    }
  ),
};

export default blog;
