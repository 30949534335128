
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button as AntButton, Form, Input, Pagination, Row, Select, Switch, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Table, { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Box from '../../components/Box';
import Button from '../../components/Button';
import accountApis from '../../services/accountApis';
import { setLoading } from '../../store/loading/actions';
import { formatCurrency, formatNumber } from '../../utils/formatCurrency';
import { TableEvenOdd } from './styles';
export enum CampaignStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    SENT = 'SENT',
    CANCEL = 'CANCEL',
    ERROR = 'ERROR',
    DELETE = 'DELETE'
}
export const campaignStatus = [
    { label: 'Pending', value: CampaignStatus.PENDING },
    { label: 'Approved', value: CampaignStatus.APPROVED },
    { label: 'Sent', value: CampaignStatus.SENT },
    { label: 'Cancel', value: CampaignStatus.CANCEL },
];
export const SalonMarketingSmsCampPage = () => {
    const dispatch = useDispatch();
    const [formSearch] = Form.useForm();
    const { id } = useParams();
    const [lstSmsCampaign, setLstSmsCampaign] = useState<any>(null);
    const COUPON_ITEMS_PER_PAGE = 10;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const getDataSmsCampaign = async (shopId: any, status: string | null, type: string | null, title: string | null, page: number, size: number) => {
        try {
            const response = await accountApis.filterEmailCampaignByShopId(shopId, status ? status : null, type, title, page, size);
            if (response.status === 200) {
                setLstSmsCampaign(response.data.data.content);
                setTotal(response.data.data.totalElements);
            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    useEffect(() => {
        const initData = async () => {
            try {
                if (id && !lstSmsCampaign) {
                    dispatch(setLoading(true));
                    await getDataSmsCampaign(id, null, 'SMS', null, currentPage, COUPON_ITEMS_PER_PAGE);
                    dispatch(setLoading(false));
                }
            }
            catch (error: any) {
                message.error({ content: error.message as string, duration: 2 });
            }
        };
        initData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        dispatch(setLoading(true));
        try {
            setCurrentPage(pageNumber);
            const values = await formSearch.validateFields();
            await getDataSmsCampaign(id, values.status, 'SMS', values.title, pageNumber, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };
    const onFinishFilter = async (values: any) => {

        dispatch(setLoading(true));
        try {
            setCurrentPage(1);
            await getDataSmsCampaign(id, values.status, 'SMS', values.title, 1, COUPON_ITEMS_PER_PAGE);
        } catch (error) {
            message.error({ content: 'Page has been deleted', duration: 2 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleOnChangeStatus = (record: any) => async (value: string) => {
        const result = await Swal.fire({
            title: '',
            html: 'Are you sure want to change status?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (!result.isConfirmed) return;
        dispatch(setLoading(true));
        try {
            const response = await accountApis.updateCampaignStatus(id, value, record.id);
            if (response.status === 200) {
                message.success('Update successful');
                const detail = response?.data?.data;
                if (detail) {
                    const temp = [...lstSmsCampaign];
                    const idx = temp.findIndex(o => o.id === detail.id);
                    if (idx !== -1) {
                        temp[idx] = detail;
                        setLstSmsCampaign(temp);
                    }
                }

            } else {
                message.error({ content: response.data.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: <p>TITLE</p>,
            dataIndex: 'title',
            className: 'title',
            render: (title: string) => <a>{title}</a>,
        },
        {
            title: <p>SEND TIME</p>,
            dataIndex: 'sendTime',
            className: 'sendTime',
            render: (sendTime: string) =>
                <span>{sendTime}</span>
        },
        {
            title: 'PROMOTION',
            dataIndex: 'promotionValue',
            className: 'promotionValue',
            render: (promotionValue: string, promotionType: string) =>
                <span>{promotionType === 'PERCENT' ? `${promotionValue}%` : formatCurrency(promotionValue)}</span>,
        },
        {
            title: 'CUSTOMER',
            dataIndex: 'totalCustomer',
            className: 'totalCustomer',
            render: (totalCustomer: string) =>
                <span>{totalCustomer ? formatNumber(totalCustomer) : '--'}</span>,
        },
        {
            title: <p>TOTAL SMS</p>,
            dataIndex: 'totalSms',
            className: 'totalSms',
            render: (totalSms: string) =>
                <span>{totalSms ? formatNumber(totalSms) : '--'}</span>
        },
        {
            title: <p>TOTAL COST </p>,
            dataIndex: 'totalCost',
            className: 'totalCost',
            render: (value: number) =>
                <span>{formatCurrency(value)}</span>
        },
        {
            title: <p>CREATED DATE</p>,
            dataIndex: 'createDated',
            className: 'createDated',
            render: (createDated: string) =>
                <span>{createDated}</span>
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (status: string, record: any) =>
                <Fragment>
                    <CampaignStatusDropDown
                        value={status}
                        onChange={handleOnChangeStatus(record)}
                    />
                </Fragment>
        },
        {
            title: <p style={{ textAlign: 'center' }}>ACTION</p>,
            render: (record: any) =>
                <Fragment>
                    {
                        <Box display="flex" justifyContent="center" alignItems="center" gap={0.2} style={{ height: '20px' }}>
                            <Switch
                                key={record.id}
                                checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                                onChange={(checked) => handleSwitchChange(checked, record.id)}
                                checked={record.active}
                            />
                            <Button className='btn-delete' icon="delete" onClick={() => handleDelete(record.id, record.title)}></Button>
                            <Button className='btn-edit' icon="edit" onClick={() => handleEditCampaign(record.id, record.status)}></Button>
                        </Box>
                    }
                </Fragment>
        },
    ];

    const handleEditCampaign = async (smsId: string, ststus: string) => {
        navigate(`/marketing/${id}/sms-campaign/${smsId}/update`);
    };

    const handleDelete = async (smsId: string, title: string) => {
        const result = await Swal.fire({
            title: title,
            html: 'Are you sure want to delete SMS?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it',
            confirmButtonColor: '#d42b3c',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (result.isConfirmed) {
            dispatch(setLoading(true));
            try {
                const campIds: any[] = [
                    smsId
                ];
                const response = await accountApis.deleteMultipleCampaign(campIds);
                if (response.status === 200) {
                    message.success('Delete successful');
                    await onPageChanged(currentPage);
                } else {
                    message.error({ content: response.data.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                dispatch(setLoading(false));
            }
        }
    };

    const handleSwitchChange = async (checked: any, campaignId: any) => {
        const result = await Swal.fire({
            title: '',
            html: 'Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#6FABB6',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        });
        if (!result.isConfirmed) return;
        try {
            const res = await accountApis.inactiveCampaign(id, campaignId, checked);
            if (res.status === 200) {
                message.success('Update successful');
                const detail = res?.data?.data;
                if (detail) {
                    const temp = [...lstSmsCampaign];
                    const idx = temp.findIndex(o => o.id === detail.id);
                    if (idx !== -1) {
                        temp[idx] = detail;
                        setLstSmsCampaign(temp);
                    }
                }
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSearch = async () => {
        await onPageChanged(1);
    };

    return (
        <ContentStyled>
            <Form form={formSearch} onFinish={onFinishFilter}>
                <HeaderCampaignFilter extendButton={
                    <Button variant="PRIMARY" icon="add" onClick={() => navigate(`/marketing/${id}/sms-campaign/create`)}>
                        Create SMS Campaign
                    </Button>
                } />
            </Form>
            <TableCampaignContentStyled
                columns={columns}
                dataSource={lstSmsCampaign}
                rowKey="id"
                pagination={false} />

            <PagingStyled>
                <Pagination
                    current={currentPage}
                    total={totalItems}
                    pageSize={COUPON_ITEMS_PER_PAGE}
                    onChange={onPageChanged}
                    showSizeChanger={false}
                />
            </PagingStyled>
        </ContentStyled>
    );
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 0.5rem;
    padding: 1rem;
`;

export const TableCampaignContentStyled = styled(TableEvenOdd)`
  && .shopName {
    width: 15%;
  };

  && .endTime {
    width: 15%;
  }

  && .btn-edit {
    background: transparent;
    border: none;
    padding: 7px;
  }

  && .btn-edit:hover {
    background: var(--blue, #008bff) !important;
    border: none !important;
  }

  && .btn-edit svg path {
    fill: black;
  }

  && .btn-delete, .btn-edit {
    background: transparent;
    border: none;
    padding: 8px;
  }

    && .btn-delete:hover {
        background: rgba(245, 118, 127, 1) !important;
        border: none !important;
    }

  && .coupon-label-status {
    border-radius: 2px;
    color: #1d2129;
    padding: 5px 20px 5px 20px;
  }

  thead > tr > th.ant-table-cell {
    padding: 8px 16px;
    p {
        margin: 0;
    }
  } 
`;

const PagingStyled = styled(Content)`
  text-align: right;
  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
`;


export const CampaignStatusDropDown = ({ value, onChange }: { value: string, onChange: (value: string) => void; }) => {
    const handleOnChange = (_value: string) => {
        onChange(_value);
    };
    return (
        <DropDownStatus className={value}>
            <Select
                options={campaignStatus}
                value={value}
                onChange={handleOnChange}
                style={{ width: '130px' }}
                suffixIcon={dropdownIcon}
            />
        </DropDownStatus>
    )
};

const DropDownStatus = styled.div`
.ant-select .ant-select-selector {
    .ant-select-selection-item {
        color: #1D2129;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
    &.CANCEL {
        .ant-select .ant-select-selector {
            background: #FDCDC5;       
            border-color: #FDCDC5;
        }
    }
    &.SENT {
        .ant-select .ant-select-selector {
            background: #DBEFFF;       
            border-color: #DBEFFF;
        }
    }
    &.APPROVED {
        .ant-select .ant-select-selector {
            background: #b1ffc4;
            border-color: #b1ffc4;
        }
    }
    &.PENDING {
        .ant-select .ant-select-selector {
            background: #E5E5E5;       
            border-color: #E5E5E5;
        }
    }
`;
const dropdownIcon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clip-path="url(#clip0_7550_5115)">
        <path d="M6.5 9L12.5 15L18.5 9" stroke="#1D2129" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0_7550_5115">
            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
    </defs>
</svg>;


export const HeaderCampaignFilter = ({ extendButton }: { extendButton?: any }) => {
    return (
        <Row style={{ gap: '16px' }}>
            <Form.Item name="status" style={{ flex: 1 }}>
                <Select size='large' style={{ width: '100%' }}
                    showSearch optionFilterProp="label"
                    defaultValue={''}>
                    <Select.Option key={''} value=''>{'All Status'}</Select.Option>
                    <Select.Option key={'PENDING'} value={CampaignStatus.PENDING}>{'Pending'}</Select.Option>
                    <Select.Option key={'APPROVED'} value={CampaignStatus.APPROVED}>{'Approved'}</Select.Option>
                    <Select.Option key={'SENT'} value={CampaignStatus.SENT}>{'Sent'}</Select.Option>
                    <Select.Option key={'CANCEL'} value={CampaignStatus.CANCEL}>{'Cancel'}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="title" style={{ flex: 1 }}>
                <Input style={{ width: '100%' }} size='large' placeholder='Title' />
            </Form.Item>
            <Row>
                <AntButton htmlType='submit' type="primary" style={{ width: '100%' }} icon={<SearchOutlined />} size='large'>
                    Search
                </AntButton>
            </Row>
            <Row>
                {extendButton}
            </Row>
        </Row>
    )
};