
import { Card, Checkbox, Form, Input, InputRef, Row, Select, Switch } from 'antd';
import styled from 'styled-components';
import Box from '../../components/Box';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import accountApis, { IAccountConfigAutoUpdateItem } from '../../services/accountApis';
import { AxiosResponse } from 'axios';
import { set } from 'lodash';
import { IPromotionCouponItem } from './Promotion/types';
import CouponInput from './Promotion/AddNewCoupon';
import CurrencyInputPrecision from './Promotion/InputPercentDollar/CurrencyInputPrecision';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/loading/actions';


type FormItemValue = {
    id: string;
    active: boolean;
    message: string;
    value: string;
    couponId: string;
    isSendCoupon: boolean;
    type: string;
}

enum ItemType {
    BIRTHDAY = 'BIRTHDAY',
    NO_VISIT = 'NO_VISIT',
    BEST_SPEND_MONEY = 'BEST_SPEND_MONEY',
    BEST_LOYALTY = 'BEST_LOYALTY',
}

export interface IAccountConfigAutoItem {
    updatedDate: Date;
    updatedBy: null;
    createdDate: Date;
    createdBy: null;
    id: string;
    type: ItemType;
    shopId: string;
    active: boolean;
    message: null;
    couponId: null;
    value: null;
}

export const SalonMarketingAuto = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id: shopId = '' } = useParams();
    // @ts-ignore
    const [initialValue, setInitialValue] = useState<Record<ItemType, FormItemValue>>({});

    const initData = async () => {
        try {
            const res: AxiosResponse<{ data: IAccountConfigAutoItem[] }> = await accountApis.getAccountConfigsAuto(shopId);
            const resData = res?.data?.data;
            if (resData) {
                // @ts-ignore
                const objectValue: Record<ItemType, FormItemValue> = {};
                resData.map(o => set(objectValue, o.type, ({
                    id: o.id,
                    active: o.active,
                    message: o.message,
                    value: o.value,
                    couponId: o.couponId,
                    isSendCoupon: !!o.couponId,
                    type: o.type,
                })));
                form.setFieldsValue(objectValue);
                setInitialValue(objectValue);
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        initData();
    }, []);


    const handleFinish = async (values: any) => {
        const formValue = values as Record<ItemType, FormItemValue>;
        dispatch(setLoading(true));
        const body: IAccountConfigAutoUpdateItem[] = Object.values(formValue)?.map((o: FormItemValue) => ({
            couponId: o?.isSendCoupon ? o.couponId : null,
            active: o?.active || false,
            id: o?.id,
            message: o.message,
            type: o.type,
            value: parseFloat(o.value),
        }));

        try {
            const res: AxiosResponse<{ data: boolean }> = await accountApis.updateAccountConfigsAuto(shopId, body);
            if (res.data.data) {
                initData();
            }
        } catch (error) {
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <ContentStyled>
            <Cards form={form} onFinish={handleFinish}>
                <Form.Item noStyle name={ItemType.BIRTHDAY}></Form.Item>
                <CardStyled title="BIRTHDAY WISHES" extra={<Form.Item name={[ItemType.BIRTHDAY, 'active']} noStyle><Switch /></Form.Item>}>
                    <Box mb={1} display='flex' flexDirection='row' gap={1}>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <Box display='flex'>
                                <p className="form-label">Send In Advance</p>
                            </Box>
                            <Form.Item noStyle name={[ItemType.BIRTHDAY, 'value']}>
                                <Input onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                    placeholder='0 day'
                                />
                            </Form.Item>
                        </Box>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <CouponInputData shopId={shopId} template={ItemType.BIRTHDAY} />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.25}>
                        <Box display='flex'>
                            <p className="form-label">Message</p>
                        </Box>
                        <Form.Item noStyle name={[ItemType.BIRTHDAY, 'message']}>
                            <AreaInput />
                        </Form.Item>
                    </Box>
                </CardStyled>

                <Form.Item noStyle name={ItemType.NO_VISIT}></Form.Item>
                <CardStyled title="CUSTOMER who have not returned" extra={<Form.Item name={[ItemType.NO_VISIT, 'active']} noStyle><Switch /></Form.Item>}>
                    <Box mb={1} display='flex' flexDirection='row' gap={1}>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <Box display='flex'>
                                <p className="form-label">The Days have not returned Yet</p>
                            </Box>
                            <Form.Item noStyle name={[ItemType.NO_VISIT, 'value']}>
                                <Input onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                    placeholder='0 day'
                                />
                            </Form.Item>
                        </Box>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <CouponInputData shopId={shopId} template={ItemType.NO_VISIT} />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.25}>
                        <Box display='flex'>
                            <p className="form-label">Message</p>
                        </Box>
                        <Form.Item noStyle name={[ItemType.NO_VISIT, 'message']}>
                            <AreaInput />
                        </Form.Item>
                    </Box>
                </CardStyled>

                <Form.Item noStyle name={ItemType.BEST_SPEND_MONEY}></Form.Item>
                <CardStyled title="Amount spent" extra={<Form.Item name={[ItemType.BEST_SPEND_MONEY, 'active']} noStyle><Switch /></Form.Item>}>
                    <Box mb={1} display='flex' flexDirection='row' gap={1}>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <Box display='flex'>
                                <p className="form-label">Amount Spent</p>
                            </Box>
                            <Form.Item noStyle name={[ItemType.BEST_SPEND_MONEY, 'value']}>
                                <CurrencyInputPrecision height={'46px'} />
                            </Form.Item>
                        </Box>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <CouponInputData shopId={shopId} template={ItemType.BEST_SPEND_MONEY} />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.25}>
                        <Box display='flex'>
                            <p className="form-label">Message</p>
                        </Box>
                        <Form.Item noStyle name={[ItemType.BEST_SPEND_MONEY, 'message']}>
                            <AreaInput />
                        </Form.Item>
                    </Box>
                </CardStyled>

                <Form.Item noStyle name={ItemType.BEST_LOYALTY}></Form.Item>
                <CardStyled title="Number of points achieved" extra={<Form.Item name={[ItemType.BEST_LOYALTY, 'active']} noStyle><Switch /></Form.Item>}>
                    <Box mb={1} display='flex' flexDirection='row' gap={1}>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <Box display='flex'>
                                <p className="form-label">Number of points achieved</p>
                            </Box>
                            <Form.Item noStyle name={[ItemType.BEST_LOYALTY, 'value']}>
                                <Input onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                    placeholder='0 points'
                                />
                            </Form.Item>
                        </Box>
                        <Box flex={1} display='flex' flexDirection='column' gap={0.25}>
                            <CouponInputData shopId={shopId} template={ItemType.BEST_LOYALTY} />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap={0.25}>
                        <Box display='flex'>
                            <p className="form-label">Message</p>
                        </Box>
                        <Form.Item noStyle name={[ItemType.BEST_LOYALTY, 'message']}>
                            <AreaInput />
                        </Form.Item>
                    </Box>
                </CardStyled>
            </Cards>
            <Box style={{ textAlign: 'right', marginTop: 16 }} className='footer-actions'>
                <button type='button' className='btn-reset' onClick={() => form.setFieldsValue(initialValue)}>
                    <span>Reset</span>
                </button>
                <button type='button' className='btn-submit' onClick={() => form.submit()}>
                    <span>Save</span>
                </button>
            </Box>
        </ContentStyled>
    )
}

const ContentStyled = styled.div`
    background: rgba(255, 255, 255, 1);
    margin-top: 0.5rem;
    padding: 1rem;

    .footer-actions {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: flex-end;
        button {
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
        .btn-reset {
            display: flex;
            width: 170px;
            height: 40px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 2px;
            border: 1px solid #86909C;
            background: #E3E9ED;
            span {
                color: #566A7F;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .btn-submit {
            display: flex;
            height: 40px;
            width: 170px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            border: 1px solid #008BFF;
            background: #008BFF;
            span {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`;

const Cards = styled(Form)`
    display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
`;

const CardStyled = styled(Card)`
    &.ant-card {
        border-radius: 5px;
        border: 1px solid #DADADA;
        background: #FFF;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10);
    }
    .ant-card-head {
        background: #E5E5E5;
        border-radius: 0;
    }
    .ant-card-body {
        padding: 16px;
    }
    .ant-card-head-title {
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }
    p.form-label {
        margin: 0;
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */    
    }

    .ant-input {
        display: flex;
        height: 46px;
        padding: 17px 16px;
        align-items: center;
        gap: 354px;
        align-self: stretch;
        border-radius: 5px;
        border: 1px solid #DADADA;
        background: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #1D2129;
    }
    textarea.ant-input {
        display: flex;
        height: 137px;
        padding: 5px 16px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 2px;
        border: 1px solid #DADADA;
        background: #F6F7FC;
    }
    .ant-select {
    flex: 1;
        .ant-select-selector {
            display: flex;
            height: 46px;
            padding: 5px 16px;
            align-items: center;
            align-self: stretch;
            border-radius: 5px;
            border: 1px solid #DADADA;
            background: #FFF;
            .ant-select-selection-search-input {
                height: 100%;
            }
        }

        .ant-select-arrow {
        }
    
    }
`;
const suggests = [
    { label: 'Name', value: '${Name}' },
    { label: 'Business name', value: '${businessName}' },
    { label: 'Service name', value: '${serviceName}' },
    { label: 'Link coupon', value: '${linkCoupon}' },
    { label: 'Birthday', value: '${birthday}' },
];
type SuggestInputsProps = {
    onChooseSuggest?: (item: string) => void;
};
const SuggestInputs = ({ onChooseSuggest = () => undefined }: SuggestInputsProps) => {
    return (
        <SuggestInputStyled display='flex' alignItems='center' style={{ gap: 4 }}>
            {suggests.map(o => <button type='button' onClick={() => onChooseSuggest(o.value)} className="suggest-input-item"><span>{o.label}</span></button>)}
        </SuggestInputStyled>
    );
};
const SuggestInputStyled = styled(Box)`
    .suggest-input-item {
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 100px;
        background: #C5DDF8;
        outline: none;
        border: none;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        span {
            color: #1D2129;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
    }
`;
type AreaInputProps = {
    value?: string;
    onChange?: (value: string) => void;
};
const AreaInput = ({ value = '', onChange = () => undefined }: AreaInputProps) => {
    const inputRef = useRef<InputRef>(null);

    const onChooseSuggest = (suggest: string) => {
        // @ts-ignore
        const selectionStart = inputRef.current?.resizableTextArea?.textArea?.selectionStart;
        inputRef.current?.focus();
        const arr = value?.split('') || [];
        arr.splice(selectionStart, 0, suggest);
        onChange(arr.join(''));
    };

    return (
        <>
            <Input.TextArea ref={inputRef} value={value} onChange={e => onChange(e.target.value)} placeholder='Please enter...' />
            <SuggestInputs onChooseSuggest={onChooseSuggest} />
        </>
    )
};
type CouponInputDataProp = {
    shopId: string;
    template: ItemType;
    name?: string;
};
const CouponInputData = ({ template, shopId, name = '' }: CouponInputDataProp) => {
    const form = Form.useFormInstance();
    const [coupons, setCoupons] = useState<IPromotionCouponItem[]>([]);
    const getCouponsByType = useCallback(async () => {
        try {
            const res: AxiosResponse<{ data: IPromotionCouponItem[] }> = await accountApis.getCouponsByType(shopId, template);
            if (res.data.data) {
                setCoupons(res.data.data);
            }
        } catch (error) { }
    }, [template]);
    useEffect(() => {
        getCouponsByType();
    }, [template]);

    return (
        <>
            <Box display='flex'>
                <Form.Item noStyle name={[template, 'isSendCoupon']} valuePropName='checked'>
                    <Checkbox><p className="form-label">Send Coupon</p></Checkbox>
                </Form.Item>
            </Box>
            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                    const isSendCoupon = getFieldValue([template, 'isSendCoupon']);
                    return (
                        <Form.Item noStyle name={[template, 'couponId']}>
                            <CouponInput disabled={!isSendCoupon} coupons={coupons} refreshList={getCouponsByType} templateType={template} />
                        </Form.Item>
                    )
                }}
            </Form.Item>

        </>
    )
};