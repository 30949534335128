import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { BanlistState } from "../types";
import actions from '../actions';
import { pagingReducerActions } from "../pagingServices";

const customerCaseHandler = (builder: ActionReducerMapBuilder<BanlistState>) => {
  builder
    .addCase(actions.customer.fetchBlogsByCustomer.pending, (state, action) => pagingReducerActions.pending(state.customerDetail.blog, action))
    .addCase(actions.customer.fetchBlogsByCustomer.fulfilled, (state, action) => pagingReducerActions.fulfilled(state.customerDetail.blog, action))
    .addCase(actions.customer.fetchBlogsByCustomer.rejected, (state) => pagingReducerActions.rejected(state.customerDetail.blog));

  builder
    .addCase(actions.customer.fetchList.pending, (state, action) => pagingReducerActions.pending(state.customer, action))
    .addCase(actions.customer.fetchList.fulfilled, (state, action) => pagingReducerActions.fulfilled(state.customer, action))
    .addCase(actions.customer.fetchList.rejected, (state) => pagingReducerActions.rejected(state.customer));
};

export default customerCaseHandler;
